<div class="card-container">
    <div class="card-main">

        <div>RAP ALMANAC: THE HIPHOP WORD COUNT</div>
        <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
        <div class="card" *ngIf="isLoading === false">
            <img [src]="cardItem.path !== null ? cardItem.path : 'assets/images/favicon.png'" class="logo">
            <h3>{{ cardItem.searchWord }}</h3>
            <div *ngIf="cardItem.chartType === 'Bar chart'">
                <canvasjs-chart [options]="getChartOptions(cardItem)"
                    [styles]="{ width: '100%', height:'300px'}"></canvasjs-chart>
            </div>
            <div *ngIf="cardItem.chartType === 'Timeline'">
                <canvasjs-chart [options]="getChartOptionsTimeLine(cardItem)"
                    [styles]="{ width: '80%', height:'300px'}"></canvasjs-chart>
            </div>


            <div class="chart-container" *ngIf="cardItem.chartType === 'Nearest Neighbour'">
                <div class="controls-section">
                    <select id="centerSelect" [(ngModel)]="centerType" (change)="updateCenterType()">
                        <option value="centroid">Centroid</option>
                        <option value="query">Query Word</option>
                    </select>
                </div>
                <div class="chart-container">
                    <div #chartContainer class="chart" style="overflow: hidden; position: relative;"></div>
                </div>

            </div>


        </div>

    </div>

</div>