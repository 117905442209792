<div class="container">

  <div class="plan-container">
    <img src="../assets/images/arrow_left.svg" style="margin-bottom: 20px;" (click)="goBack()" />
  </div>

  <div class="header">
    <pre>{{title}}</pre>
    <p>{{subTitle}}</p>
  </div>

  <div class="plan-container">
    <ng-container *ngFor="let plan of choosePlans;  let i = index">
      <div class="option1" (click)="selectPlan(plan)" [class.selected]="plan === selectedPlan">
        <div>
          <h3>{{ plan.price }}
            <h6 *ngIf="i !== 0">/month</h6>
          </h3>
        </div>
        <div>
          {{ plan.option }}
          <ng-container *ngFor="let op of plan.options">
            <div class="icon-text-container ">
              <i class="bi bi-check-circle"></i>
              <span>{{ op }}</span>
            </div>
          </ng-container>
        </div>
      </div>

    </ng-container>
  </div>
  <div class="continue-button">
    <!-- <button (click)="onContinue()">Continue</button> -->
    <div class="continue" (click)="onContinue()">
      <span *ngIf="isLoading && !isSkip" class="spinner-border spinner-border-sm" role="status"
        aria-hidden="true"></span>
      <span style="text-align: center;">{{continueTitle}}</span>
    </div>
  </div>

  <div class="continue-button">
    <div class="skip" (click)="onSkip()">
      <!-- <button class="skip" (click)="onSkip()">Skip</button> -->
      <span *ngIf="isLoading && isSkip" class="spinner-border spinner-border-sm" role="status"
        aria-hidden="true"></span>
      <span style="text-align: center;">{{skipTitle}}</span>
    </div>

  </div>

</div>