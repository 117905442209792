import { Component, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ArtistsService } from './artists.service';
import { ArtistRow, ArtistsData } from '../models/artists/artists.model';
import { BaseTemplateService } from '../base-template/base-template.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from '../shared/component/delete-modal/delete-modal.component';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '../app.service';

@Component({
  selector: 'rap-artists',
  templateUrl: './artists.component.html',
  styleUrls: ['./artists.component.scss']
})
export class ArtistsComponent {
  isLoading: boolean = false;
  artists: ArtistsData;
  artistsData: ArtistRow[] = [];
  page = 1;
  pageSize = 10;
  filters: any = {
    search: ""
  };
  pagesCount = 0;
  title = "Database";
  isContributions: boolean = false;
  constructor(private router: Router,
    private artistsService: ArtistsService,
    private route: ActivatedRoute,
    private baseTemplateService: BaseTemplateService
    , private modalService: NgbModal,
    private toastr: ToastrService,
    private loaderService: AppService) {
    this.loaderService.loading$.subscribe(isLoading => {
      // if (i)
      console.log("isLoading")
      console.log(isLoading)
      this.isLoading = isLoading;
    });


  }

  ngOnInit(): void {

    this.isLoading = true;
    this.route.data.subscribe(data => {
      this.isContributions = data["isContributions"];
      console.log(this.isContributions);
      if (this.isContributions) {
        this.title = "Contributions";
      }
    })
    this.getAllArtists("", this.page);
    console.log("ffff");
    this.baseTemplateService.showHideSearch(false);

  }

  getAllArtists(search: string, page: number) {
    this.artistsService.getAllArtists(search, page, this.isContributions).subscribe((result) => {
      this.isLoading = false;
      this.artists = result;
      this.artistsData = this.artists.rows;
      if (this.page == 1)
        this.pagesCount = result.count;

    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['artists']) {
    }
  }

  get paginatedArtists(): ArtistRow[] {
    const start = (this.page - 1) * this.pageSize;
    const end = start + this.pageSize;
    // console.log(start)
    // console.log(end)
    // console.log(this.songsData.slice(start, end))
    return this.artistsData;
  }

  onPageChange(page: number) {
    this.page = page;
    console.log(this.page)
    this.isLoading = true;
    this.getAllArtists(this.filters.search, this.page);
  }

  createArtist() {
    var url = "/dashboard/database/artists/add";
    if (this.isContributions) {
      url = "/dashboard/contributions/artists/add";
    }
    this.router.navigateByUrl(url, { skipLocationChange: true, state: { isContributions: this.isContributions } }
    );
  }

  getSearchResults() {
    this.isLoading = true;
    this.getAllArtists(this.filters.search, this.page);
  }

  editArtist(artist: any) {
    // var url = "/dashboard/database/artists/add";
    // if (this.isContributions) {
    //   url = "/dashboard/contributions/artists/" + artist.artistid + "/edit";
    // }
    // this.router.navigateByUrl(url, { skipLocationChange: true, state: { isContributions: this.isContributions, id: artist.artistid } }
    // );

    this.toastr.success('Feature Coming Soon!!!', '', {
      positionClass: 'toast-top-center',
      closeButton: false,
    });
  }

  deleteArtist(id: string) {
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.itemId = id;
    modalRef.result
      .then((id) => {
        this.isLoading = true;
        this.artistsService.deleteArtist(id).subscribe((result) => {
          this.isLoading = false;

        });
      })
      .catch((error) => {
        this.isLoading = false;
      });
  }

  onClearSearch() {
    this.filters.search = "";
    this.getSearchResults()
  }
}
