import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    const isLoggedIn = this.authService.isLoggedIn();
    if (state.url === '/signin' && isLoggedIn) {
      // If trying to access login page while authenticated, redirect to dashboard
      this.router.navigate(['/dashboard']);
      return false;
    } else if (state.url !== '/signin' && !isLoggedIn) {
      // If trying to access any other page while not authenticated, redirect to login
      this.router.navigate(['/signin']);
      return false;
    }

    return true;
  }
}
