import { Component, Input } from '@angular/core';
import { INavbarData } from './helper';
import { state, style, transition, trigger, animate } from '@angular/animations';

@Component({
  selector: 'rap-sublevel-menu',
  template: `
    <ul *ngIf="expanded && data.items && data.items.length > 0"
    [@submenu]="expanded ?
      {value: 'visible', params: {transitionParams: '400ms cubic-bezier(0.86, 0, 0.7, 1)', height: '*'}} :
      {value: 'hidden', params: {transitionParams: '400ms cubic-bezier(0.86, 0, 0.7, 1)', height: '0'}}
"
        class="sublevel-nav">

      <li *ngFor="let item of data.items" class="sublevel-nav-item">
        <a class="sublevel-nav-link" (click)="handleClick(item)" *ngIf="item.items && item.items.length > 0" >
          <i class="sublevel-link-icon fa fa-circle"></i>
          <span class="sublevel-link-text" *ngIf="collapsed">{{item.label}}</span>
          <i *ngIf="item.items && collapsed" class="menu-collapse-icon"
            [ngClass]="!item.expanded ? 'bi bi-chevron-right':'bi bi-chevron-down'"></i>
        </a>
        <a class="sublevel-nav-link"
          *ngIf="!item.items || (item.items && item.items.length === 0)"
           [routerLink]="[item.routeLink]"
           routerLinkActive="active-sublevel"
           [routerLinkActiveOptions]="{exact: true}"
        >
          <i class="sublevel-link-icon fa fa-circle"></i>
          <span class="sublevel-link-text" *ngIf="collapsed">{{item.label}}</span>
        </a>

        <div *ngIf="item.items && item.items.length > 0">
          <rap-sublevel-menu

            [collapsed]="collapsed"
            [multiple]="multiple"
            [expanded]="item.expanded"
          >
          </rap-sublevel-menu
>

        </div>
      </li>
    </ul>
  `,
  styleUrls: ['./nav-bar.component.scss'],
  animations: [
    trigger('submenu', [
      state('hidden', style({ height: 0, opacity: "hidden" })),
      state('visible', style({ height: '*' })),
      transition('visible<=>hidden', [style({ overflow: 'hidden' }), animate('{{transitionParams}}')]),
      transition('void => *', animate(0))
    ])
  ]
})
export class SublevelMenuComponent {

  @Input() data: INavbarData = {
    routeLink: '',
    icon: '',
    label: '',
    items: [],
    target: '',
    enabled: true
  }

  @Input() collapsed: boolean = false;
  @Input() animate: boolean | undefined;
  @Input() expanded: boolean | undefined;
  @Input() multiple: boolean = false;

  constructor() {
  }

  handleClick(item: any) {
    console.log(item, "submenu");
    if (!this.multiple) {
      if (this.data.items && this.data.items.length > 0) {
        for (let modelItem of this.data.items) {
          if (item !== modelItem && modelItem.expanded) {
            modelItem.expanded = false;
          }
        }
      }
    }
    item.expanded = !item.expanded;
  }
}
