<app-nav-bar (onToggleSideNav)="onToggleSideNav($event)"></app-nav-bar>
<nav class="navbar navbar-expand-lg navbar-light bg-white body-header" [ngClass]="getBodyClass()">
  <div class="container-fluid">

    <div class="search" *ngIf="showSearch">
      <img src="../assets/images/search.svg" class="search-icon" />
      <input type="text" placeholder="Search by song or album title, lyrics or artist name" class="search-input"
        [(ngModel)]="searchTerm" (keydown.enter)="onSearch()">
      <button class="search-clear-button" (click)="onClear()" *ngIf="searchTerm">
        CLEAR
      </button>
      <!-- <i *ngIf="searchTerm" class="bi bi-x-circle-fill" (click)="onSearch()"
        style="align-self: center; margin-right: none;"></i> -->
    </div>
    <button class="search-button" (click)="onSearch()" *ngIf="showSearch">
      Search
    </button>

    <!-- <div>
      <input type="text" placeholder="Search..." class="search-box" />
      <button class="search-button">
        Search
      </button>
    </div> -->

    <!-- <div>
      <i class="bi bi-person-rolodex"></i>
      <input type="text" class="borderless-input" />

    </div> -->

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">

      </ul>
      <div class="profile-section">

        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
          aria-expanded="false">
          <!-- <span class="header-avatar rounded-circle">
            <i class="sidenav-link-icon" class="bi bi-person-bounding-box"></i>
          </span> -->

          <ng-container *ngIf="avatar; else showInitials">
            <img [src]="avatar" alt="Avatar" class="header-avatar rounded-circle" style="width: 50px; height: 50px;" />
          </ng-container>
          <ng-template #showInitials>
            <span class="header-text rounded-circle">
              {{ firstName ? firstName[0].toUpperCase() : '' }}
            </span>
          </ng-template>

        </a>
        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
          <li><a class="dropdown-item" (click)="onProfile()">Profile</a></li>
          <li>
            <hr class="dropdown-divider">
          </li>
          <li><a class="dropdown-item" (click)="logoutUser()">Log out</a></li>
        </ul>

      </div>

    </div>
  </div>
</nav>

<div class="body" [ngClass]="getBodyClass()">
  <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
  <router-outlet></router-outlet>
</div>