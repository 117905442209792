import { FormGroup } from '@angular/forms';
import { createAction, props } from '@ngrx/store';
import { LoginCeredentials } from 'src/app/models/login-ceredentials.model';
import { UserData } from 'src/app/models/user-data.model';

export const authorize = createAction(
  '[User] AUTHORIZE',
  props<{ ceredentials: LoginCeredentials }>(),
);

export const authorizeSuccess = createAction('[User] AUTHORIZE_SUCCESS');

export const authorizeFailure = createAction(
  '[User] AUTHORIZE_FAILURE',
  props<{ error: string }>(),
);
export const getOnBoardingDetails = createAction(
  '[User] GET_ONBOARDING_DETAILS',
  props<{ id: string }>(),
);

export const getOnBoardingSuccess = createAction(
  '[User] GET_ONBOARDING_DETAILS_SUCCESS',
  props<{ data: any }>(),
);

export const getUserDetails = createAction('[User] GET_USER_DETAILS');

export const getUserDetailsSuccess = createAction(
  '[User] GET_USER_DETAILS_SUCCESS',
  props<{ data: UserData }>(),
);

export const sendPwdResetCode = createAction(
  '[User] SEND_PWD_RESET_CODE',
  props<{ email: string }>(),
);

export const sendPwdResetCodeSuccess = createAction(
  '[User] SEND_PWD_RESET_CODE_SUCCESS',
);

export const sendPwdResetCodeFailure = createAction(
  '[User] SEND_PWD_RESET_CODE_FAILURE',
  props<{ error: string }>(),
);

export const resetPassword = createAction(
  '[User] RESET_PASSWORD',
  props<{ newPassword: any }>(),
);

export const resetPasswordSuccess = createAction(
  '[User] RESET_PASSWORD_SUCCESS',
);

export const userAuthorizeError = createAction('[User] USER_AUTHORIZE_ERROR');

export const createNewAccount = createAction(
  '[User] CREATE_NEW_ACCOUNT',
  props<{ newAccountFormGroup: FormGroup }>(),
);

export const createNewAccountSuccess = createAction(
  '[User] CREATE_NEW_ACCOUNT_SUCCESS',
);

export const verifyEmail = createAction(
  '[User] VERIFY_EMAIL',
  props<{ code: string }>(),
);

export const verifyEmailSuccess = createAction('[User] VERIFY_EMAIL_SUCCESS');

export const resetErrorMsg = createAction('[User] RESET_ERROR_MSG');
