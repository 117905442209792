import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AnalyticsEffects } from './analytics.effects';
import { analyticsReducer } from './analytics.reducer';
import { AnalyticsSelectors } from './analytics.selectors';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature(AnalyticsEffects),
    StoreModule.forFeature('analytics', analyticsReducer),
  ],
  exports: [StoreModule, EffectsModule],
  providers: [AnalyticsSelectors],
})
export class AnalyticsStoreModule {}
