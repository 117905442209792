import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'htmlToText'
})
export class HtmlToTextPipe implements PipeTransform {

  transform(value: string): string {
    const element = document.createElement('div');
    element.innerHTML = value;
    return element.innerText || element.textContent || '';
  }

}
