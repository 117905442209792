import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Roster } from '../models/rosters/rosters.model';
import { DeleteModalComponent } from '../shared/component/delete-modal/delete-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseTemplateService } from '../base-template/base-template.service';
import { AppService } from '../app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'rap-rosters-ui',
  templateUrl: './rosters.component.html',
  styleUrls: ['./rosters.component.scss'],
})
export class RostersComponent {
  @Input() rosters: Roster[];
  @Input() rostersCount: number;
  @Output() deleteAnalyticsEmitter = new EventEmitter<string>();
  @Output() manageStudentEmitter = new EventEmitter<Roster>();
  @Output() searchEmitter = new EventEmitter<{ search: string, page: number, sortBy: string, order: string }>();
  page = 1;
  pageSize = 10;
  isLoading = false;
  filters: any = {
    search: ""
  };
  sortDirection: 'asc' | 'desc' | '' = '';
  sortedColumn: string = '';
  constructor(private modalService: NgbModal, private baseTemplateService: BaseTemplateService, private loaderService: AppService, private router: Router) {
    this.loaderService.loading$.subscribe(isLoading => {

      this.isLoading = isLoading;
    });
  }

  ngOnInit(): void {
    console.log(this.rostersCount)
    this.isLoading = true;
    this.baseTemplateService.showHideSearch(false);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['rosters'] && this.rosters) {
      this.isLoading = false;
      console.log(this.rostersCount)
    } else {
      this.isLoading = false;
      this.rosters = [];
    }
    // console.log(this.rosters.length)
  }


  get paginatedRosters(): Roster[] {
    const start = (this.page - 1) * this.pageSize;
    const end = start + this.pageSize;
    return this.rosters;
  }

  onSort({ column, direction }: { column: string, direction: 'asc' | 'desc' }) {
    console.log(column)
    if (this.sortedColumn === column) {
      // Toggle sort direction
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      // New column sorting
      this.sortedColumn = column;
      this.sortDirection = 'asc';
    }
    this.updateSortData()
  }

  sortBy({ column, direction }: { column: string, direction: 'asc' | 'desc' }) {
    this.sortedColumn = column;
    this.sortDirection = direction;
    this.updateSortData()
  }

  updateSortData() {
    this.searchEmitter.emit({
      search: this.filters.search,
      page: this.page,
      sortBy: this.sortedColumn,
      order: this.sortDirection
    });
  }

  onPageChange(page: number) {
    this.page = page;
    this.isLoading = true;
    console.log(this.page)
    this.searchEmitter.emit({
      search: this.filters.search,
      page: page,
      sortBy: this.sortedColumn,
      order: this.sortDirection
    });
  }

  deleteRoster(rosterId: string) {
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.itemId = rosterId;
    modalRef.result
      .then((id) => {
        this.deleteAnalyticsEmitter.emit(id);
      })
      .catch((error) => console.log(error));
  }

  manageStudent(roster: Roster) {
    this.manageStudentEmitter.emit(roster);
  }

  onRosters() {

  }

  getSearchResults(searchValue: string) {
    this.filters.search = searchValue;
    this.searchEmitter.emit({
      search: this.filters.search,
      page: this.page,
      sortBy: this.sortedColumn,
      order: this.sortDirection
    });

  }

  ngOnDestroy(): void {
    this.rosters = [];
    this.isLoading = false;
    this.loaderService.hide();
  }

  onClearSearch() {
    this.filters.search = "";
    this.getSearchResults("")
  }

  onAddNew() {
    this.router.navigate(['/dashboard/rosters/new']);
  }
}
