<div class="sign-in">
  <div class="left">
    <rap-spinner class="loader-view" *ngIf="isLoading" [showSpinner]="false"></rap-spinner>
    <div class="container">
      <h1 class="title">{{ title }}</h1>
      <p class="sub-title">{{ subTitle }}</p>
      <form [formGroup]="createAccountForm" (ngSubmit)="createAccount()">
        <div class="error-container" *ngIf="error">
          {{ error }}
        </div>

        <div class="form-group">
          <label for="email">Email address</label>
          <div class="input-container">
            <input type="email" class="form-control" [class.submitted]="submitted" formControlName="email"
              placeholder="Enter an email address" />
            <div class="auth-icons">
              <i *ngIf="submitted && hasEmailError()" class="bi bi-x-circle-fill" style="margin-left: 10px;"
                (click)="clearInput('email')"></i>
              <i class="bi bi-envelope-fill" style="margin-left: 10px;"></i>
              <i *ngIf="submitted && !hasEmailError()" class="bi bi-check-circle-fill"
                style="margin-left: 10px; color: green;"></i>
            </div>
          </div>
          <div *ngIf="submitted && f['email'].errors" class="rap-error-text">
            <span *ngIf="f['email'].errors?.['required']">Email is required</span>
            <span *ngIf="f['email'].errors?.['email']">Enter valid email</span>
          </div>
        </div>

        <div class="form-group">
          <label for="password">Password</label>
          <app-password-input [submitted]="submitted" [formControl]="f['password']"
            [placeHolder]="'Enter a password'"></app-password-input>
        </div>

        <div class="form-group">
          <label for="password">Re-enter your password</label>
          <app-password-input [submitted]="submitted" [formControl]="f['confirmPassword']"
            [placeHolder]="'Enter a password'"></app-password-input>
        </div>

        <p class="referring-title">
          What should we refer to you as and what is your date of birth?
        </p>
        <div class="form-group">
          <label for="email">First name</label>
          <div class="input-container">
            <input type="text" class="form-control" [class.submitted]="submitted" formControlName="firstName"
              placeholder="Enter your first name" />
          </div>
          <div *ngIf="submitted && f['firstName'].errors" class="rap-error-text">
            <span *ngIf="f['firstName'].errors?.['required']">First name is required</span>
          </div>
        </div>

        <div class="form-group">
          <label for="password">Last name</label>
          <div class="input-container">
            <input type="text" class="form-control" [class.submitted]="submitted" formControlName="lastName"
              placeholder="Enter your last name" />
          </div>
          <div *ngIf="submitted && f['lastName'].errors" class="rap-error-text">
            <span *ngIf="f['lastName'].errors?.['required']">Last name is required</span>
          </div>
        </div>

        <div class="form-group">
          <label for="password">Username</label>
          <div class="input-container">
            <input type="text" class="form-control" [class.submitted]="submitted" formControlName="userName"
              placeholder="Enter a username" />
          </div>
          <div *ngIf="submitted && f['userName'].errors" class="rap-error-text">
            <span *ngIf="f['userName'].errors?.['required']">Username is required</span>
          </div>
        </div>

        <div class="form-group">
          <label for="email">Date of Birth</label>
          <div class="input-container">
            <input class="form-control" [class.submitted]="submitted" type="date" placeholder="Enter date of birth"
              name="dob" formControlName="dateOfBirth"
              [readonly]=" submitted && f['dateOfBirth'].errors?.['underage']" />
          </div>
          <div *ngIf="submitted && f['dateOfBirth'].errors" class="rap-error-text">
            <span *ngIf="f['dateOfBirth'].errors?.['required']">Date of birth is required</span>
            <span *ngIf="f['dateOfBirth'].errors.underage">You must be at least 18 years old to register.</span>
          </div>
        </div>

        <button type="submit" class="btn-signin">
          <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span>Create an account</span>
        </button>
        <div class="links">
          <div></div>
          <span>Or</span>
          <div></div>
        </div>
        <button type="button" class="btn-create" (click)="navigateToSignin()">
          Sign in
        </button>
      </form>
      <p class="terms">
        By continuing, you agree to our <a [routerLink]="'/privacy-notice'" target="_blank">Terms of Service</a> and
        <a [routerLink]="'/privacy-notice'" target="_blank">Privacy Policy</a>
      </p>
    </div>
  </div>
  <div class="right"></div>
</div>