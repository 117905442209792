import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StudentsEffects } from './students.effects';
import { studentsReducer } from './students.reducer';
import { StudentsSelectors } from './students.selectors';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature(StudentsEffects),
    StoreModule.forFeature('students', studentsReducer),
  ],
  exports: [StoreModule, EffectsModule],
  providers: [StudentsSelectors],
})
export class StudentsStoreModule {}
