<div class="label-container">
    <div class="container">
        <div class="spinner-overlay" *ngIf="isLoading">
            <div class="spinner"></div>
        </div>
    </div>

    <div class="clickable-label" routerLink="/dashboard/explore">
        <i class="bi bi-arrow-left"></i>
        <label class="clickable-label"> Home </label>

    </div>

    <label style=" font-size: 30px;
    font-style: normal;
    font-weight: 600;"> Security </label>


    <!-- <div class="basic-info-container"> -->
    <label class="title" style=" font-size: 20px;
    font-style: normal;
    font-weight: 600; margin-bottom: 30px;">Logging in to Rap Almanac</label>
    <!-- <label>Password</label> -->
    <!-- <div>
            <label style="background-color: #E8E8E8; padding: 5px 10px; border-radius: 20px; cursor: pointer;"
                (click)="onChangePassword()">Change
                Password</label>
        </div> -->

    <!-- </div> -->

    <!-- <div class="basic-info-container"> -->

    <label style="font-size: 16px;
        font-style: normal;
      font-weight: 400;">Password</label>
    <!-- <div>
            
            <label style="background-color: #E8E8E8; padding: 5px 10px; border-radius: 20px; cursor: pointer;"
                (click)="onChangePassword()">Change
                Password</label>
        </div> -->

    <!-- </div> -->

    <div class="basic-info-container">

        <label style="font-size: 16px;
            font-style: normal;
          font-weight: 400;">*******</label>
        <div>

            <label style="background-color: #E8E8E8; padding: 5px 10px; border-radius: 20px; cursor: pointer;"
                (click)="onChangePassword()">Change
                Password</label>
        </div>

    </div>


</div>