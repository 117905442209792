import { Component, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.container.component.html',
})
export class OnboardingContainerComponent {

  currentStep: number = 1;
  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('step1')) {
          this.currentStep = 1;
        } else if (event.url.includes('step2')) {
          this.currentStep = 2;
        } else if (event.url.includes('step3')) {
          this.currentStep = 3;
        } else if (event.url.includes('step4')) {
          this.currentStep = 4;
        } else if (event.url.includes('step5')) {
          this.currentStep = 5;
        }
      }
    });
  }
}
