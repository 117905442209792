import { RolePermissions } from 'src/app/models/roles/role-permissions.model';
import { User } from 'src/app/models/users/user.model';

export interface DropdownValues {
  id: string;
  label: string;
}

export interface UsersState {
  users: User[];
  usersCount: number;
  editableUser: User;
  roles: DropdownValues[];
  rosters: DropdownValues[];
  errorMessage: string;
}

export const initialUsersState: UsersState = {
  users: null,
  editableUser: null,
  roles: null,
  rosters: null,
  errorMessage: null,
  usersCount: 0,
};
