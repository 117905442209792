import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ProducersService } from '../producers.service';
import {
  addProducer,
  deleteProducer,
  deleteProducerSuccess,
  getAllProducers,
  getMyProducers,
  getProducerById,
  getProducerByIdSuccess,
  getProducersSuccess,
  producersError,
} from './producers.action';
import { Producer } from 'src/app/models/producers/producer.model';

@Injectable()
export class ProducersEffects {
  getAllProducers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllProducers),
      switchMap(() =>
        this.producersService.getAllProducers().pipe(
          map((data: any) => {
            return getProducersSuccess({ producers: data.rows });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              producersError({
                errorMessage:
                  error.error.message ||
                  'Something went wrong, please try again later!',
              }),
            );
          }),
        ),
      ),
    ),
  );

  getMyProducers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMyProducers),
      switchMap(() =>
        this.producersService.getMyProducers().pipe(
          map((data: any) => {
            return getProducersSuccess({ producers: data.rows });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              producersError({
                errorMessage:
                  error.error.message ||
                  'Something went wrong, please try again later!',
              }),
            );
          }),
        ),
      ),
    ),
  );

  getProducerById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProducerById),
      switchMap((action) =>
        this.producersService.getProducerById(action.producerId).pipe(
          map((producer: Producer) => {
            return getProducerByIdSuccess({ producer });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(
              producersError({
                errorMessage:
                  error.error.message ||
                  'Something went wrong, please try again later!',
              }),
            );
          }),
        ),
      ),
    ),
  );

  addProducer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addProducer),
      mergeMap((action) =>
        this.producersService.addNewProducer(action.producer).pipe(
          map(() => {
            this.toastr.success('Producer added successfullly', '', {
              positionClass: 'toast-top-center',
              closeButton: false,
            });
            this.router.navigate(['dashboard/contribution/producers']);
            return getAllProducers();
          }),
          catchError((error) => {
            return of(
              producersError({
                errorMessage:
                  error.error.message ||
                  'Something went wrong, please try again later!',
              }),
            );
          }),
        ),
      ),
    ),
  );

  deleteProducer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteProducer),
      mergeMap((action) =>
        this.producersService.deleteProducer(action.producerId).pipe(
          map(() => {
            this.toastr.success('Producer deleted successfullly', '', {
              positionClass: 'toast-top-center',
              closeButton: false,
            });
            return deleteProducerSuccess({ producerId: action.producerId });
          }),
          catchError((error) => {
            return of(
              producersError({
                errorMessage:
                  error.error.message ||
                  'Something went wrong, please try again later!',
              }),
            );
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private producersService: ProducersService,
    private toastr: ToastrService,
    private router: Router,
  ) {}
}
