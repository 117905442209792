import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ExploreEffects } from './explore.effects';
import { exploreReducer } from './explore.reducer';
import { ExploreSelectors } from './explore.selectors';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature(ExploreEffects),
    StoreModule.forFeature('explore', exploreReducer),
  ],
  exports: [StoreModule, EffectsModule],
  providers: [ExploreSelectors],
})
export class ExploreStoreModule {}
