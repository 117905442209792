import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { userReducer } from './reducers/user.reducer';
import { UserSelectors } from './selectors/user.selectors';
import { UserEffects } from './effects/user.effects';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature(UserEffects),
    StoreModule.forFeature('user', userReducer),
  ],
  exports: [StoreModule, EffectsModule],
  providers: [UserSelectors],
})
export class UserStoreModule {}
