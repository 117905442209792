import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'rap-sort-arrows',
  templateUrl: './sort-arrows.component.html',
  styleUrls: ['./sort-arrows.component.scss']
})
export class SortArrowsComponent {
  @Input() column: string = '';
  @Input() sortedColumn: string = ''; // Currently sorted column
  @Input() sortDirection: 'asc' | 'desc'; // Sort direction

  @Output() sort = new EventEmitter<{ column: string; direction: 'asc' | 'desc' }>(); // Emit sort event

  ngOnInit(): void {
    console.log(this.column)
  }

  setSort(direction: 'asc' | 'desc') {
    // this.sortDirection = direction;
    // console.log(this.sortDirection)
    this.sort.emit({ column: this.column, direction });
  }
}
