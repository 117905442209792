import { Component } from '@angular/core';
import { CardsService } from './cards.service';
import { BaseTemplateService } from '../base-template/base-template.service';
import { environment } from 'src/environments/environments';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from '../shared/component/delete-modal/delete-modal.component';
import { AppService } from '../app.service';
// import * as Highcharts from 'highcharts';

@Component({
  selector: 'rap-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent {
  data: any;
  isLoading = false;

  chartOptions = {
    title: {
      text: '',
    },
    animationEnabled: true,
    axisY: {
      title: 'word count',
      gridThickness: 1,
      tickLength: 10,
    },
    data: [
      {
        type: 'column',
        cornerRadius: 10,
        dataPoints: [
          { label: "Item 1", y: 10, color: "#FF5733", cornerRadius: 5 },
          { label: "Item 2", y: 15, color: "#33FF57" },
          { label: "Item 3", y: 25, color: "#3357FF" },
          { label: "Item 4", y: 30, color: "#FF33A1" },
          { label: "Item 5", y: 28, color: "#A133FF" }
        ],
      },
    ],
  };

  // Highcharts: typeof Highcharts = Highcharts;

  constructor(private cardService: CardsService,
    private baseTemplateService: BaseTemplateService,
    private toastr: ToastrService, private router: Router, private modalService: NgbModal, private loaderService: AppService) {
    this.loaderService.loading$.subscribe(isLoading => {
      console.log("isLoading")
      console.log(isLoading)
      this.isLoading = isLoading;
    });
    // console.log(this.data.length);
    this.baseTemplateService.showHideSearch(false);
  }

  ngOnInit(): void {
    this.getAllCards()
  }

  getAllCards() {
    this.isLoading = true;
    this.cardService.getMyCards().subscribe((result) => {
      console.log(result)
      this.data = result;
      this.isLoading = false;
    });
  }

  getChartOptions(chartData: any) {
    if (!chartData.data || !chartData.data[0] || chartData.data[0].data == null) {
      console.log('Invalid data structure:', chartData);
      return {}; // Return an empty object or handle error
    }

    // return {};

    return {
      title: {
        text: ""
      },
      axisY: {
        title: 'Word Count',
        gridThickness: 1,
        tickLength: 10,
      },
      axisX: {
        title: 'Artist'
      },
      data: [{
        type: 'column',
        maxBarThickness: 30,
        dataPoints: chartData.data[0].data.map((item: any) => ({
          label: item.artist,
          y: item.count
        }))
      }]
    };
  }

  getChartOptionsTimeLine(chart) {
    // var chartData = chart.data[0].data.map(point => [Date.UTC(point.year, 0, 1), point.count]);

    // return {
    //   title: {
    //     text: `Search word: ${chart.data[0].word}`
    //   },
    //   xAxis: {
    //     type: 'datetime',
    //     title: {
    //       text: 'Year'
    //     },
    //     labels: {
    //       format: '{value:%Y}'
    //     }
    //   },
    //   yAxis: {
    //     title: {
    //       text: ''
    //     }
    //   },
    //   series: [{
    //     type: 'line',
    //     data: chartData,
    //     name: '',
    //     marker: {
    //       enabled: true
    //     }, fillColor: {
    //       linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
    //     }
    //   }],
    //   tooltip: {
    //     xDateFormat: '%Y',
    //     shared: true
    //   }
    // };
    return {
      theme: "light2",
      animationEnabled: true,
      zoomEnabled: true,

      title: {
        text: ""
      },
      axisX: {
        title: "Year",
        valueFormatString: "YYYY",
        interval: 1,
      },
      axisY: {
        title: "Count",
      },

      data: [
        {
          type: "line", // Timeline is best represented as a line chart
          xValueFormatString: "YYYY",
          markerType: "circle", // Display dots on the line
          markerSize: 6, // Set the size of the dots
          markerColor: "black", // Change the color of the dots
          lineColor: "black", // Cu
          fillOpacity: 0.5, // Adds a semi-transparent fill color below the line
          color: "white",
          dataPoints: chart.data[0].data.map(d => ({ x: new Date(d.year, 0), y: d.count }))
            .sort((a, b) => a.x.getTime() - b.x.getTime()) // Convert year to date
        }
      ]
    };


  }

  getChartOptions3D(chart) {
    return {
      title: {
        text: "Nearest Neighbour Visualization"
      },
      axisX: {
        title: "X Axis"
      },
      axisY: {
        title: "Y Axis"
      },
      axisZ: {
        title: "Z Axis",
        minimum: -20,
        maximum: 30
      },
      data: {
        type: "scatter",
        name: chart.searchWord,
        showInLegend: true,
        dataPoints: chart.data[0].neighbors.map(neighbor => ({
          x: neighbor.x,
          y: neighbor.y,
          z: neighbor.z,
          label: neighbor.word,
          color: "blue" // Set color for points
        }))
      }
    };
  }

  onCards() {

  }

  onView(item: any) {

    const baseUrl = window.location.origin;
    console.log(`${baseUrl}/cards`)
    const newWindow = window.open(`${baseUrl}/#/cards/${item.id}`, '_blank');
    // window.open('cards/privacy-notice', '_blank');
    // Wait for the new window to fully load
    setTimeout(() => {
      newWindow?.postMessage(item, '*');
    }, 1000);
  }

  copyLink(item: any) {
    const baseUrl = window.location.origin;
    console.log(`${baseUrl}/cards`) // Replace with the link you want to copy
    navigator.clipboard.writeText(`${baseUrl}/#/cards/${item.id}`).then(() => {
      // alert('Link copied to clipboard!');
      this.toastr.success('Shareable link Copied to Clipboard!', '', {
        positionClass: 'toast-top-center',
        closeButton: false,
      });

    }, () => {
      alert('Failed to copy the link');
    });
  }

  edit(item: any) {
    // this.toastr.success('Feature Coming Soon!!!', '', {
    //   positionClass: 'toast-top-center',
    //   closeButton: false,
    // });
    //Done just commented
    this.router.navigateByUrl(`/dashboard/cards/edit/${item.id}`,
    );
  }

  delte(item: any) {
    // this.toastr.success('Feature Coming Soon!!!', '', {
    //   positionClass: 'toast-top-center',
    //   closeButton: false,
    // });
    //Done just commented
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.itemId = item.id;
    modalRef.result
      .then((id) => {
        this.isLoading = true;
        this.cardService.deleteCard(item.id).subscribe((result) => {
          console.log(result)

          this.isLoading = false
          this.getAllCards();
        });
      })
      .catch((error) => {
        this.isLoading = false;
      });
  }

  convertHtmlToText(html: string): string {
    const element = document.createElement('div');
    element.innerHTML = html;
    return element.innerText || element.textContent || '';
  }


}
