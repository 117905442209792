import { Action, createReducer, on } from '@ngrx/store';
import { AnalyticsState, initialAnalyticsState } from './analytics.state';
import {
  deleteAnalyticSuccess,
  getAnalyticsSuccess,
  setErrorMessage,
  viewAnalyticSuccess,
} from './analytics.action';

export const analyticsReducer = (
  state: AnalyticsState | undefined,
  action: Action,
): AnalyticsState => reducer(state, action);

const reducer = createReducer(
  initialAnalyticsState,
  on(getAnalyticsSuccess, (state, { analytics }) => ({
    ...state,
    analytics,
  })),
  on(deleteAnalyticSuccess, (state, { analyticsId }) => ({
    ...state,
    analytics: state.analytics.filter(
      (analytic) => analytic.id !== analyticsId,
    ),
  })),
  on(viewAnalyticSuccess, (state, { viewAnalytics }) => ({
    ...state,
    viewAnalytics,
  })),
  on(setErrorMessage, (state, { msg }) => ({
    ...state,
    error: msg,
  })),
);
