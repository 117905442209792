<div class="modal-header">
    <h4 class="modal-title">Profile Photo</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p class="card-text">To change your profile photo, select upload photo. Your profile photo may be visible by others
        on Rap Almanac.</p>

    <form [formGroup]="uploadForm">
        <input type="file" (change)="onFileChange($event)" />
        <!-- <button type="submit">Upload</button> -->
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn" style="font-size: 13px;font-style: normal;font-weight: 600;"
        (click)="activeModal.dismiss('Cross click')">
        Cancel
    </button>
    <button type="button" class="btn btn-dark delete-btn" (click)="onFileSelected()">
        Upload a Photo
    </button>
    <!-- <input type="file" accept="image/*" #fileInput (change)="onFileSelected($event)" style="display: none;"> -->
</div>