import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import * as d3Zoom from 'd3-zoom';
import { points3D } from 'd3-3d';
import { Injectable } from '@angular/core';

@Component({
  selector: 'rap-word-count-chart',
  templateUrl: './word-count-chart.component.html',
  styleUrls: ['./word-count-chart.component.scss']
})
export class WordCountChartComponent {

  @ViewChild('chartContainer', { static: true }) chartContainer!: ElementRef;
  @Input() data: any;
  @Input() queryWord: any;

  private svg: any;
  private width = 400;
  private height = 200;
  private origin = [200, 100];
  private scale = 3;
  private alpha = Math.PI / 4;
  private beta = Math.PI / 4;
  centerType = 'centroid';


  ngOnInit(): void {
    if (this.chartContainer) {
      console.log(this.data)
    }
  }

  updateCenterType() {
    console.log(this.centerType)
    this.cleanupChart();
    if (this.centerType == 'centroid') {
      this.alpha = Math.PI / 4;
      this.beta = Math.PI / 4;
    } else {
      this.alpha = Math.PI / 2;
      this.beta = Math.PI / 2;
    }
    this.renderChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.cleanupChart();
    if (changes['data']) {
      console.log(this.data)
      this.initializeChart();
    } else if (changes['queryWord']) {
      console.log(this.data)
      console.log(this.queryWord)
      this.initializeChart();

    }
  }

  private initializeChart(): void {
    this.svg = d3.select(this.chartContainer.nativeElement)
      .append('svg')
      .attr('class', 'nnsContainer')
      .attr('width', this.width)
      .attr('height', this.height)
      // .attr('transform', `translate(${this.origin[0]}, ${this.origin[1]})`) // Apply initial translation
      .call(
        d3.drag()
          .on('drag', (event) => this.dragged(event))
      );

    this.renderChart();
  }

  private renderChart(): void {
    const scatterData = this.prepareData();
    const projectedPoints = scatterData.map(d => this.project3D(d.x, d.y, d.z));

    console.log(this.origin)
    console.log(this.width)
    const axisLength = 30;  // Define how long each axis should appear
    const axes = [
      { label: 'x', color: '#fbcaca', endPoint: { x: axisLength, y: 0, z: 0 } }, // x-axis
      { label: 'y', color: '#bdf6c3', endPoint: { x: 0, y: axisLength, z: 0 } }, // y-axis
      { label: 'z', color: '#bdeef6', endPoint: { x: 0, y: 0, z: axisLength } }  // z-axis
    ];

    axes.forEach(axis => {
      const projectedStart = this.centerType == 'centroid' ? this.project3D(0, 0, 0) : this.project3D(0.5, 0.5, 0.6); // Project origin
      const projectedEnd = this.project3D(axis.endPoint.x, axis.endPoint.y, axis.endPoint.z); // Project axis end

      // Draw line from origin to axis end
      this.svg.append('line')
        .attr('x1', this.origin[0] + projectedStart[0] * this.scale)
        .attr('y1', this.origin[1] - projectedStart[1] * this.scale)
        .attr('x2', this.origin[0] + projectedEnd[0] * this.scale)
        .attr('y2', this.origin[1] - projectedEnd[1] * this.scale)
        .attr('stroke', axis.color)
        .attr('stroke-width', 2);

      // Optional: Add axis label near the end point
      this.svg.append('text')
        .attr('x', this.origin[0] + projectedEnd[0] * this.scale + 5)
        .attr('y', this.origin[1] - projectedEnd[1] * this.scale - 5)
        .text(axis.label)
        .style('fill', axis.color)
        .style('font-size', '12px');
    });

    this.svg.selectAll("circle")
      .data(projectedPoints)
      .enter()
      .append("circle")
      .attr("cx", d => this.origin[0] + d[0] * this.scale)
      .attr("cy", d => this.origin[1] - d[1] * this.scale)
      .attr("r", 5)
      .style("fill", (d, i) => i === 0 ? '#FF5733' : '#3498DB'); // Different colors for query and neighbors

    this.svg.selectAll("text")
      .data(projectedPoints)
      .enter()
      .append("text")
      .attr("x", d => this.origin[0] + d[0] * this.scale + 5)
      .attr("y", d => this.origin[1] - d[1] * this.scale - 5)
      .text((d, i) => i === 0 ? this.data.query.word : this.data.neighbors[i - 1].word);
  }



  private prepareData(): { x: number; y: number; z: number; }[] {
    const query = this.data.query;
    const neighbors = this.data.neighbors;
    let center;

    if (this.centerType == 'centroid') {
      console.log("this.queryWord")
      // Calculate center as the average of query and neighbors' coordinates
      center = { x: 0, y: 0, z: 0 };
      // const allPoints = [query, ...neighbors];
      // for (const dim of ["x", "y", "z"]) {
      //   center[dim] = allPoints.reduce((sum, point) => sum + point[dim], 0) / allPoints.length;
      // }
    } else {
      console.log("this.queryWord111")
      // Use query as center if queryWord is provided
      center = { x: query.x, y: query.y, z: query.z };
    }

    // Return center as the first point and neighbors as the rest
    return [
      { x: center.x, y: center.y, z: center.z },
      ...neighbors.map(nn => ({ x: nn.x, y: nn.y, z: nn.z }))
    ];
  }

  private project3D(x: number, y: number, z: number): [number, number] {



    const cosA = Math.cos(this.alpha);
    const sinA = Math.sin(this.alpha);
    const cosB = Math.cos(this.beta);
    const sinB = Math.sin(this.beta);

    const x2d = cosB * (sinA * y + cosA * x) - sinB * z;
    const y2d = sinB * (sinA * y + cosA * x) + cosB * z;

    return [x2d, y2d];
  }

  private dragged(event: any): void {
    this.alpha += event.dx * 0.01;
    this.beta -= event.dy * 0.01;
    this.cleanupChart();
    this.renderChart();
  }

  private cleanupChart(): void {
    if (this.svg) {
      this.svg.selectAll('*').remove();
      this.svg.attr("transform", null);

    }
  }
}


