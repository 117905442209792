<rap-breadcrumb-default [exploreLabel]="'Rap Almanac'" [exploreLink]="'/dashboard/explore'"
    [exploreImage]="'../assets/images/filled.svg'" [rostersLabel]="'Cards'"
    (rostersClick)="onCards()"></rap-breadcrumb-default>

<div class="section-body">
    <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
    <div class="section-title"> Cards </div>

    <div class="grid-container">
        <div class="grid-item" *ngFor="let item of data">
            <img [src]="item.path !== null ? item.path : 'assets/images/favicon.png'" class="logo">
            <h3>Search word : {{ item.searchWord }}</h3>
            <!-- <div *ngIf="item.chartType === 'Bar chart'">
                <canvasjs-chart [options]="getChartOptions(item)"
                    [styles]="{ width: '100%', height:'300px'}"></canvasjs-chart>
            </div>
            <div *ngIf="item.chartType === 'Timeline'">
                <canvasjs-chart [options]="getChartOptionsTimeLine(item)"
                    [styles]="{ width: '80%', height:'300px'}"></canvasjs-chart>

            </div>
            <div *ngIf="item.chartType === 'Nearest Neighbour'" [styles]="{ width: '100%', height:'300px'}">
                <rap-word-count-chart [data]="item.data[0]" [queryWord]=""></rap-word-count-chart>
            </div> -->
            <p>{{ item.text | htmlToText }}</p>
            <div class="button-container">
                <rap-rect-primary [label]="'View'" [btnClass]="'btn btn-dark rect-primary-btn1'"
                    (clickEvent)="onView(item)"></rap-rect-primary>
                <rap-rect-primary [label]="'Copy Link'" [btnClass]="'btn btn-dark rect-primary-btn1'"
                    (clickEvent)="copyLink(item)"></rap-rect-primary>
                <rap-rect-primary [label]="'Edit'" [btnClass]="'btn btn-dark rect-primary-btn1'"
                    (clickEvent)="edit(item)"></rap-rect-primary>
                <rap-rect-primary [label]="'Delete'" [btnClass]="'btn btn-dark rect-primary-btn1'"
                    (clickEvent)="delte(item)"></rap-rect-primary>

            </div>
        </div>
    </div>
</div>