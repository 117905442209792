import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { usersReducer } from './users.reducer';
import { UsersSelectors } from './users.selectors';
import { UsersEffects } from './users.effects';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature(UsersEffects),
    StoreModule.forFeature('users', usersReducer),
  ],
  exports: [StoreModule, EffectsModule],
  providers: [UsersSelectors],
})
export class UsersStoreModule {}
