import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Roles } from '../models/roles/roles.model';
import { DeleteModalComponent } from '../shared/component/delete-modal/delete-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseTemplateService } from '../base-template/base-template.service';
import { AppService } from '../app.service';

@Component({
  selector: 'rap-roles-ui',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss'],
})
export class RolesComponent {
  @Input() roles: Roles[];
  @Output() createRoleEmitter = new EventEmitter<void>();
  @Output() editRoleEmitter = new EventEmitter<Roles>();
  @Output() deleteRoleEmitter = new EventEmitter<string>();
  page = 1;
  pageSize = 10;
  isLoading = false;
  searchQuery = "";
  filters: any = {
    search: ""
  };
  rolesData: Roles[] = [];
  sortDirection: string = 'asc';
  sortedColumn: string = '';
  constructor(private modalService: NgbModal, private baseTemplateService: BaseTemplateService, private loaderService: AppService) {
    this.loaderService.loading$.subscribe(isLoading => {
      console.log("isLoading")
      console.log(isLoading)
      this.isLoading = isLoading;
    });
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.roles = [];

    // this.baseTemplateService.search$.subscribe(searchTerm => {
    //   this.filters.search = searchTerm;
    //   if (this.roles.length > 0)
    //     this.getSearchResults();
    // });

    this.baseTemplateService.showHideSearch(false);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['roles'] && this.roles) {
      this.isLoading = false;
      this.page = 1;
      this.rolesData = this.roles;
    }
  }

  get paginatedRoles(): Roles[] {
    const start = (this.page - 1) * this.pageSize;
    const end = start + this.pageSize;
    return this.roles.slice(start, end);
  }

  onPageChange(page: number) {
    this.page = page;
  }

  onSort({ column, direction }: { column: string, direction: 'asc' | 'desc' }) {
    console.log(column)
    if (this.sortedColumn === column) {
      // Toggle sort direction
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      // New column sorting
      this.sortedColumn = column;
      this.sortDirection = 'asc';
    }
    this.updateSortData()
  }

  sortBy({ column, direction }: { column: string, direction: 'asc' | 'desc' }) {
    this.sortedColumn = column;
    this.sortDirection = direction;
    this.updateSortData()
  }

  updateSortData() {
    this.roles = [...this.roles].sort((a, b) => {
      if (this.sortedColumn === 'name') {
        return this.sortDirection === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      }
      else if (this.sortedColumn === 'permissions') {
        return this.sortDirection === 'asc'
          ? a.permissions.length - b.permissions.length
          : b.permissions.length - a.permissions.length;
      }
      return this.sortDirection === 'asc'
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    });
  }

  deleteRole(roleId: string) {

    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.itemId = roleId;
    modalRef.result
      .then((id) => {
        this.isLoading = true;
        this.deleteRoleEmitter.emit(id);
      })
      .catch((error) => {
        this.isLoading = false;
      });
  }

  editRole(role: Roles) {
    this.editRoleEmitter.emit(role);
  }

  getSearchResults(search: string) {
    this.filters.search = search;
    if (this.filters.search) {
      this.searchQuery = this.filters.search;
      var localArr = this.roles.filter(item =>
        item.name?.toLowerCase().includes(this.filters.search.toLowerCase())
      );
      this.roles = localArr;
    } else {
      this.roles = this.rolesData;
    }
  }

  onRoles() {
    //  window.open('https://www.rapalmanac.com/privacy-notice', '_blank');
  }

  onRapAlmanc() {

  }

  ngOnDestroy(): void {
    this.roles = [];
    this.rolesData = [];
    this.filters.search = "";
  }

  onClearSearch() {
    this.filters.search = "";
    this.getSearchResults("");
  }
}
