import { createSelector, createFeatureSelector, Store } from '@ngrx/store';
import { ProducersState } from './producers.state';
import { Injectable } from '@angular/core';

const getProducersState = createFeatureSelector<ProducersState>('producers');

const getProducers = createSelector(
  getProducersState,
  (state: ProducersState) => state.producers,
);

// Selector to get a specific song by ID
const getProducerById = createSelector(
  getProducersState,
  (state: ProducersState) => state.editableProducer,
);

const getErrorMessage = createSelector(
  getProducersState,
  (state: ProducersState) => state.errorMessage,
);

@Injectable()
export class ProducersSelectors {
  producers$ = this.store.select(getProducers);
  editableProducers$ = this.store.select(getProducerById);
  errorMessage$ = this.store.select(getErrorMessage);
  constructor(private store: Store<ProducersState>) {}
}
