import { AfterViewInit, Component, EventEmitter, HostListener, Input, Output, SimpleChanges } from '@angular/core';
import { Options } from 'ng5-slider';
import { SearchData, SongData } from '../models/search-data.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseTemplateService } from '../base-template/base-template.service';
import { AuthService } from '../services/auth.service';
import { PermissionsMap } from '../models/user-data.model';
import { COUNTRY_LIST, LANG_LIST } from './explore.data';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '../app.service';
import { ExploreService } from './explore.service';
import * as L from 'leaflet';
import { Router } from '@angular/router';
import { CardsService } from '../cards/cards.service';
import { latLng, tileLayer } from 'leaflet';
import * as Leaflet from 'leaflet';
// import * as Highcharts from 'highcharts';
import { environment } from 'src/environments/environments';

Leaflet.Icon.Default.imagePath = 'assets/';

@Component({
  selector: 'rap-explore-ui',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.scss'],
})
export class ExploreComponent implements AfterViewInit {
  @Input() searchResults: any;
  @Output() searchWithLanguage = new EventEmitter<string>();
  @Output() searchWithTimeFrame = new EventEmitter<any>();
  @Output() searchWithSelectedMoods = new EventEmitter<any[]>();
  @Output() search = new EventEmitter<string>();
  @Output() searchWithCountry = new EventEmitter<string>();
  @Output() searchWithKeyword = new EventEmitter<{ included: string[], excluded: string[] }>();
  @Output() searchCity = new EventEmitter<string>();
  @Input() randomKeyword: string;

  active = 1;
  // time frame filters
  startDate: string;
  endDate: string;
  isLoading: boolean = false;

  // keyword filter
  includeKeyword: string = '';
  includeKeywords: string[] = [];
  excludeKeyword: string = '';
  excludeKeywords: string[] = [];
  isSelectedLan = false;
  searchTerm = "";
  selectedMoods = [];
  isTimeFrameSelected = false;
  showReset = false;
  title = "Explore";
  citySearch: string = '';
  cityKeyword = '';

  // mood filters
  moodFilters = [
    { name: 'joy', checked: false, range: { value: 0.0, highValue: 1.0 } },
    { name: 'anger', checked: false, range: { value: 0.0, highValue: 1.0 } },
    { name: 'disgust', checked: false, range: { value: 0.0, highValue: 1.0 } },
    { name: 'sadness', checked: false, range: { value: 0.0, highValue: 1.0 } },
    { name: 'fear', checked: false, range: { value: 0.0, highValue: 1.0 } },
  ];

  moodFilterOptions = {
    joy: { value: 0.0, highValue: 1.0 },
    anger: { value: 0.0, highValue: 1.0 },
    disgust: { value: 0.0, highValue: 1.0 },
    sadness: { value: 0.0, highValue: 1.0 },
    fear: { value: 0.0, highValue: 1.0 },
  };

  value: number = 0.0;
  highValue: number = 1.0;
  sliderOptions: Options = {
    floor: 0,
    ceil: 1,
    step: 0.01,
  };

  sliderValue: number = 50;
  sliderOptions1: Options = {
    floor: 0,
    ceil: 100
  };

  displayStyle: string = 'cards';
  page = 1;
  pageSize = 10;

  languages = LANG_LIST;
  selectedLanguages: any[] = [];

  locations = COUNTRY_LIST;
  selectedCountry: any;
  isSelectedCountry = false;
  isSelectedKeywords = false;
  isSelectedExcludeKeywords = false;
  isSelectedCity = false;
  dropdownFilter = {
    showLocationDropdown: false,
    showKeyWordDropdown: false,
    showLanguageDropdown: false,
    showTimeframeDropdown: false,
    showMoodDropdown: false,

  };
  permissionsMap: PermissionsMap;
  dropdownOpen = false;
  activeDropdown: number | null = null;
  activeDropdownGrid: number | null = null;

  barChartData: any[] = [];
  timeLineChartData: any[] = [];
  wordCloudChartData: any[] = [];


  center = L.latLng(0, 0); // Center coordinates
  zoom = 1.621; // Initial zoom
  minZoom = 1.621; // Minimum zoom level
  outerBounds = L.latLngBounds(L.latLng(-90, -180), L.latLng(90, 180)); // Bounds

  // Highcharts: typeof Highcharts = Highcharts;
  centerType = 'centroid';
  sortDirection: 'asc' | 'desc' | '' = '';
  sortedColumn: string = '';
  constructor(
    private authService: AuthService,
    private baseTemplateService: BaseTemplateService, private router: Router, private cardService: CardsService,
    private toastr: ToastrService, private loaderService: AppService, private exploreService: ExploreService) {
    this.loaderService.loading$.subscribe(isLoading => {
      // if (i)
      console.log("isLoading")
      console.log(isLoading)
      this.isLoading = isLoading;
    });
  }

  showTooltipMap = false;
  showTooltipBar = false;
  showTooltipTimeLine = false;
  showTooltipVector = false;

  toggleTooltipMap(state: boolean) {
    this.showTooltipMap = state;
  }

  toggleTooltipBar(state: boolean) {
    this.showTooltipBar = state;
  }

  toggleTooltipTimeLine(state: boolean) {
    this.showTooltipTimeLine = state;
  }

  toggleTooltipVector(state: boolean) {
    this.showTooltipVector = state;
  }

  map!: Leaflet.Map;
  markers: Leaflet.Marker[] = [];
  urlTemp = 'https://tile.openstreetmap.org/{z}/{x}/{y}.png';
  options2 = {
    layers: [
      Leaflet.tileLayer(this.urlTemp, {
        attribution: '',
      })
    ],
    zoom: this.zoom,
    center: latLng(46.879966, -121.726909),
    minZoom: this.minZoom,
    maxBounds: this.outerBounds,

  }
  customIcon = L.icon({
    iconUrl: '../assets/images/favicon.png',  // Path to your custom icon image
    shadowUrl: '../assets/images/favicon.png',  // Optional: Path to the shadow image
    iconSize: [25, 41],  // Size of the icon
    iconAnchor: [12, 41],  // Point of the icon which will correspond to marker's location
    popupAnchor: [1, -34],  // Point from which the popup should open relative to the iconAnchor
    shadowSize: [1, 1]    // Size of the shadow icon
  });

  initMarkers() {
    const initialMarkers = [
      {
        position: { lat: 28.625485, lng: 79.821091 },
        draggable: true
      },
      {
        position: { lat: 28.625293, lng: 79.817926 },
        draggable: false
      },
      {
        position: { lat: 28.625182, lng: 79.81464 },
        draggable: true
      }
    ];

    console.log(this.paginatedSearchResults.length)
    console.log(this.searchResults['data']?.hits.length)
    for (let index = 0; index < this.searchResults['data']?.hits.length; index++) {
      const data = this.searchResults['data']?.hits[index] as any;
      console.log(data)
      console.log(data.origin_latitude)
      if (data.origin_latitude != null && data.origin_longitude != null) {
        var position = { lat: data.origin_latitude, lng: data.origin_longitude };
        const encodedQuery = encodeURIComponent(data.lyrics);
        const googleSearchUrl = `https://www.google.com/search?q=${encodedQuery}`;
        const marker = this.generateMarker(data, index);
        marker.addTo(this.map).bindPopup(`<b>${data.origin_latitude},  ${data.origin_longitude}</b>`);
        marker.setIcon(this.customIcon);
        const popupContent = `
    <div style="font-family: Arial, sans-serif;">
        <p><strong>Name:</strong> ${data.artist.artist}</p>
        <p><strong>Album:</strong> <em>${data.album.album}</em></p>
         <p><strong>Song:</strong> <em>${data.title}</em></p>
        <p><strong>Latitude:</strong> ${data.origin_latitude}</p>
        <p><strong>Longitude:</strong> ${data.origin_longitude}</p>
 <p><a href="${googleSearchUrl}" target="_blank">Song Lyrics</a></p>
    </div>
`;
        marker.bindPopup(popupContent).addTo(this.map);
        this.map.panTo(position);
        this.markers.push(marker)
      }

    }

    setTimeout(() => {
      this.map?.invalidateSize();
    }, 500);

  }

  generateMarker(data: any, index: number) {
    var position = { lat: data.origin_latitude, lng: data.origin_longitude };
    console.log(position)
    return Leaflet.marker(position, { draggable: false })
      .on('click', (event) => this.markerClicked(event, index))
      .on('dragend', (event) => this.markerDragEnd(event, index));
  }

  onMapReady($event: Leaflet.Map) {
    this.map = $event;
    // this.initMarkers();
    // Optional: Force Leaflet to recalculate map size
    // setTimeout(() => {
    //   this.map?.invalidateSize();
    // }, 0);
  }

  mapClicked($event: any) {
    console.log($event.latlng.lat, $event.latlng.lng);
  }

  markerClicked($event: any, index: number) {
    console.log($event.latlng.lat, $event.latlng.lng);
  }

  markerDragEnd($event: any, index: number) {
    console.log($event.target.getLatLng());
  }

  ngOnInit() {
    // this.isLoading = true;
    // this.endDate = new Date().getFullYear().toString();
    this.baseTemplateService.showHideSearch(true);
    this.searchTerm = '';
    this.baseTemplateService.search$.subscribe(searchTerm => {
      console.log(searchTerm);
      this.isLoading = true;
      this.searchTerm = searchTerm;
      if (searchTerm == "") {
        this.title = "Explore";
      } else {
        this.randomKeyword = null;
        this.title = '"' + searchTerm + '"';
      }

      this.search.emit(searchTerm);
      this.getTabsData();
      const storedPermissions = this.authService.getUserPermissions();

      // Convert the string back to an object
      if (storedPermissions) {
        const userObj = JSON.parse(storedPermissions);
        console.log(userObj);
        this.permissionsMap = userObj;
        console.log(this.permissionsMap);
      }

    });
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(this.searchResults);
    console.log(changes['searchResults'])
    if (changes['searchResults'] && this.searchResults) {
      this.isLoading = false;
      console.log(changes['searchResults'])

    }

    if (changes['randomKeyword'] && this.randomKeyword !== null) {
      this.title = '"' + this.randomKeyword + '"';
      this.getTabsData();
    }
  }

  // Wait until the view is fully initialized
  ngAfterViewInit(): void {
    // this.loadMap();
  }



  getTabsData() {
    console.log(this.searchTerm);
    console.log(this.includeKeywords);
    var words: string[] = [];
    if (this.randomKeyword !== null) {
      words.push(this.randomKeyword);
    }
    words.push(this.searchTerm);
    words.push(...this.includeKeywords);
    if (words.filter(word => word).length > 0) {
      this.exploreService.getApiData(words.filter(word => word)).then((responses) => {
        this.barChartData = responses[0];
        this.timeLineChartData = responses[1];
        this.wordCloudChartData = responses[2];
        // this.initMap();
        console.log('API 1 Response:', responses[0]);
        console.log('API 2 Response:', responses[1]);
        console.log('API 3 Response:', responses[2]);
      });
    } else {
      this.barChartData = [];
    }

  }

  getChartOptions(chartData: any) {
    console.log('chartData.data.length', chartData.data);
    if (!chartData.data || chartData.data == null) {
      console.log('Invalid data structure:', chartData);
      return {}; // Return an empty object or handle error
    }
    if (Object.keys(chartData.data).length === 0) {
      console.log('chartData.data.length', chartData);
      return {}; // Return an empty object or handle error
    }
    return {
      title: {
        text: ""
      },
      axisY: {
        title: 'Word Count',
        gridThickness: 1,
        tickLength: 10,
      },
      axisX: {
        title: 'Artist'
      },
      data: [{
        type: 'column',
        maxBarThickness: 30,
        dataPoints: chartData.data.map((item: any) => ({
          label: item.artist,
          y: item.count
        }))
      }]
    };
  }

  onBarChartClick(chart: any) {
    console.log(chart.word)
    var str = "?include_terms[]=" + chart.word + "&page=1";
    const storedPermissions = this.authService.getUser();
    var data = { "searchQuery": str, "chartType": "Bar chart", "chartData": chart, "searchWord": chart.word, }
    console.log(data)
    this.cardService.creatCard(data).subscribe((result) => {
      console.log(result)
      this.router.navigateByUrl(`/dashboard/cards/edit/${result.id}`,
      );
    });
  }


  getChartOptionsTimeLine(chart: any) {
    // var chartData = chart.data.map(d => ({ x: new Date(d.year, 0), y: d.count }))
    return {
      theme: "light2",
      animationEnabled: true,
      zoomEnabled: true,

      title: {
        text: ""
      },
      axisX: {
        title: "Year",
        valueFormatString: "YYYY",
        interval: 1,
      },
      axisY: {
        title: "Count",
      },

      data: [
        {
          type: "line", // Timeline is best represented as a line chart
          xValueFormatString: "YYYY",
          markerType: "circle", // Display dots on the line
          markerSize: 6, // Set the size of the dots
          markerColor: "black", // Change the color of the dots
          lineColor: "black", // Cu
          fillOpacity: 0.5, // Adds a semi-transparent fill color below the line
          color: "white",
          dataPoints: chart.data.map(d => ({ x: new Date(d.year, 0), y: d.count }))
            .sort((a, b) => a.x.getTime() - b.x.getTime()) // Convert year to date
        }
      ]
    };

  }

  onTimeLineChartClick(item: any) {
    console.log(item.word)
    var str = "?include_terms[]=" + item.word + "&page=1";
    const storedPermissions = this.authService.getUser();
    var data = { "searchQuery": str, "chartType": "Timeline", "chartData": item, "searchWord": item.word, }
    console.log(data)
    this.cardService.creatCard(data).subscribe((result) => {
      console.log(result)
      this.router.navigateByUrl(`/dashboard/cards/edit/${result.id}`,
      );
    });

  }

  onNavChange(event: any): void {
    console.log(event.nextId)

    if (event.nextId === 2) {
      // this.initMap();
    }
  }

  onMapTabClick() {
    console.log("fff")
    this.initMarkers();
    // this.loadMap()
  }

  onWordCloudClick(item: any) {
    console.log(item.query.word)
    var str = "?include_terms[]=" + item.word + "&page=1";
    const storedPermissions = this.authService.getUser();
    var data = { "searchQuery": str, "chartType": "Nearest Neighbour", "chartData": item, "searchWord": item.query.word, }
    console.log(data)
    this.cardService.creatCard(data).subscribe((result) => {
      console.log(result)
      this.router.navigateByUrl(`/dashboard/cards/edit/${result.id}`,
      );
    });
  }

  updateCenterType(): void {

  }



  showDropdownFilters(filter: string) {
    if (filter === 'language') {
      Object.keys(this.dropdownFilter).forEach((key) => {
        if (key !== 'showLanguageDropdown') this.dropdownFilter[key] = false;
      });
      this.dropdownFilter.showLanguageDropdown =
        !this.dropdownFilter.showLanguageDropdown;
    }

    if (filter === 'keyword') {
      Object.keys(this.dropdownFilter).forEach((key) => {
        if (key !== 'showKeyWordDropdown') this.dropdownFilter[key] = false;
      });
      this.dropdownFilter.showKeyWordDropdown =
        !this.dropdownFilter.showKeyWordDropdown;
    }

    if (filter === 'timeFrame') {
      Object.keys(this.dropdownFilter).forEach((key) => {
        if (key !== 'showTimeframeDropdown') this.dropdownFilter[key] = false;
      });
      this.dropdownFilter.showTimeframeDropdown =
        !this.dropdownFilter.showTimeframeDropdown;
    }

    if (filter === 'mood') {
      Object.keys(this.dropdownFilter).forEach((key) => {
        if (key !== 'showMoodDropdown') this.dropdownFilter[key] = false;
      });
      this.dropdownFilter.showMoodDropdown =
        !this.dropdownFilter.showMoodDropdown;
    }

    if (filter === 'location') {
      Object.keys(this.dropdownFilter).forEach((key) => {
        if (key !== 'showLocationDropdown') this.dropdownFilter[key] = false;
      });
      this.dropdownFilter.showLocationDropdown =
        !this.dropdownFilter.showLocationDropdown;
    }
  }

  toggleLocation(location: any) {

    this.selectedCountry = location.value;
    console.log(location)
  }

  toggleLanguage(language: any) {
    if (this.selectedLanguages.includes(language)) {
      this.selectedLanguages = this.selectedLanguages.filter(
        (lang) => lang !== language,
      );
    } else {
      this.selectedLanguages.push(language);
    }
    // console.log(this.selectedLanguages[0])
  }

  changeDisplayStyle(style: string) {
    this.displayStyle = style;
    console.log(this.searchResults)
    console.log(this.searchResults['data']?.hits.length)
    console.log(this.paginatedSearchResults)
  }

  handleMoodChange(event, type: string) {
    var target = event.target;

    this.moodFilters.forEach((mood) => {
      if (mood.name === type) {
        mood.checked = target.checked;
      }
    });
  }

  resetFilters(filter: string) {
    if (filter === 'mood') {
      Object.keys(this.moodFilters).forEach((key) => {
        this.moodFilters[key] = false;
      });
    } else if (filter === 'timeframe') {
      this.startDate = null;
      this.endDate = null;
    } else if (filter === 'keyword') {
      this.includeKeyword = null;
      this.excludeKeyword = null;
    } else if (filter === 'language') {
      this.selectedLanguages = [];
    }
  }

  get paginatedSearchResults(): SongData[] {
    const start = (this.page - 1) * this.pageSize;
    const end = start + this.pageSize;
    return this.searchResults['data']?.hits?.slice(start, end);
  }

  onPageChange(page: number) {
    this.page = page;
  }

  searchWithFilter(filter) {
    console.log("mk " + filter)
    this.showReset = false;
    if (filter === 'language' && this.selectedLanguages.length > 0) {
      console.log("mk " + this.selectedLanguages.length)
      this.isSelectedLan = true;
      this.isLoading = true;
      this.showReset = true;
      this.dropdownFilter.showLanguageDropdown = false;
      this.searchWithLanguage.emit(this.selectedLanguages.map(language => language.value).join(','));
    }
    const currentYear = new Date().getFullYear();
    console.log(this.endDate)
    if (this.endDate == null || this.endDate == '') {
      this.endDate = currentYear.toString();
    }
    if (filter === 'timeFrame') {
      this.isLoading = true;
      this.isTimeFrameSelected = true;
      this.showReset = true;
      this.dropdownFilter.showTimeframeDropdown = false;
      this.searchWithTimeFrame.emit({
        startDate: this.startDate,
        endDate: this.endDate,
      });
    }

    if (filter === 'mood') {
      this.isLoading = true;
      this.showReset = true;
      let selectedMoods = this.moodFilters.filter((mood) => mood.checked);
      this.selectedMoods = selectedMoods;
      this.dropdownFilter.showMoodDropdown = false;
      console.log(this.selectedMoods)
      this.searchWithSelectedMoods.emit(selectedMoods);
    }

    if (filter === 'location') {
      console.log("selectedCountry " + this.selectedCountry)
      this.isSelectedCountry = true;
      this.isLoading = true;
      this.showReset = true;
      this.dropdownFilter.showLocationDropdown = false;
      this.searchWithCountry.emit(this.selectedCountry);
    }

    if (filter === 'keyword') {
      console.log("this.includeKeywords " + this.includeKeywords)
      if (this.includeKeywords.length > 0)
        this.isSelectedKeywords = true;
      if (this.excludeKeywords.length > 0)
        this.isSelectedExcludeKeywords = true;
      this.isLoading = true;
      this.showReset = true;
      this.dropdownFilter.showKeyWordDropdown = false;
      // this.searchWithKeyword.emit(this.includeKeywords, this.excludeKeywords);
      this.searchWithKeyword.emit({
        included: this.includeKeywords,
        excluded: this.excludeKeywords
      });

      if (this.includeKeywords.length > 0)
        this.getTabsData();
    }

    if (filter === 'location') {
      console.log("selectedCountry " + this.cityKeyword)
      this.isSelectedCity = true;
      this.isLoading = true;
      this.showReset = true;
      // this.dropdownFilter.showLocationDropdown = false;
      this.searchCity.emit(this.cityKeyword);
    }
  }

  onSelectedLanClear(index: number) {
    console.log(index)
    this.isLoading = true;
    this.searchResults = [];
    // this.isSelectedLan = false;
    console.log(index);
    console.log("mk1 " + this.selectedLanguages.length)
    if (index > -1 && index < this.selectedLanguages.length) {
      this.selectedLanguages.splice(index, 1);
    }
    console.log("mk " + this.selectedLanguages.length)
    this.dropdownFilter["showLanguageDropdown"] = false;
    this.dropdownFilter.showLanguageDropdown = false;
    // this.searchWithFilter('language');
    if (this.selectedLanguages.length == 0) {
      this.isSelectedLan = false;
    }
    this.checkReset();
    console.log(this.selectedLanguages.map(language => language.value).join(','));
    this.searchWithLanguage.emit(this.selectedLanguages.map(language => language.value).join(','));
    console.log(this.dropdownFilter.showLanguageDropdown)
    // this.showDropdownFilters('language');
  }

  onSelectedLocationClear() {
    console.log(this.dropdownFilter.showLocationDropdown)
    this.isLoading = true;
    this.searchResults = null;
    this.isSelectedCountry = false;
    this.selectedCountry = '';
    this.dropdownFilter["showLocationDropdown"] = false;
    this.dropdownFilter.showLocationDropdown = false;
    // this.searchWithFilter('language');
    this.checkReset();
    this.searchWithCountry.emit(null);
    console.log(this.dropdownFilter.showLocationDropdown)
  }

  onSelectedMoodClear(index: number) {
    this.isLoading = true;
    this.searchResults = null;
    if (index > -1 && index < this.selectedMoods.length) {
      this.selectedMoods[index].checked = false;
    }
    let selectedMoods = this.selectedMoods.filter((mood) => mood.checked);
    this.selectedMoods = selectedMoods;
    this.dropdownFilter["mood"] = false;
    this.dropdownFilter.showMoodDropdown = false;
    this.checkReset();
    this.searchWithSelectedMoods.emit(this.selectedMoods);
    // this.searchWithFilter('mood');
    // this.showDropdownFilters('mood');
  }

  onSelectedTimeClear() {
    this.isLoading = true;
    this.searchResults = null;
    this.isTimeFrameSelected = false;
    this.startDate = '';
    this.endDate = '';
    this.dropdownFilter["timeFrame"] = false;
    this.dropdownFilter.showTimeframeDropdown = false;
    this.checkReset();
    this.searchWithTimeFrame.emit({
      startDate: '',
      endDate: '',
    });
    // this.searchWithFilter('timeFrame');
    // this.showDropdownFilters('timeFrame');
  }

  onSelectedIncludeClear(index: number) {

    // this.isLoading = true;
    this.searchResults = null;
    console.log(this.includeKeywords)
    if (index > -1 && index < this.includeKeywords.length) {
      this.includeKeywords.splice(index, 1);
    }
    console.log(this.includeKeywords)
    console.log(this.includeKeywords.length)
    this.dropdownFilter["showKeyWordDropdown"] = false;
    this.dropdownFilter.showKeyWordDropdown = false;
    if (this.includeKeywords.length == 0) {
      this.isSelectedKeywords = false;
      this.includeKeywords = [];
      console.log(this.includeKeywords.length)
      // this.getTabsData();
    }
    this.checkReset();
    this.searchWithKeyword.emit({
      included: this.includeKeywords,
      excluded: this.excludeKeywords
    });

    console.log(this.isSelectedExcludeKeywords)
    // this.showDropdownFilters('language');

    this.getTabsData();
  }

  onSelectedExcludeClear(index: number) {
    this.isLoading = true;
    this.searchResults = null;

    // this.excludeKeywords = [];
    if (index > -1 && index < this.excludeKeywords.length) {
      this.excludeKeywords.splice(index, 1);
    }
    this.dropdownFilter["showKeyWordDropdown"] = false;
    this.dropdownFilter.showKeyWordDropdown = false;

    if (this.excludeKeywords.length == 0) {
      this.isSelectedExcludeKeywords = false;
    }

    this.checkReset();
    this.searchWithKeyword.emit({
      included: this.includeKeywords,
      excluded: this.excludeKeywords
    });
    console.log(this.dropdownFilter.showKeyWordDropdown)
    // this.showDropdownFilters('language');
  }

  onSelectedCityClear() {
    console.log(this.dropdownFilter.showLocationDropdown)
    this.isLoading = true;
    this.searchResults = null;
    this.isSelectedCity = false;
    this.citySearch = '';
    this.cityKeyword = '';
    this.dropdownFilter["showLocationDropdown"] = false;
    this.dropdownFilter.showLocationDropdown = false;
    // this.searchWithFilter('language');
    this.checkReset();
    this.searchCity.emit(null);
    console.log(this.dropdownFilter.showLocationDropdown)
  }

  checkReset() {
    if (this.startDate || this.selectedLanguages.length > 0 || this.selectedMoods.length > 0 || this.includeKeywords.length > 0 || this.excludeKeywords.length > 0 || this.isSelectedCity || this.isSelectedCountry) {
      this.showReset = true;
    } else {
      this.showReset = false;
    }
  }

  onReset() {
    this.isLoading = true;
    this.searchResults = null;
    this.barChartData = [];
    this.timeLineChartData = [];
    if (this.selectedMoods.length > 0) {
      // this.onSelectedMoodClear()
      this.selectedMoods = [];
      this.searchWithSelectedMoods.emit([]);
    }

    if (this.selectedLanguages.length > 0) {
      this.isSelectedLan = false;
      this.searchWithLanguage.emit('');
      // this.onSelectedLanClear()
    }

    if (this.startDate) {
      this.onSelectedTimeClear()
    }

    if (this.selectedCountry) {
      this.onSelectedLocationClear()
    }

    if (this.includeKeywords.length > 0 || this.excludeKeywords.length > 0) {
      console.log()
      // this.onSelectedIncludeClear()
      this.includeKeywords = [];
      this.excludeKeywords = [];
      this.searchWithKeyword.emit({
        included: [],
        excluded: []
      });
      if (this.includeKeyword.length > 0)
        this.getTabsData();
    }

    if (this.isSelectedCity) {
      this.onSelectedCityClear()
    }

    this.isSelectedKeywords = false;
    this.isSelectedExcludeKeywords = false;
  }

  onPlayYouTube(song: any) {
    console.log(song.artist_name)
    var str = `${song.artist.artist.replace(" ", "+")}+${song.title.replace(" ", "+")}`;
    window.open('https://www.youtube.com/results?search_query=' + str, '_blank');
  }

  addKeyword(type: String) {
    console.log(type)
    if (type == "include") {
      if (this.includeKeyword.trim() && !this.includeKeywords.includes(this.includeKeyword)) {
        this.includeKeywords.push(this.includeKeyword);
        this.includeKeyword = ''; // Reset input after adding
      }
    } else {
      if (this.excludeKeyword.trim() && !this.excludeKeywords.includes(this.excludeKeyword)) {
        this.excludeKeywords.push(this.excludeKeyword);
        this.excludeKeyword = ''; // Reset input after adding
      }
    }

  }

  onKeydownInclude(event: KeyboardEvent): void {
    console.log(event.code)
    if (event.code === 'Space' || event.key === 'Enter') {
      event.preventDefault(); // Optionally prevent the default space behavior
      if (this.includeKeyword.trim() && !this.includeKeywords.includes(this.includeKeyword)) {
        this.includeKeywords.push(this.includeKeyword);
        this.includeKeyword = ''; // Reset input after adding

      }
    }
  }

  onIncludeAdd() {
    if (this.includeKeyword.trim() && !this.includeKeywords.includes(this.includeKeyword)) {
      this.includeKeywords.push(this.includeKeyword);
      // this.getTabsData();
      this.includeKeyword = ''; // Reset input after adding

    }
  }

  onExcludeAdd() {
    if (this.excludeKeyword.trim() && !this.excludeKeywords.includes(this.excludeKeyword)) {
      this.excludeKeywords.push(this.excludeKeyword);
      this.excludeKeyword = ''; // Reset input after adding
    }
  }

  onKeydownExclude(event: KeyboardEvent): void {
    console.log(event.code)
    if (event.code === 'Space' || event.key === 'Enter') {
      event.preventDefault(); // Optionally prevent the default space behavior
      if (this.excludeKeyword.trim() && !this.excludeKeywords.includes(this.excludeKeyword)) {
        this.excludeKeywords.push(this.excludeKeyword);
        this.excludeKeyword = ''; // Reset input after adding
      }
    }
  }

  removeKeyword(keyword: string) {
    this.includeKeywords = this.includeKeywords.filter(k => k !== keyword);
    this.getTabsData();
  }

  removeKeywordExlude(keyword: string) {
    this.excludeKeywords = this.excludeKeywords.filter(k => k !== keyword);
  }

  // onKeydownCity(event: KeyboardEvent): void {
  //   // this.searchCity.emit(this.citySearch);
  //   // this.citySearch = "";
  //   this.searchWithFilter("city");
  //   this.cityKeyword = this.citySearch;
  //   this.citySearch = "";
  // }

  onSearchCity() {
    // this.searchCity.emit(this.citySearch);
    // this.citySearch = "";
    // this.searchWithFilter("city");
    this.cityKeyword = this.citySearch;
    this.citySearch = "";
  }

  onCityClear() {
    this.citySearch = "";
    this.cityKeyword = "";
  }

  highlightText(text: string, keywords: string[]): string {
    console.log(text)
    console.log(keywords)
    let regex = new RegExp(`(${keywords.join('|')})`, ''); // Create a case-insensitive regex for all keywords
    return text.replace(regex, '<span style="color: green; text-decoration: underline;">$1</span>');
  }

  onShare(index: number) {
    console.log(index)
    this.activeDropdown = this.activeDropdown === index ? null : index;
    console.log(this.activeDropdown)
  }

  copyLink(song: any) {
    console.log(song.artist_name)
    var str = `${song.artist.artist.replace(" ", "+")}+${song.title.replace(" ", "+")}`;

    const link = 'https://www.youtube.com/results?search_query=' + str; // Replace with the link you want to copy
    navigator.clipboard.writeText(link).then(() => {
      // alert('Link copied to clipboard!');
    }, () => {
      alert('Failed to copy the link');
    });
    this.activeDropdown = null
    this.toastr.success('Link Copied', '', {
      positionClass: 'toast-top-center',
      closeButton: false,
    });
  }

  onShareGrid(index: number) {
    console.log(index)
    this.activeDropdownGrid = this.activeDropdownGrid === index ? null : index;
    console.log(this.activeDropdownGrid)
  }

  openGoogle(song: any) {
    const encodedQuery = encodeURIComponent(song.lyrics);
    const googleSearchUrl = `https://www.google.com/search?q=${encodedQuery}`;
    window.open(googleSearchUrl, '_blank');
  }

  data = [];

  isExport = false;
  exportCSV() {

    for (let i = 0; i < this.searchResults['data']?.hits.length; i++) {
      const generatedData = {
        songid: this.searchResults['data']?.hits[i].songid,
        name: this.searchResults['data']?.hits[i].title,
        description: `This is the description for Item ${i}`,
        language_iso: this.searchResults['data']?.hits[i].language_iso,
        title: this.searchResults['data']?.hits[i].title,
        // lyrics: this.searchResults['data']?.hits[i].lyrics,
        // songid: this.searchResults['data']?.hits[i].songid,
        artist: this.searchResults['data']?.hits[i].artist_name,
        // album: this.searchResults['data']?.hits[i].album.album,
        artist_country: this.searchResults['data']?.hits[i].artist_country,
        artist_latitude: this.searchResults['data']?.hits[i].artist_latitude,
        artist_longitude: this.searchResults['data']?.hits[i].artist_latitude,
        syllables_per_line: this.searchResults['data']?.hits[i].syllables_per_line, syllables_per_word: this.searchResults['data']?.hits[i].syllables_per_word, syllable_variation: this.searchResults['data']?.hits[i].syllable_variation,
        novel_word_proportion: this.searchResults['data']?.hits[i].novel_word_proportion, rhymes_per_line: this.searchResults['data']?.hits[i].rhymes_per_line, rhymes_per_syllable: this.searchResults['data']?.hits[i].rhymes_per_syllable, rhyme_density: this.searchResults['data']?.hits[i].rhyme_density, end_pairs_per_line: this.searchResults['data']?.hits[i].end_pairs_per_line, end_pairs_grown: this.searchResults['data']?.hits[i].end_pairs_grown, end_pairs_shrunk: this.searchResults['data']?.hits[i].end_pairs_shrunk, end_pairs_even: this.searchResults['data']?.hits[i].end_pairs_even, average_end_score: this.searchResults['data']?.hits[i].average_end_score,
        average_end_syl_score: this.searchResults['data']?.hits[i].average_end_syl_score, singles_per_rhyme: this.searchResults['data']?.hits[i].singles_per_rhyme, doubles_per_rhyme: this.searchResults['data']?.hits[i].doubles_per_rhyme, triples_per_rhyme: this.searchResults['data']?.hits[i].triples_per_rhyme, quads_per_rhyme: this.searchResults['data']?.hits[i].quads_per_rhyme, perfect_rhymes: this.searchResults['data']?.hits[i].perfect_rhymes, line_internals_per_line: this.searchResults['data']?.hits[i].line_internals_per_line, bridges_per_line: this.searchResults['data']?.hits[i].bridges_per_line, compounds_per_line: this.searchResults['data']?.hits[i].compounds_per_line, chaining_per_line: this.searchResults['data']?.hits[i].chaining_per_line, links_per_line: this.searchResults['data']?.hits[i].links_per_line,

      };

      this.data.push(generatedData);
    }

    const csvData = this.convertToCSV(this.data);
    this.downloadCSV(csvData, 'exported-data.csv');
  }

  convertToCSV(objArray: any[]): string {
    this.isExport = true;
    const array = [Object.keys(objArray[0])].concat(objArray);
    return array.map(row => Object.values(row).join(',')).join('\n');
  }

  downloadCSV(csvData: string, filename: string): void {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    a.click();
    this.isExport = false;
  }


  ngOnDestroy(): void {
    this.isLoading = false;
    this.searchResults = null;
    this.searchTerm = "";
    this.citySearch = "";
    this.cityKeyword = '';
    this.barChartData = [];
    this.timeLineChartData = [];
    // this.onReset()
    this.baseTemplateService.setSearchTerm("");
    console.log("ngOnDestroy")
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event): void {
    const clickedElement = event.target as HTMLElement;
    if (!clickedElement.closest('.dropdown-share')) {
      this.activeDropdown = null; // Close dropdown
    }

    if (!clickedElement.closest('.dropdown-share')) {
      this.activeDropdownGrid = null; // Close dropdown
    }

    if (!clickedElement.closest('.dropdown-container')) {
      // this.dropdownFilter.showLanguageDropdown =
      //   false;
    }

  }

  // Close dropdown on scrolling
  @HostListener('window:scroll', [])
  onScroll(): void {
    this.activeDropdown = null; // Close dropdown
  }

  getLanguageLabel(isoCode: string): string {
    const lang = this.languages.find(lang => lang.value === isoCode);
    return lang ? lang.label : isoCode; // Return the label or the code if not found
  }

  onSort({ column, direction }: { column: string, direction: 'asc' | 'desc' }) {
    console.log(column)
    if (this.sortedColumn === column) {
      // Toggle sort direction
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      // New column sorting
      this.sortedColumn = column;
      this.sortDirection = 'asc';
    }

    this.updateSortData()
  }

  sortBy({ column, direction }: { column: string, direction: 'asc' | 'desc' }) {
    this.sortedColumn = column;
    this.sortDirection = direction;
    this.updateSortData()
  }

  updateSortData() {
    this.searchResults['data']?.hits.sort((a, b) => {
      // console.log(a.title)
      let aValue = String(a?.title ?? ''); // Convert to string
      let bValue = String(b?.title ?? '');

      if (this.sortedColumn === 'title') {
        return this.sortDirection === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
      else if (this.sortedColumn === 'album') {
        let aValue = String(a?.album?.album ?? ''); // Convert to string
        let bValue = String(b?.album?.album ?? '');
        return this.sortDirection === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else if (this.sortedColumn === 'artist') {
        let aValue = String(a?.artist?.artist ?? ''); // Convert to string
        let bValue = String(b?.artist?.artist ?? '');
        return this.sortDirection === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
      else if (this.sortedColumn === 'release_date') {
        let aValue = String(a?.album?.release_date ?? ''); // Convert to string
        let bValue = String(b?.album?.release_date ?? '');

        return this.sortDirection === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
      return 0; // Default case
    });
  }
}

