import { Component } from '@angular/core';

@Component({
  selector: 'rap-contributions',
  templateUrl: './contributions.container.component.html',
  styleUrls: ['./contributions.container.component.scss']
})
export class ContributionsContainerComponent {

}
