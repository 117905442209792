import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { songsReducer } from './songs.reducer';
import { SongsSelectors } from './songs.selectors';
import { SongsEffects } from './songs.effects';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature(SongsEffects),
    StoreModule.forFeature('songs', songsReducer),
  ],
  exports: [StoreModule, EffectsModule],
  providers: [SongsSelectors],
})
export class SongsStoreModule {}
