import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';

@Injectable({
  providedIn: 'root',
})
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthService, private toastr: ToastrService, private loaderService: AppService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loaderService.show();
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // Handle 401 Unauthorized response
          // Clear user session and redirect to login page
          this.authService.logout();
          this.toastr.success('Session expired, please login again.', '', {
            positionClass: 'toast-top-center',
            closeButton: false,
          });
        } else {

          this.toastr.success('Something went wrong', '', {
            positionClass: 'toast-top-center',
            closeButton: false,
            timeOut: 5000
          });
          this.loaderService.hide();
        }
        return throwError(error);
      })
    );
  }
}