<rap-breadcrumb-default [exploreLabel]="'Rap Almanac'" [exploreLink]="'/dashboard/explore'"
  [exploreImage]="'../assets/images/filled.svg'" [rostersLabel]="'Rosters'"
  (rostersClick)="onRosters()"></rap-breadcrumb-default>

<div class="section-body">
  <div class="section-title">Rosters</div>
  <rap-rect-primary [label]="'Add New'" [btnClass]="'btn btn-dark'" (clickEvent)="onAddNew()"></rap-rect-primary>
  <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
</div>
<rap-search-input [(searchValue)]="filters.search" (search)="getSearchResults($event)"
  (clear)="onClearSearch()"></rap-search-input>
<div style="padding: 0px 10px;">
  <ng-container *ngIf="rosters?.length > 0">
    <!-- <div class="custom-container"> -->
    <div *ngIf="rosters?.length > 0">
      <table class="table rounded-4 overflow-hidden custom-table">
        <thead>
          <tr>
            <th scope="col">
              <div class="sortable">
                <div (click)="onSort({ column: 'name', direction: sortDirection })">Name</div>
                <rap-sort-arrows [column]="'name'" [sortedColumn]="sortedColumn" [sortDirection]="sortDirection"
                  (sort)="sortBy($event)">
                </rap-sort-arrows>
              </div>
            </th>
            <!-- <th scope="col" class="sortable" (click)="sortBy('name')">
              Name
              <span class="icons">
                <span class="arrow" [ngClass]="{ active: sortedColumn === 'name' && sortDirection === 'asc' }">
                  ▲
                </span>
                <span class="arrow" [ngClass]="{ active: sortedColumn === 'name' && sortDirection === 'desc' }">
                  ▼
                </span>
              </span>
            </th> -->
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let roster of paginatedRosters">
            <td scope="row">{{ roster?.name }}</td>
            <td>
              <rap-rect-primary [label]="'Manage Students'" [btnClass]="'btn btn-light rect-secondary-btn'"
                (clickEvent)="manageStudent(roster)"></rap-rect-primary>
              <rap-rect-primary [label]="'Delete'" [btnClass]="'btn btn-dark rect-primary-btn'"
                (clickEvent)="deleteRoster(roster.id)"></rap-rect-primary>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="pagination">
        <rap-pagination [collectionSize]="rostersCount" [page]="page" [pageSize]="pageSize"
          (onPageChange)="onPageChange($event)"></rap-pagination>
      </div>
    </div>
    <!-- </div> -->

  </ng-container>
</div>
<rap-no-data [data]="rosters" [isLoading]="isLoading" [filters]="filters" [message]="'No rosters found'"></rap-no-data>