import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { passwordValidator } from '../shared/validators/password.validator';

@Injectable({
  providedIn: 'root',
})
export class CreateAccountPresenter {
  constructor(private formBuilder: FormBuilder) { }

  initializeCreateAccountForm() {
    return this.formBuilder.group(
      {
        email: [null, [Validators.required, Validators.email]],
        password: [null, [Validators.required, passwordValidator()]],
        confirmPassword: [null, [Validators.required]],
        firstName: [null, [Validators.required]],
        lastName: [null, [Validators.required]],
        userName: [null, [Validators.required]],
        dateOfBirth: [null, [Validators.required, this.minimumAgeValidator(18)]],
      },
      { validator: this.passwordMatchValidator },
    );
  }

  minimumAgeValidator(minAge: number) {
    return (control: AbstractControl) => {
      const today = new Date();
      const birthDate = new Date(control.value);
      const age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();
      const dayDifference = today.getDate() - birthDate.getDate();

      // Check if the user is under 18
      if (
        age < minAge ||
        (age === minAge && (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)))
      ) {
        return { underage: true };
      }
      return null;  // Valid
    };
  }

  passwordMatchValidator(group: FormGroup) {
    const password = group.get('password').value;
    const confirmPassword = group.get('confirmPassword').value;

    return password === confirmPassword ? null : { passwordsMismatch: true };
  }
}
