import { Action, createReducer, on } from '@ngrx/store';
import { initialUsersState, UsersState } from './users.state';
import { getUsersSuccess, deleteUserSuccess, resetEditableUser, getUserByIdSuccess, usersError, approveUserSuccess, performActionSuccess, getRolesSuccess, getRostersSuccess, clearErrorMessage, updateUserSuccess } from './users.action';

export const usersReducer = (
  state: UsersState | undefined,
  action: Action,
): UsersState => reducer(state, action);

const reducer = createReducer(
  initialUsersState,
  on(getUsersSuccess, (state, { users }) => ({
    ...state,
    users: users.rows || [],
    usersCount: users.count
  })),
  on(getRolesSuccess, (state, { roles }) => ({
    ...state,
    roles,
  })),
  on(getRostersSuccess, (state, { rosters }) => ({
    ...state,
    rosters,
  })),
  on(deleteUserSuccess, (state, { userId }) => ({
    ...state,
    users: state.users.filter((user) => user.id !== userId),
  })),
  on(resetEditableUser, (state) => ({ ...state, editableUser: null })),
  on(getUserByIdSuccess, (state, { user }) => ({
    ...state,
    editableUser: user,
  })),
  on(usersError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  })),
  on(approveUserSuccess, (state, { userId }) => ({
    ...state,
    users: state.users.map((user) => {
      if (user.id === userId) {
        return { ...user, approved: true };
      }
      return user;
    }),
  })),
  on(performActionSuccess, (state, { actionOnUsers }) => ({
    ...state,
    users: updatedUser(state.users, actionOnUsers)
  })),
  on(clearErrorMessage, (state) => ({
    ...state,
    errorMessage: null,
  })),
);

function updatedUser(users, actionOnUsers) {
  if (actionOnUsers.action === 'disable') {
    return users.map((user) => {
      return { ...user, active: actionOnUsers.usersIds.includes(user.id) ? false : true };
    });
  } else {
    return users.filter((user) => !actionOnUsers.usersIds.includes(user.id));
  }
}
