import { createAction, props } from '@ngrx/store';
import { CreateProducerRequest } from 'src/app/models/producers/create-producer-request.model';
import { Producer } from 'src/app/models/producers/producer.model';
import { UpdateProducerRequest } from 'src/app/models/producers/update-producer-request.model';

export const getMyProducers = createAction('[Producers] GET_MY_PRODUCERS');

export const getAllProducers = createAction('[Producers] GET_ALL_PRODUCERS');

export const getProducersSuccess = createAction(
  '[Producers] GET_PRODUCERS_SUCCESS',
  props<{ producers: Producer[] }>(),
);

export const getProducerById = createAction(
  '[Producers] GET_PRODUCERS_BY_ID',
  props<{ producerId: string }>(),
);

export const getProducerByIdSuccess = createAction(
  '[Producers] GET_PRODUCERS_SUCCESS_BY_ID',
  props<{ producer: Producer }>(),
);

export const addProducer = createAction(
  '[Producers] ADD_PRODUCERS',
  props<{ producer: CreateProducerRequest }>(),
);

export const addProducerSuccess = createAction(
  '[Producers] ADD_PRODUCERS_SUCCESS',
  props<{ producer: Producer }>(),
);

export const updateProducer = createAction(
  '[Producers] UPDATE_PRODUCERS',
  props<{ producer: UpdateProducerRequest }>(),
);

export const updateProducerSuccess = createAction(
  '[Producers] UPDATE_PRODUCERS_SUCCESS',
  props<{ producer: Producer }>(),
);

export const deleteProducer = createAction(
  '[Producers] DELETE_PRODUCERS',
  props<{ producerId: string }>(),
);

export const deleteProducerSuccess = createAction(
  '[Producers] DELETE_PRODUCERS_SUCCESS',
  props<{ producerId: string }>(),
);

export const producersError = createAction(
  '[Producers] PRODUCERS_ERROR',
  props<{ errorMessage: string }>(),
);

export const clearErrorMessage = createAction('[Producers] PRODUCERS_ERROR');
