import { Producer } from 'src/app/models/producers/producer.model';

export interface ProducersState {
  producers: Producer[];
  editableProducer: Producer | null;
  errorMessage: string | null;
}

export const initialProducersState: ProducersState = {
  producers: [],
  editableProducer: null,
  errorMessage: null,
};
