import { Roster } from 'src/app/models/rosters/rosters.model';

export interface RostersState {
  rosters: Roster[];
  editableRoster: Roster;
  errorMessage: string;
  roasterCount: number
}

export const initialRostersState: RostersState = {
  rosters: null,
  editableRoster: null,
  errorMessage: null,
  roasterCount: 0

};
