import { Component } from '@angular/core';

@Component({
  selector: 'rap-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent {
  title: string = 'Welcome to the Static Data Page';
  description: string = 'This page contains static information.';
  items: string[] = ['Item 1', 'Item 2', 'Item 3', 'Item 4'];
}
