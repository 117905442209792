import { Action, createReducer, on } from '@ngrx/store';
import { initialRolesState, RolesState } from './roles.state';
import {
  clearErrorMessage,
  deleteRoleSuccess,
  getPermissionsSuccess,
  getRoleByIdSuccess,
  getRolesSuccess,
  resetEditableRole,
  rolesError,
} from './roles.action';

export const rolesReducer = (
  state: RolesState | undefined,
  action: Action,
): RolesState => reducer(state, action);

const reducer = createReducer(
  initialRolesState,
  on(getRolesSuccess, (state, { roles }) => ({
    ...state,
    roles,
  })),
  on(getPermissionsSuccess, (state, { permissions }) => ({
    ...state,
    permissions,
  })),
  on(deleteRoleSuccess, (state, { roleId }) => ({
    ...state,
    roles: state.roles.filter((role) => role.id !== roleId),
  })),
  on(resetEditableRole, (state) => ({ ...state, editableRole: null })),
  on(getRoleByIdSuccess, (state, { role }) => ({
    ...state,
    editableRole: role,
  })),
  on(rolesError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  })),
  on(clearErrorMessage, (state) => ({
    ...state,
    errorMessage: null,
  })),
);
