import { AfterViewInit, Component, Input, SimpleChanges } from '@angular/core';
import { latLng, tileLayer } from 'leaflet';
import * as Leaflet from 'leaflet';
import * as L from 'leaflet';
@Component({
  selector: 'rap-map-tab',
  templateUrl: './map-tab.component.html',
  styleUrls: ['./map-tab.component.scss']
})
export class MapTabComponent implements AfterViewInit {
  @Input() searchResults: any;
  center = L.latLng(0, 0); // Center coordinates
  zoom = 1.621; // Initial zoom
  minZoom = 1.621; // Minimum zoom level
  outerBounds = L.latLngBounds(L.latLng(-90, -180), L.latLng(90, 180)); // Bounds

  map!: Leaflet.Map;
  markers: Leaflet.Marker[] = [];
  options2 = {
    layers: [
      Leaflet.tileLayer('https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png?api_key=e6b82eff-d5bd-4ae8-959f-f6b0c019ff5', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      })
    ],
    zoom: this.zoom,
    center: latLng(46.879966, -121.726909),
    minZoom: this.minZoom,
    maxBounds: this.outerBounds
  }
  customIcon = L.icon({
    iconUrl: '../assets/images/favicon.png',  // Path to your custom icon image
    shadowUrl: '../assets/images/favicon.png',  // Optional: Path to the shadow image
    iconSize: [25, 41],  // Size of the icon
    iconAnchor: [12, 41],  // Point of the icon which will correspond to marker's location
    popupAnchor: [1, -34],  // Point from which the popup should open relative to the iconAnchor
    shadowSize: [1, 1]    // Size of the shadow icon
  });


  ngAfterViewInit() {

  }

  ngOnInit() {
    this.initMarkers()
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(this.searchResults);
    console.log(changes['searchResults'])
    if (changes['searchResults'] && this.searchResults) {
      this.initMarkers()
      console.log(changes['searchResults'])

    }
  }



  initMarkers() {

    console.log(this.searchResults['data']?.hits.length)
    for (let index = 0; index < this.searchResults['data']?.hits.length; index++) {
      const data = this.searchResults['data']?.hits[index] as any;
      console.log(data)
      console.log(data.origin_latitude)
      if (data.origin_latitude != null && data.origin_longitude != null) {
        var position = { lat: data.origin_latitude, lng: data.origin_longitude };
        const encodedQuery = encodeURIComponent(data.lyrics);
        const googleSearchUrl = `https://www.google.com/search?q=${encodedQuery}`;
        const marker = this.generateMarker(data, index);
        marker.addTo(this.map).bindPopup(`<b>${data.origin_latitude},  ${data.origin_longitude}</b>`);
        marker.setIcon(this.customIcon);
        const popupContent = `
    <div style="font-family: Arial, sans-serif;">
        <p><strong>Name:</strong> ${data.artist.artist}</p>
        <p><strong>Album:</strong> <em>${data.album.album}</em></p>
         <p><strong>Song:</strong> <em>${data.title}</em></p>
        <p><strong>Latitude:</strong> ${data.origin_latitude}</p>
        <p><strong>Longitude:</strong> ${data.origin_longitude}</p>
 <p><a href="${googleSearchUrl}" target="_blank">Song Lyrics</a></p>
    </div>
`;
        marker.bindPopup(popupContent).addTo(this.map);
        this.map.panTo(position);
        this.markers.push(marker)
      }

    }

    setTimeout(() => {
      this.map?.invalidateSize();
    }, 500);

  }

  generateMarker(data: any, index: number) {
    var position = { lat: data.origin_latitude, lng: data.origin_longitude };
    console.log(position)
    return Leaflet.marker(position, { draggable: false })
      .on('click', (event) => this.markerClicked(event, index))
      .on('dragend', (event) => this.markerDragEnd(event, index));
  }

  onMapReady($event: Leaflet.Map) {
    this.map = $event;
    // this.initMarkers();
    // Optional: Force Leaflet to recalculate map size
    // setTimeout(() => {
    //   this.map?.invalidateSize();
    // }, 0);
  }

  mapClicked($event: any) {
    console.log($event.latlng.lat, $event.latlng.lng);
  }

  markerClicked($event: any, index: number) {
    console.log($event.latlng.lat, $event.latlng.lng);
  }

  markerDragEnd($event: any, index: number) {
    console.log($event.target.getLatLng());
  }
}
