import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PasswordHelpComponent } from './password-help/password-help.component';
import { BaseTemplateComponent } from './base-template/base-template.component';
import { SigninComponent } from './signin/signin.component';
import { CreateAccountComponent } from './create-account/create-account.component';
import { VerifyAccountComponent } from './create-account/verify-account/verify-account.component';
import { AuthGuard } from './guards/auth.guard';
import { PrivacyPolicyComponent } from './shared/privacy-policy/privacy-policy.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { CardViewComponent } from './card-view/card-view.component';
import { LearningComponent } from './learning/learning.component';
const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'signin',
  },
  {
    path: 'signin',
    component: SigninComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'create-account',
    component: CreateAccountComponent,
  },
  {
    path: 'onboarding',
    loadChildren: () =>
      import('./onboarding/onboarding.module').then((m) => m.OnboardingModule),
  },
  {
    path: 'password-help',
    component: PasswordHelpComponent,
  },
  {
    path: 'verify-email',
    component: VerifyAccountComponent,
  },
  {
    path: 'dashboard',
    component: BaseTemplateComponent,
    loadChildren: () =>
      import('./base-template/base-template.module').then(
        (m) => m.BaseTemplateModule,
      ),
    canActivate: [AuthGuard],
  },
  { path: 'privacy-notice', component: PrivacyPolicyComponent },
  {
    path: 'app/account/payment_success',
    component: PaymentSuccessComponent,
    // canActivate: [AuthGuard],
  },
  // {
  //   path: 'profile',
  //   component: ProfileComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'profile',
    loadChildren: () =>
      import('./user-profile/user-profile.module').then((m) => m.UserProfileModule),
  },
  { path: 'cards/:id', component: CardViewComponent },
  {
    path: 'learning',
    component: LearningComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
