import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { INavbarData } from 'src/app/nav-bar/helper';

@Component({
  selector: 'rap-profile-nav-bar',
  templateUrl: './profile-nav-bar.component.html',
  styleUrls: ['./profile-nav-bar.component.scss']
})
export class ProfileNavBarComponent {
  screenWidth = 0;
  navData: INavbarData[] = [
    {
      routeLink: '/profile',
      icon: 'bi bi-person',
      label: 'Account Info',
      target: "",
      externalLink: null,
      enabled: true
    }, {
      routeLink: '/change-password',
      icon: 'bi bi-lock',
      label: 'Security',
      target: "",
      externalLink: null,
      enabled: true
    }];
  constructor(private router: Router) {
    console.log(this.navData)
  }

  ngOnInit() {
    console.log(this.navData)
    // this.screenWidth = window.innerWidth;
    // this.navData = [
    //   {
    //     routeLink: '/user/profile',
    //     icon: 'bi bi-globe',
    //     label: 'Profile',
    //     target: "",
    //     externalLink: null
    //   }, {
    //     routeLink: '/user/change-password"',
    //     icon: 'bi bi-globe',
    //     label: 'Change Password',
    //     target: "",
    //     externalLink: null
    //   }];
    // this.eventsSubscription = this.profile.subscribe(() => this.doSomething());
  }

}
