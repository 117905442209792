import { Action, createReducer, on } from '@ngrx/store';
import { initialStudentsState, StudentsState } from './students.state';
import {
  deleteStudentSuccess,
  getStudentsSuccess,
  resetErrorMessage,
  setEditableStudent,
  setErrorMessage,
  setRosterId,
} from './students.action';

export const studentsReducer = (
  state: StudentsState | undefined,
  action: Action,
): StudentsState => reducer(state, action);

const reducer = createReducer(
  initialStudentsState,
  on(getStudentsSuccess, (state, { students }) => ({
    ...state,
    students,
  })),
  on(deleteStudentSuccess, (state, { studentId }) => ({
    ...state,
    rosters: state.students.filter((student) => student.id !== studentId),
  })),
  on(setEditableStudent, (state, { student }) => ({
    ...state,
    editableStudent: student,
  })),
  on(setRosterId, (state, { rosterId }) => ({
    ...state,
    rosterId,
  })),
  on(setErrorMessage, (state, { errorMsg }) => ({
    ...state,
    errorMessage: errorMsg,
  })),
  on(resetErrorMessage, (state) => ({
    ...state,
    errorMessage: null,
  })),
);
