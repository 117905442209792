import { Component, EventEmitter, Input, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { CreateUserRequest } from 'src/app/models/users/create-user-request.model';
import { UpdateUserRequest } from 'src/app/models/users/update-user-request.model';
import { User } from 'src/app/models/users/user.model';
import { DropdownValues } from '../store/users.state';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'rap-add-edit-user-ui',
  templateUrl: './add-edit-user.component.html',
  styleUrls: ['./add-edit-user.component.scss']
})
export class AddEditUserComponent {
  @Input() title: string;
  @Input() editableUser: User;
  @Input() roles: DropdownValues[];
  @Input() rosters: DropdownValues[];
  @Input() errorMessage: string;
  @Output() createNewUserEmitter = new EventEmitter<CreateUserRequest>();
  @Output() updateUserEmitter = new EventEmitter<UpdateUserRequest>();

  userForm: FormGroup;
  submitted: boolean = false;
  isLoading = false;
  public settings = {};
  public rolesSettings = {};

  constructor(private formBuilder: FormBuilder, private toastr: ToastrService, private loaderService: AppService, private router: Router) {
    this.loaderService.loading$.subscribe(isLoading => {
      console.log("isLoading")
      console.log(isLoading)
      this.isLoading = isLoading;
    });


    if (this.editableUser) {
      this.isLoading = true
    }
  }

  ngOnInit(): void {

    this.userForm = this.formBuilder.group({
      firstName: new FormControl(null, [Validators.required]),
      lastName: new FormControl(null, [Validators.required]),
      userName: new FormControl(null, [Validators.required]),
      email: new FormControl(null),
      role: new FormControl(null, [Validators.required]),
      studentRoster: new FormControl(null),
      active: [false, [Validators.required]],
      allowMapperDelight: [false, [Validators.required]],
    });

    this.isLoading = true;
    this.settings = {
      singleSelection: true,
      idField: 'id',
      textField: 'label',
      allowSearchFilter: true, // Allows search filter
      closeDropDownOnSelection: true, // Close dropdown after selection
      enableCheckAll: false,  // Disable "Select All" option
      itemsShowLimit: 1,      // Only show selected item in dropdown
      unSelectAllText: null,  // No "Unselect All" text
      selectAllText: null,    // No "Select All" text
      allowCheckboxes: false  // 
    };

    this.rolesSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'label',
      allowSearchFilter: true, // Allows search filter
      closeDropDownOnSelection: true, // Close dropdown after selection
      enableCheckAll: false,  // Disable "Select All" option
      itemsShowLimit: 1,      // Only show selected item in dropdown
      unSelectAllText: null,  // No "Unselect All" text
      selectAllText: null,    // No "Select All" text
      allowCheckboxes: false  // 
    };
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['editableUser']?.currentValue) {
      this.isLoading = false;
      console.log(this.editableUser.studentRosterId)
      var v = { "id": this.editableUser.role.id, "label": this.editableUser.role.name }
      console.log(v)

      this.userForm.patchValue({
        firstName: this.editableUser.firstName,
        lastName: this.editableUser.lastName,
        userName: this.editableUser.userName,
        email: this.editableUser.email,
        role: v,
        studentRoster: this.editableUser.student_roster != null ? { "id": this.editableUser.student_roster.id, "label": this.editableUser.student_roster.name } : null,
        active: this.editableUser.active,
        allowMapperDelight: this.editableUser.allowMD,
      });
      console.log(this.userForm.value.role)
    } else {
      this.isLoading = false;
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.userForm.controls;
  }

  addUpdateUser() {
    console.log(this.userForm.value.active)
    console.log(this.userForm.value.allowMapperDelight)

    this.submitted = true;
    if (this.userForm.valid) {
      this.isLoading = true;
      var roleId = this.userForm.value.role.id
      console.log(roleId)
      console.log(this.userForm.value.active)
      console.log(this.userForm)
      let userRequest: CreateUserRequest = {
        data: {
          firstName: this.userForm.value.firstName,
          lastName: this.userForm.value.lastName,
          userName: this.userForm.value.userName,
          emails: [this.userForm.value.email],
          roleId: this.userForm.value.role,
          studentRosterId: this.userForm.value.studentRoster?.id ?? "",
          active: this.userForm.value.active,
          role: roleId,
          student_roster: this.userForm.value.studentRoster?.name ?? "",
        },
      };

      if (this.editableUser) {
        let userRequest: UpdateUserRequest = {
          id: this.editableUser.id,
          data: {
            firstName: this.userForm.value.firstName,
            lastName: this.userForm.value.lastName,
            userName: this.userForm.value.userName,
            emails: [this.userForm.value.email],
            roleId: roleId,
            studentRosterId: this.userForm.value.studentRoster?.id ?? "",
            active: this.userForm.value.active,
            allowMD: this.userForm.value.allowMapperDelight,
          },
        };

        this.updateUserEmitter.emit(userRequest);
      } else {
        this.createNewUserEmitter.emit(userRequest);

      }
    } else {
      if (this.userForm.value.studentRoster == null) {
        this.toastr.success('Select student', '', {
          positionClass: 'toast-top-center',
          closeButton: false,

        });
      }

    }
  }

  onReset() {
    this.userForm.reset();
  }

  Cancel() {
    this.router.navigate(['/dashboard/users'])
  }

  ngOnDestroy() {
    this.isLoading = false;
    this.userForm.reset();
  }
}
