import { Routes } from '@angular/router';
import { Step2Component } from './step2/step2.component';
import { Step3Component } from './step3/step3.component';
import { Step4Component } from './step4/step4.component';
import { OnboardingContainerComponent } from './onboarding.container.component';
import { Step1ContainerComponent } from './step1/step1.container.component';
import { Step5Component } from './step5/step5.component';

export const OnboardingRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'step1',
  },
  {
    path: '',
    component: OnboardingContainerComponent,
    children: [
      // { path: 'step1', component: Step1ContainerComponent },
      { path: 'step1', component: Step2Component },
      { path: 'step2', component: Step3Component },
      { path: 'step3', component: Step4Component },
      { path: 'step4', component: Step5Component },
    ],
  },
];
