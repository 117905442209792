import { Action, createReducer, on } from '@ngrx/store';
import { initialSongsState, SongsState } from './songs.state';
import {
  deleteSongSuccess,
  getAlbums,
  getAlbumsSuccess,
  getArtistsSuccess,
  getProducersSuccess,
  getSongByIdSuccess,
  getSongsSuccess,
  songsError,
  getSongs
} from './songs.action';

export const songsReducer = (
  state: SongsState | undefined,
  action: Action,
): SongsState => reducer(state, action);

const reducer = createReducer(
  initialSongsState,
  on(getSongsSuccess, (state, { songs }) => ({
    ...state,
    songs,
  })),

  on(getAlbumsSuccess, (state, { albums }) => ({
    ...state,
    albums,
  })),

  on(getArtistsSuccess, (state, { artists }) => ({
    ...state,
    artists,
  })),

  on(getProducersSuccess, (state, { producers }) => ({
    ...state,
    producers,
  })),

  on(deleteSongSuccess, (state, { songId }) => ({
    ...state,
    songs: state.songs.filter((song) => song.songid !== songId),
  })),
  on(getSongByIdSuccess, (state, { song }) => ({
    ...state,
    editableSong: song,
  })),
  on(songsError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  })),

  on(getSongs, (state, { search }) => ({
    ...state,
    search: search,
  })),
);
