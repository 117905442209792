import { Roster } from 'src/app/models/rosters/rosters.model';

export interface AnalyticsState {
  analytics: any;
  error: string;
  viewAnalytics: any;
}

export const initialAnalyticsState: AnalyticsState = {
  analytics: null,
  error: '',
  viewAnalytics: null
};
