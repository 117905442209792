<div class="functions-section">
    <div class="section-title">
        {{ title }}
    </div>
    <div class="error-container" *ngIf="errorMessage">
        {{ errorMessage }}
    </div>
    <div class="card">
        <div class="card-body">
            <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
            <form [formGroup]="songForm" (ngSubmit)="addUpdateSong()">
                <div class="form-group">
                    <label for="title">Title<span class="required">*</span>:</label>
                    <div class="input-container">
                        <input type="text" class="form-control" [class.submitted]="submitted" formControlName="title" />
                    </div>
                    <div *ngIf="submitted && f['title'].errors" class="rap-error-text">
                        <span *ngIf="f['title'].errors?.['required']">Title is required</span>
                    </div>
                </div>
                <div class="form-group">
                    <label for="lyrics">Lyrics<span class="required">*</span>:</label>
                    <div class="input-container">
                        <input type="text" class="form-control" [class.submitted]="submitted"
                            formControlName="lyrics" />
                    </div>
                    <div *ngIf="submitted && f['lyrics'].errors" class="rap-error-text">
                        <span *ngIf="f['lyrics'].errors?.['required']">Lyrics is required</span>
                    </div>
                </div>
                <div class="form-group">
                    <label for="typedBy">Typed by</label>
                    <div class="input-container">
                        <input type="text" class="form-control" [class.submitted]="submitted" formControlName="typedBy"
                            [readonly]="true" />
                    </div>
                </div>
                <div class="form-group">
                    <label for="tags">Tags</label>
                    <div class="input-container">
                        <input type="text" class="form-control" [class.submitted]="submitted" formControlName="tags" />
                    </div>
                </div>
                <div class="form-group">
                    <label for="feature">Feature</label>
                    <div class="input-container">
                        <input type="text" class="form-control" [class.submitted]="submitted"
                            formControlName="feature" />
                    </div>
                </div>
                <!-- <div class="form-group">
                    <label for="album">Album</label>
                    <div class="input-container">
                        <select class="form-control" formControlName="album" [class.submitted]="submitted">
                            <option value="" disabled selected>Select Album</option>
                            <option *ngFor="let album of albums" [value]="album.id">
                                {{ album.label }}
                            </option>
                        </select>
                    </div>
                </div> -->
                <div class="form-group">
                    <label for="artist">Album</label>
                    <div class="input-container">
                        <ng-multiselect-dropdown [placeholder]="'Select...'" [settings]="albumSettings" [data]="albums"
                            [(ngModel)]="songForm.value.album" [formControl]="songForm.get('album')">
                        </ng-multiselect-dropdown>

                    </div>


                </div>

                <div class="form-group">
                    <label for="artist">Artist<span class="required">*</span>:</label>
                    <div class="input-container">
                        <ng-multiselect-dropdown [placeholder]="'Select...'" [settings]="settings" [data]="artists"
                            [(ngModel)]="songForm.value.artist" [formControl]="songForm.get('artist')">
                        </ng-multiselect-dropdown>

                    </div>

                    <div class="error" *ngIf="submitted && songForm.get('artist').invalid">
                        Artist is a required field
                    </div>
                </div>
                <div class="form-group">
                    <label for="producer">Producer</label>
                    <div class="input-container">
                        <select class="form-control" formControlName="producer" [class.submitted]="submitted">
                            <option value="" disabled selected>Select Producer</option>
                            <option *ngFor="let producer of producers" [value]="producer.id">
                                {{ producer.label }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-group">
                    <label for="language">Language ISO<span class="required">*</span>:</label>
                    <div class="input-container">
                        <ng-multiselect-dropdown [placeholder]="'Select...'" [settings]="settings" [data]="languages"
                            [(ngModel)]="songForm.value.language" [formControl]="songForm.get('language')">
                        </ng-multiselect-dropdown>

                    </div>

                    <div class="error" *ngIf="submitted &&songForm.get('language').invalid">
                        Language is a required field
                    </div>
                </div>

                <!-- <div class="form-group">
                    <label for="language">Language ISO</label>
                    <div class="input-container">
                        <select class="form-control" formControlName="language" [class.submitted]="submitted">
                            <option value="" disabled selected>Select Language</option>
                            <option *ngFor="let artist of artists" [value]="artist.id">
                                {{ artist.label }}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="submitted && f['language'].errors" class="rap-error-text">
                        <span *ngIf="f['language'].errors?.['required']">Language is required</span>
                    </div>
                </div> -->

                <div class="form-group">
                    <label for="active">Explicit</label>
                    <div class="form-check">
                        <input class="form-check-input" formControlName="explicit" type="checkbox" value=""
                            id="flexCheckDefault" />
                    </div>
                </div>


                <div class="role-action-btns">
                    <button type="submit" class="btn btn-dark" style="padding: 5px 50px;">Save</button>
                    <button type="button" class="btn btn-light action-button" (click)="onReset()">
                        Reset
                    </button>
                    <button type="button" class="btn btn-light action-button" (click)="onCancel()">
                        Cancel
                    </button>
                </div>

            </form>
        </div>
    </div>
</div>