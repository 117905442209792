<div class="onboarding">
  <div class="left">
    <div class="content">
      <router-outlet></router-outlet>
      <div class="progress">
        <div class="step-indicator" [class.active]="currentStep >= 1"></div>
        <div class="step-indicator" [class.active]="currentStep >= 2"></div>
        <div class="step-indicator" [class.active]="currentStep >= 3"></div>
        <div class="step-indicator" [class.active]="currentStep >= 4"></div>
        <!-- <div class="step-indicator" [class.active]="currentStep >= 5"></div> -->
      </div>
    </div>
  </div>
  <div class="right" *ngIf="currentStep >= 1 && currentStep <= 3"></div>
  <div class="right-option" *ngIf="currentStep >= 4"></div>
</div>