import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserFacade } from 'src/app/store/user/facade/user.facade';

@Component({
  selector: 'app-step1',
  templateUrl: './step1.container.component.html',
})
export class Step1ContainerComponent {
  newAccountForm$ = this.userFacade.newAccountForm$;
  constructor(
    private router: Router,
    private userFacade: UserFacade,
  ) { }
  nextStep() {
    this.router.navigate(['/onboarding/step2']);
  }
}
