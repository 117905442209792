import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProducersFacade } from '../store/producers.facade';
import { UserFacade } from 'src/app/store/user/facade/user.facade';
import { ProducersService } from '../producers.service';

@Component({
  selector: 'rap-add-edit-producer',
  templateUrl: './add-edit-producer.container.component.html',
})
export class AddEditProducerContainerComponent {
  producers$ = this.producersFacade.producers$;
  errorMessage$ = this.producersFacade.errorMessage$;
  userDetails$ = this.userFacade.userDetails$;
  title = 'Add Producer';
  isContributions: boolean = false;
  constructor(
    private producersFacade: ProducersFacade,
    private userFacade: UserFacade,
    private route: ActivatedRoute,
    private router: Router,
    private producerService: ProducersService
  ) {
    this.route.params.subscribe((params) => {
      if (params['id']) {
        this.title = 'Edit Producer';
        this.producersFacade.getProducerById(params['id']);
      }
    });

    const navigation = this.router.getCurrentNavigation();
    console.log(navigation?.extras.state)
    if (navigation?.extras.state) {
      this.isContributions = navigation.extras.state['isContributions'];
      console.log(this.isContributions)
    }

  }

  ngOnDestroy() {
    this.producersFacade.clearErrorMessage();
  }

  addProducer(producer) {
    console.log('addUpdateProducer');

  }

  editProducer(producer) {
    // this.songsFacade.updateSong(song);
  }
}
