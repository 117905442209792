import { Component, OnInit } from '@angular/core';
import { UsersFacade } from '../store/users.facade';
import { CreateUserRequest } from 'src/app/models/users/create-user-request.model';
import { UpdateUserRequest } from 'src/app/models/users/update-user-request.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'rap-add-edit-user',
  templateUrl: './add-edit-user.container.component.html',
})
export class AddEditUserContainerComponent {
  title: string = 'Add User';
  editableUser$ = this.usersFacade.editableUser$;
  roles$ = this.usersFacade.roles$;
  rosters$ = this.usersFacade.rosters$;
  constructor(
    private usersFacade: UsersFacade,
    private route: ActivatedRoute,
  ) {
    this.usersFacade.getRoles();
    this.usersFacade.getRosters();
    this.route.params.subscribe((params) => {
      if (params['id']) {
        this.title = 'Edit User';
        this.usersFacade.getUserById(params['id']);
      }
    });
  }

  createNewUser(user: CreateUserRequest) {
    this.usersFacade.createNewUser(user);
  }

  updateUser(user: UpdateUserRequest) {
    this.usersFacade.updateUser(user);
  }

  ngOnDestroy() {
    this.usersFacade.resetEditableUser();
    this.usersFacade.clearErrorMessage();
  }
}
