import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environments';

@Injectable({
  providedIn: 'root',
})
export class ExploreService {
  private readonly BASE_URL = 'baseUrl';
  private url: string;

  constructor(private httpClient: HttpClient) {
    this.url = environment.baseUrl;
  }

  search(searchParams: string) {
    return this.httpClient.get<any>(this.url + searchParams);
  }

  async getApiData(terms: string[]) {
    const termsString = `terms=${terms.join(',')}`;
    const api1 = this.httpClient.get(this.url + 'v2/top_users_by_word?' + termsString);
    const api2 = this.httpClient.get(this.url + 'v2/history_by_word?' + termsString);
    const api3 = this.httpClient.get(this.url + 'v2/get_neighbors?' + termsString);

    try {
      const responses = await lastValueFrom(forkJoin([api1, api2, api3]));
      return responses;  // You can return the responses here
    } catch (error) {
      console.error('Error in API calls:', error);
      return [null, null, null];
    }
  }

}
