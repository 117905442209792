import { Component, OnDestroy } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { UserFacade } from '../store/user/facade/user.facade';
import { CreateAccountPresenter } from './create-account.presenter';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
})
export class CreateAccountComponent implements OnDestroy {
  authError$ = this.userFacade.authError$;
  createAccountForm!: FormGroup;
  isEmailEntered: boolean = false;
  isPasswordEntered: boolean = false;
  submitted: boolean = false;
  error: string;
  isLoading = false;

  title: string = 'Sign up';
  subTitle: string =
    'To sign up, please enter an email and password Or choose to log into your account.';

  constructor(
    private router: Router,
    private createAccountPresenter: CreateAccountPresenter,
    private userFacade: UserFacade,
  ) {
    this.isLoading = false;
  }

  ngOnInit() {
    this.isLoading = false;
    console.log(this.isLoading)
    this.createAccountForm =
      this.createAccountPresenter.initializeCreateAccountForm();
    this.authError$.subscribe((error) => {
      if (error) {
        this.error = error;
        this.isLoading = false;
      }
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.createAccountForm.controls;
  }

  clearInput(input: string): void {
    this.createAccountForm.controls['email'].setValue('');
  }

  createAccount() {
    this.submitted = true;
    if (this.createAccountForm.invalid) {
      console.log("this.error")

      return;
    }
    let formValue = this.createAccountForm.value;
    if (formValue.password != formValue.confirmPassword) {
      this.error = 'Passwords are not equal';
      console.log(this.error)
      return;
    }

    this.isLoading = true;
    this.userFacade.createNewAccount(this.createAccountForm.value);
  }

  hasEmailError(): boolean {
    const emailErrors = this.createAccountForm.controls['email'].errors;
    return !!emailErrors && !!emailErrors['email'];
  }

  navigateToSignin() {
    this.isLoading = false;
    this.router.navigate(['/signin']);
  }

  ngOnDestroy(): void {
    this.isLoading = false;
    this.userFacade.resetErrorMsg();
  }
}
