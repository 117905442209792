<div class="input-container">
  <input [type]="fieldTextType ? 'text' : 'password'" class="search-input" [class.submitted]="submitted"
    [formControl]="formControl" [placeholder]="placeHolder" />


  <div class="auth-icons">
    <i *ngIf="submitted && hasPasswordError()" class="bi bi-x-circle-fill" style="margin-left: 10px;"
      (click)="clearInput()"></i>

    <i [ngClass]="{
            'bi-eye-fill': !fieldTextType,
            'bi-eye-slash-fill': fieldTextType,
          }" (click)="toggleFieldTextType()" style="margin-left: 10px;">
    </i>
    <i *ngIf="submitted && !formControl.errors" class="bi bi-check-circle-fill"
      style="margin-left: 10px; color: green;"></i>
  </div>
</div>
<div *ngIf="submitted && formControl.errors" class="rap-error-text">
  <div *ngIf="formControl.errors?.['required']">Password is required</div>

  <div *ngIf="formControl.errors?.['passwordStrength']">
    Password should be minium of 8 characters<br />
    Password must contain at least<br />
    one uppercase letter,<br />
    one lowercase letter,<br />
    one number,<br />
    and one special character.
  </div>

</div>