<div class="sign-in">
  <div class="left">
    <rap-spinner class="loader-view" *ngIf="isLoading"></rap-spinner>
    <div class="container">
      <h1>{{ title }}</h1>
      <p>{{ subTitle }}</p>
      <div class="error-container" *ngIf="error">
        {{ error }}
      </div>

      <div class="form-group">
        <label for="code">Enter a code</label>
        <div class="input-container">
          <input type="text" class="form-control" [class.submitted]="submitted"
            [class.ng-invalid]="!emailVerificationCode" placeholder="Enter a code"
            [(ngModel)]="emailVerificationCode" />
          <i class="auth-icons bi bi-shield-fill"></i>
        </div>
        <div *ngIf="submitted && !emailVerificationCode" class="rap-error-text">
          <span>Code is required</span>
        </div>
      </div>

      <button type="submit" class="btn-signin" (click)="verifyEmail()">
        Verify
      </button>
      <p class="terms">
        By continuing, you agree to our <a [routerLink]="'/privacy-notice'" target="_blank">Terms of Service</a> and
        <a [routerLink]="'/privacy-notice'" target="_blank">Privacy Policy</a>
      </p>
    </div>
  </div>
  <div class="right"></div>
</div>