import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Analytics } from '../models/analytics.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from '../shared/component/delete-modal/delete-modal.component';
import { BaseTemplateService } from '../base-template/base-template.service';
import { AppService } from '../app.service';

@Component({
  selector: 'rap-analytics-ui',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
})
export class AnalyticsComponent {
  @Input() analytics: Analytics[];
  @Input() errorMessage: string;
  @Output() deleteAnalyticsEmitter = new EventEmitter<string>();
  @Output() viewAnalyticsEmitter = new EventEmitter<string>();
  @Input() viewAnalytics: Analytics;
  @Output() analyticsEmitter = new EventEmitter<string>();

  page = 1;
  pageSize = 10;
  searchQuery = "";
  filters: any = {
    search: ""
  };
  isLoading: boolean = false;
  analyticsData: Analytics[] = [];
  sortDirection: 'asc' | 'desc' | '' = ''; // Track sorting state
  sortedColumn: string = '';
  constructor(private modalService: NgbModal,
    private baseTemplateService: BaseTemplateService,
    private loaderService: AppService) {
    this.loaderService.loading$.subscribe(isLoading => {
      // if (i)
      console.log("isLoading")
      console.log(isLoading)
      this.isLoading = isLoading;
    });

  }

  ngOnInit(): void {
    this.isLoading = true;
    this.viewAnalytics = null;
    this.analytics = [];
    this.errorMessage = "";

    this.baseTemplateService.showHideSearch(false);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['viewAnalytics'] && this.viewAnalytics) {
      this.analytics = [];
      this.isLoading = false;
      this.searchQuery = "";
      this.filters.search = "";
      this.analytics = this.analyticsData;
      this.page = 1;
    } else if (changes['analytics'] && this.analytics) {
      this.isLoading = false;
      this.analyticsData = this.analytics;
      this.viewAnalytics = null;
    } else if (changes['errorMessage']) {
      this.isLoading = false;
    }


  }

  get paginatedAnalytics(): Analytics[] {
    const start = (this.page - 1) * this.pageSize;
    const end = start + this.pageSize;
    return this.analytics.slice(start, end);

  }

  onSort({ column, direction }: { column: string, direction: 'asc' | 'desc' }) {
    console.log(column)
    if (this.sortedColumn === column) {
      // Toggle sort direction
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      // New column sorting
      this.sortedColumn = column;
      this.sortDirection = 'asc';
    }

    this.updateSortData()
  }

  sortBy({ column, direction }: { column: string, direction: 'asc' | 'desc' }) {
    this.sortedColumn = column;
    this.sortDirection = direction;
    this.updateSortData()
  }

  updateSortData() {
    this.paginatedAnalytics.sort((a, b) => {
      console.log(a.student)
      let aValue = String(a?.student ?? ''); // Convert to string
      let bValue = String(b?.student ?? '');

      if (this.sortedColumn === 'name') {
        return this.sortDirection === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
      else if (this.sortedColumn === 'roaster') {
        let aValue = String(a?.roster ?? ''); // Convert to string
        let bValue = String(b?.roster ?? '');
        return this.sortDirection === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
      else if (this.sortedColumn === 'query') {
        let aQuery: string = a?.query_string ?? '';
        let bQuery: string = b?.query_string ?? '';

        return this.sortDirection === 'asc'
          ? aQuery.localeCompare(bQuery)
          : bQuery.localeCompare(aQuery);
      }
      return 0; // Default case
    });
  }

  onPageChange(page: number) {
    this.page = page;
  }

  deleteQuery(analyticId: string) {
    this.errorMessage = "";
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.modalTitle = 'Confirm delete';
    modalRef.componentInstance.modalBodyText =
      'Are you sure you want to delete thie item ?';
    modalRef.componentInstance.itemId = analyticId;
    modalRef.result
      .then((id) => {
        this.isLoading = true;
        console.log(id);
        this.deleteAnalyticsEmitter.emit(id);
      })
      .catch((error) => console.log(error));
  }

  getSearchResults(search: string) {
    this.filters.search = search
    this.errorMessage = "";
    if (this.filters.search) {
      this.searchQuery = this.filters.search;
      var localArr = this.analytics.filter(item =>
        this.viewAnalytics == null ? item.student?.firstName?.toLowerCase().includes(this.filters.search.toLowerCase()) :
          item.query_string?.toLowerCase().includes(this.filters.search.toLowerCase())
      );
      console.log(localArr);
      this.analytics = localArr;
    } else {
      this.analytics = this.analyticsData;
    }


  }

  viewQuery(analyticId: string) {
    this.errorMessage = "";
    this.isLoading = true;
    this.sortDirection = '';
    this.sortedColumn = "";
    this.viewAnalyticsEmitter.emit(analyticId);
  }

  onAnalytics() {
    this.errorMessage = "";
    console.log(this.analyticsData.length);
    this.viewAnalytics = null;
    this.analytics = this.analyticsData;
    console.log(this.page);
    this.page = 1;
    this.sortDirection = '';
    this.sortedColumn = "";
  }

  onClearSearch() {
    this.filters.search = "";
    this.getSearchResults("")
  }

  ngOnDestroy(): void {
    this.viewAnalytics = null;
    this.analytics = [];
    this.errorMessage = "";
    this.filters.search = "";
    this.sortDirection = '';
    this.sortedColumn = "";
    this.analyticsData = [];
    console.log(this.analytics);
    console.log(this.analyticsData);
  }
}
