import { createAction, props } from '@ngrx/store';
import { Analytics } from 'src/app/models/analytics.model';

export const getAnalytics = createAction('[Analytics] GET_ANALYTICS');

export const getAnalyticsSuccess = createAction(
  '[Analytics] GET_ANALYTICS_SUCCESS',
  props<{ analytics: Analytics[] }>(),
);

export const deleteAnalytic = createAction(
  '[Analytics] DELETE_ANALYTICS',
  props<{ analyticId: string }>(),
);

export const deleteAnalyticSuccess = createAction(
  '[Analytics] DELETE_ANALYTICS_SUCCESS',
  props<{ analyticsId: string }>(),
);

export const viewAnalytic = createAction(
  '[Analytics] VIEW_ANALYTICS',
  props<{ analyticId: string }>(),
);

export const viewAnalyticSuccess = createAction(
  '[Analytics] VIEW_ANALYTICS_SUCCESS',
  props<{ viewAnalytics: Analytics }>(),
);

export const setErrorMessage = createAction(
  '[Analytics] SET_ERROR_MESSAGE',
  props<{ msg: string }>(),
);
