import { Injectable } from '@angular/core';
import { Store, createFeatureSelector, createSelector } from '@ngrx/store';
import { FunctionsState } from './functions.state';

const getFunctionsState = createFeatureSelector<FunctionsState>('functions');

const getFunctions = createSelector(
  getFunctionsState,
  (state: FunctionsState) => state.functions,
);

const getEditableFunction = createSelector(
  getFunctionsState,
  (state: FunctionsState) => state.editableFunction,
);

const getErrorMessage = createSelector(
  getFunctionsState,
  (state: FunctionsState) => state.errorMessage,
);

@Injectable()
export class FunctionsSelectors {
  functions$ = this.store.select(getFunctions);
  editableFunction$ = this.store.select(getEditableFunction);
  errorMessage$ = this.store.select(getErrorMessage);
  constructor(private store: Store<FunctionsState>) {}
}
