<div class="label-container">
  <div>
    <label class="clickable-label" routerLink="/dashboard/explore"> Rap Almanac </label>
    <img src="../assets/images/filled.svg" />
  </div>
  <label class="non-clickable-label">Users</label>
</div>

<div class="users-section">
  <div class="section-title">Users</div>
  <div class="user-actions">
    <button type="button" class="btn btn-dark button" (click)="addUser()">
      Add New
    </button>

    <!-- Bootstrap Dropdown -->
    <div class="dropdown d-inline-block">
      <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
        aria-expanded="false" [disabled]="selectedUsers.length === 0">
        {{ strAction }}
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <li><a class="dropdown-item" (click)="performAction('disable')">Disable</a></li>
        <li><a class="dropdown-item" (click)="performAction('delete')">Delete</a></li>
      </ul>
    </div>

  </div>


  <div class="search-container">
    <img src="../assets/images/search.svg" class="search-icon" />
    <input type="text" placeholder="Search..." class="search-input" [(ngModel)]="filters.search"
      (keydown.enter)="getSearchResults()">
    <div class="auth-icons">
      <i class="bi bi-x-circle-fill" style="margin-left: 10px;" *ngIf="filters.search !== ''"
        (click)="onClearSearch()"></i>
    </div>
  </div>

  <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
  <ng-container *ngIf="users?.length > 0">
    <div>
      <div class="songs-table">
        <table class="table rounded-4 overflow-hidden" data-search="true" data-show-columns="true"
          data-show-multi-sort="true">
          <thead>
            <tr>
              <th scope="col">
                <div class="checkbox-container">
                  <label class="custom-checkbox">
                    <input (change)="toggleSelectAll($event)" type="checkbox" value="" id="flexCheckDefault" />
                    <span class="checkmark"></span>
                  </label>
                </div>

              </th>
              <th scope="col">USERNAME</th>
              <th scope="col">E-MAIL</th>
              <th scope="col">FIRST NAME</th>
              <th scope="col">LAST NAME</th>
              <!-- <th scope="col">NCES Id</th> -->
              <th scope="col">ROLE</th>
              <th scope="col">ACTIVE</th>
              <th scope="col">DATE JOINED</th>
              <th scope="col">VERIFY</th>
              <th scope="col">APPROVE</th>
              <th scope="col">DELETE</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of paginatedUsers" [class.selected]="isSelected(user)">
              <th scope="col">
                <label class="custom-checkbox">
                  <input class="form-check-input" [checked]="isSelected(user)" (change)="toggleSelection(user)"
                    type="checkbox" value="" id="flexCheckDefault" />
                  <span class="checkmark"></span>
                </label>
              </th>
              <th scope="col">
                <a class="user-details-link" (click)="editUser(user)">
                  {{user.userName}}
                </a>
              </th>
              <th scope="col">
                <a class="user-details-link" (click)="editUser(user)">
                  {{user.email}}
                </a>
              </th>
              <th scope="col">
                <a class="user-details-link" (click)="editUser(user)">
                  {{user.firstName}}
                </a>
              </th>
              <th scope="col">
                <a class="user-details-link" (click)="editUser(user)">
                  {{user.lastName}}
                </a>
              </th>
              <!-- <th scope="col">{{user.ncesId}}</th> -->
              <th scope="col">{{user?.role?.name}}</th>
              <th scope="col">{{user.active}}</th>
              <th scope="col">{{user.createdAt | date}}</th>
              <th scope="col">{{user.emailVerified == true ? 'Verified' : 'Not Verified'}}</th>
              <th>{{user.approved ? "Approved" : "Not Approved"}}</th>
              <!-- <td>
                <span *ngIf="user.approved">{{user.approved ? "Approved" : ""}}</span>
                <button *ngIf="!user.approved" type="button" class="btn btn-light approve-user-btn"
                  (click)="approveUserEmitter.emit(user.id)">
                  Approve
                </button>
              </td> -->
              <th>
                <button type="button" class="btn btn-dark delete-user-btn" (click)="deleteUser(user.id)">
                  Delete
                </button>
              </th>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <rap-pagination [collectionSize]="usersCount" [page]="page" [pageSize]="pageSize"
            (onPageChange)="onPageChange($event)"></rap-pagination>
        </div>
      </div>



    </div>
  </ng-container>

</div>

<div style="padding: 20px; text-align: center; margin-top: 100px;" *ngIf="users?.length === 0 && !isLoading">
  <img src="../assets/images/question.svg" />
  <div class="no-match">No Users found</div>
</div>