import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { RolesEffects } from './roles.effects';
import { rolesReducer } from './roles.reducer';
import { RolesSelectors } from './roles.selectors';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature(RolesEffects),
    StoreModule.forFeature('roles', rolesReducer),
  ],
  exports: [StoreModule, EffectsModule],
  providers: [RolesSelectors],
})
export class RolesStoreModule {}
