<!-- <div > -->
<img src="../assets/images/arrow_left.svg" style="margin-bottom: 20px;" (click)="goBack()" />
<h2>Tell us a bit about yourself</h2>
<p>How did you hear about us?</p>
<ng-container *ngFor="let option of options">
  <!-- <button class="options" (click)="nextStep(option)">{{ option }}</button> -->
  <div (click)="nextStep(option)" [class.selected]="option === selectedOption">
    {{ option }}
  </div>
</ng-container>
<button class="continue" (click)="onContinue()">Continue</button>
<button class="skip" (click)="onSkip()">Skip</button>
<!-- </div> -->