<div class="functions-section">
  <div class="section-title">
    {{ title }}
  </div>
  <div class="card">
    <div class="card-body">
      <form [formGroup]="functionForm" (ngSubmit)="addUpdateFunction()">
        <div class="form-group">
          <label for="name">Name</label>
          <div class="input-container">
            <input type="text" class="form-control" [class.submitted]="submitted" formControlName="name"
              placeholder="Enter an function name" />
          </div>
          <div *ngIf="submitted && f['name'].errors" class="rap-error-text">
            <span *ngIf="f['name'].errors?.['required']">Name is required</span>
          </div>
        </div>

        <div class="function-action-btns">
          <button type="submit" class="btn btn-dark save-function-btn">
            Save
          </button>
          <button type="button" class="btn btn-light reset-function-btn">
            Reset
          </button>
          <button type="button" class="btn btn-light cancel-function-btn" routerLink="/dashboard/functions">
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</div>