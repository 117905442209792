<!-- <span class="header-text">{{ label }}</span> -->
<span class="icons">
    <span class="arrow" [ngClass]="{ active: sortedColumn === column &&  sortDirection === 'asc' }"
        (click)="setSort('asc')">
        ▲
    </span>
    <span class="arrow" [ngClass]="{ active:sortedColumn === column &&  sortDirection === 'desc' }"
        (click)="setSort('desc')">
        ▼
    </span>
</span>