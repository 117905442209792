import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environments';

@Injectable({
  providedIn: 'root'
})
export class AlbumsService {


  private readonly BASE_URL = 'baseUrl';
  private url: string;

  constructor(private httpClient: HttpClient) {
    this.url = environment.baseUrl;
  }

  getAllAlbums(search: string, page: number, isContributions: boolean, sortBy: string, order: string) {

    var endPoint: string = `v2/albums` + '?limit=10&page=' + page
    if (isContributions) {
      endPoint = 'v2/albums/my-albums' + '?limit=10&page=' + page;
    }
    if (search !== '') {
      endPoint += `&term=${search}`;
    } else if (sortBy !== '') {
      endPoint += `&sortBy=${sortBy}&order=${order}`; //&sortBy=userName&order=asc
    }
    console.log(endPoint)
    return this.httpClient.get<any>(this.url + endPoint)
      .pipe(map((response: any) => response));
  }

  getAlbumsList(currentUser: string, isContributions: boolean) {

    var endPoint: string = `v2/albums/autocomplete?query=&limit=100`;
    if (isContributions) {
      endPoint = 'v2/albums/autocomplete?query=&limit=100&currentUser=' + currentUser;
    }
    console.log(endPoint)
    return this.httpClient.get<any>(this.url + endPoint)
      .pipe(map((response: any) => response));
  }

  addNew(body: any) {
    return this.httpClient.post<any>(this.url + 'v2/albums', body)
      .pipe(map((response: any) => response));
  }

  deleteAlbum(id: string) {
    return this.httpClient.delete<any>(this.url + 'v2/albums/' + id).pipe(map((response: any) => response));
  }

  getAlbum(id: string) {
    return this.httpClient.get<any>(this.url + 'v2/albums/' + id).pipe(map((response: any) => response));
  }
}
