import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UserFacade } from 'src/app/store/user/facade/user.facade';

@Component({
  selector: 'rap-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss'],
})
export class VerifyAccountComponent {
  emailVerificationCode: string = null;
  title: string = 'Email verification';
  subTitle: string =
    'Type in the code sent to your registered email to verify your email';
  submitted: boolean = false;
  isLoading = false;
  error: string;
  constructor(private userFacade: UserFacade) {
    this.userFacade.authError$.subscribe((error) => {
      typeof error === 'string'
        ? (this.error = error)
        : (this.error = error?.['error']);

      if (error) {
        this.isLoading = false;
      }

    });
  }

  verifyEmail() {
    this.submitted = true;
    if (this.emailVerificationCode) {
      this.isLoading = true;
      this.userFacade.verifyEmail(this.emailVerificationCode);
    }
  }
}
