import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RolesState } from './roles.state';
import { RolesSelectors } from './roles.selectors';
import {
  clearErrorMessage,
  createNewRole,
  deleteRole,
  getPermissions,
  getRoleById,
  getRoles,
  resetEditableRole,
  updateRole,
} from './roles.action';
import { CreateRoleRequest } from 'src/app/models/roles/create-role-request.model';
import { UpdateRoleRequest } from 'src/app/models/roles/update-role-request.model';

@Injectable({
  providedIn: 'root',
})
export class RolesFacade {
  roles$ = this.rolesSelectors.roles$;
  editableRole$ = this.rolesSelectors.editableRole$;
  permissions$ = this.rolesSelectors.permissions$;
  errorMessage$ = this.rolesSelectors.errorMessage$;
  constructor(
    private store: Store<RolesState>,
    private rolesSelectors: RolesSelectors,
  ) {}

  getRoles() {
    this.store.dispatch(getRoles());
  }

  getRoleById(roleId: string) {
    this.store.dispatch(getRoleById({ roleId }));
  }

  getPermissions() {
    this.store.dispatch(getPermissions());
  }

  // Add the createNewRole method
  createNewRole(newRole: CreateRoleRequest) {
    this.store.dispatch(createNewRole({ newRole }));
  }

  // Add the updateRole method
  updateRole(updatedRole: UpdateRoleRequest) {
    this.store.dispatch(updateRole({ updatedRole }));
  }

  deleteRole(roleId: string) {
    this.store.dispatch(deleteRole({ roleId }));
  }

  resetEditableRole() {
    this.store.dispatch(resetEditableRole());
  }

  clearErrorMessage() {
    this.store.dispatch(clearErrorMessage());
  }
}
