import { Component } from '@angular/core';

@Component({
  selector: 'rap-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent {
  getBodyClass(): string {
    let styleClass = 'body-md-screen';
    // if (!this.isSideNavCollapsed && this.screenWidth > 768) {
    //   styleClass = 'body-trimmed';
    // } else if (this.isSideNavCollapsed && this.screenWidth > 0) {
    //   styleClass = 'body-md-screen';
    // }
    return styleClass;
  }
}
