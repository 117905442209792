import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environments';

@Injectable({
  providedIn: 'root'
})
export class TrendingWordsService {

  private readonly BASE_URL = 'baseUrl';
  private url: string;

  constructor(private httpClient: HttpClient) {
    this.url = environment.baseUrl;
  }

  getAllternding() {
    return this.httpClient.get<any>(this.url + 'v2/trending_keywords')
      .pipe(map((response: any) => response));
  }

  addNew(body: any) {
    return this.httpClient.post<any>(this.url + 'v2/trending_keywords', body)
      .pipe(map((response: any) => response));
  }

  deleteKeyWord(id: string) {
    return this.httpClient.delete<any>(this.url + 'v2/trending_keywords/' + id).pipe(map((response: any) => response));
  }
}
