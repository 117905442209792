import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rap-profile-photo-modal',
  templateUrl: './profile-photo-modal.component.html',
  styleUrls: ['./profile-photo-modal.component.scss']
})
export class ProfilePhotoModalComponent {
  @ViewChild('fileInput') fileInput: ElementRef;
  selectedFile: File | null = null;
  uploadForm: FormGroup;
  constructor(private formBuilder: FormBuilder, public activeModal: NgbActiveModal) {
    this.uploadForm = this.formBuilder.group({});
  }

  triggerFileInput() {
    this.fileInput.nativeElement.click(); // Programmatically trigger file input
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      this.selectedFile = event.target.files[0];
    }
  }

  onFileSelected() {

    if (this.selectedFile) {
      const formData = new FormData();
      formData.append('file', this.selectedFile, this.selectedFile.name);
      formData.append('filename', this.selectedFile.name);
      console.log('Selected file:', formData); // 
      console.log('Selected file:', this.selectedFile); //

      formData.forEach((value, key) => {
        console.log(key, value);
      });
      this.activeModal.close(formData);
    }
  }

  onSubmit() {
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append('file', this.selectedFile, this.selectedFile.name);
      console.log('Selected file:', formData); // 
      formData.forEach((value, key) => {
        console.log(key, value);
      });
      this.activeModal.close(formData);
    }
  }

}
