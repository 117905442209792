import { createSelector, createFeatureSelector, Store } from '@ngrx/store';
import { SongsState } from './songs.state';
import { Injectable } from '@angular/core';
import { get } from 'jquery';

const getSongsState = createFeatureSelector<SongsState>('songs');

const getSongs = createSelector(
  getSongsState,
  (state: SongsState) => state.songs,
);

// Selector to get a specific song by ID
const getSongById = createSelector(
  getSongsState,
  (state: SongsState) => state.editableSong,
);

const getAlbums = createSelector(
  getSongsState,
  (state: SongsState) => state.albums,
);

const getArtists = createSelector(
  getSongsState,
  (state: SongsState) => state.artists,
);

const getProducers = createSelector(
  getSongsState,
  (state: SongsState) => state.producers,
);

const getErrorMessage = createSelector(
  getSongsState,
  (state: SongsState) => state.errorMessage,
);

@Injectable()
export class SongsSelectors {
  songs$ = this.store.select(getSongs);
  albums$ = this.store.select(getAlbums);
  artists$ = this.store.select(getArtists);
  producers$ = this.store.select(getProducers);
  editableSong$ = this.store.select(getSongById);
  errorMessage$ = this.store.select(getErrorMessage);
  constructor(private store: Store<SongsState>) {}
}
