import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rap-update-success-modal',
  templateUrl: './update-success-modal.component.html',
  styleUrls: ['./update-success-modal.component.scss']
})
export class UpdateSuccessModalComponent {
  @Input() modalTitle: string;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
  ) { }
}
