import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environments';
import { CreateStudentRequest } from '../models/students/create-student-request.model';
import { UpdateStudentRequest } from '../models/students/update-student-request.model';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AppService } from '../app.service';

@Injectable({
  providedIn: 'root',
})
export class StudentsService {
  private readonly BASE_URL = 'baseUrl';
  private url: string;

  constructor(private httpClient: HttpClient, private loaderService: AppService) {
    this.url = environment.baseUrl;
  }

  getStudents(rosterId: string) {
    return this.httpClient.get<any>(this.url + `v2/users/roster/` + rosterId);
  }

  deleteStudents(studentId: string, rosterId: string) {
    return this.httpClient.delete<string>(
      this.url + `v2/rosters/` + rosterId + `/student/` + studentId,
      { responseType: 'text' as 'json' }
    ).pipe(
      map((response: string) => {
        console.log("Response:", response); // This will log 'Success'
        this.loaderService.hide();
        return response; // Handle the plain text response as needed
      }),
      catchError((error: any) => {
        console.error("Error:", error); // Handle any errors
        return throwError(error); // Rethrow the error for further handling
      })
    );
  }

  createNewStudent(rosterId: string, newStudent: CreateStudentRequest) {
    return this.httpClient.post(
      this.url + `v2/rosters/` + rosterId + `/add`,
      newStudent,
      {
        responseType: 'text',
      },
    );
  }

  updateStudents(
    rosterId: string,
    studentId: string,
    updatedStudent: UpdateStudentRequest,
  ) {
    return this.httpClient.put(
      this.url + `v2/rosters/` + rosterId + `/student/` + studentId,
      updatedStudent,
      {
        responseType: 'text',
      },
    );
  }

  updateStudentsNew(
    rosterId: string,
    studentId: string,
    updatedStudent: UpdateStudentRequest,
  ) {
    return this.httpClient.put(
      this.url + `v2/rosters/` + rosterId + `/student/` + studentId,
      updatedStudent,
      {
        responseType: 'text',
      },
    ).pipe(map((response: any) => response));
  }
}
