<div class="sign-in">
  <div class="left">
    <ng-container *ngIf="!pwdResetCodeSent; else updatePassword">
      <div class="container">
        <h1>{{ title }}</h1>
        <p>{{ subTitle }}</p>
        <form [formGroup]="passwordHelpForm" (ngSubmit)="onSubmit()">
          <div class="error-container" *ngIf="authError$ | async">
            {{ authError$ | async }}
          </div>
          <div class="form-group">
            <rap-spinner class="loader-view" *ngIf="isLoading" [showSpinner]="false"></rap-spinner>
            <label for="email">Email address</label>
            <div class="input-container">
              <input type="email" class="form-control" [class.submitted]="submitted"
                placeholder="Enter an email address" formControlName="email" />
              <div class="auth-icons">
                <i *ngIf="submitted && hasEmailError()" class="bi bi-x-circle-fill" style="margin-left: 10px;"
                  (click)="clearInput('email')"></i>
                <i class="bi bi-envelope-fill" style="margin-left: 10px;"></i>
                <i *ngIf="!hasEmailError() && isValidEmail" class="bi bi-check-circle-fill"
                  style="margin-left: 10px; color: green;"></i>
              </div>
              <!-- <img
                    *ngIf="isEmailEntered"
                    src="../assets/images/x-circle-fill.svg"
                    alt="Clear Icon"
                    class="auth-icons clear"
                    onclick="clearInput('email')"
                  /> -->
            </div>
            <div *ngIf="submitted && f['email'].errors" class="rap-error-text">
              <span *ngIf="f['email'].errors?.['required']">Email is required</span>
              <span *ngIf="f['email'].errors?.['email']">Enter valid email</span>
            </div>
          </div>

          <button type="submit" class="btn-signin">

            <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span *ngIf="!isLoading">Send Email</span>
          </button>
          <div class="links">
            <div></div>
            <span>Or</span>
            <div></div>
          </div>
          <button type="button" class="btn-create" (click)="navigateToSignIn()">
            Sign In
          </button>
        </form>
        <p class="terms">
          By continuing, you agree to our <a [routerLink]="'/privacy-notice'" target="_blank">Terms of Service</a> and
          <a [routerLink]="'/privacy-notice'" target="_blank">Privacy Policy</a>
        </p>
      </div>
    </ng-container>
  </div>
  <div class="right"></div>
</div>

<ng-template #updatePassword>
  <div class="container">
    <h1>{{ title }}</h1>
    <p>{{ subTitle }}</p>
    <form [formGroup]="passwordHelpForm" (ngSubmit)="onSubmit()">
      <div class="error-container" *ngIf="authError$ | async">
        {{ authError$ | async }}
      </div>
      <div class="error-container" *ngIf="error">
        {{ error }}
      </div>

      <div class="form-group">
        <rap-spinner class="loader-view" *ngIf="isLoading" [showSpinner]="false"></rap-spinner>
        <label for="code">Enter a code</label>
        <div class="input-container">
          <input type="text" class="form-control" [class.submitted]="pwdUpdateSubmitted" placeholder="Enter a code"
            formControlName="code" />
          <i class="auth-icons bi bi-shield-fill"></i>
        </div>
        <div *ngIf="pwdUpdateSubmitted && f['code'].errors" class="rap-error-text">
          <span *ngIf="f['code'].errors?.['required']">Code is required</span>
        </div>
      </div>
      <div class="form-group">
        <label for="password">New password</label>
        <app-password-input [submitted]="pwdUpdateSubmitted" [formControl]="f['password']"
          [placeholder]="'Enter a new password'"></app-password-input>
      </div>
      <div class="form-group">
        <label for="confirmPassword">Re-enter new password</label>
        <app-password-input [submitted]="pwdUpdateSubmitted" [formControl]="f['confirmPassword']"
          [placeholder]="'Re-enter the new password'"></app-password-input>
      </div>

      <button type="submit" class="btn-signin">

        <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span *ngIf="!isLoading">Update</span>
      </button>
    </form>
    <p class="terms">
      By continuing, you agree to our <a [routerLink]="'/privacy-notice'" target="_blank">Terms of Service</a> and
      <a [routerLink]="'/privacy-notice'" target="_blank">Privacy Policy</a>
    </p>
  </div>
</ng-template>