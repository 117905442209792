<div class="label-container">
  <div>
    <label class="clickable-label" routerLink="/dashboard/explore"> Rap Almanac </label>
    <img src="../assets/images/filled.svg" />
  </div>

  <label (click)="onRosters()" class="non-clickable-label">Rosters</label>
</div>

<div class="analytics-section">
  <div class="section-title">Rosters</div>
  <button type="button" class="btn btn-dark add-role-btn" routerLink="/dashboard/rosters/new"
    style="margin-bottom: 20px;">
    Add New
  </button>

  <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
  <div class="search-container">
    <img src="../assets/images/search.svg" class="search-icon" />
    <input type="text" placeholder="Search..." class="search-input" [(ngModel)]="filters.search"
      (keydown.enter)="getSearchResults()">
    <div class="auth-icons">
      <i class="bi bi-x-circle-fill" style="margin-left: 10px;" *ngIf="filters.search !== ''"
        (click)="onClearSearch()"></i>
    </div>
  </div>
  <ng-container *ngIf="rosters?.length > 0">
    <div class="custom-container">

      <div class="rosters-table" *ngIf="rosters?.length > 0">
        <table class="table rounded-4 overflow-hidden">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let roster of paginatedRosters">
              <td scope="row">{{ roster?.name }}</td>
              <td>
                <button type="button" class="btn btn-light manage-student-btn" (click)="manageStudent(roster)">
                  Manage students
                </button>
                <button type="button" class="btn btn-dark delete-roster-btn" (click)="deleteRoster(roster.id)">
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="pagination">
          <rap-pagination [collectionSize]="rosters?.length" [page]="page" [pageSize]="pageSize"
            (onPageChange)="onPageChange($event)"></rap-pagination>
        </div>
      </div>
    </div>

  </ng-container>



</div>

<div style="padding: 20px; text-align: center; margin-top: 100px;"
  *ngIf="(!rosters || rosters?.length === 0) && !isLoading">
  <img src="../assets/images/question.svg" />
  <div class="no-match">No rosters found</div>
</div>