import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { RolesService } from '../roles.service';
import { HttpErrorResponse } from '@angular/common/http';
import {
  createNewRole,
  createNewRoleSuccess,
  deleteRole,
  deleteRoleSuccess,
  getPermissions,
  getPermissionsSuccess,
  getRoleById,
  getRoleByIdSuccess,
  getRoles,
  getRolesSuccess,
  rolesError,
  updateRole,
  updateRoleSuccess,
} from './roles.action';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Roles } from 'src/app/models/roles/roles.model';

@Injectable()
export class RolesEffects {
  getRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRoles),
      switchMap(() =>
        this.rolesService.getRoles().pipe(
          map((data: any) => {
            return getRolesSuccess({ roles: data.rows });
          }),
          catchError((error: HttpErrorResponse) => {
            return of();
          }),
        ),
      ),
    ),
  );

  getRoleById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRoleById),
      switchMap((action) =>
        this.rolesService.getRoleById(action.roleId).pipe(
          map((role: Roles) => {
            return getRoleByIdSuccess({ role });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(rolesError({ errorMessage: error.error.message || 'Something went wrong, please try again later!' }));
          }),
        ),
      ),
    ),
  );

  getPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPermissions),
      switchMap(() =>
        this.rolesService.getPermissions().pipe(
          map((data: any) => {
            return getPermissionsSuccess({ permissions: data });
          }),
          catchError((error: HttpErrorResponse) => {
            return of();
          }),
        ),
      ),
    ),
  );

  createNewRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createNewRole),
      switchMap((action) =>
        this.rolesService.createNewRole(action.newRole).pipe(
          map((data: any) => {
            return createNewRoleSuccess();
          }),
          catchError((error: HttpErrorResponse) => {
            return of(rolesError({ errorMessage: error.error.message || 'Something went wrong, please try again later!' }));
          }),
        ),
      ),
    ),
  );

  createNewRoleSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createNewRoleSuccess),
        tap(() => {
          this.toastr.success('Role created successfully', '', {
            positionClass: 'toast-top-center',
            closeButton: false,
          });
          this.router.navigate(['/dashboard/roles']);
        }),
      ),
    { dispatch: false },
  );

  updateRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateRole),
      mergeMap((action) =>
        this.rolesService.updateRole(action.updatedRole).pipe(
          map(() => {
            return updateRoleSuccess();
          }),
          catchError((error) => {
            console.log("errorMessage error");
            return of(rolesError({ errorMessage: 'Something went wrong, please try again later!' }));
          }),
        ),
      ),
    ),
  );

  updateRoleSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateRoleSuccess),
        tap(() => {
          this.toastr.success('Role updated', '', {
            positionClass: 'toast-top-center',
            closeButton: false,
          });
          this.router.navigate(['/dashboard/roles']);
        }),
      ),
    { dispatch: false },
  );

  deleteRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteRole),
      mergeMap((action) =>
        this.rolesService.deleteRole(action.roleId).pipe(
          map(() => {
            this.toastr.success('Role deleted successfullly', '', {
              positionClass: 'toast-top-center',
              closeButton: false,
            });
            return deleteRoleSuccess({ roleId: action.roleId });
          }),
          catchError((error) => {
            return of(rolesError({ errorMessage: error.error.message || 'Something went wrong, please try again later!' }));
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private rolesService: RolesService,
    private toastr: ToastrService,
    private router: Router,
  ) { }
}
