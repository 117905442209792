import { Component } from '@angular/core';
import { AnalyticsFacade } from './store/analytics.facade';

@Component({
  selector: 'rap-analytics',
  templateUrl: './analytics.container.component.html',
  styleUrls: ['./analytics.container.component.scss'],
})
export class AnalyticsContainerComponent {
  analytics$ = this.analyticsFacade.analytics$;
  errorMessage$ = this.analyticsFacade.errorMessage$;
  viewAnalytics$ = this.analyticsFacade.viewAnalytics$;

  constructor(private analyticsFacade: AnalyticsFacade) { }

  ngOnInit() {
    console.log("jjj");
    this.getAnalytics();
  }

  getAnalytics() {

    this.analyticsFacade.getAnalytics();
  }

  deleteAnalytics(id: string) {
    this.analyticsFacade.deleteAnalytic(id);
  }

  viewAnalytics(id: string) {
    this.analyticsFacade.viewAnalytic(id);
  }
}
