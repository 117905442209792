export const COUNTRY_LIST = [
    { label: "Afghanistan", value: "Afghanistan" },
    { label: "Albania", value: "Albania" },
    { label: "Algeria", value: "Algeria" },
    {
        label: "American Samoa",
        value: "American Samoa",
    },
    { label: "Andorra", value: "Andorra" },
    { label: "Angola", value: "Angola" },
    { label: "Anguilla", value: "Anguilla" },
    { label: "Antarctica", value: "Antarctica" },
    {
        label: "Antigua and Barbuda",
        value: "Antigua and Barbuda",
    },
    { label: "Argentina", value: "Argentina" },
    { label: "Armenia", value: "Armenia" },
    { label: "Aruba", value: "Aruba" },
    { label: "Australia", value: "Australia" },
    { label: "Austria", value: "Austria" },
    { label: "Azerbaijan", value: "Azerbaijan" },
    {
        label: "Bahamas (the)",
        value: "Bahamas (the)",
    },
    { label: "Bahrain", value: "Bahrain" },
    { label: "Bangladesh", value: "Bangladesh" },
    { label: "Barbados", value: "Barbados" },
    { label: "Belarus", value: "Belarus" },
    { label: "Belgium", value: "Belgium" },
    { label: "Belize", value: "Belize" },
    { label: "Benin", value: "Benin" },
    { label: "Bermuda", value: "Bermuda" },
    { label: "Bhutan", value: "Bhutan" },
    { label: "Bolivia", value: "Bolivia" },
    {
        label: "Bonaire, Sint Eustatius and Saba",
        value: "Bonaire, Sint Eustatius and Saba",
    },
    {
        label: "Bosnia and Herzegovina",
        value: "Bosnia and Herzegovina",
    },
    { label: "Botswana", value: "Botswana" },
    {
        label: "Bouvet Island",
        value: "Bouvet Island",
    },
    { label: "Brazil", value: "Brazil" },
    {
        label: "British Indian Ocean Territory",
        value: "British Indian Ocean Territory",
    },
    {
        label: "Brunei Darussalam",
        value: "Brunei Darussalam",
    },
    { label: "Bulgaria", value: "Bulgaria" },
    { label: "Burkina Faso", value: "Burkina Faso" },
    { label: "Burundi", value: "Burundi" },
    { label: "Cabo Verde", value: "Cabo Verde" },
    { label: "Cambodia", value: "Cambodia" },
    { label: "Cameroon", value: "Cameroon" },
    { label: "Canada", value: "Canada" },
    {
        label: "Cayman Islands",
        value: "Cayman Islands",
    },
    {
        label: "Central African Republic",
        value: "Central African Republic",
    },
    { label: "Chad", value: "Chad" },
    { label: "Chile", value: "Chile" },
    { label: "China", value: "China" },
    {
        label: "Christmas Island",
        value: "Christmas Island",
    },
    {
        label: "Cocos Islands",
        value: "Cocos Islands",
    },
    { label: "Colombia", value: "Colombia" },
    { label: "Comoros", value: "Comoros" },
    { label: "Congo", value: "Congo" },
    { label: "Cook Islands", value: "Cook Islands" },
    { label: "Costa Rica", value: "Costa Rica" },
    { label: "Croatia", value: "Croatia" },
    { label: "Cuba", value: "Cuba" },
    { label: "Curaçao", value: "Curaçao" },
    { label: "Cyprus", value: "Cyprus" },
    { label: "Czechia", value: "Czechia" },
    {
        label: "Côte d'Ivoire",
        value: "Côte d'Ivoire",
    },
    { label: "Denmark", value: "Denmark" },
    { label: "Djibouti", value: "Djibouti" },
    { label: "Dominica", value: "Dominica" },
    {
        label: "Dominican Republic",
        value: "Dominican Republic",
    },
    { label: "Ecuador", value: "Ecuador" },
    { label: "Egypt", value: "Egypt" },
    { label: "El Salvador", value: "El Salvador" },
    {
        label: "Equatorial Guinea",
        value: "Equatorial Guinea",
    },
    { label: "Eritrea", value: "Eritrea" },
    { label: "Estonia", value: "Estonia" },
    { label: "Eswatini", value: "Eswatini" },
    { label: "Ethiopia", value: "Ethiopia" },
    {
        label: "Falkland Islands",
        value: "Falkland Islands",
    },
    {
        label: "Faroe Islands",
        value: "Faroe Islands",
    },
    { label: "Fiji", value: "Fiji" },
    { label: "Finland", value: "Finland" },
    { label: "France", value: "France" },
    {
        label: "French Guiana",
        value: "French Guiana",
    },
    {
        label: "French Polynesia",
        value: "French Polynesia",
    },
    {
        label: "French Southern Territories",
        value: "French Southern Territories",
    },
    { label: "Gabon", value: "Gabon" },
    { label: "Gambia", value: "Gambia" },
    { label: "Georgia", value: "Georgia" },
    { label: "Germany", value: "Germany" },
    { label: "Ghana", value: "Ghana" },
    { label: "Gibraltar", value: "Gibraltar" },
    { label: "Greece", value: "Greece" },
    { label: "Greenland", value: "Greenland" },
    { label: "Grenada", value: "Grenada" },
    { label: "Guadeloupe", value: "Guadeloupe" },
    { label: "Guam", value: "Guam" },
    { label: "Guatemala", value: "Guatemala" },
    { label: "Guernsey", value: "Guernsey" },
    { label: "Guinea", value: "Guinea" },
    {
        label: "Guinea-Bissau",
        value: "Guinea-Bissau",
    },
    { label: "Guyana", value: "Guyana" },
    { label: "Haiti", value: "Haiti" },
    {
        label: "Heard Island and McDonald Islands",
        value: "Heard Island and McDonald Islands",
    },
    { label: "Holy See", value: "Holy See" },
    { label: "Honduras", value: "Honduras" },
    { label: "Hong Kong", value: "Hong Kong" },
    { label: "Hungary", value: "Hungary" },
    { label: "Iceland", value: "Iceland" },
    { label: "India", value: "India" },
    { label: "Indonesia", value: "Indonesia" },
    { label: "Iran", value: "Iran" },
    { label: "Iraq", value: "Iraq" },
    { label: "Ireland", value: "Ireland" },
    { label: "Isle of Man", value: "Isle of Man" },
    { label: "Israel", value: "Israel" },
    { label: "Italy", value: "Italy" },
    { label: "Jamaica", value: "Jamaica" },
    { label: "Japan", value: "Japan" },
    { label: "Jersey", value: "Jersey" },
    { label: "Jordan", value: "Jordan" },
    { label: "Kazakhstan", value: "Kazakhstan" },
    { label: "Kenya", value: "Kenya" },
    { label: "Kiribati", value: "Kiribati" },
    { label: "Korea", value: "Korea" },
    { label: "Korea", value: "Korea" },
    { label: "Kuwait", value: "Kuwait" },
    { label: "Kyrgyzstan", value: "Kyrgyzstan" },
    {
        label: "Lao People's Democratic Republic",
        value: "Lao People's Democratic Republic",
    },
    { label: "Latvia", value: "Latvia" },
    { label: "Lebanon", value: "Lebanon" },
    { label: "Lesotho", value: "Lesotho" },
    { label: "Liberia", value: "Liberia" },
    { label: "Libya", value: "Libya" },
    {
        label: "Liechtenstein",
        value: "Liechtenstein",
    },
    { label: "Lithuania", value: "Lithuania" },
    { label: "Luxembourg", value: "Luxembourg" },
    { label: "Macao", value: "Macao" },
    { label: "Madagascar", value: "Madagascar" },
    { label: "Malawi", value: "Malawi" },
    { label: "Malaysia", value: "Malaysia" },
    { label: "Maldives", value: "Maldives" },
    { label: "Mali", value: "Mali" },
    { label: "Malta", value: "Malta" },
    {
        label: "Marshall Islands",
        value: "Marshall Islands",
    },
    { label: "Martinique", value: "Martinique" },
    { label: "Mauritania", value: "Mauritania" },
    { label: "Mauritius", value: "Mauritius" },
    { label: "Mayotte", value: "Mayotte" },
    { label: "Mexico", value: "Mexico" },
    { label: "Micronesia", value: "Micronesia" },
    { label: "Moldova", value: "Moldova" },
    { label: "Monaco", value: "Monaco" },
    { label: "Mongolia", value: "Mongolia" },
    { label: "Montenegro", value: "Montenegro" },
    { label: "Montserrat", value: "Montserrat" },
    { label: "Morocco", value: "Morocco" },
    { label: "Mozambique", value: "Mozambique" },
    { label: "Myanmar", value: "Myanmar" },
    { label: "Namibia", value: "Namibia" },
    { label: "Nauru", value: "Nauru" },
    { label: "Nepal", value: "Nepal" },
    { label: "Netherlands", value: "Netherlands" },
    {
        label: "New Caledonia",
        value: "New Caledonia",
    },
    { label: "New Zealand", value: "New Zealand" },
    { label: "Nicaragua", value: "Nicaragua" },
    { label: "Niger", value: "Niger" },
    { label: "Nigeria", value: "Nigeria" },
    { label: "Niue", value: "Niue" },
    {
        label: "Norfolk Island",
        value: "Norfolk Island",
    },
    {
        label: "Northern Mariana Islands",
        value: "Northern Mariana Islands",
    },
    { label: "Norway", value: "Norway" },
    { label: "Oman", value: "Oman" },
    { label: "Pakistan", value: "Pakistan" },
    { label: "Palau", value: "Palau" },
    { label: "Palestine", value: "Palestine" },
    { label: "Panama", value: "Panama" },
    {
        label: "Papua New Guinea",
        value: "Papua New Guinea",
    },
    { label: "Paraguay", value: "Paraguay" },
    { label: "Peru", value: "Peru" },
    { label: "Philippines", value: "Philippines" },
    { label: "Pitcairn", value: "Pitcairn" },
    { label: "Poland", value: "Poland" },
    { label: "Portugal", value: "Portugal" },
    { label: "Puerto Rico", value: "Puerto Rico" },
    { label: "Qatar", value: "Qatar" },
    {
        label: "Republic of North Macedonia",
        value: "Republic of North Macedonia",
    },
    { label: "Romania", value: "Romania" },
    {
        label: "Russian Federation",
        value: "Russian Federation",
    },
    { label: "Rwanda", value: "Rwanda" },
    { label: "Réunion", value: "Réunion" },
    {
        label: "Saint Barthélemy",
        value: "Saint Barthélemy",
    },
    {
        label: "Saint Helena, Ascension and Tristan da Cunha",
        value: "Saint Helena, Ascension and Tristan da Cunha",
    },
    {
        label: "Saint Kitts and Nevis",
        value: "Saint Kitts and Nevis",
    },
    { label: "Saint Lucia", value: "Saint Lucia" },
    { label: "Saint Martin", value: "Saint Martin" },
    {
        label: "Saint Pierre and Miquelon",
        value: "Saint Pierre and Miquelon",
    },
    {
        label: "Saint Vincent and the Grenadines",
        value: "Saint Vincent and the Grenadines",
    },
    { label: "Samoa", value: "Samoa" },
    { label: "San Marino", value: "San Marino" },
    {
        label: "Sao Tome and Principe",
        value: "Sao Tome and Principe",
    },
    { label: "Saudi Arabia", value: "Saudi Arabia" },
    { label: "Senegal", value: "Senegal" },
    { label: "Serbia", value: "Serbia" },
    { label: "Seychelles", value: "Seychelles" },
    { label: "Sierra Leone", value: "Sierra Leone" },
    { label: "Singapore", value: "Singapore" },
    { label: "Sint Maarten", value: "Sint Maarten" },
    { label: "Slovakia", value: "Slovakia" },
    { label: "Slovenia", value: "Slovenia" },
    {
        label: "Solomon Islands",
        value: "Solomon Islands",
    },
    { label: "Somalia", value: "Somalia" },
    { label: "South Africa", value: "South Africa" },
    {
        label: "South Georgia and the South Sandwich Islands",
        value: "South Georgia and the South Sandwich Islands",
    },
    { label: "South Sudan", value: "South Sudan" },
    { label: "Spain", value: "Spain" },
    { label: "Sri Lanka", value: "Sri Lanka" },
    { label: "Sudan", value: "Sudan" },
    { label: "Suriname", value: "Suriname" },
    {
        label: "Svalbard and Jan Mayen",
        value: "Svalbard and Jan Mayen",
    },
    { label: "Sweden", value: "Sweden" },
    { label: "Switzerland", value: "Switzerland" },
    {
        label: "Syrian Arab Republic",
        value: "Syrian Arab Republic",
    },
    { label: "Taiwan", value: "Taiwan" },
    { label: "Tajikistan", value: "Tajikistan" },
    {
        label: "Tanzania, United Republic of",
        value: "Tanzania, United Republic of",
    },
    { label: "Thailand", value: "Thailand" },
    { label: "Timor-Leste", value: "Timor-Leste" },
    { label: "Togo", value: "Togo" },
    { label: "Tokelau", value: "Tokelau" },
    { label: "Tonga", value: "Tonga" },
    {
        label: "Trinidad and Tobago",
        value: "Trinidad and Tobago",
    },
    { label: "Tunisia", value: "Tunisia" },
    { label: "Turkey", value: "Turkey" },
    { label: "Turkmenistan", value: "Turkmenistan" },
    {
        label: "Turks and Caicos Islands",
        value: "Turks and Caicos Islands",
    },
    { label: "Tuvalu", value: "Tuvalu" },
    { label: "Uganda", value: "Uganda" },
    { label: "Ukraine", value: "Ukraine" },
    {
        label: "United Arab Emirates",
        value: "United Arab Emirates",
    },
    { label: "United Kingdom", value: "United Kingdom" },
    {
        label: "United States Minor Outlying Islands",
        value: "United States Minor Outlying Islands",
    },
    {
        label: "United States of America",
        value: "USA",
    },
    { label: "Uruguay", value: "Uruguay" },
    { label: "Uzbekistan", value: "Uzbekistan" },
    { label: "Vanuatu", value: "Vanuatu" },
    { label: "Venezuela", value: "Venezuela" },
    { label: "Viet Nam", value: "Viet Nam" },
    {
        label: "Virgin Islands",
        value: "Virgin Islands",
    },
    {
        label: "Virgin Islands",
        value: "Virgin Islands",
    },
    {
        label: "Wallis and Futuna",
        value: "Wallis and Futuna",
    },
    {
        label: "Western Sahara",
        value: "Western Sahara",
    },
    { label: "Yemen", value: "Yemen" },
    { label: "Zambia", value: "Zambia" },
    { label: "Zimbabwe", value: "Zimbabwe" },
    {
        label: "Åland Islands",
        value: "Åland Islands",
    },
];

export const LANG_LIST = [
    { label: "Afar", value: "aa" },
    { label: "Abkhazian", value: "ab" },
    { label: "Avestan", value: "ae" },
    { label: "Afrikaans", value: "af" },
    { label: "Akan", value: "ak" },
    { label: "Amharic", value: "am" },
    { label: "Aragonese", value: "an" },
    { label: "Arabic", value: "ar" },
    { label: "Assamese", value: "as" },
    { label: "Avaric", value: "av" },
    { label: "Aymara", value: "ay" },
    { label: "Azerbaijani", value: "az" },
    { label: "Bashkir", value: "ba" },
    { label: "Belarusian", value: "be" },
    { label: "Bulgarian", value: "bg" },
    { label: "Bihari", value: "bh" },
    { label: "Bislama", value: "bi" },
    { label: "Bambara", value: "bm" },
    { label: "Bengali", value: "bn" },
    { label: "Tibetan", value: "bo" },
    { label: "Tibetan", value: "bo" },
    { label: "Breton", value: "br" },
    { label: "Bosnian", value: "bs" },
    { label: "Catalan", value: "ca" },
    { label: "Chechen", value: "ce" },
    { label: "Chamorro", value: "ch" },
    { label: "Corsican", value: "co" },
    { label: "Cree", value: "cr" },
    { label: "Czech", value: "cs" },
    { label: "Czech", value: "cs" },
    { label: "Church", value: "cu" },
    { label: "Chuvash", value: "cv" },
    { label: "Welsh", value: "cy" },
    { label: "Welsh", value: "cy" },
    { label: "Danish", value: "da" },
    { label: "German", value: "de" },
    { label: "German", value: "de" },
    { label: "Divehi", value: "dv" },
    { label: "Dzongkha", value: "dz" },
    { label: "Ewe", value: "ee" },
    { label: "Greek", value: "el" },
    { label: "English", value: "en" },
    { label: "Esperanto", value: "eo" },
    { label: "Castilian", value: "es" },
    { label: "Estonian", value: "et" },
    { label: "Basque", value: "eu" },
    { label: "Basque", value: "eu" },
    { label: "Persian", value: "fa" },
    { label: "Persian", value: "fa" },
    { label: "Fulah", value: "ff" },
    { label: "Finnish", value: "fi" },
    { label: "Fijian", value: "fj" },
    { label: "Faroese", value: "fo" },
    { label: "French", value: "fr" },
    { label: "Western", value: "fy" },
    { label: "Irish", value: "ga" },
    { label: "Gaelic", value: "gd" },
    { label: "Galician", value: "gl" },
    { label: "Guarani", value: "gn" },
    { label: "Gujarati", value: "gu" },
    { label: "Manx", value: "gv" },
    { label: "Hausa", value: "ha" },
    { label: "Hebrew", value: "he" },
    { label: "Hindi", value: "hi" },
    { label: "Hiri", value: "ho" },
    { label: "Croatian", value: "hr" },
    { label: "Haitian", value: "ht" },
    { label: "Hungarian", value: "hu" },
    { label: "Armenian", value: "hy" },
    { label: "Armenian", value: "hy" },
    { label: "Herero", value: "hz" },
    { label: "Interlingua", value: "ia" },
    { label: "Indonesian", value: "id" },
    { label: "Interlingue", value: "ie" },
    { label: "Igbo", value: "ig" },
    { label: "Sichuan", value: "ii" },
    { label: "Inupiaq", value: "ik" },
    { label: "Ido", value: "io" },
    { label: "Icelandic", value: "is" },
    { label: "Icelandic", value: "is" },
    { label: "Italian", value: "it" },
    { label: "Inuktitut", value: "iu" },
    { label: "Japanese", value: "ja" },
    { label: "Javanese", value: "jv" },
    { label: "Georgian", value: "ka" },
    { label: "Georgian", value: "ka" },
    { label: "Kongo", value: "kg" },
    { label: "Kikuyu", value: "ki" },
    { label: "Kuanyama", value: "kj" },
    { label: "Kazakh", value: "kk" },
    { label: "Kalaallisut", value: "kl" },
    { label: "Central", value: "km" },
    { label: "Kannada", value: "kn" },
    { label: "Korean", value: "ko" },
    { label: "Kanuri", value: "kr" },
    { label: "Kashmiri", value: "ks" },
    { label: "Kurdish", value: "ku" },
    { label: "Komi", value: "kv" },
    { label: "Cornish", value: "kw" },
    { label: "Kirghiz", value: "ky" },
    { label: "Latin", value: "la" },
    { label: "Luxembourgish", value: "lb" },
    { label: "Ganda", value: "lg" },
    { label: "Limburgan", value: "li" },
    { label: "Lingala", value: "ln" },
    { label: "Lao", value: "lo" },
    { label: "Lithuanian", value: "lt" },
    { label: "Luba", value: "lu" },
    { label: "Latvian", value: "lv" },
    { label: "Malagasy", value: "mg" },
    { label: "Marshallese", value: "mh" },
    { label: "Maori", value: "mi" },
    { label: "Macedonian", value: "mk" },
    { label: "Malayalam", value: "ml" },
    { label: "Mongolian", value: "mn" },
    { label: "Marathi", value: "mr" },
    { label: "Malay", value: "ms" },
    { label: "Maltese", value: "mt" },
    { label: "Burmese", value: "my" },
    { label: "Burmese", value: "my" },
    { label: "Nauru", value: "na" },
    { label: "Norwegian", value: "nb" },
    { label: "North", value: "nd" },
    { label: "Nepali", value: "ne" },
    { label: "Ndonga", value: "ng" },
    { label: "Dutch", value: "nl" },
    { label: "Norwegian", value: "no" },
    { label: "South", value: "nr" },
    { label: "Navajo", value: "nv" },
    { label: "Chichewa", value: "ny" },
    { label: "Occitan", value: "oc" },
    { label: "Ojibwa", value: "oj" },
    { label: "Oromo", value: "om" },
    { label: "Oriya", value: "or" },
    { label: "Ossetian", value: "os" },
    { label: "Punjabi", value: "pa" },
    { label: "Pali", value: "pi" },
    { label: "Polish", value: "pl" },
    { label: "Pushto", value: "ps" },
    { label: "Portuguese", value: "pt" },
    { label: "Quechua", value: "qu" },
    { label: "Romansh", value: "rm" },
    { label: "Rundi", value: "rn" },
    { label: "Romanian", value: "ro" },
    { label: "Russian", value: "ru" },
    { label: "Kinyarwanda", value: "rw" },
    { label: "Sanskrit", value: "sa" },
    { label: "Sardinian", value: "sc" },
    { label: "Sindhi", value: "sd" },
    { label: "Northern", value: "se" },
    { label: "Sango", value: "sg" },
    { label: "Sinhalese", value: "si" },
    { label: "Slovak", value: "sk" },
    { label: "Slovenian", value: "sl" },
    { label: "Samoan", value: "sm" },
    { label: "Shona", value: "sn" },
    { label: "Somali", value: "so" },
    { label: "Albanian", value: "sq" },
    { label: "Serbian", value: "sr" },
    { label: "Swati", value: "ss" },
    { label: "Sotho", value: "st" },
    { label: "Sundanese", value: "su" },
    { label: "Swedish", value: "sv" },
    { label: "Swahili", value: "sw" },
    { label: "Tamil", value: "ta" },
    { label: "Telugu", value: "te" },
    { label: "Tajik", value: "tg" },
    { label: "Thai", value: "th" },
    { label: "Tigrinya", value: "ti" },
    { label: "Turkmen", value: "tk" },
    { label: "Tagalog", value: "tl" },
    { label: "Tswana", value: "tn" },
    { label: "Tonga", value: "to" },
    { label: "Turkish", value: "tr" },
    { label: "Tsonga", value: "ts" },
    { label: "Tatar", value: "tt" },
    { label: "Twi", value: "tw" },
    { label: "Tahitian", value: "ty" },
    { label: "Uighur", value: "ug" },
    { label: "Ukrainian", value: "uk" },
    { label: "Urdu", value: "ur" },
    { label: "Uzbek", value: "uz" },
    { label: "Venda", value: "ve" },
    { label: "Vietnamese", value: "vi" },
    { label: "Volapük", value: "vo" },
    { label: "Walloon", value: "wa" },
    { label: "Wolof", value: "wo" },
    { label: "Xhosa", value: "xh" },
    { label: "Yiddish", value: "yi" },
    { label: "Yoruba", value: "yo" },
    { label: "Zhuang", value: "za" },
    { label: "Chinese", value: "zh" },
    { label: "Zulu", value: "zu" },
];