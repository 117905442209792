import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ExploreState } from './explore.state';
import { ExploreSelectors } from './explore.selectors';
import {
  searchResults,
  searchWithFilters,
  searchWithLanguage,
  searchWithMoods,
  searchWithTimeframe,
  searchWithLocation,
  searchWithInclude,
  searchWithExlcude,
  searchWithCity
} from './explore.action';
import { SlicePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ExploreFacade {
  searchResults$ = this.exploreSelectors.searchResults$;
  searchTerm$ = this.exploreSelectors.searchTerm$;
  timeframes$ = this.exploreSelectors.timeframes$;
  languages$ = this.exploreSelectors.languages$;
  moods$ = this.exploreSelectors.moods$;
  location$ = this.exploreSelectors.location$;
  include$ = this.exploreSelectors.include$;
  exclude$ = this.exploreSelectors.exclude$;
  city$ = this.exploreSelectors.city$;
  constructor(
    private store: Store<ExploreState>,
    private exploreSelectors: ExploreSelectors,
  ) { }

  search(term: string) {
    this.store.dispatch(searchResults({ term }));
    this.store.dispatch(searchWithFilters());
  }

  searchWithLocation(location: string) {
    this.store.dispatch(searchWithLocation({ location }));
    this.store.dispatch(searchWithFilters());
  }

  searchWithCity(city: string) {
    this.store.dispatch(searchWithCity({ city }));
    this.store.dispatch(searchWithFilters());
  }

  searchWithKeywords(include: string[], exclude: string[]) {
    console.log('Included Keywords:', include);
    console.log('Excluded Keywords:', exclude);
    this.store.dispatch(searchWithInclude({ include }));
    this.store.dispatch(searchWithExlcude({ exclude }));
    this.store.dispatch(searchWithFilters());
  }

  searchWithLanguage(language: string) {
    this.store.dispatch(searchWithLanguage({ language }));
    this.store.dispatch(searchWithFilters());
  }

  searchWithTimeframe(startDate: string, endDate: string) {
    this.store.dispatch(searchWithTimeframe({ startDate, endDate }));
    this.store.dispatch(searchWithFilters());
  }

  searchWithSelectedMoods(selectedMoods: any[]) {
    this.store.dispatch(searchWithMoods({ selectedMoods }));
    this.store.dispatch(searchWithFilters());
  }
}
