import { Action, createReducer, on } from '@ngrx/store';
import { initialRostersState, RostersState } from './rosters.state';
import {
  deleteRosterSuccess,
  getRosterByIdSuccess,
  getRostersSuccess,
  resetEditableRoster,
} from './rosters.action';

export const rostersReducer = (
  state: RostersState | undefined,
  action: Action,
): RostersState => reducer(state, action);

const reducer = createReducer(
  initialRostersState,
  on(getRostersSuccess, (state, { rosters }) => ({
    ...state,
    rosters: rosters.rows || [],
    roasterCount: rosters.count
  })),
  on(getRosterByIdSuccess, (state, { roster }) => ({
    ...state,
    editableRoster: roster,
  })),
  on(deleteRosterSuccess, (state, { rosterId }) => ({
    ...state,
    rosters: state.rosters.filter((roster) => roster.id !== rosterId),
  })),
  on(resetEditableRoster, (state) => ({
    ...state,
    editableRoster: null,
  })),
);
