import { Injectable } from '@angular/core';
import { Store, createFeatureSelector, createSelector } from '@ngrx/store';
import { StudentsState } from './students.state';

const getStudentsState = createFeatureSelector<StudentsState>('students');

const getStudents = createSelector(
  getStudentsState,
  (state: StudentsState) => state.students,
);

const getEditableStudent = createSelector(
  getStudentsState,
  (state: StudentsState) => state.editableStudent,
);

const getRosterId = createSelector(
  getStudentsState,
  (state: StudentsState) => state.rosterId,
);

const getErrorMessage = createSelector(
  getStudentsState,
  (state: StudentsState) => state.errorMessage,
);

@Injectable()
export class StudentsSelectors {
  students$ = this.store.select(getStudents);
  editableStudent$ = this.store.select(getEditableStudent);
  rosterId$ = this.store.select(getRosterId);
  errorMessage$ = this.store.select(getErrorMessage);
  constructor(private store: Store<StudentsState>) {}
}
