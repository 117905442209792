import { Component } from '@angular/core';
import { StudentsFacade } from 'src/app/students/store/students.facade';
import { CreateStudentRequest } from 'src/app/models/students/create-student-request.model';
import { UpdateStudentRequest } from 'src/app/models/students/update-student-request.model';

import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'rap-add-edit-students',
  templateUrl: './add-edit-students.container.component.html',
})
export class AddEditStudentsContainerComponent {
  editableStudent$ = this.studentsFacade.editableStudent$;
  errorMessage$ = this.studentsFacade.errorMessage$;
  rosterId: string;
  studentId: string = null;
  constructor(private studentsFacade: StudentsFacade,
    private router: Router,
    private route: ActivatedRoute,) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      console.log(params)
      console.log(params['rosterId'])
      console.log(params['studentId'])
      this.rosterId = params['rosterId'];
      this.studentId = params['studentId'];
      console.log(this.rosterId)
    });
  }

  createNewStudent(newStudent: CreateStudentRequest) {
    this.studentsFacade.createNewStudent(newStudent, this.rosterId);
  }

  updateStudent(updatedStudent: UpdateStudentRequest) {
    this.studentsFacade.updateStudent(updatedStudent);
  }

  ngOnDestroy() {
    this.studentId = null;

    this.studentsFacade.resetErrorMessge();
  }
}
