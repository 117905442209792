import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseTemplateService } from '../base-template.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { TrendingWordsService } from './trending-words.service';

@Component({
  selector: 'rap-trending-words',
  templateUrl: './trending-words.component.html',
  styleUrls: ['./trending-words.component.scss']
})
export class TrendingWordsComponent {

  isLoading = false;
  filters: any = {
    search: ""
  };
  trendingWords: any[] = []
  constructor(private router: Router,
    private route: ActivatedRoute,
    private baseTemplateService: BaseTemplateService
    , private modalService: NgbModal,
    private toastr: ToastrService,
    private loaderService: AppService, private service: TrendingWordsService) {
    this.loaderService.loading$.subscribe(isLoading => {
      // if (i)
      console.log("isLoading")
      console.log(isLoading)
      this.isLoading = isLoading;
    });
  }

  ngOnInit(): void {
    this.isLoading = true;
    console.log("ffff");
    this.baseTemplateService.showHideSearch(false);
    this.isLoading = true;
    this.getAllKeywords();
  }

  getAllKeywords() {
    this.service.getAllternding().subscribe((result) => {
      this.isLoading = false;
      this.trendingWords = result;
      console.log(result);

    });
  }

  getSearchResults() {
    // this.trendingWords.push(this.filters.search);
    // this.filters.search = '';
    this.onAdd();
  }

  onClearSearch() {
    this.filters.search = '';
  }

  onAdd() {
    this.isLoading = true;
    var data = {
      "data": {
        "keyword": this.filters.search
      }
    }
    this.filters.search = '';
    this.service.addNew(data).subscribe((result) => {
      this.isLoading = false;
      this.getAllKeywords();
      console.log(result);

    });
  }

  onClear(index: number) {
    console.log(index);
    console.log(this.trendingWords[index])
    this.isLoading = true;
    this.service.deleteKeyWord(this.trendingWords[index].id).subscribe((result) => {
      this.isLoading = false;
      this.getAllKeywords();
      console.log(result);
    });

  }
}
