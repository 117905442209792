import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { ChangePasswordModalComponent } from '../change-password-modal/change-password-modal.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PasswordResetSuccessModalComponent } from 'src/app/password-help/password-reset-success-modal/password-reset-success-modal.component';
import { UpdateSuccessModalComponent } from '../update-success-modal/update-success-modal.component';

@Component({
  selector: 'rap-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  isLoading: boolean = false;
  constructor(private authService: AuthService, private router: Router, private userService: UserService, private modalService: NgbModal) {
  }

  ngOnInit() {

  }

  onChangePassword() {


    var data: any = {
      "email": this.authService.getUserEmail()
    }
    this.isLoading = true;
    this.userService.changePassword(data).subscribe((result) => {
      console.log(result)
      this.isLoading = false;

      const modalRef = this.modalService.open(ChangePasswordModalComponent, { centered: true });
      modalRef.result
        .then((data) => {
          console.log(data)
          if (data == true) {
            const modalRef = this.modalService.open(UpdateSuccessModalComponent);
            modalRef.componentInstance.modalTitle = 'Your password has been changed';
          }

        })
        .catch((error) => {
          this.isLoading = false;
        });
    });
  }
}
