<div class="label-container">
    <div>
        <label class="clickable-label" routerLink="/dashboard/explore"> Rap Almanac </label>
        <img src="../assets/images/filled.svg" />
    </div>


    <label class="non-clickable-label">{{title}}</label>

    <div>
        <img src="../assets/images/filled.svg" />
        <label class="non-clickable-label">Producers</label>
    </div>


</div>

<div class="producers-section">
    <div class="section-title">Producers</div>
    <button type="button" class="btn btn-dark add-song-btn" (click)="createProducerEmitter.emit()"
        style="margin-bottom: 20px;">
        Add New
    </button>

    <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
    <div class="search-container">
        <img src="../assets/images/search.svg" class="search-icon" />
        <input type="text" placeholder="Search..." class="search-input" [(ngModel)]="filters.search"
            (keydown.enter)="getSearchResults()">
        <div class="auth-icons">
            <i class="bi bi-x-circle-fill" style="margin-left: 10px;" *ngIf="filters.search !== ''"
                (click)="onClearSearch()"></i>
        </div>
    </div>
    <ng-container *ngIf="producersData?.length > 0">
        <div class="custom-container">
            <div class="songs-table" *ngIf="producersData?.length > 0">
                <table class="table rounded-4 overflow-hidden">
                    <thead>
                        <tr>

                            <th scope="col">Producer Id</th>
                            <th scope="col">Name</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let producer of paginatedProducers">
                            <td scope="row">{{ producer?.producerid }}</td>
                            <td scope="row">{{ producer?.name }}</td>
                            <td>
                                <button type="button" class="btn btn-light edit-producers-btn"
                                    (click)="editProducer(producer)">
                                    Edit
                                </button>
                                <button type="button" class="btn btn-dark delete-song-btn"
                                    (click)="deleteProducer(producer.producerid)">
                                    Delete
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="pagination">
                    <rap-pagination [collectionSize]="pagesCount" [page]="page" [pageSize]="pageSize"
                        (onPageChange)="onPageChange($event)"></rap-pagination>
                </div>
            </div>
        </div>

    </ng-container>

</div>

<div style="padding: 20px; text-align: center; margin-top: 100px;" *ngIf="producersData?.length === 0 && !isLoading">
    <img src="../assets/images/question.svg" />
    <div class="no-match">No producers found</div>
</div>