<div class="label-container">
    <div>
        <label class="clickable-label" routerLink="/dashboard/explore"> Rap Almanac </label>
        <img src="../assets/images/filled.svg" />
    </div>
    <div>
        <label class="non-clickable-label">Trending</label>
    </div>
</div>

<div class="users-section">
    <div class="section-title">Trending</div>
    <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>

    <div style="font-size: 16px; font-style: normal;">
        <span style="font-weight: 600;">Words</span>
        <span style="font-weight: 200;"> (Max Limit 10) :</span>
    </div>
    <div style="   
       width: 52% ;">
        <div class="search-container">
            <img class="search-icon" />
            <input type="text" placeholder="" class="search-input" [(ngModel)]="filters.search">
            <div class="auth-icons">
                <i class="bi bi-x-circle-fill" style="margin-left: 10px;" *ngIf="filters.search !== ''"
                    (click)="onClearSearch()"></i>
            </div>
        </div>
        <button class="search-button" (click)="onAdd()" [disabled]="trendingWords.length === 10">
            Add
        </button>
    </div>
    <div class="applied-filters">
        <div *ngFor="let include of trendingWords let i = index">
            <div class="language-row">
                <span class="language-title"> {{include.keyword}} </span>
                <i class="bi bi-x-lg" (click)="onClear(i)"></i>
            </div>
        </div>
    </div>
</div>