<div style="margin: 20px;">
    <div class="paragraph">
        <h5 style="color: black">
            <strong>Privacy Notice</strong>
        </h5>
    </div>
    <div class="paragraph">Effective Date: July 14, 2021</div>

    <div class="paragraph">
        <strong style="color: black">Last updated May 27, 2022</strong>
    </div>

    <div class="paragraph">
        Thank you for choosing to be part of our community at Rap Research Lab
        (&quot;<strong>Company</strong>&quot;, &quot;<strong>we</strong>&quot;,
        &quot;<strong>us</strong>
        &quot;, &quot;<strong>our</strong>&quot;). We are committed to
        protecting your personal information and your right to privacy. If you
        have any questions or concerns about this privacy notice, or our
        practices with regards to your personal information, please contact us
        at info@ rapresearchlab.com.
    </div>

    <div class="paragraph">
        The RapAlmanac.com website and Mapper’s Delight mobile application (the
        “App”), are owned and operated by Rap Research Lab. RapAlmanac.com
        provides a database of metadata about hip hop music and is intended for
        hip hop research. The App is an interactive globe which allows users to
        see the geographic references in a database of hip hop music. It also
        allows users to play short samples of each song. RapAlmanac.com is
        intended for use by teachers, researchers, and students. The App is
        intended for use by teachers and students.
    </div>

    <div class="paragraph">
        When you use any of our services (the &quot;<strong>Services</strong>
        &quot;, which include the App), we appreciate that you are trusting us
        with your personal information. We take your privacy seriously. In this
        privacy notice, we seek to explain to you in the clearest way possible
        what information we collect, how we use it and what rights you have in
        relation to it. We hope you take some time to read through it carefully,
        as it is important. If there are any terms in this privacy notice that
        you do not agree with, please discontinue use of our Services
        immediately.
    </div>

    <div class="paragraph">
        This privacy notice applies to all information collected through our
        Services (which, as described above, includes our App).
    </div>

    <div class="paragraph">
        <strong style="color: black">
            Please read this privacy notice carefully as it will help you
            understand what we do with the information that we collect.
        </strong>
    </div>

    <div class="paragraph">
        <strong>&nbsp;</strong>
    </div>

    <div class="paragraph">
        <strong style="color: black">TABLE OF CONTENTS</strong>
    </div>

    <div class="paragraph">
        <ol>
            <li>WHAT INFORMATION DO WE COLLECT?</li>
            <li>HOW DO WE USE YOUR INFORMATION?</li>
            <li>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</li>
            <li>SERVICE PROVIDERS AND THIRD PARTIES</li>
            <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
            <li>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
            <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
            <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
            <li>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
            <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
            <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
        </ol>
    </div>

    <div class="paragraph">
        <strong style="color: black">1. WHAT INFORMATION DO WE COLLECT?</strong>
    </div>

    <div class="paragraph">
        <strong style="color: black"> Personal information you disclose to us</strong>
    </div>

    <div class="paragraph">
        We collect personal information that you voluntarily provide to us when
        you register on the App, express an interest in obtaining information
        about us or our products and Services, when you participate in
        activities on the App (such as by posting messages in our online forums
        or entering competitions, contests or giveaways) or otherwise when you
        contact us.
    </div>

    <div class="paragraph">
        The personal information that we collect depends on the context of your
        interactions with us and the App, the choices you make and the products
        and features you use. The personal information we collect may include
        the following:
    </div>

    <div class="paragraph">Personal Information We Collect From You</div>

    <div class="paragraph">full name, email address; and passwords</div>

    <div class="paragraph">
        All personal information that you provide to us must be true, complete
        and accurate, and you must notify us of any changes to such personal
        information.
    </div>

    <div class="paragraph">Personal Information We Collect from Children</div>

    <div class="paragraph">
        We do not actively collect personal information from children and
        students. We collect some information automatically from all users
    </div>

    <div class="paragraph">
        We automatically collect certain information when you visit, use or
        navigate the App. This information does not reveal your specific
        identity (like your name or contact information) but may include device
        and usage information, such as your IP address, browser and device
        characteristics, operating system, language preferences, referring URLs,
        device name, country, location, information about how and when you use
        our App and other technical information. This information is primarily
        needed to maintain the security and operation of our App, and for our
        internal analytics and reporting purposes.
    </div>

    <div class="paragraph">The information we collect includes:</div>

    <div class="paragraph">
        <ul>
            <li>
                <i>Log and Usage Data.</i> Log and usage data is service-related,
                diagnostic, usage and performance information our servers
                automatically collect when you access or use our App and which we
                record in log files. Depending on how you interact with us, this log
                data may include your IP address, device information, browser type
                and settings and information about your activity in the App (such as
                the date/time stamps associated with your usage, pages and files
                viewed, searches and other actions you take such as which features
                you use), device event information (such as system activity, error
                reports (sometimes called 'crash dumps') and hardware settings).
            </li>
            <li>
                <i>Mobile Device Access.</i> We may request access or permission to
                certain features from your mobile device, including your mobile
                device's camera, sensors, and other features. If you wish to change
                our access or permissions, you may do so in your device's settings.
            </li>
        </ul>
    </div>

    <div class="paragraph">
        This information is primarily needed to maintain the security and
        operation of our App, for troubleshooting and for our internal analytics
        and reporting purposes.
    </div>

    <div class="paragraph">
        <strong style="color: black">2. HOW DO WE USE YOUR INFORMATION?</strong>
    </div>

    <div class="paragraph">
        We use the personal information collected via our App for a variety of
        purposes to support Rap Research Lab and to continue to provide the
        service to users. These purposes include: improving and developing the
        service, communicating with users about the service, and
        security/maintaining a trusted environment.
    </div>

    <div class="paragraph">
        <strong style="color: black">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</strong>
    </div>

    <div class="paragraph">
        <i>
            We only share information, to comply with laws, to provide you with
            services, to protect your rights, or to fulfill business obligations.
        </i>
    </div>

    <div class="paragraph">
        More specifically, we may need to process your data or share your
        personal information in the following situations:
    </div>

    <div class="paragraph">
        <ul>
            <li>
                <strong style="color: black">Business Transfers.</strong> We may share or transfer your
                information in connection with, or during negotiations of, any
                merger, sale of company assets, financing, or acquisition of all or
                a portion of our business to another company.
            </li>
        </ul>
    </div>

    <div class="paragraph">
         <strong style="color: black">4. SERVICE PROVIDERS AND THIRD PARTIES</strong>
    </div>

    <div class="paragraph">
        Mapper’s Delight uses Unity analytics to better understand your use of
        the app and to provide you with the best experience. Unity analytics
        does not share your data with any other third parties. For more
        information on the Unity, please visit the privacy policy:
        <a href='https://unity3d.com/legal/privacy-policy'>

            https://unity3d.com/legal/privacy-policy
        </a>
    </div>

    <div class="paragraph">
        If you would like further information about the use of service providers
        in the app, please email us at info@rapresearchlab.com
    </div>

    <div class="paragraph">
        <strong style="color: black">5. HOW LONG DO WE KEEP YOUR INFORMATION?</strong>
    </div>

    <div class="paragraph">
        We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy notice, unless a
        longer retention period is required or permitted by law (such as tax,
        accounting or other legal requirements). No purpose in this notice will
        require us to keep your personal information for longer than six months
        after the time in which users have an account with us.
    </div>

    <div class="paragraph">
        <strong style="color: black">6. HOW DO WE KEEP YOUR INFORMATION SAFE?</strong>
    </div>

    <div class="paragraph">
        We have implemented appropriate technical and organizational security
        measures designed to protect the security of any personal information we
        process. However, despite our safeguards and efforts to secure your
        information, no electronic transmission over the Internet or information
        storage technology can be guaranteed to be 100% secure, so we cannot
        promise or guarantee that hackers, cybercriminals, or other unauthorized
        third parties will not be able to defeat our security, and improperly
        collect, access, steal, or modify your information. Although we will do
        our best to protect your personal information, transmission of personal
        information to and from our App is at your own risk. You should only
        access the App within a secure environment.
    </div>

    <div class="paragraph">
        https://apps.apple.com/developer/rap-research-lab/id1508894142
    </div>

    <div class="paragraph">
        Our service may link out to other services. Rap Research Lab is not
        responsible for other sites or services. If you are leaving our service
        please be sure to check the privacy policies of any sites or services
        you visit.
    </div>

    <div class="paragraph">
         <strong style="color: black">7. WHAT ARE YOUR PRIVACY RIGHTS?</strong>
    </div>

    <div class="paragraph">
        <div>
            <strong style="color: black">
                A parent may review your information at any time and may have it
                deleted.
            </strong>
        </div>
        You may email us at support@rapresearchlab.com to review or delete your
        information. Upon your request to terminate your account, we
        will deactivate and delete your account and information from our
        databases. However, we may retain some information such as play history
        in our files to prevent fraud, troubleshoot problems, assist with any
        investigations, enforce our Terms of Use and/or comply with applicable
        legal requirements.
    </div>

    <div class="paragraph">
        <strong style="color: black">8. CONTROLS FOR DO-NOT-TRACK FEATURES</strong>
    </div>

    <div class="paragraph">
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track (&quot;DNT&quot;) feature or setting
        you can activate to signal your privacy preference not to have data
        about your online browsing activities monitored and collected. At this
        stage no uniform technology standard for recognizing and implementing
        DNT signals has been finalized. As such, we do not currently respond to
        DNT browser signals or any other mechanism that automatically
        communicates your choice not to be tracked online. If a standard for
        online tracking is adopted that we must follow in the future, we will
        inform you about that practice in a revised version of this privacy
        notice.
    </div>

    <div class="paragraph">
        <strong style="color: black">
            9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </strong>
    </div>

    <div class="paragraph">
        California Civil Code Section 1798.83, also known as the &quot;Shine The
        Light&quot; law, permits our users who are California residents to
        request and obtain from us, once a year and free of charge, information
        about categories of personal information (if any) we disclosed to third
        parties for direct marketing purposes and the names and addresses of all
        third parties with which we shared personal information in the
        immediately preceding calendar year. If you are a California resident
        and would like to make such a request, please submit your request in
        writing to us using the contact information provided below.
    </div>

    <div class="paragraph">
        If you are under 18 years of age, reside in California, and have a
        registered account with the App, you have the right to request removal
        of unwanted data that you publicly post on the App. To request removal
        of such data, please contact us using the contact information provided
        below, and include the email address associated with your account and a
        statement that you reside in California. We will make sure the data is
        not publicly displayed on the App, but please be aware that the data may
        not be completely or comprehensively removed from all our systems
    </div>

    <div class="table-policy">
        <tr>
            <th>Category</th>
            <th>Examples</th>
            <th>Collected</th>
        </tr>
        <tr>
            <td>A. Identifiers</td>
            <td>
                Contact details, such as real name, alias, postal address, telephone
                or mobile contact number, unique personal identifier, online
                identifier, Internet Protocol address, email address and account
                name
            </td>
            <td>YES</td>
        </tr>
        <tr>
            <td>
                B. Personal information categories listed in the California Customer
                Records statute
            </td>
            <td>
                Name, contact information, education, employment, employment history
                and financial information
            </td>
            <td>YES</td>
        </tr>
        <tr>
            <td>
                C. Protected classification characteristics under California or
                federal law
            </td>
            <td>Gender and date of birth</td>
            <td>NO</td>
        </tr>
        <tr>
            <td>D. Commercial information </td>
            <td>
                Transaction information, purchase history, financial details and
                payment information
            </td>
            <td>NO</td>
        </tr>
        <tr>
            <td>E. Biometric information </td>
            <td>Fingerprints and voiceprints</td>
            <td>NO</td>
        </tr>
        <tr>
            <td>F. Internet or other similar network activity</td>
            <td>
                Browsing history, search history, online behavior, interest data,
                and interactions with our and other websites, applications, systems
                and advertisements
            </td>
            <td>NO</td>
        </tr>
        <tr>
            <td>G. Geolocation data </td>
            <td>Device location </td>
            <td>NO</td>
        </tr>
        <tr>
            <td>
                H. Audio, electronic, visual, thermal, olfactory, or similar
                information
            </td>
            <td>
                Images and audio, video or call recordings created in connection
                with our business activities
            </td>
            <td>NO</td>
        </tr>
        <tr>
            <td>I. Professional or employment-related information</td>
            <td>
                Business contact details in order to provide you our services at a
                business level, job title as well as work history and professional
                qualifications if you apply for a job with us
            </td>
            <td>NO</td>
        </tr>
        <tr>
            <td>J. Education Information </td>
            <td>Student records and directory information</td>
            <td>NO</td>
        </tr>
        <tr>
            <td>K. Inferences drawn from other personal information</td>
            <td>
                Inferences drawn from any of the collected personal information
                listed above to create a profile or summary about, for example, an
                individual’s preferences and characteristics
            </td>
            <td>NO</td>
        </tr>
    </div>

    <div class="paragraph">
        We may also collect other personal information outside of these
        categories instances where you interact with us in-person, online, or by
        phone or mail in the context of:
    </div>

    <div class="paragraph">
        <ul>
            <li>Receiving help through our customer support channels;</li>
            <li>
                Participation in customer surveys or contests (children do not
                participate); and
            </li>
            <li>
                Facilitation in the delivery of our Services and to respond to your
                inquiries.
            </li>
        </ul>
    </div>

    <div class="paragraph">
        <strong style="color: black">10. DO WE MAKE UPDATES TO THIS NOTICE?</strong>
    </div>

    <div class="paragraph">
        We may update this privacy notice from time to time. The updated version
        will be indicated by an updated &quot;Revised&quot; date and the updated
        version will be effective as soon as it is accessible. If we make
        material changes to this privacy notice, and we have your contact
        information, we will notify you by sending you a notification before we
        make the change, as well as prominently posting a notice of such
        changes. We encourage you to review this privacy notice frequently to be
        informed of how we are protecting your information.
    </div>

    <div class="paragraph">
        <strong style="color: black">11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</strong>
    </div>

    <div class="paragraph">
        If you have questions or comments about this notice, you may email us at
        info@rapresearchlab.com or by post to:
    </div>

    <div class="paragraph">Rap Research Lab</div>

    <div class="paragraph">10 E. North Ave, Suite 5</div>

    <div class="paragraph">Baltimore, MD 21202</div>

    <div class="paragraph">United States</div>

    <div class="paragraph">646-481-0458</div>

    <div class="paragraph">
        <strong style="color: black">
            Kids Privacy Assured by PRIVO: COPPA Safe Harbor Certification and
            Student Digital Privacy Program
        </strong>
    </div>

    <div class="paragraph">
        Rap Research LLC is a member of the PRIVO Kids Privacy Assured Program
        <i> </i>(“the Program”) for COPPA Safe Harbor Certification and Student
        Digital Privacy Program. PRIVO is an independent, third-party
        organization committed to safeguarding children's personal information
        collected online.
    </div>

    <div class="paragraph">
        <strong style="color: black">COPPA Safe Harbor Certification</strong>
    </div>

    <div class="paragraph">
        <a href='https://cert.privo.com/#/companies/rapresearchlabllc' target='_blank' rel='noopener noreferrer'>
            <img src="../assets/images/coppa.png" />
        </a>
    </div>

    <div class="paragraph">
        The Program certification applies to the digital properties listed on
        the validation page that is viewable by clicking on the PRIVO COPPA
        certification Seal. The certification Seal posted on this page indicates
        Rap Research LLC has established COPPA compliant privacy practices and
        has agreed to submit to PRIVO’s oversight and consumer dispute
        resolution process. If you have questions or concerns about our privacy
        practices, please contact us at 646-481-0458 or info@rapresearchlab.com.
        If you have further concerns after you have contacted us, you can
        contact PRIVO directly at
        <a
            href='https://connect.emailsrvr.com/owa/auth/logon.aspx?url=https://connect.emailsrvr.com/owa/redir.aspx%3FC=wkyuGTKjk4BL06fVgwOlAbUOTHdrV8it-WKjYwds-cbcRSOv043VCA..%26URL=mailto%25252525253aprivacy%252525252540privo.com&reason=0'>
            privacy@privo.com
        </a>

    </div>

    <div class="paragraph">
        <strong style="color: black">Student Digital Privacy</strong>
        <strong style="color: black">Assured</strong>
    </div>

    <div class="paragraph">
        <a href='https://cert.privo.com/#/companies/rapresearchlabllc' target='_blank' rel='noopener noreferrer'>
            <img src="../assets/images/digital_privacy.png" alt='Student Digital Privacy logo' />
        </a>
    </div>

    <div class="paragraph">
        The Program applies to the digital properties listed on the validation
        page that is viewable by clicking on the PRIVO Student Digital Privacy
        Verified Shield. The PRIVO Student Digital Privacy Assured Program
        supports EdTech providers to comply with key student digital privacy
        regulations, including Family Educational Rights and Privacy Act
        (“FERPA”), Protection of Pupil Rights Amendment (“PPRA”) Student Online
        Personal Information Protection Act (“SOPIPA”), California AB 1584
        Education Code section 49073.1 - Privacy of Pupil Records: 3rd-Party
        Digital, California Education Code 49073.6 - Collection of student
        Information from social media and Student Privacy Pledge. The Program
        includes regular monitoring of its members.
    </div>

    <div class="paragraph">&nbsp;</div>

    <h4>
        <strong style="color: black">End-User License Agreement</strong>
    </h4>
    <div class="paragraph">
        This End-User License Agreement (&quot;EULA&quot;) is a legal agreement
        between you and Rap Research Lab. This EULA agreement governs your
        acquisition and use of our Rap Almanac software (&quot;Software&quot;)
        directly from Rap Research Lab or indirectly through a Rap Research Lab
        authorized reseller or distributor (a &quot;Reseller&quot;).
    </div>

    <div class="paragraph">
        Please read this EULA agreement carefully before completing the
        installation process and using the Rap Almanac software. It provides a
        license to use the Rap Almanac software and contains warranty
        information and liability disclaimers.
    </div>

    <div class="paragraph">
        If you register for a free trial of the Rap Almanac software, this EULA
        agreement will also govern that trial. By clicking &quot;accept&quot; or
        installing and/or using the Rap Almanac software, you are confirming
        your acceptance of the Software and agreeing to become bound by the
        terms of this EULA agreement.
    </div>

    <div class="paragraph">
        If you are entering into this EULA agreement on behalf of a company or
        other legal entity, you represent that you have the authority to bind
        such entity and its affiliates to these terms and conditions. If you do
        not have such authority or if you do not agree with the terms and
        conditions of this EULA agreement, do not install or use the Software,
        and you must not accept this EULA agreement. This EULA agreement shall
        apply only to the Software supplied by Rap Research Lab herewith
        regardless of whether other software is referred to or described herein.
        The terms also apply to any Rap Research Lab updates, supplements,
        Internet-based services, and support services for the Software, unless
        other terms accompany those items on delivery. If so, those terms apply.
    </div>

    <div class="paragraph">
        <h5>
            <strong style="color: black">License Grant</strong>
        </h5>
    </div>

    <div class="paragraph">
        Rap Research Lab hereby grants you a personal, non-transferable,
        non-exclusive license to use the Rap Almanac software on your devices in
        accordance with the terms of this EULA agreement.
    </div>

    <div class="paragraph">
        You are permitted to load the Rap Almanac software (for example a PC,
        laptop, mobile or tablet) under your control. You are responsible for
        ensuring your device meets the minimum requirements of the Rap Almanac
        software. You are not permitted to:
    </div>

    <div class="paragraph">
        <ul>
            <li>
                Edit, alter, modify, adapt, translate or otherwise change the whole
                or any part of the Software nor permit the whole or any part of the
                Software to be combined with or become incorporated in any other
                software, nor decompile, disassemble or reverse engineer the
                Software or attempt to do any such things.
            </li>
            <li>
                Reproduce, copy, distribute, resell or otherwise use the Software
                for any commercial purpose
            </li>
            <li>
                Allow any third party to use the Software on behalf of or for the
                benefit of any third party
            </li>
            <li>
                Use the Software in any way which breaches any applicable local,
                national or international law
            </li>
            <li>
                Use the Software for any purpose that Rap Research Lab considers is
                a breach of this EULA agreement
            </li>
        </ul>
    </div>

    <div class="paragraph">
        <h5>
            <strong style="color: black">Intellectual Property and Ownership</strong>
        </h5>
    </div>

    <div class="paragraph">
        Rap Research Lab shall at all times retain ownership of the Software as
        originally downloaded by you and all subsequent downloads of the
        Software by you. The Software (and the copyright, and other intellectual
        property rights of whatever nature in the Software, including any
        modifications made thereto) are and shall remain the property of Rap
        Research Lab. Rap Research Lab reserves the right to grant licenses to
        use the Software to third parties.
    </div>

    <div class="paragraph">
        <h5>
            <strong style="color: black">Termination</strong>
        </h5>
    </div>

    <div class="paragraph">
        This EULA agreement is effective from the date you first use the
        Software and shall continue until terminated. You may terminate it at
        any time upon written notice to Rap Research Lab.
    </div>

    <div class="paragraph">
        It will also terminate immediately if you fail to comply with any term
        of this EULA agreement. Upon such termination, the licenses granted by
        this EULA agreement will immediately terminate and you agree to stop all
        access and use of the Software. The provisions that by their nature
        continue and survive will survive any termination of this EULA
        agreement.
    </div>

    <div class="paragraph">
        <h5>
            <strong style="color: black">Governing Law</strong>
        </h5>
    </div>

    <div class="paragraph">
        This EULA agreement, and any dispute arising out of or in connection
        with this EULA agreement, shall be governed by and construed in
        accordance with the laws of the State of New York, USA.
    </div>
</div>