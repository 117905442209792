<!-- <div #nnsContainer id="nns" class="d-flex justify-content-center align-items-center"
    style="width: 500px; height: 500px;"></div> -->
<!-- 
<div id="nns" class="chart-container"></div> -->
<!-- <div id="chart-container"></div> -->


<!-- <div #chartContainer style="width: 100%;  height: 100%;"></div> -->

<!-- <canvas #wordCloudCanvas></canvas> -->

<!-- <div #chartContainer style=" overflow: hidden; position: relative;"></div> -->
<!-- <div #chart style="background-color: brown;  overflow: hidden; position: relative;"></div> -->
<!-- <div #chart class="chart-container"></div> -->

<div class="chart-container">
    <div class="controls-section">
        <select id="centerSelect" [(ngModel)]="centerType" (change)="updateCenterType()">
            <option value="centroid">Centroid</option>
            <option value="query">Query Word</option>
        </select>
    </div>
    <div class="chart-section">
        <div #chartContainer class="chart"
            style=" height: 300px; padding-top: 80px; overflow: hidden; position: relative;"></div>
    </div>

</div>