import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FunctionsState } from './functions.state';
import {
  clearErrorMessage,
  createNewFunction,
  deleteFunction,
  getFunctionById,
  getFunctions,
  resetEditableFunction,
  updateFunction,
} from './functions.action';
import { FunctionsSelectors } from './functions.selectors';
import { Function } from 'src/app/models/functions/functions.model';
import { CreateFunctionRequest } from 'src/app/models/functions/create-function-request.model';
import { UpdateFunctionRequest } from 'src/app/models/functions/update-function-request.model';

@Injectable({
  providedIn: 'root',
})
export class FunctionsFacade {
  functions$ = this.functionsSelectors.functions$;
  editableFunction$ = this.functionsSelectors.editableFunction$;
  errorMessage$ = this.functionsSelectors.errorMessage$;
  constructor(
    private store: Store<FunctionsState>,
    private functionsSelectors: FunctionsSelectors,
  ) {}

  getFunctions() {
    this.store.dispatch(getFunctions());
  }

  getFunctionById(id: string) {
    this.store.dispatch(getFunctionById({ functionId: id }));
  }

  createNewFunction(func: CreateFunctionRequest) {
    this.store.dispatch(createNewFunction({ function: func }));
  }

  deleteFunction(id: string) {
    this.store.dispatch(deleteFunction({ functionId: id }));
  }

  updateFunction(func: UpdateFunctionRequest) {
    this.store.dispatch(updateFunction({ function: func }));
  }

  resetEditableFunction() {
    this.store.dispatch(resetEditableFunction());
  }

  clearErrorMessage() {
    this.store.dispatch(clearErrorMessage());
  }
}
