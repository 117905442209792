<div class="label-container" *ngIf="!isEditProfile">
    <div class="container">
        <div class="spinner-overlay" *ngIf="isLoading">
            <div class="spinner"></div>
        </div>
    </div>

    <div class="clickable-label" routerLink="/dashboard/explore">
        <i class="bi bi-arrow-left"></i>
        <label class="clickable-label"> Home </label>

    </div>

    <label style=" font-size: 30px;
    font-style: normal;
    font-weight: 600;"> Account Info </label>

    <div class="profile-icon" *ngIf="user !== null">

        <ng-container *ngIf="avatar; else showInitials">
            <img [src]="avatar" alt="Avatar" class="header-avatar rounded-circle" style="width: 80px; height: 80px;" />

            <!-- <i class="bi bi-person-circle" style="width: 80px; height: 80px;" class="header-text rounded-circle"></i> -->
        </ng-container>
        <ng-template #showInitials>
            <!-- <span class="header-text rounded-circle" style="width: 80px; height: 80px;">
                {{ firstName ? firstName[0].toUpperCase() : '' }}
            </span> -->
            <span class="header-text rounded-circle" style=" background-color: black;">
                <i class="bi bi-person-circle"></i>
            </span>
        </ng-template>
        <button class="edit-icon" (click)="onEdit()">Edit
        </button>

    </div>
    <div class="basic-info-container">
        <label class="title" style=" font-size: 20px;
    font-style: normal;
    font-weight: 600;">Basic Info</label>

        <div>
            <label style="background-color: #E8E8E8; padding: 5px 10px; border-radius: 20px; cursor: pointer;"
                (click)="onEditProfile()" *ngIf="!isLoading">Edit
                Profile</label>
        </div>

    </div>

    <div class="info-container " *ngIf="user !== null">
        <label class="title"> First and last name </label>
        <label class="name"> {{user.firstName}} {{user.lastName}}</label>
    </div>

    <div class="info-container " *ngIf="user !== null">
        <label class="title"> Email Address</label>
        <label class="name"> {{user.email}}</label>
    </div>


    <div class="info-container " *ngIf="user !== null">
        <label class="title"> Username </label>
        <label class="name"> {{user.userName}}</label>
    </div>


    <div class="info-container " *ngIf="user !== null">
        <label class="title"> Role </label>
        <label class="name"> {{user.role.name}}</label>
    </div>
</div>

<div style="padding: 50px 20px;" *ngIf="isEditProfile">
    <div class="container">
        <div class="spinner-overlay" *ngIf="isLoading">
            <div class="spinner"></div>
        </div>
    </div>

    <div class="clickable-label" (click)="onBack()">
        <i class="bi bi-arrow-left"></i>
        <label class="clickable-label"> Account Info </label>
    </div>

    <label style=" font-size: 30px;
        font-style: normal;
        font-weight: 600;">Edit Account Info </label>

    <form [formGroup]="editForm" (ngSubmit)="onEditSubmit()">
        <div class="row">
            <div class="input-container">
                <div class="form-group">
                    <label for="firstname">First Name</label>

                    <input id="firstname" class="form-control" formControlName="firstname"
                        style="background-color: #E8E8E8;" />
                    <!-- <div class="auth-icons">
                        <i class="bi bi-check-circle-fill" style="margin-left: 10px; color: green;"></i>
                        <i class="bi bi-check-circle-fill" style="margin-left: 10px; color: green;"></i>
                    </div> -->
                    <div *ngIf="editForm.get('firstname').invalid && editForm.get('firstname').touched">
                        First name is required.
                    </div>
                </div>
            </div>
            <div class="input-container">
                <div class="form-group">
                    <label for="lastname">Last Name</label>
                    <input id="lastname" class="form-control" formControlName="lastname"
                        style="background-color: #E8E8E8;" />
                    <div *ngIf="editForm.get('lastname').invalid && editForm.get('lastname').touched">
                        Last name is required.
                    </div>
                </div>
            </div>
        </div>
        <div class="input-email-container">
            <div class="form-group">
                <label for="email">Email</label>
                <input id="email" class="form-control" formControlName="email" [readonly]="true" />
                <div *ngIf="editForm.get('email').invalid && myForm.get('email').touched">
                    Enter a valid email.
                </div>
            </div>
        </div>
        <div class="input-email-container">
            <div class="form-group">
                <label for="username">Username</label>
                <input id="username" class="form-control" formControlName="username"
                    style="background-color: #E8E8E8;" />
                <div *ngIf="editForm.get('username').invalid && myForm.get('username').touched">
                    Username is required.
                </div>
            </div>
        </div>
        <!-- <div class="input-email-container">
            <div class="form-group">
                <label for="dob">Date of Birth</label>
                <input id="dob" class="form-control" formControlName="dob" type="date" />
                <div *ngIf="editForm.get('dob').invalid && editForm.get('dob').touched">
                    Date of birth is required.
                </div>
            </div>
        </div> -->

        <div class="input-email-container">
            <div class="form-group">
                <label for="role">Role</label>
                <input id="role" class="form-control" formControlName="role" [readonly]="true" />
                <div *ngIf="editForm.get('role').invalid && editForm.get('role').touched">
                    Role is required.
                </div>
            </div>
        </div>

        <div style="width: 48%; margin-top: 25px;">

            <button type="submit" class="btn btn-dark button"> Save Changes </button>
            <button style="background-color: transparent; color: black; margin-right: 10px;"
                (click)="onBack()">Cancel</button>

        </div>

    </form>
</div>