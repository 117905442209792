import { createAction, props } from '@ngrx/store';

export const searchResults = createAction(
  '[Explore] SEARCH_RESULTS',
  props<{ term: string }>(),
);

export const searchWithFilters = createAction('[Explore] SEARCH_WITH_FILTERS');

export const searchWithLanguage = createAction(
  '[Explore] SEARCH_WITH_LANGUAGE',
  props<{ language: string }>(),
);

export const searchWithTimeframe = createAction(
  '[Explore] SEARCH_WITH_TIMEFRAME',
  props<{ startDate: string; endDate: string }>(),
);

export const searchWithMoods = createAction(
  '[Explore] SEARCH_WITH_MOODS',
  props<{ selectedMoods: any[] }>(),
);

export const searchResultsSucess = createAction(
  '[Explore] SEARCH_RESULTS_SUCCESS',
  props<{ searchResults: any }>(),
);

export const searchWithLocation = createAction(
  '[Explore] SEARCH_WITH_LOCATION',
  props<{ location: string }>(),
);

export const searchWithCity = createAction(
  '[Explore] SEARCH_WITH_CITY',
  props<{ city: string }>(),
);

export const searchWithInclude = createAction(
  '[Explore] SEARCH_WITH_INCLUDE',
  props<{ include: string[] }>(),
);

export const searchWithExlcude = createAction(
  '[Explore] SEARCH_WITH_EXCLUDE',
  props<{ exclude: string[] }>(),
);