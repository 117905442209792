import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { AnalyticsService } from '../analytics.service';
import {
  deleteAnalytic,
  deleteAnalyticSuccess,
  getAnalytics,
  getAnalyticsSuccess,
  setErrorMessage,
  viewAnalytic,
  viewAnalyticSuccess,
} from './analytics.action';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AnalyticsEffects {
  getAnalytics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAnalytics),
      switchMap(() =>
        this.analyticsService.getAnalytics().pipe(
          map((data: any) => {
            return getAnalyticsSuccess({ analytics: data.rows });
          }),
          catchError((error: HttpErrorResponse) => {
            return of();
          }),
        ),
      ),
    ),
  );

  deleteAnalytics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAnalytic),
      mergeMap((action) =>
        this.analyticsService.deleteAnalytics(action.analyticId).pipe(
          map(() => {
            this.toastr.success('Querie deleted successfullly', '', {
              positionClass: 'toast-top-center',
              closeButton: false,
            });
            return deleteAnalyticSuccess({ analyticsId: action.analyticId });
          }),
          catchError((error) => {
            return of(setErrorMessage({ msg: error.error }));
          }),
        ),
      ),
    ),
  );

  viewAnalytic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewAnalytic),
      mergeMap((action) =>
        this.analyticsService.viewAnalytics(action.analyticId).pipe(
          map((data: any) => {
            console.log(data);
            return viewAnalyticSuccess({ viewAnalytics: data });
          }),
          catchError((error) => {
            return of(setErrorMessage({ msg: error.error }));
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private analyticsService: AnalyticsService,
    private toastr: ToastrService,
  ) { }
}
