import { Component } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { BaseTemplateService } from './base-template.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

interface SideNavToggle {
  screenWidth: number;
  collapsed: boolean;
}

@Component({
  selector: 'app-base-template',
  templateUrl: './base-template.component.html',
  styleUrls: ['./base-template.component.scss'],
})
export class BaseTemplateComponent {
  isSideNavCollapsed = false;
  screenWidth = 0;
  searchTerm = "";
  showSearch = false;
  isLoading = false;
  eventsSubject: Subject<void> = new Subject<void>();
  avatar: string | null;
  firstName: string;
  constructor(private authService: AuthService, private baseTemplateService: BaseTemplateService, private router: Router) { }

  ngOnInit(): void {
    this.firstName = this.authService.getUserName();
    this.avatar = this.authService.getUserAvatar();
    console.log(this.firstName);
    console.log(this.avatar);
    this.baseTemplateService.showSearch$.subscribe(show => {
      this.showSearch = show;
      console.log(this.showSearch);
    });

    this.baseTemplateService.search$.subscribe(show => {
      this.searchTerm = '';
      console.log(this.showSearch);
    });
  }

  onToggleSideNav(data: SideNavToggle): void {
    this.screenWidth = data.screenWidth;
    this.isSideNavCollapsed = data.collapsed;
  }

  getBodyClass(): string {
    let styleClass = 'body-trimmed';
    if (!this.isSideNavCollapsed && this.screenWidth > 768) {
      styleClass = 'body-trimmed';
    } else if (this.isSideNavCollapsed && this.screenWidth > 0) {
      styleClass = 'body-md-screen';
    }
    return styleClass;
  }

  logoutUser() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false
      this.authService.logout();
    }, 1500);
  }

  // onSearch(searchTerm: string) {
  //   console.log("searchTerm" + searchTerm)
  //   this.searchTerm = searchTerm;
  //   this.baseTemplateService.setSearchTerm(searchTerm);
  // }

  onSearch() {
    console.log('Searching for:', this.searchTerm);
    // Implement your search logic here
    this.baseTemplateService.setSearchTerm(this.searchTerm);
  }

  onClear() {
    this.searchTerm = "";
    this.baseTemplateService.setSearchTerm(this.searchTerm);
  }

  onProfile() {
    console.log('Profile');
    this.router.navigate(['profile'])
  }


}
