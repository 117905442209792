import { Component } from '@angular/core';
import { RolesFacade } from '../store/roles.facade';
import { CreateRoleRequest } from 'src/app/models/roles/create-role-request.model';
import { UpdateRoleRequest } from 'src/app/models/roles/update-role-request.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'rap-add-edit-role',
  templateUrl: './add-edit-role.container.component.html',
})
export class AddEditRoleContainerComponent {
  editableRole$ = this.roleFacade.editableRole$;
  permissions$ = this.roleFacade.permissions$;
  errorMessage$ = this.roleFacade.errorMessage$;
  title = 'Add Role';
  constructor(
    private roleFacade: RolesFacade,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe((params) => {
      if (params['id'] != "add") {
        this.title = 'Edit Role';
        this.roleFacade.getRoleById(params['id']);
        this.roleFacade.getPermissions();
      } else {
        this.roleFacade.getPermissions();
      }
    });
  }

  createNewRole(newRole: CreateRoleRequest) {
    this.roleFacade.createNewRole(newRole);
  }

  updateRole(updatedRole: UpdateRoleRequest) {
    this.roleFacade.updateRole(updatedRole);
  }

  ngOnDestroy() {
    this.roleFacade.resetEditableRole();
    this.roleFacade.clearErrorMessage();
  }
}
