import { Component, EventEmitter, Input, Output, SimpleChange, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CreateFunctionRequest } from 'src/app/models/functions/create-function-request.model';
import { Function } from 'src/app/models/functions/functions.model';
import { UpdateFunctionRequest } from 'src/app/models/functions/update-function-request.model';

@Component({
  selector: 'rap-add-edit-function-ui',
  templateUrl: './add-edit-function.component.html',
  styleUrls: ['./add-edit-function.component.scss'],
})
export class AddEditFunctionComponent {
  @Input() editableFunction: Function;
  @Input() errorMessage: string;
  @Input() title: string;
  @Output() createNewFunctionEmitter =
    new EventEmitter<CreateFunctionRequest>();

  @Output() updateFunctionEmitter = new EventEmitter<UpdateFunctionRequest>();
  functionForm: FormGroup;
  submitted: boolean = false;

  constructor(private formBuilder: FormBuilder) {
    this.functionForm = this.formBuilder.group({
      name: [null, [Validators.required]],
    });
  }

  ngOnChanges(changes: SimpleChange) {
    if (changes['editableFunction'].currentValue) {
      this.functionForm.patchValue({
        name: this.editableFunction.name,
      });
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.functionForm.controls;
  }

  addUpdateFunction() {
    this.submitted = true;
    if (this.functionForm.valid) {
      if (this.editableFunction) {
        this.updateFunction();
      } else {
        this.addFunction();
      }
    }
  }

  addFunction() {
    let functionRequest: CreateFunctionRequest = {
      data: {
        name: this.functionForm.value.name,
      },
    };
    this.createNewFunctionEmitter.emit(functionRequest);
  }

  updateFunction() {
    this.submitted = true;
    if (this.functionForm.valid) {
      let functionRequest: UpdateFunctionRequest = {
        id: this.editableFunction.id,
        data: {
          name: this.functionForm.value.name,
        },
      };
      this.updateFunctionEmitter.emit(functionRequest);
    }
  }
}
