import { FormGroup } from '@angular/forms';

export interface UserState {
  userDetails: any;
  error: string;
  pwdResetCodeSent: boolean;
  newAccountFormGroup: any;
  passwordResetSuccess: boolean;
  onBoardingDetails: any;
}

export const initialUserState: UserState = {
  userDetails: null,
  error: null,
  pwdResetCodeSent: false,
  newAccountFormGroup: null,
  passwordResetSuccess: false,
  onBoardingDetails: null
};
