<div class="label-container">
    <div>
        <label class="clickable-label" routerLink="/dashboard/explore"> Rap Almanac </label>
        <img src="../assets/images/filled.svg" />
    </div>

    <label (click)="onCards()" class="non-clickable-label">Cards</label>
</div>

<div class="analytics-section">
    <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
    <div class="section-title"> Cards </div>

    <div class="grid-container">
        <div class="grid-item" *ngFor="let item of data">
            <img [src]="item.path !== null ? item.path : 'assets/images/favicon.png'" class="logo">
            <h3>Search word : {{ item.searchWord }}</h3>
            <div *ngIf="item.chartType === 'Bar chart'">
                <canvasjs-chart [options]="getChartOptions(item)"
                    [styles]="{ width: '100%', height:'300px'}"></canvasjs-chart>
            </div>
            <div *ngIf="item.chartType === 'Timeline'">
                <canvasjs-chart [options]="getChartOptionsTimeLine(item)"
                    [styles]="{ width: '80%', height:'300px'}"></canvasjs-chart>
                <!-- <highcharts-chart [Highcharts]="Highcharts" [options]="getChartOptionsTimeLine(item)"
                    style="width: 100%; height: 300px; display: block;">
                </highcharts-chart> -->
            </div>
            <div *ngIf="item.chartType === 'Nearest Neighbour'">
                <rap-word-count-chart [data]="item.data[0]" [queryWord]=""></rap-word-count-chart>
            </div>
            <p>{{ item.text | htmlToText }}</p>
            <div class="button-container">
                <button class="btn" (click)="onView(item)">View</button>
                <button class="btn" (click)="copyLink(item)">Copy Link</button>
                <button class="btn" (click)="edit(item)">Edit</button>
                <button class="btn" (click)="delte(item)">Delete</button>
            </div>
        </div>
    </div>
</div>