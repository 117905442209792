<div class="step1">
  <h2>Let's start with the basics</h2>
  <p>What should we refer to you as and what is your date of birth?</p>
  <form [formGroup]="newAccountForm" (ngSubmit)="goToNextStep()">
    <div class="form-group">
      <label for="password">First name</label>
      <input class="input-field" type="text" placeholder="First name" name="firstName" formControlName="firstName"
        required />
    </div>

    <div class="form-group">
      <label for="password">Last name</label>
      <input class="input-field" type="text" placeholder="Last name" name="lastName" formControlName="lastName"
        required />
    </div>

    <div class="form-group">
      <label for="password">Username</label>
      <input class="input-field" type="text" placeholder="Username" name="username" formControlName="username"
        required />
    </div>

    <div class="form-group">
      <label for="password">Date of Birth</label>
      <input class="input-field" type="date" placeholder="Enter date of birth" name="dob" formControlName="dateOfBirth"
        required />
    </div>

    <button type="submit" class="continue-btn">Continue</button>
  </form>
</div>