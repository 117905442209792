import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProducersFacade } from './store/producers.facade';
import { Producer } from '../models/producers/producer.model';
import { ProducersService } from './producers.service';

@Component({
  selector: 'rap-producers',
  templateUrl: './producers.container.component.html',
  styleUrls: ['./producers.container.component.scss'],
})
export class ProducersContainerComponent {
  // producers$ = this.producersFacade.producers$;
  endPoint: string;
  isContributions: boolean = false;
  producers: any;
  page = 1;
  search = "";
  constructor(
    private producersFacade: ProducersFacade,
    private route: Router,
    private router: ActivatedRoute,
    private producerService: ProducersService,
  ) { }

  ngOnInit() {
    console.log("Ddd")
    this.router.data.subscribe(data => {
      this.isContributions = data["isContributions"];
      if (data["isContributions"]) {
        this.endPoint = `v2/producers/my-producers` + '?limit=10&page=' + 1 + '&term=' + '';
      } else {
        this.endPoint = `v2/producers` + '?limit=10&page=' + 1 + '&term=' + '';
      }
      console.log(data)
    })
    this.searchProducer({
      search: '',
      page: this.page,
      sortBy: "",
      order: ''
    });
  }

  getProducers() {
    if (this.isContributions) {
      this.endPoint = `v2/producers/my-producers`;
    } else {
      this.endPoint = `v2/producers`
    }
    this.producerService.getProducersNew(this.endPoint).subscribe((result) => {
      console.log(result)
      this.producers = result;
    });
  }

  createProducers() {
    var url = "/dashboard/database/producers/add";
    if (this.isContributions) {
      url = "/dashboard/contributions/producers/add";
    }
    console.log(this.isContributions)
    this.route.navigateByUrl(url, { skipLocationChange: true, state: { isContributions: this.isContributions } }
    );
  }

  deleteProducers(songId: string) {
    // this.songsFacade.deleteSong(songId);
    this.producerService.deleteProducerNew(songId).subscribe((result) => {
      this.getProducers();
    });
  }

  searchProducer(event: { search: string, page: number, sortBy: string, order: string }) {
    this.page = event.page;
    this.search = event.search;

    if (this.isContributions) {
      this.endPoint = `v2/producers/my-producers` + '?limit=10&page=' + this.page;
    } else {
      this.endPoint = `v2/producers` + '?limit=10&page=' + this.page;
    }

    if (this.search !== '') {
      this.endPoint += `&term=${this.search}`;
    } else if (event.sortBy !== '') {
      this.endPoint += `&sortBy=${event.sortBy}&order=${event.order}`;
    }
    this.producerService.getProducersNew(this.endPoint).subscribe((result) => {
      console.log(result)
      this.producers = result;
    });
  }

  editProducers(producer: Producer) {

  }

}
