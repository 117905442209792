import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rap-password-reset-success-modal',
  templateUrl: './password-reset-success-modal.component.html',
  styleUrls: ['./password-reset-success-modal.component.scss'],
})
export class PasswordResetSuccessModalComponent {
  @Input() modalTitle: string;
  @Input() modalBodyText: string;
  @Input() moveToSignin: boolean;
  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
  ) { }

  gotoSigninAndCloseModal() {
    if (this.moveToSignin) {
      this.router.navigate(['/signin']);
    }

    this.activeModal.close('Close click');
  }
}
