import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UserStoreModule } from './user/user-store.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ExploreStoreModule } from '../explore/store/explore-store.module';
import { RostersStoreModule } from '../rosters/store/rosters-store.module';
import { AnalyticsStoreModule } from '../analytics/store/analytics-store.module';
import { FunctionsStoreModule } from '../functions/store/functions-store.module';
import { RolesStoreModule } from '../roles/store/roles-store.module';
import { StudentsStoreModule } from '../students/store/students-store.module';
import { UsersStoreModule } from '../users/store/users-store.module';
import { SongsStoreModule } from '../songs/store/songs-store.module';
import { ProducersStoreModule } from '../producers/store/producers-store.module';

@NgModule({
  imports: [
    CommonModule,
    UserStoreModule,
    ExploreStoreModule,
    RolesStoreModule,
    RostersStoreModule,
    AnalyticsStoreModule,
    FunctionsStoreModule,
    StudentsStoreModule,
    UsersStoreModule,
    SongsStoreModule,
    ProducersStoreModule,
  ],
  exports: [StoreModule, EffectsModule],
  providers: [],
})
export class AppStoreModule {}
