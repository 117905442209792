import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ProducersSelectors } from './producers.selectors';
import { ProducersEffects } from './producers.effects';
import { producersReducer } from './producers.reducer';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature(ProducersEffects),
    StoreModule.forFeature('producers', producersReducer),
  ],
  exports: [StoreModule, EffectsModule],
  providers: [ProducersSelectors],
})
export class ProducersStoreModule {}
