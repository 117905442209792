import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FunctionsSelectors } from './functions.selectors';
import { functionsReducer } from './functions.reducer';
import { FunctionsEffects } from './functions.effects';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature(FunctionsEffects),
    StoreModule.forFeature('functions', functionsReducer),
  ],
  exports: [StoreModule, EffectsModule],
  providers: [FunctionsSelectors],
})
export class FunctionsStoreModule {}
