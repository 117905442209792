<div class="sign-in">
  <div class="left">

    <div class="container">
      <h1>{{ title }}</h1>
      <p>{{ subTitle }}</p>
      <form [formGroup]="authForm" (ngSubmit)="login()">
        <div class="error-container" *ngIf="authError$ | async">
          {{ authError$ | async }}
        </div>

        <div class="form-group">
          <rap-spinner class="loader-view" *ngIf="isLoading" [showSpinner]="false"></rap-spinner>
          <label for="email">Email address</label>
          <div class="input-container">
            <input type="email" class="form-control" [class.submitted]="submitted" formControlName="email"
              placeholder="Enter an email address" />
            <div class="auth-icons">
              <i *ngIf="submitted && hasEmailError()" class="bi bi-x-circle-fill" style="margin-left: 10px;"
                (click)="clearInput('email')"></i>
              <i class="bi bi-envelope-fill" style="margin-left: 10px;"></i>
              <i *ngIf="submitted && !hasEmailError()" class="bi bi-check-circle-fill"
                style="margin-left: 10px; color: green;"></i>
            </div>

          </div>
          <div *ngIf="submitted && f['email'].errors" class="rap-error-text">
            <span *ngIf="f['email'].errors?.['required']">Email is required</span>
            <span *ngIf="authForm.controls.email.errors.email">Please enter a valid email address</span>
          </div>
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <app-password-input [submitted]="submitted" [formControl]="f['password']"></app-password-input>
        </div>

        <p class="help-text" (click)="navigateToPasswordHelp()">
          Need help signing in?
        </p>
        <button type="submit" class="btn-signin">
          <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isLoading">Sign In</span>
        </button>
        <div class="links">
          <div></div>
          <span>Or</span>
          <div></div>
        </div>
        <button type="button" class="btn-create" (click)="navigateToCreateAccount()">

          Create an account
        </button>
        <!-- <button type="button" class="btn-create" (click)="navigateToCreateVILSAccount()">

          Login as VILS?
        </button> -->
      </form>
      <p class="terms">
        By continuing, you agree to our <a [routerLink]="'/privacy-notice'" target="_blank">Terms of Service</a> and
        <a [routerLink]="'/privacy-notice'" target="_blank">Privacy Policy</a>
      </p>
    </div>
  </div>
  <div class="right"></div>
</div>