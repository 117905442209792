import { Component } from '@angular/core';
import { FunctionsFacade } from './store/functions.facade';
import { Router } from '@angular/router';
import { Function } from '../models/functions/functions.model';

@Component({
  selector: 'rap-functions',
  templateUrl: './functions.container.component.html',
  styleUrls: ['./functions.container.component.scss'],
})
export class FunctionsContainerComponent {
  functions$ = this.functionsFacade.functions$;

  constructor(
    private router: Router,
    private functionsFacade: FunctionsFacade,
  ) {}

  ngOnInit() {
    this.getFunctions();
  }

  getFunctions() {
    this.functionsFacade.getFunctions();
  }

  deleteFunction(id: string) {
    this.functionsFacade.deleteFunction(id);
  }

  editFunction(func: Function) {
    this.router.navigate(['/dashboard/functions/'+func.id]);
  }
}
