import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { rostersReducer } from './rosters.reducer';
import { RostersSelectors } from './rosters.selectors';
import { RostersEffects } from './rosters.effects';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature(RostersEffects),
    StoreModule.forFeature('rosters', rostersReducer),
  ],
  exports: [StoreModule, EffectsModule],
  providers: [RostersSelectors],
})
export class RostersStoreModule {}
