import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { OnboardingPresenter } from '../onboarding.presenter';

@Component({
  selector: 'app-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.scss'],
})
export class Step2Component {
  options = ['Software Development', 'Research & Development', 'Other'];
  selectedOption: string | null = null;
  constructor(private router: Router, private location: Location, private onBoardingPresenter: OnboardingPresenter) { }

  ngOnInit(): void {
    const data = this.onBoardingPresenter.getFormData().section1;
    if (data != "") {
      this.selectedOption = data;
    }

  }

  nextStep(option: string) {
    this.selectedOption = option;

  }
  onContinue() {
    if (this.selectedOption != null) {
      this.router.navigate(['/onboarding/step2']);
      this.onBoardingPresenter.updateSection1(this.selectedOption);
    }

  }

  onSkip() {
    this.router.navigate(['/onboarding/step2']);
    this.onBoardingPresenter.updateSection1("");
  }

  goBack() {
    this.location.back();
  }


}
