import { Component } from '@angular/core';

@Component({
  selector: 'rap-contributions-ui',
  templateUrl: './contributions.component.html',
  styleUrls: ['./contributions.component.scss']
})
export class ContributionsComponent {

}
