import { Action, createReducer, on } from '@ngrx/store';
import { UserState, initialUserState } from '../state/user.state';
import {
  authorizeFailure,
  createNewAccount,
  getUserDetailsSuccess,
  resetErrorMsg,
  resetPassword,
  resetPasswordSuccess,
  sendPwdResetCodeSuccess,
  getOnBoardingSuccess
} from '../actions/user.action';

export const userReducer = (
  state: UserState | undefined,
  action: Action,
): UserState => reducer(state, action);

const reducer = createReducer(
  initialUserState,
  on(authorizeFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(sendPwdResetCodeSuccess, (state) => ({
    ...state,
    pwdResetCodeSent: true,
    error: '',
  })),
  on(getUserDetailsSuccess, (state, { data }) => ({
    ...state,
    userDetails: data,
    error: '',
  })),
  on(createNewAccount, (state, { newAccountFormGroup }) => ({
    ...state,
    newAccountFormGroup,
    error: '',
  })),
  on(resetPassword, (state, { newPassword }) => ({
    ...state,
    error: null,
  })),
  on(resetPasswordSuccess, (state) => ({
    ...state,
    passwordResetSuccess: true,
    error: '',
  })),
  on(resetErrorMsg, (state) => ({
    ...state,
    error: '',
  })),
  on(getUserDetailsSuccess, (state, { data }) => ({
    ...state,
    onBoardingDetails: data,
    error: '',
  })),
);
