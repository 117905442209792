import { createAction, props } from '@ngrx/store';
import { CreateUserRequest } from 'src/app/models/users/create-user-request.model';
import { UpdateUserRequest } from 'src/app/models/users/update-user-request.model';
import { User } from 'src/app/models/users/user.model';
import { DropdownValues } from './users.state';

export const getRoles = createAction('[Users] GET_ROLES');

export const getRolesSuccess = createAction(
  '[Users] GET_ROLES_SUCCESS',
  props<{ roles: DropdownValues[] }>(),
);

export const getRosters = createAction('[Users] GET_ROSTERS');

export const getRostersSuccess = createAction(
  '[Users] GET_ROSTERS_SUCCESS',
  props<{ rosters: DropdownValues[] }>(),
);

export const resetEditableUser = createAction('[Users] RESET_EDITABLE_USER');

export const getUsers = createAction('[Users] GET_USERS', props<{ page: number, search: string }>());

export const getUsersSuccess = createAction(
  '[Users] GET_USERS_SUCCESS',
  props<{ users: any }>(),
);

export const getUserById = createAction(
  '[Users] GET_USER_BY_ID',
  props<{ userId: string }>(),
);

export const getUserByIdSuccess = createAction(
  '[Users] GET_USER_BY_ID_SUCCESS',
  props<{ user: User }>(),
);

export const createNewUser = createAction(
  '[Users] CREATE_NEW_USER',
  props<{ newUser: CreateUserRequest }>(),
);

export const createNewUserSuccess = createAction(
  '[Users] CREATE_NEW_USER_SUCCESS',
);

export const updateUser = createAction(
  '[Users] UPDATE_USER',
  props<{ updatedUser: UpdateUserRequest }>(),
);

export const updateUserSuccess = createAction('[Users] UPDATE_USER_SUCCESS');

export const deleteUser = createAction(
  '[Users] DELETE_USERS',
  props<{ userId: string }>(),
);

export const deleteUserSuccess = createAction(
  '[Users] DELETE_USERS_SUCCESS',
  props<{ userId: string }>(),
);

export const usersError = createAction(
  '[Users] USERS_ERROR',
  props<{ errorMessage: string }>(),
);

export const clearErrorMessage = createAction(
  '[Users] CLEAR_ERROR_MESSAGE'
);

export const approveUser = createAction(
  '[Users] APPROVE_USER',
  props<{ userId: string }>(),
);

export const approveUserSuccess = createAction(
  '[Users] APPROVE_USER_SUCCESS',
  props<{ userId: string }>(),
);

export const performAction = createAction(
  '[Users] PERFORM_ACTION',
  props<{ actionOnUsers: any }>(),
);

export const performActionSuccess = createAction(
  '[Users] PERFORM_ACTION_SUCCESS',
  props<{ actionOnUsers: any }>(),
);