import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { BaseTemplateRoutes } from './base-template.routes';
import { NavBarComponent } from '../nav-bar/nav-bar.component';
import { BaseTemplateComponent } from './base-template.component';
import { ExploreComponent } from '../explore/explore.component';
import { AlbumsComponent } from '../albums/albums.component';
import { RolesContainerComponent } from '../roles/roles.container.component';
import { RolesComponent } from '../roles/roles.component';
import { SublevelMenuComponent } from '../nav-bar/sublevel-menu.component';
import { ContributionsContainerComponent } from '../contributions/contributions.container.component';
import { ContributionsComponent } from '../contributions/contributions.component';
import { SongsContainerComponent } from '../songs/songs.container.component';
import { SongsComponent } from '../songs/songs.component';
import { ExploreContainerComponent } from '../explore/explore.container.component';
import {
  NgbModule,
  NgbNavModule,
  NgbPagination,
} from '@ng-bootstrap/ng-bootstrap';
import { Ng5SliderModule } from 'ng5-slider';
import { RostersContainerComponent } from '../rosters/rosters.container.component';
import { RostersComponent } from '../rosters/rosters.component';
import { AnalyticsContainerComponent } from '../analytics/analytics.container.component';
import { AnalyticsComponent } from '../analytics/analytics.component';
import { FunctionsComponent } from '../functions/functions.component';
import { FunctionsContainerComponent } from '../functions/functions.container.component';
import { CanvasJSAngularChartsModule } from '@canvasjs/angular-charts';
import { AddEditFunctionComponent } from '../functions/add-edit-function/add-edit-function.component';
import { AddEditRoleComponent } from '../roles/add-edit-role/add-edit-role.component';
import { AddEditRostersComponent } from '../rosters/add-edit-rosters/add-edit-rosters.component';
import { AddEditStudentsComponent } from '../students/add-edit-students/add-edit-students.component';
import { AddEditFunctionsContainerComponent } from '../functions/add-edit-function/add-edit-functions.container.component';
import { AddEditRostersContainerComponent } from '../rosters/add-edit-rosters/add-edit-rosters.container.component';
import { StudentsComponent } from '../students/students.component';
import { StudentsContainerComponent } from '../students/students.container.component';
import { AddEditStudentsContainerComponent } from '../students/add-edit-students/add-edit-students.container.component';
import { AddEditRoleContainerComponent } from '../roles/add-edit-role/add-edit-role.container.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { UsersComponent } from '../users/users.component';
import { UsersContainerComponent } from '../users/users.container.component';
import { AddEditUserContainerComponent } from '../users/add-edit-user/add-edit-user.container.component';
import { AddEditUserComponent } from '../users/add-edit-user/add-edit-user.component';
import { AddEditSongComponent } from '../songs/add-edit-song/add-edit-song.component';
import { AddEditSongContainerComponent } from '../songs/add-edit-song/add-edit-song.container.component';
import { ProducersComponent } from '../producers/producers.component';
import { ProducersContainerComponent } from '../producers/producers.container.component';
import { AddEditProducerContainerComponent } from '../producers/add-edit-producer/add-edit-producer.container.component';
import { AddEditProducerComponent } from '../producers/add-edit-producer/add-edit-producer.component';
import { CardsComponent } from '../cards/cards.component';
import { ArtistsComponent } from '../artists/artists.component';
import { AddEditArtistComponent } from '../artists/add-edit-artist/add-edit-artist.component';
import { AddEditAlbumComponent } from '../albums/add-edit-album/add-edit-album.component';
import { EditCardComponent } from '../cards/edit-card/edit-card.component';
import { QuillModule } from 'ngx-quill';
import { HtmlToTextPipe } from '../cards/html-to-text.pipe';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { MapTabComponent } from './map-tab/map-tab.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { WordCountChartComponent } from './word-count-chart/word-count-chart.component';
import { TrendingWordsComponent } from './trending-words/trending-words.component';


@NgModule({
  declarations: [
    NavBarComponent,
    ExploreContainerComponent,
    ExploreComponent,
    AlbumsComponent,
    BaseTemplateComponent,
    RolesContainerComponent,
    RolesComponent,
    AddEditRoleComponent,
    SublevelMenuComponent,
    ContributionsContainerComponent,
    ContributionsComponent,
    SongsContainerComponent,
    SongsComponent,
    RostersContainerComponent,
    RostersComponent,
    AddEditRostersComponent,
    AddEditStudentsComponent,
    AnalyticsContainerComponent,
    AnalyticsComponent,
    FunctionsComponent,
    FunctionsContainerComponent,
    AddEditFunctionsContainerComponent,
    AddEditFunctionComponent,
    AddEditRostersComponent,
    AddEditRostersContainerComponent,
    StudentsContainerComponent,
    StudentsComponent,
    AddEditStudentsComponent,
    AddEditStudentsContainerComponent,
    AddEditRoleComponent,
    AddEditRoleContainerComponent,
    UsersComponent,
    UsersContainerComponent,
    AddEditUserContainerComponent,
    AddEditUserComponent,
    AddEditSongComponent,
    AddEditSongContainerComponent,
    ProducersComponent,
    ProducersContainerComponent,
    AddEditProducerContainerComponent,
    AddEditProducerComponent,
    CardsComponent,
    ArtistsComponent,
    AddEditArtistComponent,
    AddEditAlbumComponent,
    EditCardComponent,
    HtmlToTextPipe,
    MapTabComponent,
    WordCountChartComponent,
    TrendingWordsComponent,
  ],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    RouterModule.forChild(BaseTemplateRoutes),
    NgbPagination,
    Ng5SliderModule,
    NgbModule,
    NgbNavModule,
    CanvasJSAngularChartsModule,
    NgMultiSelectDropDownModule.forRoot(),
    QuillModule.forRoot(),
    LeafletModule,
    HighchartsChartModule
  ],
  providers: [],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BaseTemplateModule { }
