import { Component } from '@angular/core';
import { RostersFacade } from './store/rosters.facade';
import { Roster } from '../models/rosters/rosters.model';
import { Router } from '@angular/router';

@Component({
  selector: 'rap-rosters',
  templateUrl: './rosters.container.component.html',
  styleUrls: ['./rosters.container.component.scss'],
})
export class RostersContainerComponent {
  rosters$ = this.rostersFacade.roles$;

  constructor(
    private rostersFacade: RostersFacade,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getRosters();
  }

  getRosters() {
    this.rostersFacade.getRosters('');
  }

  deleteRoster(id: string) {
    this.rostersFacade.deleteRoster(id);
  }

  manageStudent(roster: Roster) {
    this.router.navigate(['/dashboard/rosters/' + roster.id + '/edit']);
  }

  search(search: string) {
    this.rostersFacade.getRosters(search);
  }
}
