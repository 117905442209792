import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'rap-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],


})
export class PaginationComponent {
  @Input() page: number;
  @Input() pageSize: number;
  @Input() collectionSize: number;
  @Output() onPageChange = new EventEmitter<number>();
}
