import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environments';
import { CreateFunctionRequest } from '../models/functions/create-function-request.model';
import { UpdateFunctionRequest } from '../models/functions/update-function-request.model';

@Injectable({
  providedIn: 'root',
})
export class FunctionsService {
  private readonly BASE_URL = 'baseUrl';
  private url: string;

  constructor(private httpClient: HttpClient) {
    this.url = environment.baseUrl;
  }

  getFunctions() {
    return this.httpClient.get<any>(this.url + `v2/functions`);
  }

  getFunctionById(id: string) {
    return this.httpClient.get<any>(this.url + `v2/functions/`+id);
  }

  createNewFunction(func: CreateFunctionRequest) {
    return this.httpClient.post(this.url + `v2/functions`, func, {
      responseType: 'text',
    });
  }

  deleteFunction(functionId: string) {
    return this.httpClient.delete(this.url + `v2/functions/` + functionId, {
      responseType: 'text',
    });
  }

  updateFunction(func: UpdateFunctionRequest) {
    return this.httpClient.put(this.url + `v2/functions/` + func.id, func, {
      responseType: 'text',
    });
  }
}
