import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { OnboardingPresenter } from '../onboarding.presenter';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environments';

@Component({
  selector: 'app-step5',
  templateUrl: './step5.component.html',
  styleUrls: ['./step5.component.scss']
})
export class Step5Component {

  choosePlans: ChoosePlan[] = [{
    option: "Basic User",
    price: "$0",
    stripePriceId: "price_1Nt5KYKVVLv7QuRNHx1e53Dv",
    options: [
      "Basic Search",
      "Data Visualizations",
      "Social Media Preview Cards"]
  }, {
    option: "Researcher",
    stripePriceId: "price_1OpX8MKVVLv7QuRN6jG2yIp5",
    price: "$9.99", options: ["Basic Search",
      "Data Visualizations",
      "Social Share Preview", "Advanced Search (Semantic + Rhyme Scheme Data)",
      "CSV Export",
      "Rap Almanac X"]
  }, {
    option: "Developer",
    stripePriceId: "price_1OpXHyKVVLv7QuRNRJysLEuL",
    price: "$14.99", options: ["Basic Search",
      "Data Visualizations",
      "Social Share Preview", "Advanced Search (Semantic + Rhyme Scheme Data)",
      "CSV Export",
      "Rap Almanac X", "API Key access"]
  }];
  options = ['From a friend', 'Media or our blog', 'Other'];
  selectedOption: string | null = null;
  title: string = 'Get more from Rap Almanac';
  subTitle: string =
    'Sign up or account creation sub-copy \ndisplayed here.';
  selectedPlan: ChoosePlan | null = null;
  isLoading: boolean = false;
  isSkip = false;
  private url: string;
  skipTitle = "Skip";
  continueTitle = "Continue";
  constructor(private router: Router, private location: Location, private onBoardingPresenter: OnboardingPresenter, private http: HttpClient) { }


  ngOnInit(): void {
    this.url = environment.baseUrl;
    this.selectedPlan = this.choosePlans[0];
    // const data = this.onBoardingPresenter.getFormData().section4;
    // if (data != null) {
    //   this.selectedPlan = data;
    //   console.log(data);
    //   console.log(this.selectedPlan);
    // }


  }
  selectPlan(plan: ChoosePlan) {
    this.selectedPlan = plan;
    this.onBoardingPresenter.updateSection4(this.selectedPlan);
  }

  goBack() {
    this.isLoading = false;
    this.location.back();
  }

  onContinue() {

    if (this.isLoading == false) {
      this.isSkip = false;
      this.skipTitle = "Skip";
      this.continueTitle = "";
      this.isLoading = true;
      if (this.selectedPlan?.option == this.choosePlans[0].option) {
        this.isLoading = false;
        console.log(this.selectedPlan?.option)
        this.onBoardingPresenter.putOnboardingSkip(true);
      } else {
        this.onBoardingPresenter.putOnboardingPlan();
        console.log(this.selectedPlan?.stripePriceId)
        this.http.get(this.url + 'auth/my_plan/update?plan_id=' + this.selectedPlan?.stripePriceId, {}).subscribe(async (session: any) => {
          console.log(session.stripeCheckoutUrl)
          var url = session.stripeCheckoutUrl;
          window.location.href = url;
          this.isLoading = false;
        });
      }
    }

  }

  onSkip() {

    console.log("skip")
    if (this.isLoading == false) {
      this.continueTitle = "Continue";
      this.skipTitle = "";
      this.isLoading = true;
      this.isSkip = true;
      this.onBoardingPresenter.putOnboardingSkip(false);
    }
  }

  ngOnDestroy() {
    this.isLoading = false;
  }
}


export interface ChoosePlan {
  option: string;
  price: string;
  stripePriceId: string;
  options: string[];
}