import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SongsFacade } from './store/songs.facade';
import { AddEditSongContainerComponent } from './add-edit-song/add-edit-song.container.component';
import { SongsService } from './songs.service';

@Component({
  selector: 'rap-songs',
  templateUrl: './songs.container.component.html',
  styleUrls: ['./songs.container.component.scss'],
})
export class SongsContainerComponent {
  songs$ = this.songsFacade.songs$;
  endPoint: string;
  isContributions: boolean = false;
  page = 1;
  search = "";
  sortDirection: string = 'asc';
  sortedColumn: string = '';
  constructor(
    private songsFacade: SongsFacade,
    private router: ActivatedRoute,
    private route: Router,
    private songsService: SongsService
  ) { }

  ngOnInit() {
    this.router.data.subscribe(data => {
      this.isContributions = data["isContributions"];
      if (data["isContributions"]) {
        this.endPoint = `v2/songs/my-songs` + '?limit=10&page=' + 1 + '&term=' + '';
      } else {
        this.endPoint = `v2/songs` + '?limit=10&page=' + 1 + '&term=' + '';
      }
      console.log(data)
    })
    this.searchSong({
      search: '',
      page: this.page,
      sortBy: "",
      order: ''
    });
  }

  // getSongs() {
  //   if (this.isContributions) {
  //     this.endPoint = `v2/songs/my-songs`;
  //   } else {
  //     this.endPoint = `v2/songs`
  //   }
  //   this.songsFacade.getSongs(this.endPoint, 1);
  // }

  createSong() {
    var url = "/dashboard/database/songs/add";
    if (this.isContributions) {
      url = "/dashboard/contributions/songs/add";
    }
    this.route.navigateByUrl(url, { skipLocationChange: true, state: { isContributions: this.isContributions } }
    );
  }

  deleteSong(songId: string) {
    // this.songsFacade.deleteSong(songId);
    this.songsService.deleteSongNew(songId).subscribe((result) => {
      this.searchSong({
        search: '',
        page: this.page,
        sortBy: this.sortedColumn,
        order: this.sortDirection
      });
    });
  }

  searchSong(event: { search: string, page: number, sortBy: string, order: string }) {
    this.page = event.page;
    this.search = event.search;
    this.sortDirection = event.order;
    this.sortedColumn = event.sortBy;
    if (this.isContributions) {
      this.endPoint = `v2/songs/my-songs` + '?limit=10&page=' + event.page;
    } else {
      this.endPoint = `v2/songs` + '?limit=10&page=' + event.page;
    }
    if (this.search !== '') {
      this.endPoint += `&term=${this.search}`;
    } else if (event.sortBy !== '') {
      this.endPoint += `&sortBy=${event.sortBy}&order=${event.order}`;
    }
    this.songsFacade.getSongs(this.endPoint, event.page);
  }
}
