import { Action, createReducer, on } from '@ngrx/store';
import { initialProducersState, ProducersState } from './producers.state';
import {
  deleteProducerSuccess,
  getProducerByIdSuccess,
  getProducersSuccess,
  producersError,
} from './producers.action';

export const producersReducer = (
  state: ProducersState | undefined,
  action: Action,
): ProducersState => reducer(state, action);

const reducer = createReducer(
  initialProducersState,
  on(getProducersSuccess, (state, { producers }) => ({
    ...state,
    producers,
  })),
  on(getProducerByIdSuccess, (state, { producer }) => ({
    ...state,
    editableProducer: producer,
  })),
  on(deleteProducerSuccess, (state, { producerId }) => ({
    ...state,
    Producers: state.producers.filter(
      (producer) => producer.producerid !== producerId,
    ),
  })),
  on(producersError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  })),
);
