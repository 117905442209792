<div class="label-container">
    <div>
        <label class="clickable-label" routerLink="/dashboard/explore"> Rap Almanac </label>
        <img src="../assets/images/filled.svg" />
    </div>

    <div>
        <label class="non-clickable-label">{{subTitle}}</label>
        <img src="../assets/images/filled.svg" />
    </div>
    <div>
        <label class="clickable-label" (click)="onCancel()">Artists</label>
        <!-- <img src="../assets/images/filled.svg" /> -->
    </div>
    <!-- <label class="clickable-label" routerLink="/dashboard/roles">Roles</label> -->
    <div *ngIf="isNew === false">
        <img src="../assets/images/filled.svg" />
        <label class="non-clickable-label">{{ artistId }}</label>
    </div>
    <div>
        <img src="../assets/images/filled.svg" />
        <label class="non-clickable-label" style="text-decoration: none;">{{ isNew === true ? 'New' : 'Edit' }}</label>
    </div>
</div>


<div class="functions-section">
    <div class="section-title">
        {{ title }}
    </div>
    <div class="card">
        <div class="card-body">
            <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
            <form [formGroup]="artistForm" (ngSubmit)="addUpdateArtist()">
                <div class="form-group">
                    <label for="artist">Artist Name<span class="required">*</span></label>
                    <div class="input-container">
                        <input id="artist" class="search-input" formControlName="artist" type="text"
                            [ngClass]="{'is-invalid': artistForm.get('artist').invalid && artistForm.get('artist').touched}">
                    </div>
                    <div class="error" *ngIf="artistForm.get('artist').invalid && artistForm.get('artist').touched">
                        Artist name is required.
                    </div>
                </div>

                <div class="form-group">
                    <label for="dateOfBirth">Date of Birth<span class="required">*</span></label>
                    <div class="input-container">
                        <input id="dateOfBirth" formControlName="dateOfBirth" type="date" class="search-input"
                            style="padding: 2px 0px;"
                            [ngClass]="{'is-invalid': artistForm.get('dateOfBirth').invalid && artistForm.get('dateOfBirth').touched}">
                    </div>
                    <div class="error"
                        *ngIf="artistForm.get('dateOfBirth').invalid && artistForm.get('dateOfBirth').touched">
                        Date of birth is required.
                    </div>
                </div>

                <div class="form-group">
                    <label for="dateOfBirth">End</label>
                    <div class="input-container">
                        <input id="end" formControlName="end" type="date" class="search-input"
                            style="padding: 2px 0px;">
                    </div>

                </div>

                <div class="form-group">
                    <label for="county">Alias</label>
                    <div class="input-container">
                        <input id="alias" class="search-input" formControlName="alias" type="text">
                    </div>

                </div>

                <div class="form-group">
                    <label for="city">City<span class="required">*</span></label>
                    <div class="input-container">
                        <input id="city" class="search-input" formControlName="city" type="text"
                            [ngClass]="{'is-invalid': artistForm.get('city').invalid && artistForm.get('city').touched}">
                    </div>
                    <div class="error" *ngIf="artistForm.get('city').invalid && artistForm.get('city').touched">
                        City is required.
                    </div>
                </div>

                <div class="form-group">
                    <label for="county">County</label>
                    <div class="input-container">
                        <input id="county" class="search-input" formControlName="county" type="text">
                    </div>

                </div>

                <div class="form-group">
                    <label for="country">Country<span class="required">*</span>:</label>
                    <!-- <div class="multi-dropdown"> -->
                    <!-- <ng-multiselect-dropdown [placeholder]="'Select...'" [settings]="settings" [data]="locations"
                            [(ngModel)]="artistForm.value.country" [formControl]="artistForm.get('country')">
                        </ng-multiselect-dropdown> -->
                    <ng-select [items]="locations" bindLabel="label" placeholder="Select.."
                        [(ngModel)]="artistForm.value.country" class="custom-ng-select"
                        [formControl]="artistForm.get('country')">
                    </ng-select>
                    <!-- </div> -->

                    <div class="error" *ngIf="artistForm.get('country').invalid">
                        Country is a required field
                    </div>
                </div>

                <div class="form-group">
                    <label for="latitude">Latitude<span class="required">*</span></label>
                    <div class="input-container">
                        <input id="latitude" class="search-input" formControlName="latitude" type="text"
                            [ngClass]="{'is-invalid': artistForm.get('latitude').invalid && artistForm.get('latitude').touched}">
                    </div>
                    <div class="error" *ngIf="artistForm.get('latitude').invalid && artistForm.get('latitude').touched">
                        Latitude is required and must be a valid number.
                    </div>
                </div>

                <div class="form-group">
                    <label for="longitude">Longitude<span class="required">*</span></label>
                    <div class="input-container">
                        <input id="longitude" class="search-input" formControlName="longitude" type="text"
                            [ngClass]="{'is-invalid': artistForm.get('longitude').invalid && artistForm.get('longitude').touched}">
                    </div>
                    <div class="error"
                        *ngIf="artistForm.get('longitude').invalid && artistForm.get('longitude').touched">
                        Longitude is required and must be a valid number.
                    </div>
                </div>

                <div class="form-group">
                    <label for="originCity">Origin City<span class="required">*</span></label>
                    <div class="input-container">
                        <input id="originCity" class="search-input" formControlName="originCity" type="text"
                            [ngClass]="{'is-invalid': artistForm.get('originCity').invalid && artistForm.get('originCity').touched}">
                    </div>
                    <div class="error"
                        *ngIf="artistForm.get('originCity').invalid && artistForm.get('originCity').touched">
                        Origin city is required.
                    </div>
                </div>

                <div class="form-group">
                    <label for="originCounty">Origin County:</label>
                    <div class="input-container">
                        <input id="originCounty" class="search-input" formControlName="originCounty" type="text"
                            [ngClass]="{'is-invalid': artistForm.get('originCounty').invalid && artistForm.get('originCounty').touched}">
                    </div>
                    <div class="error"
                        *ngIf="artistForm.get('originCounty').invalid && artistForm.get('originCounty').touched">
                        Origin county is required.
                    </div>
                </div>

                <div class="form-group">
                    <label for="originCountry">Origin Country<span class="required">*</span>:</label>
                    <!-- <div class="multi-dropdown">
                        <ng-multiselect-dropdown [placeholder]="'Select...'" [settings]="settings" [data]="locations"
                            [(ngModel)]="artistForm.value.originCountry"
                            [formControl]="artistForm.get('originCountry')">
                        </ng-multiselect-dropdown>

                    </div> -->
                    <ng-select [items]="locations" bindLabel="label" placeholder="Select.."
                        [(ngModel)]="artistForm.value.originCountry" class="custom-ng-select"
                        [formControl]="artistForm.get('originCountry')">
                    </ng-select>

                    <div class="error" *ngIf="artistForm.get('originCountry').invalid">
                        Origin Country is a required field
                    </div>
                </div>

                <div class="form-group">
                    <label for="originLatitude">Origin Latitude<span class="required">*</span></label>
                    <div class="input-container">
                        <input id="originLatitude" class="search-input" formControlName="originLatitude" type="text"
                            [ngClass]="{'is-invalid': artistForm.get('originLatitude').invalid && artistForm.get('originLatitude').touched}">
                    </div>
                    <div class="error"
                        *ngIf="artistForm.get('originLatitude').invalid && artistForm.get('originLatitude').touched">
                        Origin latitude is required and must be a valid number.
                    </div>
                </div>

                <div class="form-group">
                    <label for="originLongitude">Origin Longitude<span class="required">*</span></label>
                    <div class="input-container">
                        <input id="originLongitude" class="search-input" formControlName="originLongitude" type="text"
                            [ngClass]="{'is-invalid': artistForm.get('originLongitude').invalid && artistForm.get('originLongitude').touched}">
                    </div>
                    <div class="error"
                        *ngIf="artistForm.get('originLongitude').invalid && artistForm.get('originLongitude').touched">
                        Origin longitude is required and must be a valid number.
                    </div>

                </div>
                <div class="role-action-btns">
                    <rap-rect-primary [label]="'Save'" [btnClass]="'btn btn-dark rect-primary-btn1'"
                        (clickEvent)="addUpdateArtist()"></rap-rect-primary>
                    <rap-rect-primary [label]="'Reset'" [btnClass]="'btn btn-light rect-secondary-btn1'"
                        (clickEvent)="onReset()"></rap-rect-primary>
                    <rap-rect-primary [label]="'Cancel'" [btnClass]="'btn btn-light rect-secondary-btn1'"
                        (clickEvent)="onCancel()"></rap-rect-primary>

                </div>
            </form>
        </div>
    </div>
</div>