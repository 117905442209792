import { Action, createReducer, on } from '@ngrx/store';
import {
  clearErrorMessage,
  deleteFunctionSuccess,
  functionsError,
  getFunctionById,
  getFunctionByIdSuccess,
  getFunctionsSuccess,
  resetEditableFunction,
  updateFunctionSuccess,
} from './functions.action';
import { FunctionsState, initialFunctionsState } from './functions.state';

export const functionsReducer = (
  state: FunctionsState | undefined,
  action: Action,
): FunctionsState => reducer(state, action);

const reducer = createReducer(
  initialFunctionsState,
  on(getFunctionsSuccess, (state, { functions }) => ({
    ...state,
    functions,
  })),
  on(deleteFunctionSuccess, (state, { functionId }) => ({
    ...state,
    functions: state.functions.filter((func) => func.id !== functionId),
  })),
  on(getFunctionByIdSuccess, (state, { func }) => ({
    ...state,
    editableFunction: func
  })),
  on(functionsError, (state, { error }) => ({
    ...state,
    errorMessage: error,
  })),
  on(clearErrorMessage, (state) => ({
    ...state,
    errorMessage: null,
  })),
  on(resetEditableFunction, (state) => ({
    ...state,
    editableFunction: null,
  })),
);
