<div class="analytics-section">
  <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
  <div class="section-title">Students</div>
  <div class="search-container">
    <img src="../assets/images/search.svg" class="search-icon" />
    <input type="text" placeholder="Search..." class="search-input" [(ngModel)]="filters.search"
      (keydown.enter)="getSearchResults()">
  </div>
  <ng-container *ngIf="studentsData?.length > 0">
    <div class="custom-container">
      <table class="table rounded-3 overflow-hidden">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Clear Audio</th>
            <th scope="col">View Progress</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let student of paginatedStudents">
            <td scope="row">{{ student?.firstName }}</td>
            <td scope="row">{{ student?.forceCleanAudio }}</td>
            <td scope="row">

              <a class="user-details-link" (click)="onProgress(student)">
                View Progress
              </a>
            </td>
            <td>
              <button type="button" class="btn btn-light manage-student-btn" (click)="manageStudent(student)">
                Edit
              </button>
              <button type="button" class="btn btn-dark delete-roster-btn" (click)="deleteStudent(student.id)">
                Delete
              </button>

            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="pagination">
      <rap-pagination [collectionSize]="studentsData?.length" [page]="page" [pageSize]="pageSize"
        (onPageChange)="onPageChange($event)"></rap-pagination>
    </div>

  </ng-container>





  <div class="button-row">
    <button type="button" class="btn btn-dark add-role-btn" (click)="addStudent()">
      Add New
    </button>
    <div class="right-buttons">
      <button type="button" class="btn btn-dark add-role-btn" (click)="onHelp()">Help</button>
      <button type="button" class="btn btn-dark add-role-btn" (click)="exportCSV()">Export to CSV</button>
    </div>
  </div>
</div>

<div style="padding: 20px; text-align: center; margin-top: 100px;" *ngIf="!studentsData || studentsData?.length === 0">
  <img src="../assets/images/question.svg" />
  <div class="no-match">No students found</div>
</div>