import { Injectable } from '@angular/core';
import { Store, createFeatureSelector, createSelector } from '@ngrx/store';
import { AnalyticsState } from './analytics.state';

const getAnalyticsState = createFeatureSelector<AnalyticsState>('analytics');

const getAnalytics = createSelector(
  getAnalyticsState,
  (state: AnalyticsState) => state.analytics,
);

const getErrorMessage = createSelector(
  getAnalyticsState,
  (state: AnalyticsState) => state.error,
);

const getViewAnalytics = createSelector(
  getAnalyticsState,
  (state: AnalyticsState) =>
    state.viewAnalytics

);

@Injectable()
export class AnalyticsSelectors {

  analytics$ = this.store.select(getAnalytics);
  errorMessage$ = this.store.select(getErrorMessage);
  viewAnalytics$ = this.store.select(getViewAnalytics);
  constructor(private store: Store<AnalyticsState>) {
  }
}
