import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { FunctionsService } from '../functions.service';
import {
  createNewFunction,
  createNewFunctionSuccess,
  deleteFunction,
  deleteFunctionSuccess,
  functionsError,
  getFunctionById,
  getFunctionByIdSuccess,
  getFunctions,
  getFunctionsSuccess,
  updateFunction,
  updateFunctionSuccess,
} from './functions.action';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Function } from 'src/app/models/functions/functions.model';

@Injectable()
export class FunctionsEffects {
  getFunctions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getFunctions),
      switchMap(() =>
        this.functionsService.getFunctions().pipe(
          map((data: any) => {
            return getFunctionsSuccess({ functions: data.rows });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(functionsError({ error: error.error.message || 'Something went wrong, please try again!' }));
          }),
        ),
      ),
    ),
  );

  getFunctionById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getFunctionById),
      switchMap((action) =>
        this.functionsService.getFunctionById(action.functionId).pipe(
          map((func: Function) => {
            return getFunctionByIdSuccess({ func });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(functionsError({ error: error.error.message || 'Something went wrong, please try again!' }));
          }),
        ),
      ),
    ),
  );

  createNewFunctions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createNewFunction),
      switchMap((action) =>
        this.functionsService.createNewFunction(action.function).pipe(
          map((data: any) => {
            console.log(data);
            return createNewFunctionSuccess();
          }),
          catchError((error: HttpErrorResponse) => {
            return of(functionsError({ error: error.error.message || 'Something went wrong, please try again!' }));
          }),
        ),
      ),
    ),
  );

  createNewFunctionSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createNewFunctionSuccess),
        tap(() => {
          this.toastr.success('Function created successfully', '', {
            positionClass: 'toast-top-center',
            closeButton: false,
          });
          getFunctions(); // Add this line to refresh the functions list after creating a new function`
          this.router.navigate(['/dashboard/functions']);
        }),
      ),
    { dispatch: false },
  );

  deleteAnalytics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteFunction),
      mergeMap((action) =>
        this.functionsService.deleteFunction(action.functionId).pipe(
          map(() => {
            this.toastr.success('Function deleted successfullly', '', {
              positionClass: 'toast-top-center',
              closeButton: false,
            });
            return deleteFunctionSuccess({ functionId: action.functionId });
          }),
          catchError((error) => {
            return of(functionsError({ error: error.error.message || 'Something went wrong, please try again!' }));
          }),
        ),
      ),
    ),
  );

  updateFunction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateFunction),
      mergeMap((action) =>
        this.functionsService.updateFunction(action.function).pipe(
          map(() => {
            return updateFunctionSuccess();
          }),
          catchError((error) => {
            return of(functionsError({ error: error.error.message || 'Something went wrong, please try again!' }));
          }),
        ),
      ),
    ),
  );

  updateFunctionSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateFunctionSuccess),
        tap(() => {
          this.toastr.success('Function updated', '', {
            positionClass: 'toast-top-center',
            closeButton: false,
          });
          this.router.navigate(['/dashboard/functions']);
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private functionsService: FunctionsService,
    private router: Router,
    private toastr: ToastrService,
  ) { }
}
