import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environments';

@Injectable({
  providedIn: 'root'
})
export class LearningService {

  private readonly BASE_URL = 'baseUrl';
  private url: string;

  constructor(private httpClient: HttpClient) {
    this.url = environment.baseUrl;
  }

  vils(body: any) {
    return this.httpClient.post<any>(
      this.url + `auth/vils`,
      body,
    ).pipe(map((response: any) => response));;
  }
}
