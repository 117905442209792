import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  getProducerById,
  addProducer,
  updateProducer,
  deleteProducer,
  clearErrorMessage,
  getMyProducers,
  getAllProducers,
} from './producers.action';
import { ProducersState } from './producers.state';
import { ProducersSelectors } from './producers.selectors';

@Injectable({
  providedIn: 'root',
})
export class ProducersFacade {
  producers$ = this.producersSelectors.producers$;
  errorMessage$ = this.producersSelectors.errorMessage$;
  constructor(
    private store: Store<ProducersState>,
    private producersSelectors: ProducersSelectors,
  ) {}

  getMyProducers() {
    this.store.dispatch(getMyProducers());
  }

  getAllProducers() {
    this.store.dispatch(getAllProducers());
  }

  getProducerById(producerId: string) {
    this.store.dispatch(getProducerById({ producerId }));
  }

  addProducer(producer) {
    this.store.dispatch(addProducer({ producer }));
  }

  updateProducer(producer) {
    this.store.dispatch(updateProducer({ producer }));
  }

  deleteProducer(producerId: string) {
    this.store.dispatch(deleteProducer({ producerId }));
  }

  clearErrorMessage() {
    this.store.dispatch(clearErrorMessage());
  }
}
