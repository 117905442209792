import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LearningService } from './learning.service';

@Component({
  selector: 'rap-learning',
  templateUrl: './learning.component.html',
  styleUrls: ['./learning.component.scss']
})
export class LearningComponent {
  authForm!: FormGroup;
  isEmailEntered: boolean = false;
  isPasswordEntered: boolean = false;
  isSignUpFlow: boolean = false;
  submitted: boolean = false;
  isLoading = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private learningService: LearningService
  ) { }

  ngOnInit() {
    this.authForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(7)]],
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.authForm.controls;
  }

  hasEmailError(): boolean {
    const emailErrors = this.authForm.controls['email'].errors;
    return !!emailErrors && !!emailErrors['email'];
  }

  hasPasswordError(): boolean {
    const passwordErrors = this.authForm.controls['password'].errors;
    if ((this.authForm.controls['password'].errors != null)) {
      if (this.authForm.controls['password'].errors["required"] == null)
        return true;
      else
        return false;
    } else {
      return false;
    }
    // return (this.formControl.value != '' || this.formControl.value != null);
  }

  hasPasswordError1(): boolean {
    const passwordControl = this.authForm.controls['password'];
    return passwordControl && passwordControl.invalid && passwordControl.touched;
  }

  login() {
    this.submitted = true;
    if (this.authForm.invalid) {
      console.log(this.authForm.value)
      return;

    }
    console.log(this.authForm.value)
    this.learningService.vils(this.authForm.value).subscribe((result) => {
      this.isLoading = false;


    });

  }
  navigateToForgotPassword() {
    window.open('https://www98.verizon.com/learning/forgot-password', '_blank');
  }

  navigateToSignin() {
    this.isLoading = false;
    this.router.navigate(['/signin']);
  }

  clearInput(input: string): void {
    console.log("ws");
    if (input == 'email')
      this.authForm.controls['email'].setValue('');
    else if (input == 'password')
      this.authForm.controls['password'].setValue('');
  }
}
