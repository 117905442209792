import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Function } from '../models/functions/functions.model';
import { Router } from '@angular/router';
import { DeleteModalComponent } from '../shared/component/delete-modal/delete-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../app.service';

@Component({
  selector: 'rap-functions-ui',
  templateUrl: './functions.component.html',
  styleUrls: ['./functions.component.scss'],
})
export class FunctionsComponent {
  @Input() functions: Function[];
  @Output() deleteFunctionEmitter = new EventEmitter<string>();
  @Output() editFunctionEmitter = new EventEmitter<Function>();
  page = 1;
  pageSize = 10;
  isLoading = false;
  functionsData: Function[] = [];
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private loaderService: AppService) {
    this.loaderService.loading$.subscribe(isLoading => {
      console.log("isLoading")
      console.log(isLoading)
      this.isLoading = isLoading;
    });
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.functions = [];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['functions'] && this.functions) {
      this.isLoading = false;
      this.page = 1;
      this.functionsData = this.functions;
    }
  }

  get paginatedFunctions(): Function[] {
    const start = (this.page - 1) * this.pageSize;
    const end = start + this.pageSize;
    return this.functions.slice(start, end);
  }

  onPageChange(page: number) {
    this.page = page;
  }

  addFunction() {
    this.router.navigate(['/functions/add']);
  }

  deleteFunction(functionId: string) {

    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.modalTitle = 'Confirm delete';
    modalRef.componentInstance.modalBodyText =
      'Are you sure you want to delete thie item ?';
    modalRef.componentInstance.itemId = functionId;
    modalRef.result
      .then((id) => {
        this.isLoading = true;
        this.deleteFunctionEmitter.emit(id);
      })
      .catch((error) => console.log(error));
  }

  editFunction(func: Function) {
    this.editFunctionEmitter.emit(func);
  }

  ngOnDestroy(): void {
    this.functions = [];
    this.functionsData = [];
    this.isLoading = false;
  }
}
