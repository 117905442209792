import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rap-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss'],
})
export class DeleteModalComponent {
  @Input() itemId: string;
  @Input() modalTitle: string = 'Confirm delete';
  @Input() modalBodyText: string =
    'Are you sure you want to delete thie item ?';
  @Input() deleteTitle: string = "Delete";
  constructor(public activeModal: NgbActiveModal) { }

  capitalizeFirstChar(text: string): string {
    if (!text) return text;
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
}
