import { Injectable } from '@angular/core';
import { UserState } from '../state/user.state';
import { Store, createFeatureSelector, createSelector } from '@ngrx/store';

const getUserState = createFeatureSelector<UserState>('user');

export const getUserDetails = createSelector(
  getUserState,
  (state: UserState) => state.userDetails,
);

export const getOnBoardingDetails = createSelector(
  getUserState,
  (state: UserState) => state.onBoardingDetails,
);

export const getAuthError = createSelector(
  getUserState,
  (state: UserState) => state.error,
);

export const getPwdResetCodeSent = createSelector(
  getUserState,
  (state: UserState) => state.pwdResetCodeSent,
);

export const getNewAccountForm = createSelector(
  getUserState,
  (state: UserState) => state.newAccountFormGroup,
);

export const getPasswordResetSuccess = createSelector(
  getUserState,
  (state: UserState) => state.passwordResetSuccess,
);

@Injectable()
export class UserSelectors {
  userDetails$ = this.store.select(getUserDetails);
  authError$ = this.store.select(getAuthError);
  pwdResetCodeSent$ = this.store.select(getPwdResetCodeSent);
  newAccountFormGroup$ = this.store.select(getNewAccountForm);
  passwordResetSuccess$ = this.store.select(getPasswordResetSuccess);
  onBoardingDetails$ = this.store.select(getOnBoardingDetails);
  constructor(private store: Store<UserState>) { }
}
