import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { User } from '../models/users/user.model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from '../shared/component/delete-modal/delete-modal.component';
import { Dropdown } from 'bootstrap';
import { BaseTemplateService } from '../base-template/base-template.service';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '../app.service';

@Component({
  selector: 'rap-users-ui',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent {
  @Input() users: User[];
  @Input() usersCount: number;
  @Output() deleteUserEmitter = new EventEmitter<{ id: string, search: string, page: number, sortBy: string, order: string }>();
  @Output() editUserEmitter = new EventEmitter<User>();
  @Output() approveUserEmitter = new EventEmitter<string>();
  @Output() getUsersEmitter = new EventEmitter<{ search: string, page: number, sortBy: string, order: string }>();
  @Output() performActionEmitter = new EventEmitter<any>();
  selectedUsers: User[] = [];
  page = 1;
  pageSize = 10;
  modalRef;
  strAction = "  Actions  "
  isLoading = false;
  filters: any = {
    search: ""
  };
  sortDirection: string = ''; // Track sorting state
  sortedColumn: string = '';
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private baseTemplateService: BaseTemplateService, private toastr: ToastrService, private loaderService: AppService) {
    this.loaderService.loading$.subscribe(isLoading => {
      console.log("isLoading")
      console.log(isLoading)
      this.isLoading = isLoading;
    });
  }

  get paginatedUsers(): User[] {
    const start = (this.page - 1) * this.pageSize;
    const end = start + this.pageSize;
    return this.users;
  }

  // sortBy(column: string) {
  //   console.log(column)
  //   if (this.sortedColumn === column) {
  //     // Toggle sort direction
  //     this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
  //   } else {
  //     // New column sorting
  //     this.sortedColumn = column;
  //     this.sortDirection = 'asc';
  //   }

  //   this.isLoading = true;
  //   console.log(this.page)
  //   this.getUsersEmitter.emit({
  //     search: this.filters.search,
  //     page: this.page,
  //     sortBy: this.sortedColumn,
  //     order: this.sortDirection
  //   });
  // }

  onSort({ column, direction }: { column: string, direction: 'asc' | 'desc' }) {
    console.log(column)
    if (this.sortedColumn === column) {
      // Toggle sort direction
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      // New column sorting
      this.sortedColumn = column;
      this.sortDirection = 'asc';
    }
    this.updateSortData()
  }

  sortBy({ column, direction }: { column: string, direction: 'asc' | 'desc' }) {
    this.sortedColumn = column;
    this.sortDirection = direction;
    this.updateSortData()
  }

  updateSortData() {

    if (this.sortedColumn === 'emailVerified' || this.sortedColumn === 'approved') {
      this.users.sort((a, b) => {
        if (this.sortedColumn === 'emailVerified') {
          let aBool = Number(a?.emailVerified ?? false); // Convert to 0 or 1
          let bBool = Number(b?.emailVerified ?? false);

          return this.sortDirection === 'asc'
            ? aBool - bBool
            : bBool - aBool;
        } else if (this.sortedColumn === 'approved') {
          let aBool = Number(a?.approved ?? false); // Convert to 0 (false) or 1 (true)
          let bBool = Number(b?.approved ?? false);

          return this.sortDirection === 'asc'
            ? aBool - bBool
            : bBool - aBool;
        }
        return 0;
      });
    } else {
      this.isLoading = true;
      console.log(this.page)
      this.getUsersEmitter.emit({
        search: this.filters.search,
        page: this.page,
        sortBy: this.sortedColumn,
        order: this.sortDirection
      });
    }


  }

  ngOnInit(): void {
    this.isLoading = true;
    this.baseTemplateService.showHideSearch(false);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['users'] && this.users) {
      this.isLoading = false;
      // this.page = 1;
      console.log(changes['users']);

    }
  }

  onPageChange(page: number) {
    this.page = page;
    this.isLoading = true;
    console.log(this.page)
    this.getUsersEmitter.emit({
      search: this.filters.search,
      page: page,
      sortBy: this.sortedColumn,
      order: this.sortDirection
    });
  }

  addUser() {
    // this.router.navigate(['/users/add']);
    this.toastr.success('Feature Coming Soon!!!', '', {
      positionClass: 'toast-top-center',
      closeButton: false,

    });
  }

  deleteUser(userId: string) {
    this.openModal(userId, 'delete');
    this.modalRef.result
      .then((id) => {
        this.isLoading = true;
        this.filters.search = ""
        console.log(this.filters.search)
        this.deleteUserEmitter.emit({
          id: id, search: this.filters.search, page: this.page,
          sortBy: this.sortedColumn,
          order: this.sortDirection
        });
      })
      .catch((error) => console.log(error));
  }

  editUser(user: User) {

    this.editUserEmitter.emit(user);
  }

  toggleSelection(user: User): void {
    if (this.selectedUsers.includes(user)) {
      this.selectedUsers = this.selectedUsers.filter(selectedUser => selectedUser !== user);
    } else {
      this.selectedUsers.push(user);
    }
  }

  isSelected(user: User): boolean {
    return this.selectedUsers.includes(user);
  }

  toggleSelectAll(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (isChecked) {
      this.users.forEach(user => this.selectedUsers.push(user));
    } else {
      this.selectedUsers = [];
    }
  }

  toggle(modalElement) {
    const modal = new Dropdown(modalElement);
    modal.toggle();
  }

  performAction(action: string) {
    let request = {
      action: action,
      usersIds: this.selectedUsers.map(user => user.id)
    }

    this.openModal('', action);
    this.modalRef.result
      .then((id) => {
        this.isLoading = true;
        this.performActionEmitter.emit(request);
      })
      .catch((error) => console.log(error));

  }

  openModal(id, action) {
    this.modalRef = this.modalService.open(DeleteModalComponent);
    this.modalRef.componentInstance.modalTitle = `Confirm ${action}`;
    this.modalRef.componentInstance.modalBodyText =
      `Are you sure you want to ${action} thie item ?`;
    this.modalRef.componentInstance.deleteTitle = `${action}`;
    this.modalRef.componentInstance.itemId = id;
  }

  getSearchResults(search: string) {
    this.filters.search = search
    this.isLoading = true;
    this.getUsersEmitter.emit({
      search: this.filters.search,
      page: this.page,
      sortBy: this.sortedColumn,
      order: this.sortDirection
    });
  }

  onClearSearch() {
    this.filters.search = '';
    this.getSearchResults('')
  }

  onUsers() {

  }
}
