import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rap-students-help-modal',
  templateUrl: './students-help-modal.component.html',
  styleUrls: ['./students-help-modal.component.scss']
})
export class StudentsHelpModalComponent {
  constructor(public activeModal: NgbActiveModal) { }
}
