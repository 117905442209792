import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-step1-ui',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.scss'],
})
export class Step1Component {
  @Input() newAccountForm: FormGroup;
  @Output() nextStepEmitter = new EventEmitter();
  constructor() { }

  ngOnInit() {
    console.log(this.newAccountForm.value);
  }

  goToNextStep() {
    console.log(this.newAccountForm.value);
    this.nextStepEmitter.emit();
  }
}
