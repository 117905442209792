import { Student } from 'src/app/models/students/student.model';

export interface StudentsState {
  rosterId: string;
  students: Student[];
  editableStudent: Student;
  errorMessage: string;
}

export const initialStudentsState: StudentsState = {
  rosterId: null,
  students: null,
  editableStudent: null,
  errorMessage: null,
};
