import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CardsService } from '../cards/cards.service';
import { ActivatedRoute } from '@angular/router';
import * as d3 from 'd3';
@Component({
  selector: 'rap-card-view',
  templateUrl: './card-view.component.html',
  styleUrls: ['./card-view.component.scss']
})
export class CardViewComponent implements OnInit, OnChanges, AfterViewInit {
  cardItem: any;
  id: string;
  isLoading = true;
  data: any;
  @ViewChild('chartContainer', { static: false }) chartContainer!: ElementRef;


  private svg: any;
  private width = 400;
  private height = 200;
  private origin = [200, 100];
  private scale = 3;
  private alpha = Math.PI / 2;
  private beta = Math.PI / 2;
  centerType = 'centroid';
  constructor(private cardService: CardsService, private route: ActivatedRoute, private cdr: ChangeDetectorRef
  ) {

  }

  ngOnInit(): void {
    this.isLoading = true;

    this.id = this.route.snapshot.paramMap.get('id');
    console.log(this.id)
    this.cardService.getCard(this.id).subscribe((result) => {
      console.log(result)
      this.cardItem = result;
      console.log(this.cardItem.chartType)
      console.log(this.cardItem.data)
      this.isLoading = false
      if (this.cardItem.chartType === 'Nearest Neighbour') {
        this.data = this.cardItem.data[0]
        console.log(this.cardItem.data)
        this.cdr.detectChanges();
        console.log(this.cardItem.data)
        this.initializeChart()

      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  ngAfterViewInit() {

  }

  getChartOptions(chartData: any) {
    console.log(chartData.chartType)
    if (!chartData.data || !chartData.data[0] || chartData.data[0].data == null) {
      console.log('Invalid data structure:', chartData);
      return {}; // Return an empty object or handle error
    }

    // return {};

    return {
      title: {
        text: ""
      },
      axisY: {
        title: 'Word Count',
        gridThickness: 1,
        tickLength: 10,
      },
      axisX: {
        title: 'Artist'
      },
      data: [{
        type: 'column',
        maxBarThickness: 30,
        dataPoints: chartData.data[0].data.map((item: any) => ({
          label: item.artist,
          y: item.count
        }))
      }]
    };
  }

  getChartOptionsTimeLine(chart: any) {
    return {
      theme: "light2",
      animationEnabled: true,
      zoomEnabled: true,
      title: {
        text: ""
      },
      axisX: {
        title: "Year",
        valueFormatString: "YYYY",
        interval: 1,
      },
      axisY: {
        title: "Count",
      },
      data: [
        {
          type: "line", // Timeline is best represented as a line chart
          xValueFormatString: "YYYY",
          markerType: "circle", // Display dots on the line
          markerSize: 6, // Set the size of the dots
          markerColor: "black", // Change the color of the dots
          lineColor: "black", // Cu
          fillOpacity: 0.5, // Adds a semi-transparent fill color below the line
          color: "white",
          dataPoints: chart.data[0].data.map(d => ({ x: new Date(d.year, 0), y: d.count }))
            .sort((a, b) => a.x.getTime() - b.x.getTime()) // Convert year to date
        }
      ]
    };
  }

  updateCenterType() {
    console.log(this.centerType)
    this.renderChart();
  }


  private initializeChart(): void {
    if (this.chartContainer && this.chartContainer.nativeElement && this.data) {
      this.svg = d3.select(this.chartContainer.nativeElement)
        .append('svg')
        .attr('class', 'nnsContainer')
        .attr('width', this.width)
        .attr('height', this.height)
        // .attr('transform', `translate(${this.origin[0]}, ${this.origin[1]})`) // Apply initial translation
        .call(
          d3.drag()
            .on('drag', (event) => this.dragged(event))
        );

      this.renderChart();
    } else {
      console.log("hxhx")
    }

  }

  private renderChart(): void {
    const scatterData = this.prepareData();
    const projectedPoints = scatterData.map(d => this.project3D(d.x, d.y, d.z));

    console.log(this.origin)
    console.log(this.width)
    const axisLength = 30;  // Define how long each axis should appear
    const axes = [
      { label: 'x', color: 'red', endPoint: { x: axisLength, y: 0, z: 0 } }, // x-axis
      { label: 'y', color: 'green', endPoint: { x: 0, y: axisLength, z: 0 } }, // y-axis
      { label: 'z', color: 'blue', endPoint: { x: 0, y: 0, z: axisLength } }  // z-axis
    ];

    axes.forEach(axis => {
      const projectedStart = this.project3D(0, 0, 0); // Project origin
      const projectedEnd = this.project3D(axis.endPoint.x, axis.endPoint.y, axis.endPoint.z); // Project axis end

      // Draw line from origin to axis end
      this.svg.append('line')
        .attr('x1', this.origin[0] + projectedStart[0] * this.scale)
        .attr('y1', this.origin[1] - projectedStart[1] * this.scale)
        .attr('x2', this.origin[0] + projectedEnd[0] * this.scale)
        .attr('y2', this.origin[1] - projectedEnd[1] * this.scale)
        .attr('stroke', axis.color)
        .attr('stroke-width', 2);

      // Optional: Add axis label near the end point
      this.svg.append('text')
        .attr('x', this.origin[0] + projectedEnd[0] * this.scale + 5)
        .attr('y', this.origin[1] - projectedEnd[1] * this.scale - 5)
        .text(axis.label)
        .style('fill', axis.color)
        .style('font-size', '12px');
    });

    this.svg.selectAll("circle")
      .data(projectedPoints)
      .enter()
      .append("circle")
      .attr("cx", d => this.origin[0] + d[0] * this.scale)
      .attr("cy", d => this.origin[1] - d[1] * this.scale)
      .attr("r", 5)
      .style("fill", (d, i) => i === 0 ? '#FF5733' : '#3498DB'); // Different colors for query and neighbors

    this.svg.selectAll("text")
      .data(projectedPoints)
      .enter()
      .append("text")
      .attr("x", d => this.origin[0] + d[0] * this.scale + 5)
      .attr("y", d => this.origin[1] - d[1] * this.scale - 5)
      .text((d, i) => i === 0 ? this.data.query.word : this.data.neighbors[i - 1].word);
  }



  private prepareData(): { x: number; y: number; z: number; }[] {
    const query = this.data.query;
    const neighbors = this.data.neighbors;
    let center;

    if (this.centerType == 'centroid') {
      console.log("this.queryWord")
      // Calculate center as the average of query and neighbors' coordinates
      center = { x: 0, y: 0, z: 0 };
      // const allPoints = [query, ...neighbors];
      // for (const dim of ["x", "y", "z"]) {
      //   center[dim] = allPoints.reduce((sum, point) => sum + point[dim], 0) / allPoints.length;
      // }
    } else {
      console.log("this.queryWord111")
      // Use query as center if queryWord is provided
      center = { x: query.x, y: query.y, z: query.z };
    }

    // Return center as the first point and neighbors as the rest
    return [
      { x: center.x, y: center.y, z: center.z },
      ...neighbors.map(nn => ({ x: nn.x, y: nn.y, z: nn.z }))
    ];
  }

  private project3D(x: number, y: number, z: number): [number, number] {
    const cosA = Math.cos(this.alpha);
    const sinA = Math.sin(this.alpha);
    const cosB = Math.cos(this.beta);
    const sinB = Math.sin(this.beta);

    const x2d = cosB * (sinA * y + cosA * x) - sinB * z;
    const y2d = sinB * (sinA * y + cosA * x) + cosB * z;

    return [x2d, y2d];
  }

  private dragged(event: any): void {
    this.alpha += event.dx * 0.01;
    this.beta -= event.dy * 0.01;
    this.cleanupChart();
    this.renderChart();
  }

  private cleanupChart(): void {
    if (this.svg) {
      this.svg.selectAll('*').remove();
      // this.svg.attr("transform", null);

    }
  }
}
