<div class="sign-in">
    <div class="left">
        <!-- <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner> -->
        <div class="container">

            <h2>Payment Status</h2>
            <div *ngIf="isPaymentSuccess === false" class="error">
                <i class="bi bi-check-circle"></i>
                <div>{{ message }}</div>
            </div>
            <div *ngIf="isPaymentSuccess === true" class="success">
                <i class="bi bi-check-circle" style="width: 300px; height: 300px;"></i>
                <div>{{ message }}</div>
            </div>
            <button type="submit" class="btn-signin" (click)="onContinue()">
                Continue
            </button>
        </div>
    </div>
    <div class="right"></div>
</div>