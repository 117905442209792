import { Component } from '@angular/core';
import { RostersFacade } from '../store/rosters.facade';
import { CreateRosterRequest } from 'src/app/models/rosters/create-roster-request.model';
import { UpdateRosterRequest } from 'src/app/models/rosters/update-roster-request.model';
import { StudentsFacade } from 'src/app/students/store/students.facade';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'rap-add-edit-rosters',
  templateUrl: './add-edit-rosters.container.component.html',
})
export class AddEditRostersContainerComponent {
  editableRoster$ = this.rosterFacade.editableRoster$;
  students$ = this.studentsFacade.students$;
  errorMessage$ = this.rosterFacade.errorMessage$;
  title = 'Add Roster';
  constructor(
    private rosterFacade: RostersFacade,
    private studentsFacade: StudentsFacade,
    private route: ActivatedRoute) {
    this.route.params.subscribe((params) => {
      if (params['rosterId']) {
        this.title = 'Edit Roster';
        this.rosterFacade.getRosterById(params['rosterId']);
      } else {
        console.log("else")
      }
    });
  }

  ngOnInit() {

    this.getStudents();

  }

  getStudents() {
    this.editableRoster$.subscribe((roster) => {
      if (roster) {
        this.studentsFacade.getStudents(roster.id);
      }
    });
  }

  createNewRoster(newRoster: CreateRosterRequest) {
    this.rosterFacade.createNewRoster(newRoster);
  }

  updateRoster(updatedRoster: UpdateRosterRequest) {
    this.rosterFacade.updateRoster(updatedRoster);
  }

  ngOnDestroy() {
    this.rosterFacade.resetEditableRoster();
    this.rosterFacade.clearErrorMessage();
  }
}
