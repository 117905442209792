import { Injectable } from '@angular/core';
import { Store, createFeatureSelector, createSelector } from '@ngrx/store';
import { RostersState } from './rosters.state';
import { get } from 'jquery';

const getRostersState = createFeatureSelector<RostersState>('rosters');

const getRosters = createSelector(
  getRostersState,
  (state: RostersState) => state.rosters,
);

const getEditableRoster = createSelector(
  getRostersState,
  (state: RostersState) => state.editableRoster,
);

const getErrorMessage = createSelector(
  getRostersState,
  (state: RostersState) => state.errorMessage,
);

@Injectable()
export class RostersSelectors {
  rosters$ = this.store.select(getRosters);
  editableRoster$ = this.store.select(getEditableRoster);
  errorMessage$ = this.store.select(getErrorMessage);
  constructor(private store: Store<RostersState>) {}
}
