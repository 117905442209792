import { createAction, props } from '@ngrx/store';
import { CreateFunctionRequest } from 'src/app/models/functions/create-function-request.model';
import { Function } from 'src/app/models/functions/functions.model';
import { UpdateFunctionRequest } from 'src/app/models/functions/update-function-request.model';

export const getFunctions = createAction('[Functions] GET_FUNCTIONS');

export const getFunctionsSuccess = createAction(
  '[Functions] GET_FUNCTIONS_SUCCESS',
  props<{ functions: Function[] }>(),
);

export const getFunctionById = createAction(
  '[Functions] GET_FUNCTION_BY_ID',
  props<{ functionId: string }>(),
);

export const getFunctionByIdSuccess = createAction(
  '[Functions] GET_FUNCTION_BY_ID_SUCCESS',
  props<{ func: Function }>(),
);

export const createNewFunction = createAction(
  '[Functions] CREATE_NEW_FUNCTION',
  props<{ function: CreateFunctionRequest }>(),
);

export const createNewFunctionSuccess = createAction(
  '[Functions] CREATE_NEW_FUNCTION_SUCCESS',
);

export const deleteFunction = createAction(
  '[Functions] DELETE_FUNCTION',
  props<{ functionId: string }>(),
);

export const deleteFunctionSuccess = createAction(
  '[Functions] DELETE_FUNCTION_SUCCESS',
  props<{ functionId: string }>(),
);

export const updateFunction = createAction(
  '[Functions] UPDATE_FUNCTION',
  props<{ function: UpdateFunctionRequest }>(),
);

export const updateFunctionSuccess = createAction(
  '[Functions] UPDATE_FUNCTION_SUCCESS',
);

export const functionsError = createAction(
  '[Functions] FUNCTIONS_ERROR',
  props<{ error: any }>(),
);

export const clearErrorMessage = createAction('[Functions] CLEAR_ERROR_MESSAGE');

export const resetEditableFunction = createAction('[Functions] RESET_EDITABLE_FUNCTION');
