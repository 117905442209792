import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
})
export class PasswordInputComponent {
  @Input() submitted: boolean;
  @Input() formControl: FormControl;
  @Input() placeHolder: string = 'Enter a password';
  fieldTextType: boolean;

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  hasPasswordError(): boolean {
    const passwordErrors = this.formControl.errors;
    if ((this.formControl?.errors != null)) {
      if (this.formControl?.errors["required"] == null)
        return true;
      else if (this.formControl?.errors["passwordStrength"] == null)
        return true;
      else
        return false;
    } else {
      return false;
    }
    // return (this.formControl.value != '' || this.formControl.value != null);
  }

  clearInput() {
    this.formControl.setValue('');
  }

}
