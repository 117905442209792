import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { OnboardingPresenter } from '../onboarding.presenter';
@Component({
  selector: 'app-step4',
  templateUrl: './step4.component.html',
  styleUrls: ['./step4.component.scss'],
})
export class Step4Component {
  options = ['From a friend', 'Media or our blog', 'Other'];
  selectedOption: string | null = null;
  constructor(private router: Router, private location: Location, private onBoardingPresenter: OnboardingPresenter) { }

  ngOnInit(): void {
    const data = this.onBoardingPresenter.getFormData().section3;
    if (data != "") {
      this.selectedOption = data;
    }

  }

  nextStep(option: string) {
    this.selectedOption = option;
  }

  onContinue() {
    if (this.selectedOption != null) {
      this.router.navigate(['/onboarding/step4']);
      this.onBoardingPresenter.updateSection3(this.selectedOption);
    }

  }
  onSkip() {
    this.router.navigate(['/onboarding/step4']);
    this.onBoardingPresenter.updateSection3("");
  }


  goBack() {
    this.location.back();
  }
}
