import { Injectable } from '@angular/core';
import { Store, createFeatureSelector, createSelector } from '@ngrx/store';
import { RolesState } from './roles.state';

const getRolesState = createFeatureSelector<RolesState>('roles');

const getRoles = createSelector(
  getRolesState,
  (state: RolesState) => state.roles,
);

const getPermissions = createSelector(
  getRolesState,
  (state: RolesState) => state.permissions,
);

const getEditableRole = createSelector(
  getRolesState,
  (state: RolesState) => state.editableRole,
);

const getErrorMessage = createSelector(
  getRolesState,
  (state: RolesState) => state.errorMessage,
);

@Injectable()
export class RolesSelectors {
  roles$ = this.store.select(getRoles);
  editableRole$ = this.store.select(getEditableRole);
  permissions$ = this.store.select(getPermissions);
  errorMessage$ = this.store.select(getErrorMessage);
  constructor(private store: Store<RolesState>) {}
}
