<div class="modal-header">
    <h4 class="modal-title">Rap Almanac STUDENT ROSTER HELP</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <h3>Overview</h3>
    <p>
        Rap Almanac Sudent rosters allow instructors to manage and
        track searches of groups of students who are using the
        Mapper's Delight augmented reality app in their classes.
        Rosters can represent entire classes or working subgroups of
        students in a given class.
    </p>
    <p>
        Each roster needs a NAME. With the CLEAN AUDIO ONLY checkbox,
        a roster's members can be restricted so that the audio samples
        that accompany Mapper's Delight search results only feature
        clean lyrics. This check box sets the restriction for all
        students in the roster. Instructors who want to assign
        privileges individually can do so by clicking EDIT for the
        corresponding student.
    </p>
    <h3>Creating and editing student records</h3>
    <p>
        To add a student to a roster, click the "Add Student" button.
        To edit an existing student, click the "EDIT" in the row that
        corresponds with the given student.
    </p>
    <p>
        The NAME associated with a student can be changed, but the
        STUDENT CODE generated by Rap Almanac cannot. The STUDENT CODE
        is a randomly-generated assword, used in conjunction with the
        instructor's email address, that grants access to the Mapper's
        Delight augmented reality application.
    </p>
    <p>
        The Mapper's Delight augmented reality app currently tracks
        the following interactions: Search terms entered Pins tapped
    </p>
    <h3>Tracking student app usage</h3>
    <p>
        The Mapper's Delight augmented reality app currently tracks
        the following interactions:
        <br />
        <br />
        <strong>Search terms entered</strong>
        <br />
        <strong>Pins tapped</strong>
    </p>
    <p>
        This usage record can be exported as a CSV file for closer
        evaluation by clicking "View Progress" in the roster's student
        list. Click EXPORT TO CSV button on the next screen.
    </p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-light close-modal-btn" (click)="activeModal.dismiss('Cross click')">
        Close
    </button>

</div>