import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ArtistsService } from 'src/app/artists/artists.service';
import { AuthService } from 'src/app/services/auth.service';
import { AlbumsService } from '../albums.service';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'rap-add-edit-album',
  templateUrl: './add-edit-album.component.html',
  styleUrls: ['./add-edit-album.component.scss']
})
export class AddEditAlbumComponent {
  title = 'Add Album';
  isContributions = false;
  isLoading = false;
  isNew = true;
  subTitle = "Database";
  albumForm: FormGroup;
  public settings = {};
  artistLst: any;
  albumId: any;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService, private albumService: AlbumsService, private toastr: ToastrService, private artistService: ArtistsService
    , private loaderService: AppService) {
    this.loaderService.loading$.subscribe(isLoading => {
      console.log("isLoading")
      console.log(isLoading)
      this.isLoading = isLoading;
    });

    console.log(this.authService.getUserId());
    const navigation = this.router.getCurrentNavigation();
    console.log(navigation?.extras.state)
    if (navigation?.extras.state) {
      this.isContributions = navigation.extras.state['isContributions'];
      console.log(this.isContributions)
      console.log(navigation.extras.state['id'])
      if (this.isContributions) {
        this.subTitle = "Contributions";

      }

      if (navigation.extras.state['id']) {
        this.albumId = navigation.extras.state['id'];
        this.isNew = false;
        this.title = 'Edit Album';
        this.isLoading = true;
        this.isLoading = true;
        this.albumService.getAlbum(this.albumId).subscribe((apiResponse) => {
          this.isLoading = false;
          this.albumForm.patchValue({
            album: apiResponse.album,
            releaseDate: apiResponse.release_date,
            artist: apiResponse.artist,
            unitsSold: apiResponse.units_sold,
            recordLabel: apiResponse.record_label,
          });

        });
      }

    }
    this.isLoading = true;
    this.artistService.getAutoComplete(this.authService.getUserId(), this.isContributions).subscribe((result) => {
      this.isLoading = false;
      this.artistLst = result;
    });
  }

  ngOnInit(): void {
    this.albumForm = this.fb.group({
      album: ['', Validators.required],
      releaseDate: ['', Validators.required],
      artist: ['', Validators.required],
      unitsSold: [''],
      recordLabel: ['']

    });

    this.settings = {
      singleSelection: true,
      idField: 'id',
      textField: 'label',
      allowSearchFilter: true, // Allows search filter
      closeDropDownOnSelection: true, // Close dropdown after selection
      enableCheckAll: false,  // Disable "Select All" option
      itemsShowLimit: 1,      // Only show selected item in dropdown
      unSelectAllText: null,  // No "Unselect All" text
      selectAllText: null,    // No "Select All" text
      allowCheckboxes: false  // 
    };
  }

  addUpdateAlbum() {
    if (this.albumForm.valid) {
      console.log(this.albumForm.value.artist);
      const formData = {
        album: this.albumForm.value.album,
        release_date: new Date(this.albumForm.value.releaseDate).toISOString(),
        units_sold: this.albumForm.value.unitsSold,
        record_label: this.albumForm.value.recordLabel,
        artistid: this.albumForm.value.artist[0].id,
        creation_time: null, // Optional, set to null
        update_time: null // Optional, set to null
      };
      console.log(formData);
      const storedPermissions = this.authService.getUser();
      if (storedPermissions) {
        const userObj = JSON.parse(storedPermissions);
        console.log(userObj);
        const jsonBody = {
          "data": formData,
          "currentUser": userObj
        }
        console.log(jsonBody);
        this.isLoading = true;
        this.albumService.addNew(jsonBody).subscribe((result) => {
          this.isLoading = false;
          this.toastr.success('Album created successfully', '', {
            positionClass: 'toast-top-center',
            closeButton: false,
          });
          // this.router.navigate(['/dashboard/roles']);
          this.onCancel();
        });
      }

    } else {
      console.log(this.albumForm.value);
    }
  }

  onReset() {
    this.albumForm = this.fb.group({
      album: ['', Validators.required],
      releaseDate: ['', Validators.required],
      artist: ['', Validators.required],
      unitsSold: [''],
      recordLabel: ['']
    });
  }

  onCancel() {
    var url = "/dashboard/database/albums";
    if (this.isContributions) {
      url = "/dashboard/contributions/albums";
    }
    this.router.navigateByUrl(url, { skipLocationChange: true, state: { isContributions: this.isContributions } }
    );
  }
}
