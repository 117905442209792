
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { UserProfileRoutes } from './user-profile-routing.module';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ProfileComponent } from './profile/profile.component';
import { ProfileNavBarComponent } from './profile-nav-bar/profile-nav-bar.component';
import { ProfilePhotoModalComponent } from './profile-photo-modal/profile-photo-modal.component';
import { ChangePasswordModalComponent } from './change-password-modal/change-password-modal.component';

import { SharedModule } from '../shared/shared.module';
import { UpdateSuccessModalComponent } from './update-success-modal/update-success-modal.component';

@NgModule({
  declarations: [
    UserProfileComponent,
    ChangePasswordComponent,
    ProfileComponent,
    ProfileNavBarComponent,
    ProfilePhotoModalComponent,
    ChangePasswordModalComponent,
    UpdateSuccessModalComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forChild(UserProfileRoutes),
    SharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UserProfileModule { }
