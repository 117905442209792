import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-onboarding-ui',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent {
  @Input() currentStep: number;
  constructor() {}
}
