import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root', // This makes the service available application-wide
})
export class SigninPresenter {
  constructor(private formBuilder: FormBuilder) { }

  initializeAuthForm() {
    return this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(8)]],
    });
  }
}
