import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { COUNTRY_LIST } from 'src/app/explore/explore.data';
import { AuthService } from 'src/app/services/auth.service';
import { ArtistsService } from '../artists.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'rap-add-edit-artist',
  templateUrl: './add-edit-artist.component.html',
  styleUrls: ['./add-edit-artist.component.scss']
})
export class AddEditArtistComponent {
  title = 'Add Artist';
  isContributions = false;
  isLoading = false;
  isNew = true;
  subTitle = "Database";
  locations = COUNTRY_LIST;
  artistForm: FormGroup;
  public settings = {};
  artistId: any;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService, private artistsService: ArtistsService, private toastr: ToastrService,
  ) {

    console.log(this.authService.getUserId());
    const navigation = this.router.getCurrentNavigation();
    console.log(navigation?.extras.state)
    if (navigation?.extras.state) {
      this.isContributions = navigation.extras.state['isContributions'];
      console.log(this.isContributions)
      console.log(navigation.extras.state['id'])
      if (this.isContributions) {
        this.subTitle = "Contributions";
      }

      if (navigation.extras.state['id']) {
        this.artistId = navigation.extras.state['id'];
        this.isNew = false;
        this.title = 'Edit Artist';
        this.isLoading = true;
        this.artistsService.getArtist(this.artistId).subscribe((apiResponse) => {
          this.isLoading = false;
          this.artistForm.patchValue({
            artist: apiResponse.artist,
            dateOfBirth: apiResponse.start,
            end: apiResponse.end,
            alias: apiResponse.alias,
            city: apiResponse.city,
            county: apiResponse.county,
            country: apiResponse.country,
            latitude: apiResponse.latitude,
            longitude: apiResponse.longitude,
            originCity: apiResponse.origin_city,
            originCounty: apiResponse.origin_county,
            originCountry: apiResponse.origin_country,
            originLatitude: apiResponse.origin_latitude,
            originLongitude: apiResponse.origin_longitude
          });

        });
      }

    }
  }

  ngOnInit(): void {

    this.settings = {
      singleSelection: true,
      idField: 'value',
      textField: 'label',
      allowSearchFilter: true, // Allows search filter
      closeDropDownOnSelection: true, // Close dropdown after selection
      enableCheckAll: false,  // Disable "Select All" option
      itemsShowLimit: 1,      // Only show selected item in dropdown
      unSelectAllText: null,  // No "Unselect All" text
      selectAllText: null,    // No "Select All" text
      allowCheckboxes: false  // 
    };

    this.artistForm = this.fb.group({
      artist: ['', Validators.required],
      dateOfBirth: ['', Validators.required],
      end: [''],
      alias: [''],
      city: ['', Validators.required],
      county: [''],
      country: ['', Validators.required],
      latitude: ['', [Validators.required, Validators.pattern('^-?\\d{1,2}\\.\\d+$')]],
      longitude: ['', [Validators.required, Validators.pattern('^-?\\d{1,3}\\.\\d+$')]],
      originCity: ['', Validators.required],
      originCounty: [''],
      originCountry: ['', Validators.required],
      originLatitude: ['', [Validators.required, Validators.pattern('^-?\\d{1,2}\\.\\d+$')]],
      originLongitude: ['', [Validators.required, Validators.pattern('^-?\\d{1,3}\\.\\d+$')]]
    });

    // Subscribe to changes on 'latitude'
    this.artistForm.get('latitude')?.valueChanges.subscribe(value => {
      if (value) {
        this.artistForm.patchValue({
          originLatitude: value // Update originLatitude when latitude changes
        });
      }
    });

    this.artistForm.get('longitude')?.valueChanges.subscribe(value => {
      if (value) {
        this.artistForm.patchValue({
          originLongitude: value // Update originLatitude when latitude changes
        });
      }
    });
  }

  addUpdateArtist() {
    if (this.artistForm.valid) {
      console.log(this.artistForm.value);
      console.log(this.artistForm.value.country);
      console.log(this.artistForm.value.country);
      console.log(this.artistForm.value.country.label);
      const formData = {
        artist: this.artistForm.value.artist,
        start: new Date(this.artistForm.value.dateOfBirth).toISOString(),  // Convert date to ISO format
        end: this.artistForm.value.end || null,  // Set to null if not filled
        alias: this.artistForm.value.alias,
        city: this.artistForm.value.city,
        state: this.artistForm.value.county || null, // Optional, set to null if empty
        country: this.artistForm.value.country.label,
        latitude: this.artistForm.value.latitude,
        longitude: this.artistForm.value.longitude,
        origin_city: this.artistForm.value.originCity,
        origin_state: this.artistForm.value.originCounty,
        origin_country: this.artistForm.value.originCountry.label,
        origin_latitude: this.artistForm.value.originLatitude,
        origin_longitude: this.artistForm.value.originLongitude,
        creation_time: null, // Optional, set to null
        update_time: null // Optional, set to null
      };
      console.log(formData);
      const storedPermissions = this.authService.getUser();
      if (storedPermissions) {
        const userObj = JSON.parse(storedPermissions);
        console.log(userObj);
        const jsonBody = {
          "data": formData,
          "currentUser": userObj
        }
        console.log(jsonBody);
        this.isLoading = true;
        this.artistsService.addNew(jsonBody).subscribe((result) => {
          this.isLoading = false;
          this.toastr.success('Artist created successfully', '', {
            positionClass: 'toast-top-center',
            closeButton: false,
          });
          // this.router.navigate(['/dashboard/roles']);
          this.onCancel();
        });
      }

      return; // Prevent submission
    } else {
      this.markFormGroupTouched(this.artistForm); // Mark all controls as touched
      console.log(this.artistForm.value);
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control?.markAsTouched({ onlySelf: true });
    });
  }


  onReset() {
    this.artistForm = this.fb.group({
      artist: ['', Validators.required],
      dateOfBirth: ['', Validators.required],
      end: [''],
      alias: [''],
      city: ['', Validators.required],
      county: [''],
      country: ['', Validators.required],
      latitude: ['', [Validators.required, Validators.pattern('^-?\\d{1,2}\\.\\d+$')]],
      longitude: ['', [Validators.required, Validators.pattern('^-?\\d{1,3}\\.\\d+$')]],
      originCity: ['', Validators.required],
      originCounty: [''],
      originCountry: ['', Validators.required],
      originLatitude: ['', [Validators.required, Validators.pattern('^-?\\d{1,2}\\.\\d+$')]],
      originLongitude: ['', [Validators.required, Validators.pattern('^-?\\d{1,3}\\.\\d+$')]]
    });
  }

  onCancel() {
    var url = "/dashboard/database/artists";
    if (this.isContributions) {
      url = "/dashboard/contributions/artists";
    }
    this.router.navigateByUrl(url, { skipLocationChange: true, state: { isContributions: this.isContributions } }
    );
  }
}
