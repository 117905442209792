<div class="label-container">
  <div>
    <label class="clickable-label" routerLink="/dashboard/explore"> Rap Almanac </label>
    <img src="../assets/images/filled.svg" />
  </div>

  <label (click)="onRoles()" class="non-clickable-label">Roles</label>
</div>

<div class="roles-section">
  <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
  <div class="section-title">Roles</div>
  <button type="button" class="btn btn-dark button" (click)="createRoleEmitter.emit()">
    Add New
  </button>

  <div class="search-container">
    <img src="../assets/images/search.svg" class="search-icon" />
    <input type="text" placeholder="Search..." class="search-input" [(ngModel)]="filters.search"
      (keydown.enter)="getSearchResults()">
    <div class="auth-icons">
      <i class="bi bi-x-circle-fill" style="margin-left: 10px;" *ngIf="filters.search !== ''"
        (click)="onClearSearch()"></i>
    </div>
  </div>

  <ng-container *ngIf="roles?.length > 0">
    <div class="custom-container">
      <table class="table rounded-3 overflow-hidden">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Permissions</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let role of paginatedRoles">
            <td scope="row">{{ role?.name }}</td>
            <td scope="row">
              <div *ngFor="let permission of role.permissions">
                <div class="permissions-label" (click)="onRoles()">{{ permission.name }}</div>
              </div>
            </td>
            <td>
              <button type="button" class="btn btn-light view-queries-btn" (click)="editRole(role)">
                Edit
              </button>
              <button type="button" class="btn btn-light delete-queries-btn" (click)="deleteRole(role.id)">
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="pagination">
      <rap-pagination [collectionSize]="roles?.length" [page]="page" [pageSize]="pageSize"
        (onPageChange)="onPageChange($event)"></rap-pagination>
    </div>
  </ng-container>

</div>

<div style="padding: 20px; text-align: center; margin-top: 100px;" *ngIf="roles?.length === 0 && !isLoading">
  <img src="../assets/images/question.svg" />
  <div class="no-match" *ngIf="searchQuery !== ''"> We didn’t find a match for {{searchQuery}}</div>
  <div class="no-match" *ngIf="searchQuery === ''">No roles found </div>
</div>