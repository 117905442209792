import { Store } from '@ngrx/store';
import { UserState } from '../state/user.state';
import { Injectable } from '@angular/core';
import { UserSelectors } from '../selectors/user.selectors';
import {
  authorize,
  getUserDetails,
  resetPassword,
  sendPwdResetCode,
  createNewAccount,
  verifyEmail,
  resetErrorMsg,
  getOnBoardingDetails
} from '../actions/user.action';
import { LoginCeredentials } from 'src/app/models/login-ceredentials.model';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class UserFacade {
  userDetails$ = this.userSelectors.userDetails$;
  authError$ = this.userSelectors.authError$;
  pwdResetCodeSent$ = this.userSelectors.pwdResetCodeSent$;
  newAccountForm$ = this.userSelectors.newAccountFormGroup$;
  passwordResetSuccess$ = this.userSelectors.passwordResetSuccess$;
  onBoardingDetails$ = this.userSelectors.onBoardingDetails$;
  constructor(
    private store: Store<UserState>,
    private userSelectors: UserSelectors,
  ) { }

  authorize(ceredentials: LoginCeredentials) {
    this.store.dispatch(authorize({ ceredentials }));
  }

  sendPwdResetCode(email: string) {
    this.store.dispatch(sendPwdResetCode({ email }));
  }

  resetPassword(newPassword: any) {
    this.store.dispatch(resetPassword({ newPassword }));
  }

  getUserDetails() {
    this.store.dispatch(getUserDetails());
  }

  createNewAccount(formGroup: FormGroup) {
    this.store.dispatch(createNewAccount({ newAccountFormGroup: formGroup }));
  }

  verifyEmail(code: string) {
    this.store.dispatch(verifyEmail({ code }));
  }

  resetErrorMsg() {
    this.store.dispatch(resetErrorMsg());
  }

  getOnBoardingDetails(id: string) {
    this.store.dispatch(getOnBoardingDetails({ id }));
  }
}
