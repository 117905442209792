import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environments';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ArtistsService {

  private readonly BASE_URL = 'baseUrl';
  private url: string;

  constructor(private httpClient: HttpClient) {
    this.url = environment.baseUrl;
  }

  getAllArtists(search: string, page: number, isContributions: boolean, sortBy: string, order: string) {
    var endPoint: string = `v2/artists` + '?limit=10&page=' + page;
    if (isContributions) {
      endPoint = 'v2/artists/my-artists' + '?limit=10&page=' + page;
    }
    if (search !== '') {
      endPoint += `&term=${search}`;
    } else if (sortBy !== '') {
      endPoint += `&sortBy=${sortBy}&order=${order}`; //&sortBy=userName&order=asc
    }
    console.log(endPoint)
    return this.httpClient.get<any>(this.url + endPoint)
      .pipe(map((response: any) => response));
  }

  addNew(body: any) {
    return this.httpClient.post<any>(this.url + 'v2/artists', body)
      .pipe(map((response: any) => response));
  }

  deleteArtist(id: string) {
    return this.httpClient.delete<any>(this.url + 'v2/artists/' + id).pipe(map((response: any) => response));
  }

  getArtist(id: string) {
    return this.httpClient.get<any>(this.url + 'v2/artists/' + id).pipe(map((response: any) => response));
  }

  getAutoComplete(userID: string, isContributions: boolean) {
    var endpoint = this.url + 'v2/artists/autocomplete?query=&limit=100';
    if (isContributions)
      endpoint = this.url + 'v2/artists/autocomplete?query=&limit=100&currentUser=' + userID

    return this.httpClient.get<any>(endpoint).pipe(map((response: any) => response));
  }
}
