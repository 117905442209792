import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StudentsFacade } from './store/students.facade';
import { Student } from '../models/students/student.model';

@Component({
  selector: 'rap-students',
  templateUrl: './students.container.component.html',
})
export class StudentsContainerComponent {
  rosterId: string;
  @Input() students: Student[];
  students$ = this.stduentsFacade.students$;
  @Input() showLoader: boolean;
  @Input() rosterID: string;
  constructor(
    private stduentsFacade: StudentsFacade,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe((params) => {
      // this.rosterId = params['id'];
      console.log(params)
      console.log(params['rosterId'])
      this.rosterId = params['rosterId'];
      console.log(this.rosterId)

      this.stduentsFacade.setRosterId(this.rosterId);
    });

  }

  ngOnInit() {
    console.log(this.showLoader)
    console.log(this.rosterID)
    console.log(this.rosterId)
  }

  getStudents(rosterId: string) {
    this.stduentsFacade.getStudents(rosterId);
  }

  deleteStudent(id: string) {
    console.log(id)
    this.stduentsFacade.deleteStudent(id, this.rosterId);
  }

  manageStudent(student: Student) {
    console.log(student)
    this.stduentsFacade.setEditableStudent(student);
    // this.router.navigate(['/dashboard/rosters/manage-students']);
    this.router.navigate(['/dashboard/rosters/' + this.rosterId + '/student/' + student.id]);
  }
}
