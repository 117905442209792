import { createAction, props } from '@ngrx/store';
import { Roster } from 'src/app/models/rosters/rosters.model';
import { CreateStudentRequest } from 'src/app/models/students/create-student-request.model';
import { Student } from 'src/app/models/students/student.model';
import { UpdateStudentRequest } from 'src/app/models/students/update-student-request.model';

export const getStudents = createAction(
  '[Students] GET_STUDENTS',
  props<{ rosterId: string }>(),
);

export const getStudentsSuccess = createAction(
  '[Students] GET_ROSTERS_SUCCESS',
  props<{ students: any }>(),
);

export const createNewStudent = createAction(
  '[Students] CREATE_NEW_STUDENT',
  props<{ newStudent: CreateStudentRequest, rosterId: string }>(),
);

export const createNewStudentSuccess = createAction(
  '[Students] CREATE_NEW_STUDENT_SUCCESS',
);

export const deleteStudent = createAction(
  '[Students] DELETE_STUDENT',
  props<{ rosterId: string, studentId: string }>(),
);

export const deleteStudentSuccess = createAction(
  '[Students] DELETE_STUDENT_SUCCESS',
  props<{ studentId: string }>(),
);

export const setEditableStudent = createAction(
  '[Students] SET_EDITABLE_STUDENT',
  props<{ student: Student }>(),
);

export const updateStudent = createAction(
  '[Students] UPDATE_STUDENT',
  props<{ updatedStudent: UpdateStudentRequest }>(),
);

export const updateStudentSuccess = createAction(
  '[Students] UPDATE_STUDENT_SUCCESS',
);

export const setRosterId = createAction(
  '[Students] SET_ROSTER_ID',
  props<{ rosterId: string }>(),
);

export const setErrorMessage = createAction(
  '[Students] SET_ERROR_MESSAGE',
  props<{ errorMsg: string }>(),
);

export const resetErrorMessage = createAction('[Students] RESET_ERROR_MESSAGE');
