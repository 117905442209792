import { RolePermissions } from 'src/app/models/roles/role-permissions.model';
import { Roles } from 'src/app/models/roles/roles.model';

export interface RolesState {
  roles: Roles[];
  editableRole: Roles;
  permissions: RolePermissions[];
  errorMessage: string;
}

export const initialRolesState: RolesState = {
  roles: null,
  editableRole: null,
  permissions: null,
  errorMessage: null,
};
