<div class="sidenav">
    <div class="logo-container">
        Profile Settings
    </div>
    <ul class="sidenav-nav">
        <li class="sidenav-item" *ngFor="let data of navData">
            <a class="sidenav-link" [routerLink]="[data.routeLink]" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <i class="sidenav-link-icon" [class]="data.icon"></i>
                <span class="sidenav-link-text" *ngIf="!collapsed">{{
                    data.label
                    }}</span>

            </a>
        </li>
    </ul>
</div>