import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AppService } from 'src/app/app.service';

import { CreateRoleRequest } from 'src/app/models/roles/create-role-request.model';
import { RolePermissions } from 'src/app/models/roles/role-permissions.model';
import { Roles } from 'src/app/models/roles/roles.model';
import { UpdateRoleRequest } from 'src/app/models/roles/update-role-request.model';

@Component({
  selector: 'rap-add-edit-role-ui',
  templateUrl: './add-edit-role.component.html',
  styleUrls: ['./add-edit-role.component.scss'],
})
export class AddEditRoleComponent {
  @ViewChild('multiSelect') multiSelect;
  @Input() editableRole: Roles;
  @Input() permissions: RolePermissions[];
  @Input() errorMessage: string;
  @Input() title: string;
  @Output() createNewRoleEmitter = new EventEmitter<CreateRoleRequest>();
  @Output() updateRoleEmitter = new EventEmitter<UpdateRoleRequest>();
  roleForm: FormGroup;
  submitted: boolean = false;
  data = [];
  isNew = false;
  isLoading = false;
  public settings = {};

  permissionIds = [];
  constructor(private formBuilder: FormBuilder, private loaderService: AppService) {
    this.loaderService.loading$.subscribe(isLoading => {
      console.log("isLoading")
      console.log(isLoading)
      this.isLoading = isLoading;
    });
  }

  ngOnInit() {

    if (this.title == "Add Role") {
      this.isNew = true;
      this.isLoading = true;
    } else {
      this.isLoading = true;
    }

    this.settings = {
      singleSelection: false,
      idField: 'id',
      textField: 'label',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false,
      defaultOpen: true,

    };
    this.roleForm = this.formBuilder.group({
      name: new FormControl(null, [Validators.required]),
      permissions: new FormControl([], [Validators.required]),

    });

  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['permissions'] && this.permissions) {
      this.permissions = this.permissions;
    }
    if (this.editableRole) {
      this.isLoading = false;
      this.roleForm.patchValue({
        name: this.editableRole.name,
        permissions: this.permissions?.filter((permission) =>
          this.editableRole.permissions.some(
            (rolePermission) => rolePermission.id === permission.id,
          ),
        ),
      });
    } else {
      this.isLoading = false;
    }
  }
  get f(): { [key: string]: AbstractControl } {
    return this.roleForm.controls;
  }

  addUpdateRole() {
    this.isLoading = true;
    this.submitted = true;
    if (this.roleForm.valid) {
      let rolePermissions = this.roleForm.value.permissions.map(
        (permission) => {
          return permission.id;
        },
      );
      this.permissionIds = rolePermissions;
      if (!this.editableRole) {
        this.addRole();
      } else {
        this.updateRole();
      }
    }
  }

  addRole() {
    let roleRequest: CreateRoleRequest = {
      data: {
        name: this.roleForm.value.name,
        permissions: this.permissionIds,
      },
    };
    this.createNewRoleEmitter.emit(roleRequest);
  }

  updateRole() {
    let updateRoleRequest: UpdateRoleRequest = {
      id: this.editableRole.id,
      data: {
        name: this.roleForm.value.name,
        permissions: this.permissionIds,
      },
    };
    this.updateRoleEmitter.emit(updateRoleRequest);
  }

  onReset() {
    this.roleForm.reset();
  }

  onRapAlmanc() { }

  onRoles() { }

}
