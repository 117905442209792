import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PasswordInputComponent } from './form-fields/password-input/password-input.component';
import { PaginationComponent } from './component/pagination/pagination.component';
import { NgbPagination, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from './component/delete-modal/delete-modal.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

@NgModule({
  declarations: [PasswordInputComponent, PaginationComponent, DeleteModalComponent, SpinnerComponent, PrivacyPolicyComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgbPagination, NgbPaginationModule],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PasswordInputComponent,
    PaginationComponent,
    SpinnerComponent,
    PrivacyPolicyComponent
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
