import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { OnboardingPresenter } from '../onboarding.presenter';
@Component({
  selector: 'app-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.scss'],
})
export class Step3Component {
  options = ['Developer or Engineer', 'Researcher or Professional', 'Other'];
  selectedOption: string | null = null;

  constructor(private router: Router, private location: Location, private onBoardingPresenter: OnboardingPresenter) { }

  ngOnInit(): void {
    const data = this.onBoardingPresenter.getFormData().section2;
    if (data != "") {
      this.selectedOption = data;
    }

  }
  nextStep(option: string) {
    this.selectedOption = option;

  }

  onContinue() {
    if (this.selectedOption != null) {
      console.log(this.selectedOption)
      this.router.navigate(['/onboarding/step3']);
      this.onBoardingPresenter.updateSection2(this.selectedOption);
    }

  }
  onSkip() {
    this.router.navigate(['/onboarding/step3']);
    this.onBoardingPresenter.updateSection2("");
  }
  goBack() {
    this.location.back();
  }
}
