import { Component, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { SigninPresenter } from './signin.presenter';
import { UserFacade } from '../store/user/facade/user.facade';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../services/user.service';
import { environment } from 'src/environments/environments';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit, OnDestroy {
  authError$ = this.userFacade.authError$;
  authForm!: FormGroup;
  isEmailEntered: boolean = false;
  isPasswordEntered: boolean = false;
  isSignUpFlow: boolean = false;
  submitted: boolean = false;
  isLoading = false;
  title: string = 'Sign in';
  subTitle: string =
    'To sign in, please enter an email and password or create an account to get started.';
  bodyText = 'This text can be updated in modal 1';
  private url: string;
  constructor(
    private router: Router,
    private signinPresenter: SigninPresenter,
    private userFacade: UserFacade,
  ) { }

  ngOnInit() {
    this.url = environment.baseUrl;
    if (history.state.navigationId === 1) {
      // First navigation
      history.pushState(null, '', location.href);
      window.addEventListener('popstate', () => {
        history.pushState(null, '', location.href);
      });
    }
    this.authForm = this.signinPresenter.initializeAuthForm();

    this.userFacade.authError$.subscribe(error => {
      if (error) {
        // this.authError = error;
        console.log('Auth Error:', error);
        this.isLoading = false;
        // Optionally, handle the error, such as showing a message to the user
      } else {
        // this.authError = null;
        console.log('Auth Error:', error);
        this.isLoading = false;
      }
    });
  }

  onSave() {
    console.log('Save changes clicked');
    // Handle save action here
  }

  get f(): { [key: string]: AbstractControl } {
    return this.authForm.controls;
  }

  clearInput(input: string): void {
    console.log("ws");
    this.authForm.controls['email'].setValue('');
  }

  login() {
    this.submitted = true;
    if (this.authForm.invalid) {

      return;

    }
    this.isLoading = true;
    this.userFacade.authorize(this.authForm.value);
  }

  hasEmailError(): boolean {
    const emailErrors = this.authForm.controls['email'].errors;
    return !!emailErrors && !!emailErrors['email'];
  }

  navigateToCreateAccount() {
    this.isLoading = false;
    this.router.navigate(['/create-account']);
  }

  navigateToPasswordHelp() {
    this.isLoading = false;
    this.router.navigate(['/password-help']);
  }

  onPrivacy() {
    console.log(this.url + "privacy-notice")
    window.open("privacy-policy", '_blank');
  }

  openInNewWindow(route: string) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([route])
    );

    window.open(url, '_blank');
  }

  navigateToCreateVILSAccount() {
    this.isLoading = false;
    this.router.navigate(['/learning']);
  }

  ngOnDestroy(): void {
    //this.isLoading = false;
    this.userFacade.resetErrorMsg();
  }
}
