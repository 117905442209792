import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environments';
import { RolePermissions } from '../models/roles/role-permissions.model';
import { CreateRoleRequest } from '../models/roles/create-role-request.model';
import { UpdateRoleRequest } from '../models/roles/update-role-request.model';
import { Roles } from '../models/roles/roles.model';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  private readonly BASE_URL = 'baseUrl';
  private url: string;

  constructor(private httpClient: HttpClient) {
    this.url = environment.baseUrl;
  }

  getRoles() {
    return this.httpClient.get<any>(this.url + `v2/roles`);
  }

  getRoleById(roleId: string) {
    return this.httpClient.get<Roles>(this.url + `v2/roles/` + roleId);
  }

  getPermissions() {
    return this.httpClient.get<RolePermissions[]>(
      this.url + `v2/functions/autocomplete?query=&limit=100`,
    );
  }

  createNewRole(newRole: CreateRoleRequest) {
    return this.httpClient.post(this.url + `v2/roles`, newRole);
  }

  updateRole(updatedRole: UpdateRoleRequest) {
    return this.httpClient.put(
      this.url + `v2/roles/` + updatedRole.id,
      updatedRole,
    );
  }

  deleteRole(roleId: string) {
    return this.httpClient.delete(this.url + `v2/roles/` + roleId);
  }
}
