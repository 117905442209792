<div class="label-container">
    <div>
        <label class="clickable-label" routerLink="/dashboard/explore"> Rap Almanac </label>
        <img src="../assets/images/filled.svg" />
    </div>

    <div>
        <label class="non-clickable-label">{{subTitle}}</label>
        <img src="../assets/images/filled.svg" />
    </div>
    <div>
        <label class="clickable-label" (click)="onCancel()">Albums</label>
        <!-- <img src="../assets/images/filled.svg" /> -->
    </div>
    <!-- <label class="clickable-label" routerLink="/dashboard/roles">Roles</label> -->
    <!-- <div *ngIf="albumId !== null">
        <img src="../assets/images/filled.svg" />
        <label class="non-clickable-label">{{ albumId }}</label>
    </div> -->
    <div>
        <img src="../assets/images/filled.svg" />
        <label class="non-clickable-label" style="text-decoration: none;">{{ isNew === true ? 'New' : 'Edit' }}</label>
    </div>
</div>
<div class="functions-section">
    <div class="section-title">
        {{ title }}
    </div>
    <div class="card">
        <div class="card-body">
            <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
            <form [formGroup]="albumForm" (ngSubmit)="addUpdateAlbum()">
                <div class="form-group">
                    <label for="album">Album<span class="required">*</span></label>
                    <div class="input-container">
                        <input id="album" class="search-input" formControlName="album" type="text"
                            [ngClass]="{'is-invalid': albumForm.get('album').invalid && albumForm.get('album').touched}">
                    </div>
                    <div class="error" *ngIf="albumForm.get('artist').invalid && albumForm.get('artist').touched">
                        Album name is required.
                    </div>
                </div>
                <div class="form-group">
                    <label for="artist">Artist<span class="required">*</span>:</label>
                    <!-- <div class="multi-dropdown"> -->
                    <!-- <ng-multiselect-dropdown [placeholder]="'Select...'" [settings]="settings" [data]="artistLst"
                            [(ngModel)]="albumForm.value.artist" [formControl]="albumForm.get('artist')">
                        </ng-multiselect-dropdown> -->

                    <ng-select [items]="artistLst" bindLabel="label" placeholder="Select.."
                        [(ngModel)]="albumForm.value.artist" class="custom-ng-select"
                        [formControl]="albumForm.get('artist')">
                    </ng-select>

                    <!-- </div> -->

                    <!-- <div class="error" *ngIf="albumForm.get('artist').invalid">
                        Artist is a required field
                    </div> -->
                </div>

                <div class="form-group" style="padding-top: 8px;">
                    <label for="releaseDate">Release date<span class="required">*</span></label>
                    <div class="input-container">
                        <input id="releaseDate" formControlName="releaseDate" type="date" class="search-input"
                            style="padding: 2px 0px;"
                            [ngClass]="{'is-invalid': albumForm.get('releaseDate').invalid && albumForm.get('releaseDate').touched}">
                    </div>
                    <div class="error"
                        *ngIf="albumForm.get('releaseDate').invalid && albumForm.get('releaseDate').touched">
                        Release date is required.
                    </div>
                </div>
                <div class="form-group">
                    <label for="unitsSold">Units sold</label>
                    <div class="input-container">
                        <input id="unitsSold" class="search-input" formControlName="unitsSold" type="number" step="1"
                            [ngClass]="{'is-invalid': albumForm.get('unitsSold').invalid && albumForm.get('unitsSold').touched}">
                    </div>

                </div>

                <div class="form-group">
                    <label for="recordLabel">Record label</label>
                    <div class="input-container">
                        <input id="recordLabel" class="search-input" formControlName="recordLabel" type="text"
                            [ngClass]="{'is-invalid': albumForm.get('recordLabel').invalid && albumForm.get('recordLabel').touched}">
                    </div>

                </div>
                <div class="form-group">

                </div>


                <div class="action-btns">
                    <rap-rect-primary [label]="'Save'" [btnClass]="'btn btn-dark rect-primary-btn1'"
                        (clickEvent)="addUpdateAlbum()"></rap-rect-primary>
                    <rap-rect-primary [label]="'Reset'" [btnClass]="'btn btn-light rect-secondary-btn1'"
                        (clickEvent)="onReset()"></rap-rect-primary>
                    <rap-rect-primary [label]="'Cancel'" [btnClass]="'btn btn-light rect-secondary-btn1'"
                        (clickEvent)="onCancel()"></rap-rect-primary>
                </div>
            </form>
        </div>
    </div>
</div>