import { Component } from '@angular/core';
import { FunctionsFacade } from '../store/functions.facade';
import { CreateFunctionRequest } from 'src/app/models/functions/create-function-request.model';
import { UpdateFunctionRequest } from 'src/app/models/functions/update-function-request.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'rap-add-edit-function',
  templateUrl: './add-edit-functions.container.component.html',
})
export class AddEditFunctionsContainerComponent {
  editableFunction$ = this.functionsFacade.editableFunction$;
  errorMessage$ = this.functionsFacade.errorMessage$;
  title = 'Add Function';
  constructor(private functionsFacade: FunctionsFacade, private route: ActivatedRoute,) {
    this.route.params.subscribe((params) => {
      if(params['id']) {
        this.title = 'Edit Function';
        this.functionsFacade.getFunctionById(params['id']);
      }
    });
  }

  createNewFunction(func: CreateFunctionRequest) {
    this.functionsFacade.createNewFunction(func);
  }

  updateFunction(func: UpdateFunctionRequest) {
    this.functionsFacade.updateFunction(func);
  }

  ngOnDestroy() {
    this.functionsFacade.resetEditableFunction();
    this.functionsFacade.clearErrorMessage();
  }
}
