<!-- <rap-breadcrumb-view-new [routerLink]="'/dashboard/explore'" [clickableLabel]="'Rap Almanac'"
    [clickableIcon]="'../assets/images/filled.svg'" [title]="title" [nonClickableLabel]="'Producers'"
    [nonClickableIcon]="'../assets/images/filled.svg'">
</rap-breadcrumb-view-new> -->
<div class="label-container">
    <div>
        <label class="clickable-label" routerLink="/dashboard/explore"> Rap Almanac </label>
        <img src="../assets/images/filled.svg" />
    </div>

    <div>
        <label class="non-clickable-label">{{title}}</label>
        <img src="../assets/images/filled.svg" />
    </div>
    <div>
        <label class="non-clickable-label" style="text-decoration: none;">Producers</label>
    </div>

</div>
<div class="section-body">
    <div class="section-title">Producers</div>
    <button type="button" class="btn btn-dark add-song-btn" (click)="createProducerEmitter.emit()"
        style="margin-bottom: 20px;">
        Add New
    </button>

    <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
</div>
<rap-search-input [(searchValue)]="filters.search" (search)="getSearchResults($event)"
    (clear)="onClearSearch()"></rap-search-input>
<div style="padding: 0px 10px;">
    <ng-container *ngIf="producersData?.length > 0">
        <div class="custom-container">
            <div class="songs-table" *ngIf="producersData?.length > 0">
                <table class="table rounded-4 overflow-hidden">
                    <thead>
                        <tr>
                            <th scope="col">
                                <div class="sortable">
                                    <div (click)="onSort({ column: 'producerid', direction: sortDirection })">PRODUCER
                                        ID
                                    </div>
                                    <rap-sort-arrows [column]="'producerid'" [sortedColumn]="sortedColumn"
                                        [sortDirection]="sortDirection" (sort)="sortBy($event)">
                                    </rap-sort-arrows>
                                </div>
                            </th>
                            <!-- <th scope="col">Name</th> -->
                            <th scope="col">
                                <div class="sortable">
                                    <div (click)="onSort({ column: 'name', direction: sortDirection })">NAME</div>
                                    <rap-sort-arrows [column]="'name'" [sortedColumn]="sortedColumn"
                                        [sortDirection]="sortDirection" (sort)="sortBy($event)">
                                    </rap-sort-arrows>
                                </div>
                            </th>
                            <th scope="col">ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let producer of paginatedProducers">
                            <td scope="row">{{ producer?.producerid }}</td>
                            <td scope="row">{{ producer?.name }}</td>
                            <td>
                                <button type="button" class="btn btn-light rect-secondary-btn"
                                    (click)="editProducer(producer)">
                                    Edit
                                </button>
                                <button type="button" class="btn btn-dark rect-primary-btn"
                                    (click)="deleteProducer(producer.producerid)">
                                    Delete
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="pagination">
                    <rap-pagination [collectionSize]="pagesCount" [page]="page" [pageSize]="pageSize"
                        (onPageChange)="onPageChange($event)"></rap-pagination>
                </div>
            </div>
        </div>

    </ng-container>
</div>


<rap-no-data [data]="producersData" [isLoading]="isLoading" [filters]="filters" [message]="'No producers found'"
    [view]=""></rap-no-data>