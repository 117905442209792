<div class="label-container">
    <div>
        <label class="clickable-label" routerLink="/dashboard/explore"> Rap Almanac </label>
        <img src="../assets/images/filled.svg" />
    </div>

    <div>
        <label class="non-clickable-label">{{subTitle}}</label>
        <img src="../assets/images/filled.svg" />
    </div>
    <div>
        <label class="clickable-label" (click)="onCancel()">Songs</label>
        <!-- <img src="../assets/images/filled.svg" /> -->
    </div>

    <div>
        <img src="../assets/images/filled.svg" />
        <label class="non-clickable-label" style="text-decoration: none;"> New</label>
    </div>
</div>

<div class="functions-section">
    <div class="section-title">
        {{ title }}
    </div>
    <div class="error-container" *ngIf="errorMessage">
        {{ errorMessage }}
    </div>
    <div class="card">
        <div class="card-body">
            <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
            <form [formGroup]="songForm" (ngSubmit)="addUpdateSong()">
                <div class="form-group">
                    <label for="title">Title<span class="required">*</span>:</label>
                    <div class="input-container">
                        <input type="text" class="search-input" [class.submitted]="submitted" formControlName="title" />
                    </div>
                    <div *ngIf="submitted && f['title'].errors" class="rap-error-text">
                        <span *ngIf="f['title'].errors?.['required']">Title is required</span>
                    </div>
                </div>
                <div class="form-group">
                    <label for="lyrics">Lyrics<span class="required">*</span>:</label>
                    <div class="input-container">
                        <input type="text" class="search-input" [class.submitted]="submitted"
                            formControlName="lyrics" />
                    </div>
                    <div *ngIf="submitted && f['lyrics'].errors" class="rap-error-text">
                        <span *ngIf="f['lyrics'].errors?.['required']">Lyrics is required</span>
                    </div>
                </div>
                <div class="form-group">
                    <label for="typedBy">Typed by</label>
                    <div class="input-container-read">
                        <input type="text" class="search-input-read" [class.submitted]="submitted"
                            formControlName="typedBy" [readonly]="true" style="width: 100%;" />
                    </div>
                </div>
                <div class="form-group" style="padding-top: 8px;">
                    <label for="tags">Tags</label>
                    <div class="input-container">
                        <input type="text" class="search-input" [class.submitted]="submitted" formControlName="tags" />
                    </div>
                </div>
                <div class="form-group">
                    <label for="feature">Feature</label>
                    <div class="input-container">
                        <input type="text" class="search-input" [class.submitted]="submitted"
                            formControlName="feature" />
                    </div>
                </div>
                <!-- <div class="form-group">
                    <label for="album">Album</label>
                    <div class="input-container">
                        <select class="form-control" formControlName="album" [class.submitted]="submitted">
                            <option value="" disabled selected>Select Album</option>
                            <option *ngFor="let album of albums" [value]="album.id">
                                {{ album.label }}
                            </option>
                        </select>
                    </div>
                </div> -->
                <div class="form-group">
                    <label for="album">Album</label>
                    <!-- <div class="multi-dropdown">
                        <ng-multiselect-dropdown [placeholder]="'Select...'" [settings]="albumSettings" [data]="albums"
                            [(ngModel)]="songForm.value.album" [formControl]="songForm.get('album')">
                        </ng-multiselect-dropdown>

                    </div> -->

                    <ng-select [items]="albums" bindLabel="label" placeholder="Select.."
                        [(ngModel)]="songForm.value.album" class="custom-ng-select"
                        [formControl]="songForm.get('album')">
                    </ng-select>
                </div>

                <div class="form-group">
                    <label for="artist" style="margin-top: 10px;">Artist<span class="required">*</span>:</label>
                    <!-- <div class="multi-dropdown">
                        <ng-multiselect-dropdown [placeholder]="'Select...'" [settings]="settings" [data]="artists"
                            [(ngModel)]="songForm.value.artist" [formControl]="songForm.get('artist')">
                        </ng-multiselect-dropdown>

                    </div> -->
                    <ng-select [items]="artists" bindLabel="label" placeholder="Select.."
                        [(ngModel)]="songForm.value.artist" class="custom-ng-select"
                        [formControl]="songForm.get('artist')">
                    </ng-select>

                    <div class="error" *ngIf="submitted && songForm.get('artist').invalid">
                        Artist is a required field
                    </div>
                </div>
                <div class="form-group">
                    <label for="producer" style="margin-top: 10px;">Producer</label>
                    <ng-select [items]="producers" bindLabel="label" placeholder="Select.."
                        [(ngModel)]="songForm.value.producer" class="custom-ng-select"
                        [formControl]="songForm.get('producer')">
                    </ng-select>
                    <!-- <div class="custom-select-wrapper">
                        <select formControlName="producer" [class.submitted]="submitted" class="custom-select"
                            [ngClass]="{'selected': true}">
                            <option value="" disabled selected>Select Producer</option>
                            <option *ngFor="let producer of producers" [value]="producer.id"
                                style="border: black; padding-left: 40px">
                                {{ producer.label }}
                            </option>
                        </select>
                        <i class="fa fa-chevron-down select-icon"></i>
                    </div> -->
                </div>

                <div class="form-group">
                    <label for="language" style="margin-top: 10px;">Language ISO<span class="required">*</span>:</label>
                    <!-- <div class="multi-dropdown">
                        <ng-multiselect-dropdown [placeholder]="'Select...'" [settings]="lanSettings" [data]="languages"
                            [(ngModel)]="songForm.value.language" [formControl]="songForm.get('language')">
                        </ng-multiselect-dropdown>

                    </div> -->

                    <ng-select [items]="languages" bindLabel="label" placeholder="Select.."
                        [(ngModel)]="songForm.value.language" class="custom-ng-select"
                        [formControl]="songForm.get('language')">
                    </ng-select>

                    <div class="error" *ngIf="submitted &&songForm.get('language').invalid">
                        Language is a required field
                    </div>
                </div>

                <!-- <div class="form-group">
                    <label for="language">Language ISO</label>
                    <div class="input-container">
                        <select class="form-control" formControlName="language" [class.submitted]="submitted">
                            <option value="" disabled selected>Select Language</option>
                            <option *ngFor="let artist of artists" [value]="artist.id">
                                {{ artist.label }}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="submitted && f['language'].errors" class="rap-error-text">
                        <span *ngIf="f['language'].errors?.['required']">Language is required</span>
                    </div>
                </div> -->

                <div class="form-group">
                    <label for="active" style="margin-top: 10px;">Explicit</label>
                    <div class="checkbox-container">
                        <input class="form-check-input" formControlName="explicit" type="checkbox" value=""
                            id="flexCheckDefault" class="custom-checkbox" />
                    </div>
                </div>


                <div class="action-btns">

                    <rap-rect-primary [label]="'Save'" [btnClass]="'btn btn-dark rect-primary-btn1'"
                        (clickEvent)="addUpdateSong()"></rap-rect-primary>
                    <rap-rect-primary [label]="'Reset'" [btnClass]="'btn btn-light rect-secondary-btn1'"
                        (clickEvent)="onReset()"></rap-rect-primary>
                    <rap-rect-primary [label]="'Cancel'" [btnClass]="'btn btn-light rect-secondary-btn1'"
                        (clickEvent)="onCancel()"></rap-rect-primary>

                </div>

            </form>
        </div>
    </div>
</div>