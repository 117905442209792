import { Song } from 'src/app/models/songs/song.model';
import { DropdownValues } from 'src/app/users/store/users.state';

export interface SongsState {
  songs: Song[];
  albums: DropdownValues[];
  artists: any;
  producers: DropdownValues[];
  editableSong: Song | null;
  errorMessage: string | null;
}

export const initialSongsState: SongsState = {
  songs: [],
  albums: [],
  artists: [],
  producers: [],
  editableSong: null,
  errorMessage: null,
};
