import { Component } from '@angular/core';
import { RolesFacade } from './store/roles.facade';
import { Router } from '@angular/router';

@Component({
  selector: 'rap-roles',
  templateUrl: './roles.container.component.html',
  styleUrls: ['./roles.container.component.scss'],
})
export class RolesContainerComponent {
  roles$ = this.rolesFacade.roles$;

  constructor(
    private rolesFacade: RolesFacade,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getRoles();

  }

  getRoles() {
    this.rolesFacade.getRoles();
  }

  createRole() {
    this.rolesFacade.resetEditableRole();
    this.router.navigate(['dashboard/roles/add']);
  }

  editRole(role) {
    this.router.navigate(['dashboard/roles/' + role.id]);
  }

  deleteRole(id: string) {
    this.rolesFacade.deleteRole(id);
  }
}
