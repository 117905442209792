<div class="explore-section">
  <div class="section-title">{{title}}</div>
  <div class="filters">
    <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
    <div class="filter">
      <button class="filter-btn" [ngClass]="{'active': showReset === false}" style="padding: 7px 25px;">All</button>
    </div>
    <!-- Mood -->
    <div class="filter" *ngIf="permissionsMap.AdvancedSearch === 1">
      <button class="filter-btn" [ngClass]="{'active': selectedMoods.length > 0}" (click)="showDropdownFilters('mood')">
        Mood
        <img [src]="selectedMoods.length > 0 ? '../assets/images/filled-up.svg' : '../assets/images/filled-down.svg'" />

      </button>
      <div class="dropdown-container" *ngIf="dropdownFilter.showMoodDropdown">
        <div class="dropdown-header">
          <span>Mood</span>
          <!-- <button (click)="showDropdownFilters('mood')" type="button" class="btn"
            style="font-size: 13px;font-style: normal;font-weight: 600;">
            Cancel
          </button> -->
          <rap-rect-primary [label]="'Cancel'" [btnClass]="'btn btn-light rect-secondary-btn'"
            (clickEvent)="showDropdownFilters('mood')"></rap-rect-primary>
        </div>
        <div class="dropdown-content">
          <!-- Joy -->
          <div class="mood-filter">
            <div style="display: flex;align-items: center;gap: 8px; padding-bottom: 5px;">
              <input [checked]="moodFilters[0].checked" type="checkbox" name="mood" id="joy" value="Joy"
                (change)="handleMoodChange($event, 'joy')" />
              <label class="form-check-label" for="joy">Joy</label>
            </div>
            <i *ngIf="!moodFilters[0].checked" class="bi bi-chevron-right"></i>
            <!-- <i *ngIf="moodFilters[0].checked" class="bi bi-chevron-down"></i> -->
            <img *ngIf="moodFilters[0].checked" src="../assets/images/chevron-down.svg" />
          </div>
          <ng-container *ngIf="moodFilters[0].checked">
            <div class="range-slider-container">
              <!-- <div class="range-slider-labels">
                <span>Very Low</span>
                <span>Very High</span>
              </div> -->

              <ng5-slider [(value)]="moodFilters[0].range.value" [(highValue)]="moodFilters[0].range.highValue"
                [options]="sliderOptions" style="height: 8px;"></ng5-slider>

            </div>
          </ng-container>
          <!-- Anger -->
          <div class="mood-filter">
            <div style="display: flex;align-items: center;gap: 8px; padding-bottom: 5px;">
              <input [checked]="moodFilters[1].checked" type="checkbox" name="mood" id="anger" value="Anger"
                (change)="handleMoodChange($event, 'anger')" />
              <label class="form-check-label" for="anger">Anger</label>
            </div>
            <i *ngIf="!moodFilters[1].checked" class="bi bi-chevron-right"></i>
            <!-- <i *ngIf="moodFilters[1].checked" class="bi bi-chevron-down"></i> -->
            <img *ngIf="moodFilters[1].checked" src="../assets/images/chevron-down.svg" />
          </div>
          <ng-container *ngIf="moodFilters[1].checked">
            <div class="range-slider-container">
              <!-- <div class="range-slider-labels">
                <span>Very Low</span>
                <span>Very High</span>
              </div> -->
              <ng5-slider [(value)]="moodFilters[1].range.value" [(highValue)]="moodFilters[1].range.highValue"
                [options]="sliderOptions"></ng5-slider>
            </div>
          </ng-container>
          <!-- Disgust -->
          <div class="mood-filter">
            <div style="display: flex;align-items: center;gap: 8px; padding-bottom: 5px;">
              <input [checked]="moodFilters[2].checked" type="checkbox" name="mood" id="disgust" value="Disgust"
                (change)="handleMoodChange($event, 'disgust')" />
              <label class="form-check-label" for="disgust">Disgust</label>
            </div>
            <i *ngIf="!moodFilters[2].checked" class="bi bi-chevron-right"></i>
            <!-- <i *ngIf="moodFilters[2].checked" class="bi bi-chevron-down"></i> -->
            <img *ngIf="moodFilters[2].checked" src="../assets/images/chevron-down.svg" />
          </div>
          <ng-container *ngIf="moodFilters[2].checked">
            <div class="range-slider-container">
              <!-- <div class="range-slider-labels">
                <span>Very Low</span>
                <span>Very High</span>
              </div> -->
              <ng5-slider [(value)]="moodFilters[2].range.value" [(highValue)]="moodFilters[2].range.highValue"
                [options]="sliderOptions"></ng5-slider>
            </div>
          </ng-container>
          <!-- sadness -->
          <div class="mood-filter">
            <div style="display: flex;align-items: center;gap: 8px; padding-bottom: 5px;">
              <input [checked]="moodFilters[3].checked" type="checkbox" name="mood" id="sadness" value="Sadness"
                (change)="handleMoodChange($event, 'sadness')" />
              <label class="form-check-label" for="sadness">Sadness</label>
            </div>
            <i *ngIf="!moodFilters[3].checked" class="bi bi-chevron-right"></i>
            <!-- <i *ngIf="moodFilters[3].checked" class="bi bi-chevron-down"></i> -->
            <img *ngIf="moodFilters[3].checked" src="../assets/images/chevron-down.svg" />
          </div>
          <ng-container *ngIf="moodFilters[3].checked">
            <div class="range-slider-container">
              <!-- <div class="range-slider-labels">
                <span>Very Low</span>
                <span>Very High</span> -->
              <!-- </div> -->
              <ng5-slider [(value)]="moodFilters[3].range.value" [(highValue)]="moodFilters[3].range.highValue"
                [options]="sliderOptions"></ng5-slider>
            </div>
          </ng-container>
          <!-- Fear -->
          <div class="mood-filter">
            <div style="display: flex;align-items: center;gap: 8px; padding-bottom: 5px;">
              <input [checked]="moodFilters[4].checked" type="checkbox" name="mood" id="fear" value="Fear"
                (change)="handleMoodChange($event, 'fear')" />
              <label class="form-check-label" for="fear">Fear</label>
            </div>
            <i *ngIf="!moodFilters[4].checked" class="bi bi-chevron-right"></i>
            <!-- <i *ngIf="moodFilters[4].checked" class="bi bi-chevron-down"></i> -->
            <img *ngIf="moodFilters[4].checked" src="../assets/images/chevron-down.svg" />
          </div>
          <ng-container *ngIf="moodFilters[4].checked">
            <div class="range-slider-container">
              <!-- <div class="range-slider-labels">
                <span>Very Low</span>
                <span>Very High</span>
              </div> -->
              <ng5-slider [(value)]="moodFilters[4].range.value" [(highValue)]="moodFilters[4].range.highValue"
                [options]="sliderOptions"></ng5-slider>
            </div>
          </ng-container>
        </div>
        <hr />
        <div class="dropdown-footer">
          <rap-rect-primary [label]="'Reset'" [btnClass]="'btn btn-light rect-secondary-btn'"
            (clickEvent)="resetFilters('mood')"></rap-rect-primary>
          <rap-rect-primary [label]="'Apply'" [btnClass]="'btn btn-dark rect-primary-btn'"
            (clickEvent)="searchWithFilter('mood')"></rap-rect-primary>
        </div>
      </div>
    </div>
    <!-- Keyword -->
    <div class="filter">
      <button class="filter-btn" [ngClass]="{'active': (isSelectedExcludeKeywords || isSelectedKeywords)}"
        (click)="showDropdownFilters('keyword')">
        Key Word
        <img
          [src]="(isSelectedExcludeKeywords || isSelectedKeywords) ? '../assets/images/filled-up.svg' : '../assets/images/filled-down.svg'" />
      </button>
      <div class="dropdown-container" *ngIf="dropdownFilter.showKeyWordDropdown">
        <div class="dropdown-header">
          <span>Key word</span>
          <!-- <button (click)="showDropdownFilters('keyword')" type="button" class="btn btn btn-light"
            >
            Cancel
          </button> -->
          <rap-rect-primary [label]="'Cancel'" [btnClass]="'btn btn-light rect-secondary-btn'"
            (clickEvent)="showDropdownFilters('keyword')"></rap-rect-primary>
        </div>
        <div class="dropdown-content">
          <div class="form-group">
            <label for="">
              <span class="label-main">Include key word</span> <br>
              <span class="label-hint">(Hint: press enter to apply)</span>
            </label>
            <div class="input-container">
              <input type="text" class="search-input" [(ngModel)]="includeKeyword" (keydown)="onKeydownInclude($event)"
                placeholder="Enter a key word" />
              <div class="auth-icons">
                <!-- <button class="search-clear-button" *ngIf="includeKeyword">
                  ADD
                </button> -->
                <i class="bi bi-plus-circle-fill" style="margin-left: 10px;" *ngIf="includeKeyword"
                  (click)="onIncludeAdd()"></i>
              </div>
            </div>
            <div class="keyword-list">
              <div *ngFor="let keyword of includeKeywords" class="keyword-item">
                {{ keyword }}
                <span (click)="removeKeyword(keyword)" class="remove-keyword">&times;</span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="">
              <span class="label-main">Exclude key word</span> <br>
              <span class="label-hint">(Hint: press enter to apply)</span>
            </label>
            <div class="input-container">
              <input type="text" class="search-input" [(ngModel)]="excludeKeyword" (keydown)="onKeydownExclude($event)"
                placeholder="Enter a key word" />
              <div class="auth-icons">
                <!-- <button class="search-clear-button" *ngIf="includeKeyword">
                                  ADD
                                </button> -->
                <i class="bi bi-plus-circle-fill" style="margin-left: 10px;" *ngIf="excludeKeyword"
                  (click)="onExcludeAdd()"></i>
              </div>
            </div>
            <div class="keyword-list">
              <div *ngFor="let keyword of excludeKeywords" class="keyword-item">
                {{ keyword }}
                <span (click)="removeKeywordExlude(keyword)" class="remove-keyword">&times;</span>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="dropdown-footer">
          <rap-rect-primary [label]="'Reset'" [btnClass]="'btn btn-light rect-secondary-btn'"
            (clickEvent)="resetFilters('keyword')"></rap-rect-primary>
          <rap-rect-primary [label]="'Apply'" [btnClass]="'btn btn-dark rect-primary-btn'"
            (clickEvent)="searchWithFilter('keyword')"></rap-rect-primary>
        </div>
      </div>
    </div>
    <!-- Location -->
    <div class="filter">
      <button class="filter-btn" [ngClass]="{'active': isSelectedCountry || isSelectedCity}"
        (click)="showDropdownFilters('location')">
        Location
        <img
          [src]="(isSelectedCountry || isSelectedCity) ? '../assets/images/filled-up.svg' : '../assets/images/filled-down.svg'" />
      </button>

      <div class="dropdown-container" *ngIf="dropdownFilter.showLocationDropdown">
        <div class="dropdown-header">
          <span>Location</span>

          <rap-rect-primary [label]="'Cancel'" [btnClass]="'btn btn-light rect-secondary-btn'"
            (clickEvent)="showDropdownFilters('location')"></rap-rect-primary>
        </div>

        <div class="dropdown-content">
          <div *ngFor="let loc of locations" class="dropdown-title">
            <div style="display: flex;align-items: center;gap: 2px; padding-bottom: 0px;">
              <input type="radio" [checked]="selectedCountry === loc.value" (change)="toggleLocation(loc)"
                class="styled-radio" />
              <label class="txt" style="margin-top: 2px;"> {{ loc.label }} </label>
            </div>


          </div>
        </div>
        <hr />
        <div>
          <div class="footer-content">
            <div class="input-container" style="padding: 0px 10px;">
              <input type="text" class="search-input" [(ngModel)]="citySearch" placeholder="Search city" />
              <div class="auth-icons-text">
                <button class="search-clear-button" *ngIf="citySearch" (click)="onSearchCity()">
                  Add
                </button>
                <!-- <i class="bi bi-x-circle-fill" style="margin-left: 10px;" *ngIf="citySearch"></i> -->
              </div>
              <!-- <div class="auth-icons">
                <i class="bi bi-x-circle-fill" *ngIf="citySearch" (click)="onCityClear()"></i>
              </div> -->
            </div>
            <div *ngIf="cityKeyword" style="padding: 0px 10px;">
              <span class="language-title"> {{cityKeyword}} </span>
              <i class="bi bi-x-lg" (click)="onCityClear()"></i>
            </div>
            <div class="custom-divider"></div>
            <div class="dropdown-footer">
              <!-- <button type="button" class="btn"
                style="font-size: 13px;font-style: normal;font-weight: 600;">Reset</button>
              <button class="primary footer-btn" (click)="searchWithFilter('location')">Apply</button> -->
              <rap-rect-primary [label]="'Reset'" [btnClass]="'btn btn-light rect-secondary-btn'"
                (clickEvent)="resetFilters('location')"></rap-rect-primary>
              <rap-rect-primary [label]="'Apply'" [btnClass]="'btn btn-dark rect-primary-btn'"
                (clickEvent)="searchWithFilter('location')"></rap-rect-primary>

            </div>
          </div>


        </div>
      </div>
    </div>
    <!-- Language -->
    <div class="filter">
      <button class="filter-btn" [ngClass]="{'active': isSelectedLan}" (click)="showDropdownFilters('language')">
        Language
        <!-- <i class="bi bi-chevron-down" class="list-view" [class.active]="isSelectedLan === true"></i> -->
        <img [src]="(isSelectedLan) ? '../assets/images/filled-up.svg' : '../assets/images/filled-down.svg'"
          class="list-view" [class.active]="isSelectedLan === true" style="color: red;" />
      </button>

      <div class="dropdown-container" *ngIf="dropdownFilter.showLanguageDropdown">
        <div class="dropdown-header">
          <span>Language</span>

          <rap-rect-primary [label]="'Cancel'" [btnClass]="'btn btn-light rect-secondary-btn'"
            (clickEvent)="showDropdownFilters('language')"></rap-rect-primary>
        </div>
        <div class="dropdown-content">
          <div *ngFor="let language of languages" class="dropdown-title">
            <div style="display: flex;align-items: center;gap: 8px; padding-bottom: 5px;">
              <input type="checkbox" [checked]="selectedLanguages.includes(language)"
                (change)="toggleLanguage(language)" />
              <a> {{
                language.label }} </a>
            </div>

          </div>
        </div>
        <hr />
        <div class="dropdown-footer">
          <!-- <button class="primary footer-btn" (click)="searchWithFilter('language')">
            Apply
          </button> -->
          <rap-rect-primary [label]="'Reset'" [btnClass]="'btn btn-light rect-secondary-btn'"
            (clickEvent)="resetFilters('language')"></rap-rect-primary>
          <rap-rect-primary [label]="'Apply'" [btnClass]="'btn btn-dark rect-primary-btn'"
            (clickEvent)="searchWithFilter('language')"></rap-rect-primary>
        </div>
      </div>
    </div>
    <!-- Time frame -->
    <div class="filter">
      <button class="filter-btn" [ngClass]="{'active': isTimeFrameSelected}" (click)="showDropdownFilters('timeFrame')">
        Time Frame
        <img [src]="(isTimeFrameSelected) ? '../assets/images/filled-up.svg' : '../assets/images/filled-down.svg'" />
      </button>

      <div class="dropdown-container" *ngIf="dropdownFilter.showTimeframeDropdown">
        <div class="dropdown-header">
          <span>Timeframe</span>
          <!-- <button (click)="showDropdownFilters('timeFrame')" type="button" class="btn"
            style="font-size: 13px;font-style: normal;font-weight: 600;">
            Cancel
          </button> -->
          <rap-rect-primary [label]="'Cancel'" [btnClass]="'btn btn-light rect-secondary-btn'"
            (clickEvent)="showDropdownFilters('timeFrame')"></rap-rect-primary>
        </div>
        <div class="dropdown-content">
          <div class="row">
            <div class="col">
              <label for="startDate">Start date</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" style="background-color: #E8E8E8;">
                    <i class="bi bi-calendar" style="background-color: #E8E8E8;"></i>
                  </span>
                </div>
                <input type="text" class="search-input" maxlength="4" placeholder="YYYY" id="startDate"
                  [(ngModel)]="startDate" style="background-color: #E8E8E8;" />
              </div>
            </div>
            <div class="col">
              <label for="endDate">End date</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" style="background-color: #E8E8E8;">
                    <i class="bi bi-calendar" style="background-color: #E8E8E8;"></i>
                  </span>
                </div>
                <input type="text" class="search-input" maxlength="4" placeholder="YYYY" id="endDate"
                  style="background-color: #E8E8E8;" [(ngModel)]="endDate" />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="dropdown-footer">
          <rap-rect-primary [label]="'Reset'" [btnClass]="'btn btn-light rect-secondary-btn'"
            (clickEvent)="resetFilters('timeframe')"></rap-rect-primary>
          <rap-rect-primary [label]="'Apply'" [btnClass]="'btn btn-dark rect-primary-btn'"
            (clickEvent)="searchWithFilter('timeFrame')"></rap-rect-primary>

        </div>
      </div>
    </div>
    <!-- change display -->
    <div class="toggle-container">
      <div class="toggle-button card-view" [class.active]="displayStyle == 'cards'"
        (click)="changeDisplayStyle('cards')">
        <i class="bi bi-ui-checks-grid"></i>
        <span>Cards</span>
      </div>
      <div class="toggle-button list-view" [class.active]="displayStyle == 'list'" (click)="changeDisplayStyle('list')">
        <i class="bi bi-list-task"></i>
        <span>List</span>
      </div>

    </div>
  </div>

  <!-- Total count text -->
  <div class="title-row">
    <div class="title" *ngIf="!isLoading && searchResults !== null">
      Discover over {{ searchResults['data']?.hits.length }} entries listed in Rap Almanac
    </div>
    <button class="btn btn-light rect-secondary-btn" *ngIf="showReset && !isLoading" (click)="onReset()">
      Reset
    </button>
  </div>

  <div class="applied-filters">
    <div *ngFor="let mood of selectedMoods let i = index">
      <div class="language-row">
        Mood :
        <span class="language-title"> {{mood.name }} </span>
        <i class="bi bi-x-lg" (click)="onSelectedMoodClear(i)"></i>
      </div>
    </div>
    <div class="language-row" *ngIf="startDate && isTimeFrameSelected">
      From :
      <span class="language-title"> {{startDate}} - {{endDate}} </span>
      <i class="bi bi-x-lg" (click)="onSelectedTimeClear()"></i>
    </div>
    <div class="language-row" *ngIf="selectedCountry && isSelectedCountry">
      Location :
      <span class="language-title"> {{ selectedCountry }} </span>
      <i class="bi bi-x-lg" (click)="onSelectedLocationClear()"></i>
    </div>
    <div class="language-row" *ngIf=" cityKeyword && isSelectedCity">
      City :
      <span class="language-title"> {{ cityKeyword }} </span>
      <i class="bi bi-x-lg" (click)="onSelectedCityClear()"></i>
    </div>
  </div>

  <div class="applied-filters">
    <ng-container *ngIf="isSelectedKeywords">
      <div *ngFor="let include of includeKeywords let i = index">
        <div class="language-row">
          Include :
          <span class="language-title"> {{include}} </span>
          <i class="bi bi-x-lg" (click)="onSelectedIncludeClear(i)"></i>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isSelectedExcludeKeywords">
      <div *ngFor="let exclude of excludeKeywords let i = index">
        <div class="language-row">
          Exclude :
          <span class="language-title"> {{exclude}} </span>
          <i class="bi bi-x-lg" (click)="onSelectedExcludeClear(i)"></i>
        </div>
      </div>
    </ng-container>

  </div>


  <div class="applied-filters" *ngIf="isSelectedLan">
    <div *ngFor="let lan of selectedLanguages let i = index">
      <div class="language-row">
        Language :
        <span class="language-title"> {{lan.label }} </span>
        <i class="bi bi-x-lg" (click)="onSelectedLanClear(i)"></i>
      </div>
    </div>
    <!-- <div class="language-row" *ngIf="selectedLanguages.length >=2 && isSelectedLan">
      Language :
      <span class="language-title"> {{selectedLanguages[0]}} ({{selectedLanguages.length - 1}})</span>
      <i class="bi bi-x-lg" (click)="onSelectedLanClear()"></i>
    </div>
    <div class="language-row" *ngIf="selectedLanguages.length ==1 && isSelectedLan">
      Language :
      <span class="language-title"> {{selectedLanguages[0] }} </span>
      <i class="bi bi-x-lg" (click)="onSelectedLanClear()"></i>
    </div> -->
    <!-- <div class="language-row" *ngIf="selectedMoods.length >=2">
      Mood :
      <span class="language-title"> {{selectedMoods[0].name}} ({{selectedMoods.length - 1}})</span>
      <i class="bi bi-x-lg" (click)="onSelectedMoodClear()"></i>
    </div>
    <div class="language-row" *ngIf="selectedMoods.length ==1">
      Mood :
      <span class="language-title"> {{selectedMoods[0].name }} </span>
      <i class="bi bi-x-lg" (click)="onSelectedMoodClear()"></i>
    </div> -->
    <!-- <div class="language-row" *ngIf="startDate && isTimeFrameSelected">
      From :
      <span class="language-title"> {{startDate}} - {{endDate}} </span>
      <i class="bi bi-x-lg" (click)="onSelectedTimeClear()"></i>
    </div>
    <div class="language-row" *ngIf="selectedCountry && isSelectedCountry">
      Location :
      <span class="language-title"> {{ selectedCountry }} </span>
      <i class="bi bi-x-lg" (click)="onSelectedLocationClear()"></i>
    </div> -->
    <!-- <div class="language-row" *ngIf="includeKeywords.length >=2 && isSelectedKeywords">
      Include :
      <span class="language-title"> {{includeKeywords[0]}} ({{includeKeywords.length - 1}})</span>
      <i class="bi bi-x-lg" (click)="onSelectedIncludeClear()"></i>
    </div>
    <div class="language-row" *ngIf="includeKeywords.length ==1 && isSelectedKeywords">
      Include :
      <span class="language-title"> {{includeKeywords[0] }} </span>
      <i class="bi bi-x-lg" (click)="onSelectedIncludeClear()"></i>
    </div>
    <div class="language-row" *ngIf="excludeKeywords.length >=2 && isSelectedExcludeKeywords">
      Exclude :
      <span class="language-title"> {{excludeKeywords[0]}} ({{excludeKeywords.length - 1}})</span>
      <i class="bi bi-x-lg" (click)="onSelectedExcludeClear()"></i>
    </div>
    <div class="language-row" *ngIf="excludeKeywords.length ==1 && isSelectedExcludeKeywords">
      Exclude :
      <span class="language-title"> {{excludeKeywords[0] }} </span>
      <i class="bi bi-x-lg" (click)="onSelectedExcludeClear()"></i>
    </div> -->
    <!-- <button class="btn btn-light"
      style=" align-self: self-end; background-color: #E8E8E8; border-radius: 15px; padding: 5px 20px; margin-left: 10px;"
      *ngIf="showReset" (click)="onReset()">
      Reset
    </button> -->
  </div>

  <rap-rect-primary
    *ngIf="searchResults && searchResults['data']?.hits.length !== 0 && this.permissionsMap.ExportCSV === 1"
    [label]="'Export CSV'" [btnClass]="'btn btn-dark '" (clickEvent)="exportCSV()"></rap-rect-primary>
  <!-- tabs -->
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs" (ngbNavChange)="onNavChange($event)">
    <!-- lyrics -->
    <li [ngbNavItem]="1">
      <!-- <div class="tooltip-container"> -->
      <button class="nav-link-btn" ngbNavLink>
        <!-- <i class="bi bi-chat-left-text"></i> -->
        <img src="../assets/images/message-text.svg" />
        <span>Lyrics</span>
        <!-- <div class="tooltip" *ngIf="showTooltip">Click me!</div> -->
      </button>
      <!-- </div> -->

      <ng-template ngbNavContent>
        <ng-container *ngIf="displayStyle == 'cards'; else dataInListStyle">
          <div class="cards" *ngIf="
              searchResults && searchResults['data']?.hits.length !== 0;
              else noSongsFound
            ">
            <div *ngFor="let song of paginatedSearchResults let i = index" style="padding-bottom: 30px;">
              <div class="lyrics">
                <div class="text-limit"> {{song.lyrics}}</div>
                <!-- <span style="color: green">rhyme</span> -->
                <!-- <div class="play-button">

                  <img src="../assets/images/bookmark.svg" />
                </div> -->
              </div>
              <div class="song-details">
                <div class="information">
                  <div class="song-title">{{song.title}}</div>
                  <div class="artist">{{song.artist_name}}</div>
                  <div class="album">{{song.artist.city}} {{song.artist.state}} {{song.artist.country}}</div>
                </div>
                <div class="controls">
                  <div class="play-button" style="cursor: pointer;" title="Search song online"
                    (click)="onPlayYouTube(song)">
                    <img src="../assets/images/play.svg" (click)="onPlayYouTube(song)" />
                  </div>

                  <div class="play-button" style="cursor: pointer;" title="Search lyrics online"
                    (click)="openGoogle(song)">
                    <img src="../assets/images/search.svg" (click)="openGoogle(song)" />
                  </div>

                  <!-- <div class="dropdown-share">
                    <div class="more-options" (click)="onShareGrid(i)">
                      <img src="../assets/images/dots-vertical.svg" style="cursor: pointer;" />
                    </div>
                    <div class="dropdown-content-share" [class.show]="activeDropdownGrid === i">
                      <button (click)="copyLink(song)">
                        <i class="bi bi-copy"></i>
                        Copy Song Link</button>
                      <button (click)="openGoogle(song)">
                        <i class="bi bi-search"></i>
                        Search lyrics</button>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="pagination" *ngIf="searchResults && searchResults['data']?.hits.length > 0">
            <rap-pagination [collectionSize]="searchResults['data']?.hits.length" [page]="page" [pageSize]="pageSize"
              (onPageChange)="onPageChange($event)"></rap-pagination>
          </div>
        </ng-container>
      </ng-template>
    </li>
    <!-- Map -->
    <li [ngbNavItem]="2" *ngIf="searchResults && searchResults['data']?.hits.length > 0">
      <div class="tooltip-container">
        <button class="nav-link-btn" ngbNavLink (click)="onMapTabClick()" (mouseover)="toggleTooltipMap(true)"
          (mouseleave)="toggleTooltipMap(false)">
          <!-- <i class="bi bi-map"></i> -->
          <img src="../assets/images/globe-02.svg" />
          <span>Map</span>
          <div class="tooltip" *ngIf="showTooltipMap">A geographic distribution of songs<br>
            that use the first search term entered;<br>
            mapped to the rapper's birthplace.
            <div class="tooltip-arrow"></div>
          </div>
        </button>
      </div>

      <ng-template ngbNavContent>
        <!-- <rap-map-tab [searchResults]="searchResults"></rap-map-tab> -->
        <div id="map" style="height: 500px; width: 100%;" leaflet [leafletOptions]="options2"
          (leafletMapReady)="onMapReady($event)" (leafletClick)="mapClicked($event)">
        </div>
      </ng-template>
    </li>
    <!-- Rankings -->
    <li [ngbNavItem]="3" *ngIf="barChartData.length > 0 && !isLoading">
      <div class="tooltip-container">
        <button class="nav-link-btn" ngbNavLink (click)="onMapTabClick()" (mouseover)="toggleTooltipBar(true)"
          (mouseleave)="toggleTooltipBar(false)">
          <img src="../assets/images/bar-chart-10.svg" />
          <!-- <i class="bi bi-lightning-charge"></i> -->
          <span>Rankings</span>
          <div class="tooltip" *ngIf="showTooltipBar">Usage frequencey for the<br>
            top five rappers who use <br>
            the first search term entered.
          </div>
        </button>
      </div>
      <ng-template ngbNavContent>

        <div class="grid-container">
          <!-- <div class="grid-item" *ngFor="let item of barChartData"></div> -->
          <div class="grid-item">
            <button type="button" style="padding: 8px 15px;
                border-radius: 4px;
                font-size: 13px;
                font-weight: 600;
                cursor: pointer;
                margin-top: auto;
                background-color: #000;
                align-self: flex-end; " (click)="onBarChartClick(barChartData[0])">
              Create Card
            </button>
            <h3 style="text-align: center;">{{ barChartData[0].word.toUpperCase() }}</h3>
            <div>
              <canvasjs-chart [options]="getChartOptions(barChartData[0])"
                [styles]="{ width: '100%', height:'300px'}"></canvasjs-chart>
            </div>

          </div>
        </div>
      </ng-template>
    </li>
    <!-- Word cloud -->
    <li [ngbNavItem]="4" *ngIf="timeLineChartData.length > 0 && !isLoading">
      <div class="tooltip-container">
        <button class="nav-link-btn" ngbNavLink (mouseover)="toggleTooltipTimeLine(true)"
          (mouseleave)="toggleTooltipTimeLine(false)">
          <!-- <i class="bi bi-cloud-lightning"></i> -->
          <img src="../assets/images/line-chart-up-01.svg" />
          <span>Time Line</span>
          <div class="tooltip" *ngIf="showTooltipTimeLine">Usage frequencey for the first <br>
            search term, over specified time range.<br>
          </div>
        </button>
      </div>
      <ng-template ngbNavContent>
        <div class="grid-container">
          <!-- <div class="grid-item" *ngFor="let item of barChartData"></div> -->
          <div class="grid-item">
            <button type="button" style="padding: 8px 15px;
                      border-radius: 4px;
                      font-size: 13px;
                      font-weight: 600;
                      cursor: pointer;
                      margin-top: auto;
                      background-color: #000;
                      align-self: flex-end; " (click)="onTimeLineChartClick(timeLineChartData[0])">
              Create Card
            </button>
            <h3 style="text-align: center;">{{ timeLineChartData[0].word.toUpperCase() }}</h3>
            <div>
              <!-- <highcharts-chart [Highcharts]="Highcharts" [options]="getChartOptionsTimeLine(timeLineChartData[0])"
                [styles]="{ width: '80%', height:'300px'}"> 
                  </highcharts-chart> -->
              <canvasjs-chart [options]="getChartOptionsTimeLine(timeLineChartData[0])"
                [styles]="{ width: '80%', height:'300px'}"></canvasjs-chart>

            </div>

          </div>
        </div>
      </ng-template>
    </li>
    <!-- Word cloud -->
    <li [ngbNavItem]="5" *ngIf="searchResults && searchResults['data']?.hits.length > 0 && !isLoading">
      <div class="tooltip-container">
        <button class="nav-link-btn" ngbNavLink (mouseover)="toggleTooltipVector(true)"
          (mouseleave)="toggleTooltipVector(false)">
          <!-- <i class="bi bi-map"></i> -->
          <img src="../assets/images/cube-outline.svg" />
          <span>Word Cloud</span>
          <div class="tooltip" *ngIf="showTooltipVector">Three dimensional representation of words<br>
            related to the first search term entered<br>
          </div>
        </button>
      </div>
      <ng-template ngbNavContent>
        <div class="grid-container">
          <!-- <div class="grid-item" *ngFor="let item of barChartData"></div> -->
          <div class="grid-item">
            <!-- <div class="chart-container">
              <div class="controls-section">
                <select id="centerSelect" [(ngModel)]="centerType" (change)="updateCenterType()">
                  <option value="centroid">Centroid</option>
                  <option value="query">Query Word</option>
                </select>
              </div>
            </div> -->
            <button type="button" style="padding: 8px 15px;
                                border-radius: 4px;
                                font-size: 13px;
                                font-weight: 600;
                                cursor: pointer;
                                margin-top: auto;
                                background-color: #000;
                                align-self: flex-end; " (click)="onWordCloudClick(wordCloudChartData[0])">
              Create Card
            </button>

            <div>
              <rap-word-count-chart [data]="wordCloudChartData[0]" [queryWord]="centerType"></rap-word-count-chart>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>

<ng-template #dataInListStyle>
  <!-- <div class="custom-container"> -->
  <div>
    <table class="table rounded-4 overflow-hidden custom-table" *ngIf="
      searchResults && searchResults['data']?.hits.length > 0;
      else noSongsFound
    ">
      <thead>
        <tr>
          <!-- <th scope="col">Title</th> -->
          <th scope="col">
            <div class="sortable">
              <div (click)="onSort({ column: 'title', direction: sortDirection })">Title</div>
              <rap-sort-arrows [column]="'title'" [sortedColumn]="sortedColumn" [sortDirection]="sortDirection"
                (sort)="sortBy($event)">
              </rap-sort-arrows>
            </div>
          </th>
          <!-- <th scope="col">Album</th> -->
          <th scope="col">
            <div class="sortable">
              <div (click)="onSort({ column: 'album', direction: sortDirection })">Album</div>
              <rap-sort-arrows [column]="'album'" [sortedColumn]="sortedColumn" [sortDirection]="sortDirection"
                (sort)="sortBy($event)">
              </rap-sort-arrows>
            </div>
          </th>
          <!-- <th scope="col">Artist</th> -->
          <th scope="col">
            <div class="sortable">
              <div (click)="onSort({ column: 'artist', direction: sortDirection })">Artist</div>
              <rap-sort-arrows [column]="'artist'" [sortedColumn]="sortedColumn" [sortDirection]="sortDirection"
                (sort)="sortBy($event)">
              </rap-sort-arrows>
            </div>
          </th>
          <th scope="col">Language</th>
          <!-- <th scope="col">Date</th> -->
          <th scope="col">
            <div class="sortable">
              <div (click)="onSort({ column: 'release_date', direction: sortDirection })">Date</div>
              <rap-sort-arrows [column]="'release_date'" [sortedColumn]="sortedColumn" [sortDirection]="sortDirection"
                (sort)="sortBy($event)">
              </rap-sort-arrows>
            </div>
          </th>
          <th scope="col">Location</th>
          <th scope="col">Lyrics</th>
          <!-- <th scope="col">Share</th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let song of paginatedSearchResults let i = index">
          <td scope="row">
            <div style="display: flex; align-items: start; gap: 10px;">
              <div class="play-button" style="cursor: pointer;" title="Search song online"
                (click)="onPlayYouTube(song)">
                <img src="../assets/images/play.svg" (click)="onPlayYouTube(song)" />
              </div>
              <div style="word-wrap: break-word; white-space: normal;">{{ song.title }}</div>
            </div>
          </td>

          <td style="max-width: 180px; word-wrap: break-word; white-space: normal;">
            {{ song.album.album }}
          </td>
          <td style="max-width: 100px; word-wrap: break-word; white-space: normal;">{{ song.artist.artist }}</td>

          <td>{{ getLanguageLabel(song.language_iso) }}</td>
          <td>{{ song.album.release_date ? (song.album.release_date | date: 'yyyy-MM-dd') : 'N/A' }}</td>

          <td>
            {{
            song.artist.city || song.artist.state || song.artist.country
            ? (song.artist.city || '') + ' ' + (song.artist.state || '') + ' ' + (song.artist.country || '')
            : 'N/A'
            }}
          </td>

          <td style="vertical-align: top;">
            <div style="max-width: 600px; word-wrap: break-word; white-space: normal;">
              {{ song.lyrics | slice:0:150 }}...
            </div>
          </td>

          <!-- <td>
            <div class="dropdown-share">
              <button type="button" class="btn btn-light rect-secondary-btn" (click)="onShare(i)">
                Share
                <i class="bi bi-share-fill"></i>
              </button>

              <div class="dropdown-content-share" [class.show]="activeDropdown === i">
                <button (click)="copyLink(song)">
                  <i class="bi bi-copy"></i>
                  Copy Song</button>
              </div>
            </div>

          </td> -->
        </tr>
        <tr *ngIf="searchResults.length === 0">
          <td colspan="4" style="text-align: center">No songs found</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="pagination" *ngIf="searchResults && searchResults['data']?.hits.length > 0">
    <rap-pagination [collectionSize]="searchResults['data']?.hits.length" [page]="page" [pageSize]="pageSize"
      (onPageChange)="onPageChange($event)"></rap-pagination>
  </div>
</ng-template>

<ng-template #noSongsFound>
  <div style="padding: 20px; text-align: center; margin-top: 100px;" *ngIf="!isLoading">
    <img src="../assets/images/question.svg" />
    <div class="no-match" *ngIf="searchTerm !== ''"> We didn’t find a match for {{searchTerm}}</div>
    <div class="no-match" *ngIf="searchTerm === ''"> Search by song or album title, lyrics or artist name</div>
    <div *ngIf="showReset">Try searching for something else instead or remove some filters.</div>
  </div>
</ng-template>