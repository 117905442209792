import { createAction, props } from '@ngrx/store';
import { CreateRoleRequest } from 'src/app/models/roles/create-role-request.model';
import { RolePermissions } from 'src/app/models/roles/role-permissions.model';
import { Roles } from 'src/app/models/roles/roles.model';
import { UpdateRoleRequest } from 'src/app/models/roles/update-role-request.model';

export const getPermissions = createAction('[Roles] GET_PERMISSIONS');

export const getPermissionsSuccess = createAction(
  '[Roles] GET_PERMISSIONS_SUCCESS',
  props<{ permissions: RolePermissions[] }>(),
);

export const resetEditableRole = createAction('[Roles] RESET_EDITABLE_ROLE');

export const getRoles = createAction('[Roles] GET_ROLES');

export const getRolesSuccess = createAction(
  '[Roles] GET_ROLES_SUCCESS',
  props<{ roles: Roles[] }>(),
);

export const getRoleById = createAction(
  '[Roles] GET_ROLE_BY_ID',
  props<{ roleId: string }>(),
);

export const getRoleByIdSuccess = createAction(
  '[Roles] GET_ROLE_BY_ID_SUCCESS',
  props<{ role: Roles }>(),
);

export const createNewRole = createAction(
  '[Roles] CREATE_NEW_ROLE',
  props<{ newRole: CreateRoleRequest }>(),
);

export const createNewRoleSuccess = createAction(
  '[Roles] CREATE_NEW_ROLE_SUCCESS',
);

export const updateRole = createAction(
  '[Roles] UPDATE_ROLE',
  props<{ updatedRole: UpdateRoleRequest }>(),
);

export const updateRoleSuccess = createAction('[Roles] UPDATE_ROLE_SUCCESS');

export const deleteRole = createAction(
  '[Roles] DELETE_ROLE',
  props<{ roleId: string }>(),
);

export const deleteRoleSuccess = createAction(
  '[Roles] DELETE_ROLE_SUCCESS',
  props<{ roleId: string }>(),
);

export const rolesError = createAction(
  '[Roles] ROLES_ERROR',
  props<{ errorMessage: string }>(),
);

export const clearErrorMessage = createAction('[Roles] ROLES_ERROR');
