import { createAction, props } from '@ngrx/store';
import { CreateRosterRequest } from 'src/app/models/rosters/create-roster-request.model';
import { Roster } from 'src/app/models/rosters/rosters.model';
import { UpdateRosterRequest } from 'src/app/models/rosters/update-roster-request.model';

export const getRosters = createAction('[Rosters] GET_ROSTERS', props<{ page: number, search: string, sortBy: string, order: string }>(),);

export const getRostersSuccess = createAction(
  '[Rosters] GET_ROSTERS_SUCCESS',
  props<{ rosters: any }>(),
);

export const getRosterById = createAction(
  '[Rosters] GET_ROSTER_BY_ID',
  props<{ rosterId: string }>(),
);

export const getRosterByIdSuccess = createAction(
  '[Rosters] GET_ROSTER_BY_ID_SUCCESS',
  props<{ roster: Roster }>(),
);

export const createNewRoster = createAction(
  '[Rosters] CREATE_NEW_ROSTER',
  props<{ newRoster: CreateRosterRequest }>(),
);

export const createNewRosterSuccess = createAction(
  '[Rosters] CREATE_NEW_ROSTER_SUCCESS',
);

export const deleteRoster = createAction(
  '[Roster] DELETE_ROSTER',
  props<{ rosterId: string }>(),
);

export const deleteRosterSuccess = createAction(
  '[Roster] DELETE_ROSTER_SUCCESS',
  props<{ rosterId: string }>(),
);

export const updateRoster = createAction(
  '[Roster] UPDATE_ROSTER',
  props<{ updatedRoster: UpdateRosterRequest }>(),
);

export const updateRosterSuccess = createAction(
  '[Roster] UPDATE_ROSTER_SUCCESS',
);

export const resetEditableRoster = createAction(
  '[Roster] RESET_EDITABLE_ROSTER',
);

export const rostersError = createAction(
  '[Roster] ROSTERS_ERROR',
  props<{ error: any }>(),
);

export const clearErrorMessage = createAction('[Roster] CLEAR_ERROR_MESSAGE');
