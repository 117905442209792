import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AnalyticsState } from './analytics.state';
import { AnalyticsSelectors } from './analytics.selectors';
import { deleteAnalytic, getAnalytics, viewAnalytic } from './analytics.action';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsFacade {
  analytics$ = this.analyticsSelectors.analytics$;
  errorMessage$ = this.analyticsSelectors.errorMessage$;
  viewAnalytics$ = this.analyticsSelectors.viewAnalytics$;
  constructor(
    private store: Store<AnalyticsState>,
    private analyticsSelectors: AnalyticsSelectors,
  ) { }

  getAnalytics() {
    this.store.dispatch(getAnalytics());
  }

  deleteAnalytic(analyticId: string) {
    this.store.dispatch(deleteAnalytic({ analyticId }));
  }

  viewAnalytic(analyticId: string) {
    this.store.dispatch(viewAnalytic({ analyticId }));
  }
}
