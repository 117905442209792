import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Function } from '../models/functions/functions.model';
import { Router } from '@angular/router';
import { DeleteModalComponent } from '../shared/component/delete-modal/delete-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../app.service';

@Component({
  selector: 'rap-functions-ui',
  templateUrl: './functions.component.html',
  styleUrls: ['./functions.component.scss'],
})
export class FunctionsComponent {
  @Input() functions: Function[];
  @Output() deleteFunctionEmitter = new EventEmitter<string>();
  @Output() editFunctionEmitter = new EventEmitter<Function>();
  page = 1;
  pageSize = 10;
  isLoading = false;
  functionsData: Function[] = [];
  filters: any = {
    search: ""
  };
  searchQuery = "";
  sortDirection: string = 'asc';
  sortedColumn: string = '';
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private loaderService: AppService,
  ) {
    this.loaderService.loading$.subscribe(isLoading => {
      console.log("isLoading")
      console.log(isLoading)
      this.isLoading = isLoading;
    });
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.functions = [];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['functions'] && this.functions) {
      this.isLoading = false;
      this.page = 1;
      this.functionsData = this.functions;
    }
  }

  get paginatedFunctions(): Function[] {
    const start = (this.page - 1) * this.pageSize;
    const end = start + this.pageSize;
    return this.functions.slice(start, end);
  }


  onSort({ column, direction }: { column: string, direction: 'asc' | 'desc' }) {
    console.log(column)
    if (this.sortedColumn === column) {
      // Toggle sort direction
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      // New column sorting
      this.sortedColumn = column;
      this.sortDirection = 'asc';
    }
    this.updateSortData()
  }

  sortBy({ column, direction }: { column: string, direction: 'asc' | 'desc' }) {
    this.sortedColumn = column;
    this.sortDirection = direction;
    this.updateSortData()
  }

  updateSortData() {
    this.functions = [...this.functions].sort((a, b) => {

      let aValue = String(a?.name ?? '');
      let bValue = String(b?.name ?? '');
      return this.sortDirection === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    });
  }


  onPageChange(page: number) {
    this.page = page;
  }

  addFunction() {
    this.router.navigate(['/functions/add']);
  }

  deleteFunction(functionId: string) {

    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.modalTitle = 'Confirm delete';
    modalRef.componentInstance.modalBodyText =
      'Are you sure you want to delete thie item ?';
    modalRef.componentInstance.itemId = functionId;
    modalRef.result
      .then((id) => {
        this.isLoading = true;
        this.deleteFunctionEmitter.emit(id);
      })
      .catch((error) => console.log(error));
  }

  editFunction(func: Function) {
    this.editFunctionEmitter.emit(func);
  }

  onFunctions() {

  }

  onAdd() {
    this.router.navigate(['/dashboard/functions/add']);
  }

  getSearchResults(search: string) {
    this.filters.search = search

    if (this.filters.search) {
      this.searchQuery = this.filters.search;
      var localArr = this.functions.filter(item =>
        item.name?.toLowerCase().includes(this.filters.search.toLowerCase())
      );
      console.log(localArr);
      this.functions = localArr;
    } else {
      this.functions = this.functionsData;
    }
  }

  onClearSearch() {
    this.filters.search = "";
    this.getSearchResults("")
  }

  ngOnDestroy(): void {
    this.functions = [];
    this.functionsData = [];
    this.isLoading = false;
  }
}
