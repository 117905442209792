<div class="modal-header">
  <h4 class="modal-title">{{ modalTitle }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p class="card-text">{{ modalBodyText }}</p>
</div>
<div class="modal-footer" *ngIf="moveToSignin">
  <button type="button" class="btn btn-outline-dark" (click)="gotoSigninAndCloseModal()">
    Back to login
  </button>
</div>