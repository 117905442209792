import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Song } from '../models/songs/song.model';
import { DeleteModalComponent } from '../shared/component/delete-modal/delete-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseTemplateService } from '../base-template/base-template.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '../app.service';

@Component({
  selector: 'rap-songs-ui',
  templateUrl: './songs.component.html',
  styleUrls: ['./songs.component.scss']
})
export class SongsComponent {
  @Input() songs: any;
  @Output() deleteSongEmitter = new EventEmitter<string>();
  @Output() createSongEmitter = new EventEmitter<any>();
  @Output() searchSongEmitter = new EventEmitter<{ search: string, page: number, sortBy: string, order: string }>();
  @Input() isContributions: boolean;
  page = 1;
  pageSize = 10;
  isLoading = false;
  filters: any = {
    search: ""
  };
  pagesCount = 0;
  songsData: Song[] = [];
  title = "Database";
  sortDirection: string = 'asc';
  sortedColumn: string = '';
  constructor(private modalService: NgbModal,
    private baseTemplateService: BaseTemplateService,
    private route: ActivatedRoute,
    private toastr: ToastrService, private loaderService: AppService) {
    this.loaderService.loading$.subscribe(isLoading => {
      console.log("isLoading")
      console.log(isLoading)
      this.isLoading = isLoading;
    });
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.baseTemplateService.showHideSearch(false);
    if (this.isContributions)
      this.title = "Contributions"

    this.route.data.subscribe(data => {
      console.log(data["isContributions"])
      if (data["isContributions"])
        this.title = "Contributions"
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['songs'] && this.songs) {
      this.isLoading = false;
      // this.page = 1;
      console.log(changes['songs']);
      this.songsData = this.songs.rows;
      console.log(this.songsData);
      console.log(this.pagesCount);
      if (this.page == 1)
        this.pagesCount = this.songs.count / 10;
    }
  }


  get paginatedSongs(): Song[] {
    const start = (this.page - 1) * this.pageSize;
    const end = start + this.pageSize;
    // console.log(start)
    // console.log(end)
    // console.log(this.songsData.slice(start, end))
    return this.songsData;
  }

  onSort({ column, direction }: { column: string, direction: 'asc' | 'desc' }) {
    console.log(column)
    if (this.sortedColumn === column) {
      // Toggle sort direction
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      // New column sorting
      this.sortedColumn = column;
      this.sortDirection = 'asc';
    }
    this.updateSortData()
  }

  sortBy({ column, direction }: { column: string, direction: 'asc' | 'desc' }) {
    this.sortedColumn = column;
    this.sortDirection = direction;
    this.updateSortData()
  }

  updateSortData() {
    this.isLoading = true;
    console.log(this.page)
    this.searchSongEmitter.emit({
      search: this.filters.search,
      page: this.page,
      sortBy: this.sortedColumn,
      order: this.sortDirection
    });
  }

  onPageChange(page: number) {
    this.page = page;
    console.log(this.page)
    this.isLoading = true;
    this.searchSongEmitter.emit({
      search: '',
      page: page,
      sortBy: this.sortedColumn,
      order: this.sortDirection
    });
  }

  deleteSong(songId: string) {
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.itemId = songId;
    modalRef.result
      .then((id) => {
        this.isLoading = true;
        this.deleteSongEmitter.emit(id);
      })
  }

  editSong(song: any) {
    this.toastr.success('Feature Coming Soon!!!', '', {
      positionClass: 'toast-top-center',
      closeButton: false,
    });
  }

  getSearchResults(search: string) {
    this.isLoading = true;
    this.filters.search = search
    this.searchSongEmitter.emit({
      search: this.filters.search,
      page: 1,
      sortBy: this.sortedColumn,
      order: this.sortDirection
    });
  }

  ngOnDestroy(): void {
    this.songs = [];
    this.songsData = [];
    this.isLoading = false;
    this.pagesCount = 0;
  }

  onClearSearch() {
    this.filters.search = "";
    this.getSearchResults('')
  }
}
