export interface ExploreState {
  searchResults: any;
  searchTerm: string;
  selectedTimeframe: any;
  selectedLanguage: any;
  selectedMoods: any;
  selectedLocation: any;
  selectedInclude: string[];
  selectedExclude: string[];
  selectedCity: any;
}

export const initialExploreState: ExploreState = {
  searchResults: null,
  searchTerm: '',
  selectedTimeframe: null,
  selectedLanguage: null,
  selectedMoods: null,
  selectedLocation: null,
  selectedInclude: null,
  selectedExclude: null,
  selectedCity: null
};
