import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BaseTemplateService {

  constructor() { }

  private searchSubject = new BehaviorSubject<string>('');
  search$ = this.searchSubject.asObservable();

  private showSearchSubject = new BehaviorSubject<boolean>(false);
  showSearch$ = this.showSearchSubject.asObservable();

  setSearchTerm(searchTerm: string) {
    this.searchSubject.next(searchTerm);
  }

  showHideSearch(show: boolean) {
    this.showSearchSubject.next(show);
  }
}
