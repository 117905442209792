import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, map } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class OnboardingPresenter {
  constructor(private formBuilder: FormBuilder, private authService: AuthService, private userService: UserService, private router: Router) { }

  // The form data is stored as a single object
  private formDataSubject = new BehaviorSubject<any>({
    section1: '',
    section2: '',
    section3: '',
    section4: {
      option: "Basic User", options: [
        "Basic Search",
        "Data Visualizations",
        "Social Media Preview Cards"]
    }
  });

  formData$ = this.formDataSubject.asObservable();

  initializeAuthForm() {
    console.log(this.authService.getUserId());
    return this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(8)]],
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      username: [null, [Validators.required]],
      dateOfBirth: [null, [Validators.required]],
    });
  }

  updateSection1(data: any) {

    const currentData = this.formDataSubject.value;
    this.formDataSubject.next({ ...currentData, section1: data });
  }

  updateSection2(data: any) {
    console.log(data)
    const currentData = this.formDataSubject.value;
    this.formDataSubject.next({ ...currentData, section2: data });
  }

  updateSection3(data: any) {
    const currentData = this.formDataSubject.value;
    this.formDataSubject.next({ ...currentData, section3: data });
  }

  updateSection4(data: any) {
    console.log(data)
    const currentData = this.formDataSubject.value;
    this.formDataSubject.next({ ...currentData, section4: data });
  }

  // Retrieve the current state of the form data
  getFormData() {
    return this.formDataSubject.value;
  }

  putOnboardingSkip(planOn: boolean) {
    console.log(this.authService.getUserId());
    console.log(this.formDataSubject.value.section1)
    console.log(this.formDataSubject.value.section2)
    console.log(this.formDataSubject.value.section3)
    console.log(this.formDataSubject.value.section4);

    var data: any = {
      "data": {
        "fieldOfStudy": this.formDataSubject.value.section1,
        "occupation": this.formDataSubject.value.section2,
        "referralSource": this.formDataSubject.value.section3,
        "skipOnboarding": true,
        "plans_on_registration_shown": planOn,
      }
    };
    console.log(data)
    this.userService.onBoardingSkip(data, this.authService.getUserId()).subscribe((result) => {
      console.log(result)
      this.router.navigate(['/dashboard/explore']).then(() => {
        history.replaceState(null, '', '/dashboard/explore');
      });
    });

  }

  putOnboardingPlan() {
    console.log(this.authService.getUserId());
    console.log(this.formDataSubject.value.section1)
    console.log(this.formDataSubject.value.section2)
    console.log(this.formDataSubject.value.section3)
    console.log(this.formDataSubject.value.section4);

    var data: any = {
      "data": {
        "fieldOfStudy": this.formDataSubject.value.section1,
        "occupation": this.formDataSubject.value.section2,
        "referralSource": this.formDataSubject.value.section3,
        "skipOnboarding": true,
      }
    };
    console.log(data)
    this.userService.onBoardingSkip(data, this.authService.getUserId()).subscribe((result) => {
      console.log(result)

    });

  }


}
