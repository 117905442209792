import { Injectable } from '@angular/core';
import { Store, createFeatureSelector, createSelector } from '@ngrx/store';
import { UsersState } from './users.state';

const getUsersState = createFeatureSelector<UsersState>('users');

const getUsers = createSelector(
  getUsersState,
  (state: UsersState) => state.users,
);

const getUsersCount = createSelector(
  getUsersState,
  (state: UsersState) => state.usersCount,
);

const getRoles = createSelector(
  getUsersState,
  (state: UsersState) => state.roles,
);

const getRosters = createSelector(
  getUsersState,
  (state: UsersState) => state.rosters,
);

const getEditableUser = createSelector(
  getUsersState,
  (state: UsersState) => state.editableUser,
);

const getErrorMessage = createSelector(
  getUsersState,
  (state: UsersState) => state.errorMessage,
);

@Injectable()
export class UsersSelectors {
  users$ = this.store.select(getUsers);
  editableUser$ = this.store.select(getEditableUser);
  roles$ = this.store.select(getRoles);
  rosters$ = this.store.select(getRosters);
  errorMessage$ = this.store.select(getErrorMessage);
  usersCount$ = this.store.select(getUsersCount);
  constructor(private store: Store<UsersState>) {}
}
