import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { LANG_LIST } from 'src/app/explore/explore.data';
import { CreateSongRequest } from 'src/app/models/songs/create-song-request.model';
import { Song } from 'src/app/models/songs/song.model';
import { AuthService } from 'src/app/services/auth.service';
import { DropdownValues } from 'src/app/users/store/users.state';
import { SongsService } from '../songs.service';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'rap-add-edit-song-ui',
  templateUrl: './add-edit-song.component.html',
  styleUrls: ['./add-edit-song.component.scss'],
})
export class AddEditSongComponent {
  @Input() title: string;
  @Input() albums: any;
  @Input() artists: any;
  @Input() producers: DropdownValues[];
  @Input() editableSong: Song;
  @Input() errorMessage: string;
  @Output() addSong = new EventEmitter<CreateSongRequest>();
  languages = LANG_LIST;
  songForm: FormGroup;
  @Input() songId: string;
  @Input() isContributions: boolean;
  submitted: boolean = false;
  public settings = {};
  public albumSettings = {};
  public lanSettings = {};
  isLoading = false;
  subTitle = "Database";
  constructor(private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private songService: SongsService,
    private loaderService: AppService) {
    this.loaderService.loading$.subscribe(isLoading => {
      console.log("isLoading")
      this.isLoading = isLoading;
    });
  }

  ngOnInit() {
    console.log(this.isContributions)

    if (this.isContributions) {
      this.subTitle = "Contributions";

    }
    this.isLoading = true;
    this.songForm = this.formBuilder.group({
      title: new FormControl(null, [Validators.required]),
      lyrics: new FormControl([], [Validators.required]),
      typedBy: new FormControl(this.authService.getUserName()),
      tags: new FormControl(''),
      feature: new FormControl([]),
      album: new FormControl([]),
      artist: new FormControl([], [Validators.required]),
      producer: new FormControl(),
      language: new FormControl([], [Validators.required]),
      explicit: new FormControl(false),
    });

    this.settings = {
      singleSelection: true,
      idField: 'id',
      textField: 'label',
      allowSearchFilter: true, // Allows search filter
      closeDropDownOnSelection: true, // Close dropdown after selection
      enableCheckAll: false,  // Disable "Select All" option
      itemsShowLimit: 1,      // Only show selected item in dropdown
      unSelectAllText: null,  // No "Unselect All" text
      selectAllText: null,    // No "Select All" text
      allowCheckboxes: false  // 
    };

    // this.albumSettings = {
    //   singleSelection: true,
    //   idField: 'id',
    //   textField: 'label',
    //   allowSearchFilter: true, // Allows search filter
    //   closeDropDownOnSelection: true, // Close dropdown after selection
    //   enableCheckAll: false,  // Disable "Select All" option
    //   itemsShowLimit: 1,      // Only show selected item in dropdown
    //   unSelectAllText: null,  // No "Unselect All" text
    //   selectAllText: null,    // No "Select All" text
    //   allowCheckboxes: false,  // 
    //   customClasses: {
    //     itemSelected: 'selected-item'
    //   }
    // };

    this.albumSettings = { singleSelection: true, idField: 'id', textField: 'label', allowSearchFilter: false, closeDropDownOnSelection: true, enableCheckAll: false, itemsShowLimit: 1, unSelectAllText: null, selectAllText: null, };

    this.lanSettings = {
      singleSelection: true,
      idField: 'value',
      textField: 'label',
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      enableCheckAll: false,
      itemsShowLimit: 1,
      unSelectAllText: null,
      selectAllText: null,
      allowCheckboxes: false
    };

    // if (title == )
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.editableSong) {
      this.songForm.patchValue({
        title: this.editableSong.title,
      });
    }

    if (changes['artists']) {
      this.isLoading = false;
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.songForm.controls;
  }

  addUpdateSong() {
    this.submitted = true;
    if (this.songForm.invalid) {
      return;
    }
    // const song: CreateSongRequest = {
    //   data: {
    //     title: this.f['title'].value,
    //     lyrics: this.f['lyrics'].value,
    //     typed_by: this.f['typedBy'].value,
    //     albumid: this.f['album'].value,
    //     artistid: this.f['artist'].value,
    //     producerid: this.f['producer'].value,
    //     language_iso: this.f['language'].value,
    //   },
    // };

    const formData = {
      data: {
        title: this.f['title'].value,
        lyrics: this.f['lyrics'].value,
        typed_by: this.f['typedBy'].value,
        albumid: this.f['album'].value.id,
        artistid: this.f['artist'].value.id,
        producerid: this.f['producer'].value.id,
        language_iso: this.f['language'].value.label,
        creation_time: null, // Optional, set to null
        update_time: null // Optional, set to null
        , tags: this.f['tags'].value, feature: this.f['feature'].value,
        explicit: this.f['explicit'].value,
      }

    };

    console.log(formData)
    this.isLoading = true;
    this.songService.addSong(formData).subscribe((result) => {
      this.isLoading = false;
      this.onCancel()
    });


  }

  onReset() {
    this.songForm.reset({
      // album: ['', Validators.required],
      releaseDate: ['', Validators.required],
      // artist: ['', Validators.required],
      unitsSold: [''],
      recordLabel: ['']
    });
  }

  onCancel() {
    var url = "/dashboard/database/songs";
    if (this.isContributions) {
      url = "/dashboard/contributions/songs";
    }
    this.router.navigateByUrl(url, { skipLocationChange: true, state: { isContributions: this.isContributions } }
    );
  }
}
