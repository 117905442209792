import { Component, EventEmitter, Input, Output, SimpleChange } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { CreateStudentRequest } from 'src/app/models/students/create-student-request.model';
import { Student } from 'src/app/models/students/student.model';
import { UpdateStudentRequest } from 'src/app/models/students/update-student-request.model';
import { StudentsService } from '../students.service';
import { Router } from '@angular/router';

@Component({
  selector: 'rap-add-edit-students-ui',
  templateUrl: './add-edit-students.component.html',
  styleUrls: ['./add-edit-students.component.scss'],
})
export class AddEditStudentsComponent {
  @Input() editableStudent: Student;
  @Input() errorMessage: string;
  @Output() createNewStudentEmitter = new EventEmitter<CreateStudentRequest>();
  @Input() rosterId: string
  @Input() studentId: string

  @Output() updateStudentEmitter = new EventEmitter<UpdateStudentRequest>();
  studentForm: FormGroup;
  submitted: boolean = false;
  isLoading = false;
  isNew = false;
  btnTitle = "Save"
  constructor(private formBuilder: FormBuilder, private loaderService: AppService, private studentService: StudentsService, private router: Router,) {
    this.loaderService.loading$.subscribe(isLoading => {
      console.log("isLoading")
      console.log(isLoading)
      this.isLoading = isLoading;
    });
    this.studentForm = this.formBuilder.group({
      studentName: [null, [Validators.required]],
      studentCode: [this.makeId(7), [Validators.required]],
    });
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChange) {
    console.log(this.studentId);
    if (changes['editableStudent'].currentValue && this.studentId != null) {
      console.log(this.studentId);
      this.isLoading = false;

      if (this.editableStudent == null) {
        // this.isNew = true;
        this.isLoading = false;
      } else {
        this.isNew = true;
        this.btnTitle = "Update"
        console.log(this.editableStudent.studentCode)
        console.log(this.editableStudent.userName)
        this.isLoading = false;
        this.studentForm.patchValue({
          studentName: this.editableStudent.firstName,
          studentCode: this.editableStudent.studentCode,

        });
      }
    } else {
      this.isNew = false;
    }

  }

  get f(): { [key: string]: AbstractControl } {
    return this.studentForm.controls;
  }

  makeId(length: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  addUpdateStudent() {
    console.log(this.studentForm.valid)
    this.submitted = true;
    if (this.studentForm.valid) {
      if (this.studentId == null) {
        console.log("add")
        this.addStudent();
      } else {
        this.updateStudent();
      }
    }
  }

  updateStudent() {
    this.submitted = true;
    let studentRequest: UpdateStudentRequest = {
      studentCode: this.editableStudent.id,
      name: this.studentForm.value.studentName,
      forceCleanAudio: this.studentForm.value.cleanAudioOnly,
    };
    // this.updateStudentEmitter.emit(studentRequest);
    this.isLoading = true;
    this.studentService.updateStudents(this.rosterId, this.editableStudent.id, studentRequest).subscribe((result) => {
      this.isLoading = false;
      this.router.navigate(['/dashboard/rosters']);
    });
  }
  addStudent() {
    let studentRequest: CreateStudentRequest = {
      code: this.studentForm.value.studentCode,
      firstName: this.studentForm.value.studentName,
      userName: this.studentForm.value.studentName,
    };
    this.createNewStudentEmitter.emit(studentRequest);
  }

  ngOnDestroy(): void {
    this.isLoading = false;

    this.editableStudent = null;
    console.log(this.editableStudent)
    this.studentForm.patchValue({
      studentName: '',
      studentCode: '',

    });
  }
}
