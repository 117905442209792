<rap-breadcrumb-default [exploreLabel]="'Rap Almanac'" [exploreLink]="'/dashboard/explore'"
  [exploreImage]="'../assets/images/filled.svg'" [rostersLabel]="'Roles'"
  (rostersClick)="onRoles()"></rap-breadcrumb-default>

<div class="section-body">
  <rap-spinner class="loader-overlay" *ngIf="isLoading"></rap-spinner>
  <div class="section-title">Roles</div>
  <rap-rect-primary [label]="'Add New'" [btnClass]="'btn btn-dark'"
    (clickEvent)="createRoleEmitter.emit()"></rap-rect-primary>
</div>

<rap-search-input [(searchValue)]="filters.search" (search)="getSearchResults($event)"
  (clear)="onClearSearch()"></rap-search-input>

<div style="padding: 0px 10px;">
  <ng-container *ngIf="roles?.length > 0">
    <div class="custom-container">
      <table class="table rounded-4 overflow-hidden">
        <thead>
          <tr>
            <!-- <th scope="col">Name</th> -->
            <th scope="col">
              <div class="sortable">
                <div (click)="onSort({ column: 'name', direction: sortDirection })">Name</div>
                <rap-sort-arrows [column]="'name'" [sortedColumn]="sortedColumn" [sortDirection]="sortDirection"
                  (sort)="sortBy($event)">
                </rap-sort-arrows>
              </div>
            </th>
            <!-- <th scope="col">Permissions</th> -->
            <th scope="col">
              <div class="sortable">
                <div (click)="onSort({ column: 'permissions', direction: sortDirection })">PERMISSIONS</div>
                <rap-sort-arrows [column]="'permissions'" [sortedColumn]="sortedColumn" [sortDirection]="sortDirection"
                  (sort)="sortBy($event)">
                </rap-sort-arrows>
              </div>
            </th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let role of paginatedRoles">
            <td scope="row">{{ role?.name }}</td>
            <td scope="row">
              <div *ngFor="let permission of role.permissions">
                <div class="permissions-label" (click)="onRoles()">{{ permission.name }}</div>
              </div>
            </td>
            <td>
              <rap-rect-primary [label]="'Edit'" [btnClass]="'btn btn-light rect-secondary-btn'"
                (clickEvent)="editRole(role)"></rap-rect-primary>
              <rap-rect-primary [label]="'Delete'" [btnClass]="'btn btn-dark rect-primary-btn'"
                (clickEvent)="deleteRole(role.id)"></rap-rect-primary>

            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="pagination">
      <rap-pagination [collectionSize]="roles?.length" [page]="page" [pageSize]="pageSize"
        (onPageChange)="onPageChange($event)"></rap-pagination>
    </div>
  </ng-container>
</div>

<rap-no-data [data]="roles" [isLoading]="isLoading" [filters]="filters" [message]="'No roles found'"></rap-no-data>