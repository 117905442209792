import { Function } from 'src/app/models/functions/functions.model';

export interface FunctionsState {
  functions: Function[];
  editableFunction: Function;
  errorMessage: string;
}

export const initialFunctionsState: FunctionsState = {
  functions: null,
  editableFunction: null,
  errorMessage: null,
};
