import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'rap-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent {
  pwdResetCodeSent: boolean = true;
  pwdUpdateSubmitted: boolean = false;
  confirmPwdUpdateSubmitted: boolean = false;
  passwordHelpForm: FormGroup;
  error: String;
  fieldTextType: boolean;
  fieldTextType1: boolean;
  isLoading: boolean = false;

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleFieldTextType1() {
    this.fieldTextType1 = !this.fieldTextType; 1
  }
  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private authService: AuthService, private userService: UserService) {
    this.passwordHelpForm = this.fb.group({
      email: new FormControl(this.authService.getUserEmail()),
      code: new FormControl(null),
      password: new FormControl(null, [Validators.required, Validators.minLength(8)]),
      confirmPassword: new FormControl(null, [Validators.required, Validators.minLength(8)]),
    }, { validators: this.passwordMatchValidator });
  }


  ngOnInit() {
    this.passwordHelpForm.get('code').setValidators(Validators.required);
    this.passwordHelpForm
      .get('password')
      .setValidators(Validators.required);
    this.passwordHelpForm
      .get('confirmPassword')
      .setValidators(Validators.required);

  }

  get password() {
    return this.passwordHelpForm.get('password');
  }

  get confirmPassword() {
    return this.passwordHelpForm.get('confirmPassword');
  }

  passwordMatchValidator(formGroup: FormGroup) {
    const newPassword = formGroup.get('password');
    const confirmPassword = formGroup.get('confirmPassword');

    if (newPassword && confirmPassword && newPassword.value !== confirmPassword.value) {
      confirmPassword.setErrors({ mismatch: true });
    } else {
      confirmPassword.setErrors(null);
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.passwordHelpForm.controls;
  }

  onSubmit() {
    if (this.passwordHelpForm.valid) {
      this.isLoading = true;
      // { code: '1234', password: '1234', confirmPassword: '1234' }
      console.log(this.passwordHelpForm.value)
      console.log('Form is  valid');
      this.userService.updatePassword(this.passwordHelpForm.value).subscribe((result) => {
        console.log(result)
        this.isLoading = false;
        this.activeModal.close(true);
      });
    } else {
      console.log('Form is not valid');
    }
  }
}
